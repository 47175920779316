import './index.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../store';
import { useUiState } from '../../context/hooks';
import { useToggleSearchFiltersModal } from '../../context/hooks';
import { Box } from '@mui/material';
import { isDatagrip } from '../../utils/checkOrigin';
import { useSearchAndFilterState } from '../../store/searchAndFilter/selector';
import { SearchActions } from '../../store/searchAndFilter';
import { defaultSearchFilter } from './utils/data';
import { DateQueriedFilter } from './components/dateQueried';
import { QueriedByFilter } from './components/queriedBy';
import { TagsFilter } from './components/tags';
import { RulesFilter } from './components/rulesFilter';
import { FilterActionButtons } from './components/actionButtons';
import { useInitTags } from '../../lib/hooks/useInitTags';
import { useNavigateToSearchSubPage } from '../../lib/hooks/search/useNavigateToSearchSubPage';
import { SourceFilter } from './components/source';
import { SelectedFolder } from './components/selectedFolder';

const SearchFilterComponent = () => {
  const dispatch = useAppDispatch();
  const { searchFiltersVisible } = useUiState();
  const { tags } = useInitTags();
  const navigateToSearchSubTab = useNavigateToSearchSubPage();
  const divRef = useRef(null);

  const [showRules, setShowRules] = useState(false);
  const searchAndFilters = useSearchAndFilterState();
  const [localFilters, setLocalSearchFilters] = useState(defaultSearchFilter);

  const toggleSearchFilters = useToggleSearchFiltersModal()!;

  const handleOnChangeRuleField = (e: any) => {
    const field = e.target.value;
    setLocalSearchFilters(prev => ({ ...prev, rule: { ...prev.rule, field } }));
  };
  const handleOnChangeRuleAccessibility = (e: any) => {
    const value = e.target.value;
    setLocalSearchFilters(prev => ({
      ...prev,
      rule: { ...prev.rule, accessibility: value }
    }));
  };
  const handleOnChangeRuleValue = (e: any) => {
    const value = e.target.value;
    setLocalSearchFilters(prev => ({
      ...prev,
      rule: {
        query: value,
        field: prev.rule?.field ?? 'query',
        accessibility: prev.rule?.accessibility ?? 'contains'
      }
    }));
  };

  const handleResetFilter = () => {
    setShowRules(false);
    setLocalSearchFilters({
      ...defaultSearchFilter,
      type: localFilters.type,
      q: searchAndFilters.q
    });
  };
  const cancelSearch = () => {
    toggleSearchFilters();
  };

  const prepareSearchFilter = () => {
    let searchFilterData = {
      ...localFilters,
      q: searchAndFilters.q,
      rule: showRules && localFilters.rule?.query ? localFilters.rule : null
    };
    dispatch(SearchActions.setFilters(searchFilterData))
  };

  const submitSearch = () => {
    prepareSearchFilter();
    toggleSearchFilters();
    const searchType = localFilters.in_folder == '-1' ? searchAndFilters.type : 'saved'
    navigateToSearchSubTab(searchType);
  };

  const handleOnClickTagChip = e => {
    const $tags = localFilters.tags.filter(tag => tag.name !== e.name);
    setLocalSearchFilters(state => ({ ...state, tags: $tags }));
  };

  const handleOnClickQueriedByChip = (value: string) => {
    const queriedBy = localFilters.last_queried_by.filter(usr => usr !== value);
    setLocalSearchFilters(state => ({ ...state, last_queried_by: queriedBy }));
  };

  const handleOnChangeTags = (e: any) => {
    setLocalSearchFilters(state => ({
      ...state,
      tags: e.target.value
    }));
  };

  const handleOnChangeSource = (e: any) => {
    setLocalSearchFilters(state => ({
      ...state,
      source: e.target.value
    }));
  };

  const handleOnChangeQueriedIn = e => {
    setLocalSearchFilters(state => ({ ...state, queried_in: e.target.value }));
  };

  const handleOnChangeInFolder = uid => {
    setLocalSearchFilters(state => ({ ...state, in_folder: uid }));
  };

  const handleOnChangeQueriedBy = e => {
    const { value } = e.target;
    const newValue = value[value.length - 1];
    if (newValue === 'anyone') {
      // to clear members when user selects anyone
      return setLocalSearchFilters(state => ({
        ...state,
        last_queried_by: ['anyone']
      }));
    }
    if (value.includes('anyone')) {
      // to clear anyone if user selects any other users
      const last_queried_by = e.target.value.filter(
        filter => filter !== 'anyone'
      );
      return setLocalSearchFilters(state => ({
        ...state,
        last_queried_by
      }));
    }
    setLocalSearchFilters(state => ({
      ...state,
      last_queried_by: e.target.value
    }));
  };

  const handleEnterKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      cancelSearch()
    }
  };
  const handleOnChangeRuleVisibility = () => {
    setShowRules(p => {
      if (!p) {
        setLocalSearchFilters(p => ({
          ...p,
          rule: { ...p.rule, accessibility: 'contains', field: 'query' }
        }));
      }
      return !p;
    });
  };

  useEffect(() => {
    // Focus the div when the component mounts
    if (divRef.current) {
      divRef.current.focus();
    }
  }, [searchFiltersVisible]);

  useEffect(() => {
    setLocalSearchFilters(state => ({ ...state, in_folder: searchAndFilters.in_folder }))
  }, [searchAndFilters])

  if (searchFiltersVisible) {
    return (
      <div className={`filter-backdrop`} onKeyDown={handleEnterKeyDown} tabIndex={0} ref={divRef}>
        <Box
          className="search-query-container"
          sx={{ background: isDatagrip() ? '#424242' : 'white' }}
        >
          <DateQueriedFilter
            value={localFilters.queried_in}
            onChange={handleOnChangeQueriedIn}
          />
          <SelectedFolder
            value={localFilters.in_folder}
            onChange={handleOnChangeInFolder}
          />
          <QueriedByFilter
            value={localFilters.last_queried_by}
            onChange={handleOnChangeQueriedBy}
            onClickSelectedChip={handleOnClickQueriedByChip}
          />
          <TagsFilter
            options={tags}
            value={localFilters.tags}
            onChange={handleOnChangeTags}
            onClickSelectedValueChip={handleOnClickTagChip}
          />
          <RulesFilter
            value={localFilters.rule}
            showRules={showRules}
            onChangeRuleVisibility={handleOnChangeRuleVisibility}
            onChangeRuleAccessibility={handleOnChangeRuleAccessibility}
            onChangeRuleField={handleOnChangeRuleField}
            onChangeRuleValue={handleOnChangeRuleValue}
          />
          <SourceFilter
            value={localFilters.source}
            onChange={handleOnChangeSource}
          />
          <FilterActionButtons
            onCancelSearch={cancelSearch}
            onResetFilters={handleResetFilter}
            onSubmitSearch={submitSearch}
          />
        </Box>
      </div>
    );
  }

  return null;
};

export default SearchFilterComponent;
