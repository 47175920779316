import React from 'react';
import { Typography, BoxProps, TypographyProps } from '@mui/material';
import { EmptyBox } from './components';

interface Props {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  labelProps?: TypographyProps;
  children?: string;
}

export default ({ children, containerProps, labelProps }: Props) => {
  return (
    <EmptyBox {...containerProps}>
      <Typography variant="caption" {...labelProps}>
        {children}
      </Typography>
    </EmptyBox>
  );
};
