import { DiscoveryType } from '../types';

export const buildAssetChooseMessage = (type: DiscoveryType) => {
  switch (type) {
    case 'table':
    case 'field':
    case 'schema':
    case 'dashboard':
    case 'query':
      return `I'm looking for a ${type}`;
    case 'join':
      return `I'm looking for how to join between tables`;
    case 'calculated-field':
      return `I'm looking for a calculated field`;
    case 'partition':
      return `I'm looking for a field`;
    case 'where':
      return `I'm looking for where filters`;
  }
};
