import { BotSearchInputBox } from '../../pages/Watson/styles';
import { useSendBotMessage } from '../hooks/useSendBotMessage';
import { AiBotInput } from './components/Input';
import { useAiBotInputProps } from './hooks/useAiBotInputProps';

export const BotSearchInputManager = () => {
  const sendMessage = useSendBotMessage();
  const { hidden, placeholder } = useAiBotInputProps();
  const handleOnSendMessage = (value: string) => {
    sendMessage(value);
  };

  if (hidden) return null;
  return (
    <BotSearchInputBox>
      <AiBotInput placeholder={placeholder} submit={handleOnSendMessage} />
    </BotSearchInputBox>
  );
};
