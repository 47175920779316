import { styled, TypographyProps, Typography } from '@mui/material';
export const NotAuthorizedBox = styled('div')(() => ({
  flex: 1,
  alignItems: 'center',
  justifyItems: 'center',
  display: 'flex',
  flexDirection: 'column'
}));

export const HeaderBox = styled('header')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 18px',
  boxSizing: 'border-box',
  height: '48px'
}));

export const ContentSectionBox = styled('section')(
  () => `
position: relative;
display: flex;
flex: 1;
width: 100%;
height: 100%;
box-sizing: border-box;
flex-direction: column;
padding-top: 16px;
`
);

export const MessagingContainer = styled('div')(
  () => `
position: relative;
height: calc(100vh - 220px);
padding: 12px 0px;
overflow: hidden;
`
);

export const MessagesBox = styled('div')(
  () => `
position: relative;
row-gap: 24px;
padding: 0 28px;
padding-bottom: 24px;
display: flex;
flex-direction: column;
box-sizing: border-box;
width: calc(100vw - 64px);
overflow-y: auto;
overflow-x: hidden;
height: 100%;
& > :first-child {
  margin-top: auto;
}
`
);

export const BotTypeDescLabel = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(
  ({
    theme: {
      palette: { mode }
    }
  }) => {
    const isDark = mode === 'dark';
    return {
      color: isDark ? '#ffffff80' : '#808080'
    };
  }
);

export const IntroBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start'
}));

export const IntroMessageBox = styled('div')(
  () => `
flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
`
);

export const IntroHeading = styled((props: TypographyProps) => (
  <Typography {...props} />
))(
  () => `

`
);

export const IntroLabel = styled((props: TypographyProps) => (
  <Typography {...props} />
))(
  () => `
  margin: 12px 0;
  font-size: initial;
`
);

export const IntroPromptsBox = styled('div')(() => {
  return `
display: flex;
flex-direction: column;
align-items: flex-start;
row-gap: 8px;
  `;
});
