import DiscoveryCustomMessage from '../../../pages/Watson/components/DiscoveryCustomMessage';
import DiscoveryData from '../../../pages/Watson/components/DiscoveryData';
import { Filters } from '../../../pages/Watson/components/filters/Filters';
import { JoinMessage } from '../../../pages/Watson/components/join/JoinMessage';
import { JoinResponse } from '../../../pages/Watson/components/join/JoinResponse';
import { WhereMessage } from '../../../pages/Watson/components/where/WhereMessage';
import { WhereResponse } from '../../../pages/Watson/components/where/WhereResponse';
import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../hooks/useAiBotVariantMeta';
import { AiBotResponseMessage } from '../../ui/messenger/AiBotResponseMessage';
import {
  isBotsonDiscoveryAssetFilterMessage,
  isBotsonDiscoveryCustomMessage,
  isBotsonDiscoveryMessage,
  isBotsonDiscoveryMessageJoin,
  isBotsonDiscoveryMessageJoinPick,
  isBotsonDiscoveryMessageWhere,
  isBotsonDiscoveryMessageWherePick,
  isBotsonErrorMessage
} from './utils/typeGuards';
import { ErrorMessage } from '../../ui/messenger/ErrorMessage';

interface Props {
  data: any;
}

export const DiscoveryBotMessageAdapter = ({ data }: Props) => {
  const { sessionId: conversationId } = useAiBotMeta();
  const { discoveryType } = useAiBotVariantMeta();

  if (isBotsonErrorMessage(data)) {
    return <ErrorMessage />;
  }

  if (isBotsonDiscoveryMessageJoinPick(data, discoveryType)) {
    return <JoinMessage filters={data.discoveryFilters} />;
  }

  if (isBotsonDiscoveryMessageJoin(data, discoveryType)) {
    return (
      <JoinResponse
        results={data.discoveryAssets}
        filters={data.discoveryFilters}
      />
    );
  }

  if (isBotsonDiscoveryMessageWherePick(data, discoveryType)) {
    return <WhereMessage filters={data.discoveryFilters} />;
  }

  if (isBotsonDiscoveryMessageWhere(data, discoveryType)) {
    return (
      <WhereResponse
        results={data.discoveryAssets}
        filters={data.discoveryFilters}
      />
    );
  }
  if (
    isBotsonDiscoveryAssetFilterMessage({
      ...data,
      discoveryType
    })
  ) {
    return (
      <Filters
        filters={data.discoveryFilters}
        type={discoveryType}
        showFiltersOnMount={data.showFiltersOnMount}
        summary={data.discoverySummary}
        conversationId={conversationId}
      />
    );
  }
  if (isBotsonDiscoveryMessage(data)) {
    return <DiscoveryData data={data} />;
  }
  if (isBotsonDiscoveryCustomMessage(data)) {
    return <DiscoveryCustomMessage children={data.children} />;
  }

  return <AiBotResponseMessage data={data} />;
};
