import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentCopyRounded, BookmarkAddOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import Empty from '../DiscoveryData/empty';
import { ICustomMessageComponent } from '../../interfaces';
import { DiscoveryDataFeedback } from '../customMessages';
import { useWatsonMessages } from '../../hooks/useWatsonMessages';
import { useWatsonMeta } from '../../hooks/useWatsonMeta';
import { ConditionComponent } from '../../../../lib/utils';
import { FeedbackState } from '../LikeDislikeForAssets';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useAppDispatch } from '../../../../store';
import { useFolderSummarySelector } from '../../../../store/folderSummary/selectors';
import { queriesAsyncThunk } from '../../../../store/queries/queries.thunk';
import { NewCreateQueryPayload } from '../../../../store/queries/types';
import { localTime } from '../../../Editor/helpers/helpers';
import { addShortcut } from '../../../Editor/api/fetch';
import { useDiscoveryBotPhaseResponseActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryBotPhaseResponseActions';
import { useAiBotMeta } from '../../../../Botson/hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../../../Botson/hooks/useAiBotVariantMeta';
import { useAiBotEntryMessage } from '../../../../Botson/hooks/useAiBotEntryMessage';

const Content = ({ results, filters }) => {
  const [showCopySuccessIndex, setShowCopySuccessIndex] = useState(null);
  const { displayDataFeedback } = useDiscoveryBotPhaseResponseActions();
  const { sessionId: conversation_uid } = useAiBotMeta();
  const { discoveryType } = useAiBotVariantMeta();
  const messages = useAiBotEntryMessage();

  const [loadingBookmarks, setLoadingBookmarks] = useState(() => {
    const initialState = {};
    results.forEach((_, index) => {
      initialState[index] = false;
    });
    return initialState;
  });
  const [bookmarks, setBookmarks] = useState(() => {
    const initialState = {};
    results.forEach((_, index) => {
      initialState[index] = false;
    });
    return initialState;
  });
  const handleBookmarkClick = async (index, query) => {
    setLoadingBookmarks(prevLoading => ({ ...prevLoading, [index]: true }));
    const res = await saveQuery(String(query).replace(/\n$/, ''));
    if (res) {
      setBookmarks(prevBookmarks => ({
        ...prevBookmarks,
        [index]: true
      }));
    }
    setLoadingBookmarks(prevLoading => ({ ...prevLoading, [index]: false }));
  };
  const [feedbackState, setFeedbackState] = useState<FeedbackState>('none');
  const likeActive = feedbackState === 'liked' ? 'active-liked' : '';
  const unlikeActive = feedbackState === 'disliked' ? 'active-disliked' : '';
  const disabled = Boolean(likeActive || unlikeActive);
  const handleCopy = (index: number) => {
    setShowCopySuccessIndex(index);
    setTimeout(() => {
      setShowCopySuccessIndex(null);
    }, 1500);
  };
  // const messages = useWatsonMessages()[0];
  // const addMessage = useWatsonMessages()[5];
  // const meta = useWatsonMeta()[0];
  // const setMeta = useWatsonMeta()[1];
  // const displayDataFeedback = () => {
  //   const message: ICustomMessageComponent = {
  //     body: '',
  //     variant: 'response',
  //     status: 'success',
  //     type: 'botson',
  //     children: DiscoveryDataFeedback
  //   };
  //   addMessage(message);
  //   setMeta(p => ({
  //     ...p,
  //     discoveryPhase: 'feedback',
  //     discoveryPhasePayload: results
  //   }));
  // };
  // useEffect(() => {
  //   displayDataFeedback();
  // }, []);

  useEffect(() => {
    displayDataFeedback(results);
    MixedPanelEvent.watsonDataTableResults({
      conversation_uid,
      question_type: discoveryType,
      question_message: messages[0].body,
      table_name: filters['table_name'],
      db_name: filters['db_name'],
      answer: results
    });
  }, [results]);

  const dispatch = useAppDispatch();
  const folderList = useFolderSummarySelector();
  const saveQuery = async (code: string) => {
    let fid: string = folderList.list[0].uid;
    let fname: string = folderList.list[0].name;
    folderList.list.map((folder: { name: any; uid: any }) => {
      if (folder.name === 'Default') {
        fid = folder.uid;
        fname = folder.name;
      }
    });
    let requestBody: NewCreateQueryPayload = {
      parent_id: fid,
      folder: undefined,
      query: {
        name: `Botson Where Filter ${localTime(new Date(), true) as string}`,
        description: `Botson Where Filter`,
        query: code
      }
    };
    const res: any = await dispatch(queriesAsyncThunk.create(requestBody));
    if (res?.payload?.query?.uid) {
      addShortcut(res?.payload?.query?.uid);
      MixedPanelEvent.watsonSnippetSaved({
        message: code,
        conversation_uid,
        chat_type: 'botson'
      });
      return true;
    }
    return false;
  };

  return (
    <Box>
      <ConditionComponent condition={results.length > 1}>
        <Box marginBottom={'10px'}>
          <Typography fontFamily="Roboto, Helvetica, Arial, sans-serif">
            Sure, based on your team queries history
            <Typography fontFamily="Roboto, Helvetica, Arial, sans-serif">
              here are the most common where filters on {''}
              <Typography
                component={'span'}
                fontWeight={'bold'}
                fontFamily="Roboto, Helvetica, Arial, sans-serif"
              >
                {filters['table_name']}
              </Typography>
              , sorted by most relevant:
            </Typography>
          </Typography>
        </Box>
      </ConditionComponent>
      {results.map(
        (result: { header: string; query: string }, index: number) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="body1" gutterBottom>
                {result.header}
              </Typography>
              <ConditionComponent condition={result && 'query' in result}>
                <CustomTooltip
                  title={
                    bookmarks[index] ? 'Saved to Sherloq' : 'Save to Sherloq'
                  }
                  placement="top"
                >
                  {loadingBookmarks[index] ? (
                    <CircularProgress size={20} />
                  ) : bookmarks[index] ? (
                    <CheckIcon sx={{ fontSize: '20px', color: '#7209B7' }} />
                  ) : (
                    <BookmarkAddOutlined
                      onClick={() => handleBookmarkClick(index, result.query)}
                      style={{ fontSize: '20px', cursor: 'pointer' }}
                    />
                  )}
                </CustomTooltip>
                <CopyToClipboard
                  text={String(result.query).replace(/\n$/, '')}
                  onCopy={() => handleCopy(index)}
                >
                  <CustomTooltip
                    title={
                      showCopySuccessIndex === index
                        ? 'Copied!'
                        : 'Copy snippet'
                    }
                    placement="top"
                  >
                    {showCopySuccessIndex === index ? (
                      <CheckIcon
                        sx={{
                          fontSize: '22px',
                          visibility: `${
                            showCopySuccessIndex === index
                              ? 'visible'
                              : 'hidden'
                          }`,
                          fill: '#7209B7'
                        }}
                      />
                    ) : (
                      <ContentCopyRounded
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                      />
                    )}
                  </CustomTooltip>
                </CopyToClipboard>
              </ConditionComponent>
            </Box>
            <ConditionComponent condition={result?.query && 'query' in result}>
              <SyntaxHighlighter
                children={result.query}
                style={{ ...atomDark }}
                language={'sql'}
                PreTag="div"
                wrapLongLines={true}
                codeTagProps={{
                  style: {
                    maxWidth: '100%',
                    fontSize: '11px',
                    width: 'inherit',
                    display: 'inline-block',
                    overflowWrap: 'break-word',
                    transition: 'max-height 2s ease'
                  }
                }}
              />
            </ConditionComponent>
          </Box>
        )
      )}
    </Box>
  );
};

interface Props {
  results: any[];
  filters: {};
}
export const WhereResponse = ({ results, filters }: Props) => {
  if (results.length === 0) {
    return <Empty>There are no where filters for the table</Empty>;
  }
  return <Content results={results} filters={filters} />;
};
