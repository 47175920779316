import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ApiClient from '../../lib/utils/apiClient';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { Invitation, User } from './types';

const index = createAsyncThunk('auth/index', () => {
    return localStorage.getItem('auth');
});

const userInfo = createAsyncThunk('accounts/auth/me', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const response = await ApiClient.get<User>(`/accounts/auth/me`, { isProtected: true });
        const userInfo = JSON.stringify(response.data);
        const token = await localStorage.getItem('sherloq-auth-token');

        parent.postMessage(
            {
                sherloq_command_to_ext: {
                    user: {
                        info: userInfo,
                        token: token,
                    },
                },
            },
            '*',
        );

        MixedPanelEvent.identify(response.data);
        localStorage.setItem('sherloq-auth-user', userInfo);
        window.Appcues.identify(
            response.data.uid, // unique, required
            response.data)
        return fulfillWithValue(response.data);
    } catch (error) {
        return rejectWithValue('Error occurred');
    }
});

const userAllowed = createAsyncThunk('accounts/auth/user-allowed', async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
        const response = await ApiClient.get<{ domain_status: number }>(`/accounts/auth/user-allowed`, { isProtected: true });
        return fulfillWithValue(response.data.domain_status);
    } catch (error) {
        return rejectWithValue('Error occurred');
    }
});

const invite = createAsyncThunk(
    'accounts/users/invite',
    async (payload: Invitation, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.post<Invitation>(`/accounts/users/invite`, payload, {
                isProtected: true,
            });
            toast('Invitation was successfully sent', { type: 'success' });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

export const authThunks = { index, userInfo, invite, userAllowed };
