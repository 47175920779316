import ApiClient from '../../../lib/utils/apiClient';

export const getQueryVersions = async (uid: string) => {
    return ApiClient.post(
        'query-versions/versions',
        { query_uid: uid },
        {
            isProtected: true,
        },
    );
};

export const updateQueryVersion = async (query: any) => {
    return ApiClient.post('query-versions/update', query, {
        isProtected: true,
    });
};

export const updateComment = async (req: any) => {
    return ApiClient.post('queries/update/comment', req, {
        isProtected: true,
    });
};

export const updateCommentVersion = async (req: any) => {
    return ApiClient.post('query-versions/update/comment', req, {
        isProtected: true,
    });
};

export const updateQueryVersionName = async (req: { id: number; name: string }) => {
    return ApiClient.path(
        `query-versions/${req.id}`,
        { name: req.name },
        {
            isProtected: true,
        },
    );
};

export const optimizeQueryVersion = async (query: any) => {
    return ApiClient.post(
        'ai/optimize',
        { query },
        {
            isProtected: true,
        },
    );
};

export const documentQueryVersion = async (query: any) => {
    return ApiClient.post(
        'ai/document',
        { query },
        {
            isProtected: true,
        },
    );
};

export const getSavedQuery = async (uid: string) => {
    return ApiClient.get(`queries/${uid}`, {
        isProtected: true,
    });
};
export const getShortcuts = async () => {
    return ApiClient.get(`shortcuts/`, {
        isProtected: true,
    });
};

export const getShortcut = async (query_uid: string) => {
    return ApiClient.get(`shortcuts/${query_uid}`, {
        isProtected: true,
    });
};
export const addShortcut = async (query_uid: string) => {
    return ApiClient.post('shortcuts/', { query_uid }, { isProtected: true });
};

export const deleteShortcut = async (query_uid: string) => {
    return ApiClient.delete(`shortcuts/${query_uid}`, { isProtected: true });
};

