import React from 'react';
import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

const Button = styled(MuiButton)(({ theme }) => {
  const isDark = theme.palette.mode === 'dark';
  const { text, primary } = theme.palette;
  const backgroundColor = isDark ? primary.main : '#7209b730';
  const hoverBgbackgroundColor = isDark ? primary.light : primary.main;
  return {
    color: isDark ? text.primary : 'rgb(114, 9, 183)',
    backgroundColor,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: '8px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '8px',
    borderRadius: '6px',
    flexGrow: 0,
    marginBottom: '2px',
    fontSize: '12px',
    fontWeight: 400,
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: hoverBgbackgroundColor,
      color: '#fff',
      '& svg': {
        fill: '#fff'
      }
    }
  };
});

export const PromptButton = (props: ButtonProps) => {
  return (
    <Button
      disableRipple
      variant="text"
      className="search-prompt-button"
      {...props}
    >
      {props.children}
    </Button>
  );
};
