import React from 'react';

interface UserAddIconProps{
    color: string
}

const UserAddIcon = ({color}: UserAddIconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <path d="M16.65 20.2V20.49C15.17 21.44 13.4 22 11.5 22C9.57002 22 7.77002 21.42 6.27002 20.43V20.2C6.27002 17.87 8.60002 15.97 11.46 15.97C14.33 15.97 16.65 17.87 16.65 20.2Z" fill={color} fill-opacity="0.8"/>
            <path opacity="0.4" d="M21 12.5C21 15.85 19.27 18.79 16.65 20.49V20.2C16.65 17.87 14.33 15.97 11.46 15.97C8.6 15.97 6.27 17.87 6.27 20.2V20.43C3.7 18.73 2 15.82 2 12.5C2 7.25 6.25 3 11.5 3C12.81 3 14.06 3.25999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C20.73 9.92998 21 11.19 21 12.5Z" fill={color} fill-opacity="0.8"/>
            <path d="M21.97 2.33002C21.25 1.51002 20.18 1 19 1C17.88 1 16.86 1.46002 16.13 2.21002C15.71 2.64002 15.39 3.15999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C21.17 8.49998 21.94 7.87 22.42 7.06C22.63 6.72 22.79 6.32999 22.88 5.92999C22.96 5.62999 23 5.32 23 5C23 3.98 22.61 3.04002 21.97 2.33002ZM20.49 5.72998H19.75V6.51001C19.75 6.92001 19.41 7.26001 19 7.26001C18.59 7.26001 18.25 6.92001 18.25 6.51001V5.72998H17.51C17.1 5.72998 16.76 5.38998 16.76 4.97998C16.76 4.56998 17.1 4.22998 17.51 4.22998H18.25V3.52002C18.25 3.11002 18.59 2.77002 19 2.77002C19.41 2.77002 19.75 3.11002 19.75 3.52002V4.22998H20.49C20.9 4.22998 21.24 4.56998 21.24 4.97998C21.24 5.38998 20.91 5.72998 20.49 5.72998Z" fill={color} fill-opacity="0.8"/>
            <path d="M11.4599 14.73C13.0118 14.73 14.2699 13.4719 14.2699 11.92C14.2699 10.3681 13.0118 9.10999 11.4599 9.10999C9.90798 9.10999 8.6499 10.3681 8.6499 11.92C8.6499 13.4719 9.90798 14.73 11.4599 14.73Z" fill={color} fill-opacity="0.8"/>
        </svg>
    );
};

export default UserAddIcon;
