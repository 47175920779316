import React from 'react';

interface BigQueryIconProps {
    size: string;
}

const BigQueryIcon = ({ size }: BigQueryIconProps) => {
    return (
        <svg height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd">
                <path d="m6.73 10.83v2.63a4.91 4.91 0 0 0 1.71 1.74v-4.37z" fill="#aecbfa" />
                <path
                    d="m9.89 8.41v7.53a7.62 7.62 0 0 0 1.11.06 8 8 0 0 0 1 0v-7.59z"
                    fill="#669df6"
                />
                <path d="m13.64 11.86v3.29a5 5 0 0 0 1.7-1.82v-1.47z" fill="#aecbfa" />
                <path
                    d="m17.74 16.32-1.42 1.42a.42.42 0 0 0 0 .6l3.54 3.54a.42.42 0 0 0 .59 0l1.43-1.43a.42.42 0 0 0 0-.59l-3.54-3.54a.42.42 0 0 0 -.6 0"
                    fill="#4285f4"
                />
                <path
                    d="m11 2a9 9 0 1 0 9 9 9 9 0 0 0 -9-9m0 15.69a6.68 6.68 0 1 1 6.69-6.69 6.68 6.68 0 0 1 -6.69 6.69"
                    fill="#669df6"
                />
            </g>
        </svg>
    );
};

export default BigQueryIcon;
