import React from 'react';
import { Button, useTheme, ButtonProps } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';

export const ScrollToBottomButton = (props: ButtonProps) => {
  const {
    palette: { mode },
    shadow
  } = useTheme();
  const isDark = mode === 'dark';

  return (
    <Button
      {...props}
      sx={{
        position: 'fixed',
        bottom: '84px',
        width: '30px',
        height: '30px',
        borderRadius: '20px',
        boxShadow: shadow.ring,
        zIndex: 9990,
        minWidth: 0,
        right: '18px',
        backgroundColor: isDark ? 'white' : 'background.paper',
        border: '1px solid #a9a9a930',
        ':hover': {
          backgroundColor: isDark ? 'primary.light' : 'secondary.light'
        }
      }}
    >
      <SouthIcon
        sx={{
          fontSize: '16px',
          color: isDark ? 'primary.main' : 'secondary.main'
        }}
      />
    </Button>
  );
};
