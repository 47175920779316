import { LocalStorage } from '../utils/LocalStorage';

const getUser = () => {
    const user = LocalStorage.get('sherloq-auth-user');
    return user;
};
/**
 * Exposes Redux Persists data in local storage
 */
export const PersistedGlobalState = {
    getUser,
} as const;
