import { ConditionComponent } from '../../../lib/utils';
import { ShowMoreBtn } from '../../../pages/Watson/components/DiscoveryData/components';
import { formatDate } from '../../../pages/Watson/components/DiscoveryData/filterTableDataRows';
import {
  DefaultTableContentContainer,
  TableDataContainer,
  TableDataTitle,
  TableDataValue
} from './components';
export const AssetDataContentRow = ({ data, type, setDataForModal }) => {
  const users = data.all_users
    ?.filter(user => user.username !== '' && user.username !== null)
    .map(user => user.username);
  const handleShowMore = (event: any) => {
    event.stopPropagation();
    setDataForModal(users);
  };

  return (
    <DefaultTableContentContainer>
      <ConditionComponent condition={type === 'field'}>
        <TableDataContainer>
          <TableDataTitle>Scheme</TableDataTitle>
          <TableDataValue>{data.db_name}</TableDataValue>
        </TableDataContainer>
      </ConditionComponent>
      <TableDataContainer>
        <TableDataTitle>Monthly references</TableDataTitle>
        <TableDataValue>{data.num_of_references ?? '-'}</TableDataValue>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Top queried by</TableDataTitle>
        <TableDataValue>{users[0] ?? '-'}</TableDataValue>
        <ConditionComponent condition={users && users.length > 1}>
          <ShowMoreBtn onClick={handleShowMore} variant="text">
            show more
          </ShowMoreBtn>
        </ConditionComponent>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Last queried</TableDataTitle>
        <TableDataValue>{`${formatDate(data.last_reference_datetime)} by ${
          data.last_username
        }`}</TableDataValue>
      </TableDataContainer>
      <ConditionComponent condition={data.asset_owner}>
        <TableDataContainer>
          <TableDataTitle>Owner</TableDataTitle>
          <TableDataValue>{data.asset_owner ?? '-'}</TableDataValue>
        </TableDataContainer>
      </ConditionComponent>
    </DefaultTableContentContainer>
  );
};
