export default class WebAccessibleEvents {
    public static GET_SELECTED_TEXT = 'get_selected_text';
    public static DISPATCH_SELECTED_TEXT = 'dispatched_selected_text';
    public static GET_FULL_TEXT = 'get_full_text';
    public static DISPATCH_FULL_TEXT = 'dispatch_full_text';
    public static GET_FULL_AND_SELECTED_TEXT = 'get_both_full_and_selected_text';
    public static DISPATCH_FULL_AND_SELECTED_TEXT = 'dispatch_both_full_and_selected_text';
    public static CREATE_NEW_QUERY = 'create_new_query';
    public static DISPATCH_CREATE_NEW_QUERY = 'dispatch_create_new_query';
    public static CREATE_TAB_LISTENER = 'create_tab_listener';
    public static DISPATCH_CREATE_TAB_LISTENER = 'dispatch_create_tab_listener';
    public static GET_FULL_AND_SELECTED_SAVE_TEXT = 'get_full_and_selected_save_text';
    public static DISPATCH_FULL_AND_SELECTED_SAVE_TEXT = 'dispatch_full_and_selected_save_text';
    public static DISPATCH_TOGGLE_FRAME_WITH_REDIRECT = 'dispatch_toggle_frame_with_redirect';
    public static DISPATCH_NAVIGATE_TO = 'dispatch_navigate_to';
    public static GET_SEARCH_QUERY_TEXT = 'get_search_query_text';
    public static DISPATCH_SEARCH_QUERY_TEXT = 'dispatch_search_query_text';
}
