import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import InviteFeedbackModal from '../../../components/modals/InviteFeedbackModal';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { IWatsonGeneratedQueryResponseAsset } from '../interfaces';

interface LikeDislikeCopyForQueryProps {
  message: string; 
  asset?: IWatsonGeneratedQueryResponseAsset;
}
export type FeedbackState = 'liked' | 'disliked' | 'none';

const LikeDislikeCopyForQuery = ({
  message, asset
}: LikeDislikeCopyForQueryProps) => {
  const [text, setText] = useState('');
  const [feedbackState, setFeedbackState] = useState<FeedbackState>('none');
  const [dislikeModal, setDislikeModal] = useState(false);
  const likeActive = feedbackState === 'liked' ? 'active-liked' : '';
  const unlikeActive = feedbackState === 'disliked' ? 'active-disliked' : '';
  const disabled = Boolean(likeActive || unlikeActive);
  
  const LikeClicked = () => {
    MixedPanelEvent.watsonAssetFeedbackLike({
      asset_name: asset.name,
      asset_type: asset.type,
      message: message,
      chat_type: 'none'
    });
    setFeedbackState('liked');
  };

  const DislikeClicked = () => {
    setDislikeModal(true);
    MixedPanelEvent.watsonAssetFeedbackDislike({
      asset_name: asset.name,
      asset_type: asset.type,
      message: message,
      chat_type: 'none'
    });
    setFeedbackState('disliked');
  };

  const handleSubmitFeedback = () => {
    setDislikeModal(false);
    MixedPanelEvent.watsonAssetFeedbackDislikeInfo({
      asset_name: asset.name,
      asset_type: asset.type,
      message: message,
      chat_type: 'none',
      text
    });
  };

  return (
    <Box sx={{alignSelf:'flex-end'}}>
      <Button
        disabled={disabled}
        className={`feedback-asset-button`}
        onClick={LikeClicked}
      >
        <ThumbUpIcon
          className={`icon ${likeActive}`}
          color={'success'}
        />
      </Button>
      <Button
        disabled={disabled}
        className={`feedback-asset-button`}
        onClick={DislikeClicked}
      >
        <ThumbDownIcon
          color={'error'}
          className={`icon ${unlikeActive}`}
        />
      </Button>
      <InviteFeedbackModal
        title="Help us improve"
        subTitle="What table or data asset are relevant instead of that table?"
        show={dislikeModal}
        setClose={() => setDislikeModal(false)}
        handlerSubmit={handleSubmitFeedback}
        text={text}
        setText={e => setText(e.target.value)}
        placeholder="Enter Table name"
        showCancel={false}
        saveButtonText={'Send'}
        rows={3}
      />
    </Box>
  );
};

export default LikeDislikeCopyForQuery;
