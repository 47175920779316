import React, { useState } from 'react';
import { Box } from '@mui/material';
import ShowMoreDataModal from '../../../../components/modals/ShowMoreDataModal';
import { ShowMoreBtn } from './components';
import SearchResultsFolderQuery from '../../../SearchResults/components/SearchResultsFolderQuery';

interface Props {
  data: Record<string, any>[];
}

const TrimDataLimit = 5;

const HistoryQueries = ({ data }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [dataForModal, setDataForModal] = useState<Record<string, any>[]>([]);
  const [dataType, setDataType] = useState('');
  const [visibleCount, setVisibleCount] = useState(TrimDataLimit);

  const handleShowMore = () => {
    setVisibleCount(prevVisibleCount => prevVisibleCount + TrimDataLimit);
  };

  const handleOpenModal = (dataItem: Record<string, any>) => {
    setDataForModal([dataItem]); // Assuming you want to show details of a single item
    setDataType('query'); // Set the type of data you're showing in the modal
    setShowModal(true);
  };

  return (
    <Box>
      {data.slice(0, visibleCount).map(query => (
        <Box key={query?.query_id}>
          <SearchResultsFolderQuery
            data={{
              query: query?.query,
              query_uid: query?.query_id,
              query_name: 'History Query',
              query_origin: query?.origin,
              query_created_at: query?.created_at,
              query_description: '',
              query_owner_username: `${query?.created_by}`,
              folderName: '',
              folderId: '',
              type: 'history',
              pageType: 'query'
            }}
            key={query?.query_id}
            forcedState={'list'}
            disableOnClick={true}
          />
        </Box>
      ))}
      {data.length > visibleCount && (
        <ShowMoreBtn onClick={handleShowMore}>Show More</ShowMoreBtn>
      )}
      <ShowMoreDataModal
        show={showModal}
        data={dataForModal}
        setClose={() => setShowModal(false)}
        dataType={dataType}
      />
    </Box>
  );
};

export default HistoryQueries;
