import { useEffect } from 'react';
import { useUserAuthenticatedState } from '../../store/auth/selectors';
import { teamMemberThunk } from '../../store/teamMembers/thunk';
import { useAppDispatch } from '../../store';

/**
 * Hook to fetch user resources after login; E.g: teammembers, etc
 */
export const useInitAuthenticatedUserResources = () => {
  const isLoggedIn = useUserAuthenticatedState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(teamMemberThunk.index());
    }
  }, [isLoggedIn]);
};
