import { IRoutingRule } from '../../lib/utils/interfaces';
import { useUserInfoSelector } from '../../store/auth/selectors';

export const useSimplyRoutingIntegration = (): IRoutingRule => {
  const user = useUserInfoSelector();
  const authorize = () => {
    if (user)
      return [
        'nadav@sherloqdata.io',
        'anthony@sherloqdata.io',
        'hello@sherloqdata.com',
        'hello@sherloqdata.io',
        'yoav.arad@hellosimply.com'
      ].includes(user?.email);
    /**
     * on reload, user object is null so the true will allow it to pass the check as the state syncs with the app.
     * Need to investigate
     */
    return true;
  };

  return {
    authorize
  };
};
