import { styled } from '@mui/material';

export const SideViewContainer = styled('section')(({ theme }) => {
  const { default: bg } = theme.palette.background;
  return {
    width: '550px',
    boxSizing: 'border-box',
    backgroundColor: bg,
    padding: '24px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    right: '0',
    top: '60px',
    height: '95%',
    zIndex: '101',
    display: 'flex',
    flexDirection: 'column'
  };
});
