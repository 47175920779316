import RepositoryQuery from '../../../../../../pages/QueryListPage/components/RepositoryQuery';
import { IRepositoryFolderQuery } from '../../../../../../pages/QueryListPage/interface';
import { AccordionPreview } from './RowDataPreview';

interface Props {
  data: IRepositoryFolderQuery;
  handleClick?: () => void;
}
export const RowData = ({ data, handleClick }: Props) => {
  return (
    <RepositoryQuery handleClick={handleClick} state="list" data={data} listPreview={AccordionPreview} />
  );
};
