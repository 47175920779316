import React, { Fragment, useMemo } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ConditionComponent } from '../../../../lib/utils';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { ShwoMoreButton } from './showMoreButton';

interface Props extends CodeProps {
  expandState: [boolean, (boolean) => void];
  onExpand?: (...args: any[]) => void;
  onMinimize?: (...args: any[]) => void;
}

export const ExpandableSyntaxHighlighter = ({
  expandState,
  node,
  inline,
  className,
  children,
  onExpand,
  onMinimize,
  ...props
}: Props) => {
  const [expand, setExpand] = expandState;
  const parsed = useMemo(() => {
    return /language-(\w+)/.exec(className || '');
  }, [className]);
  const lang = parsed ? parsed[1] : '';
  const isSql = parsed && parsed[1] === 'sql';
  const isBash = parsed && parsed[1] === 'bash';
  const isCode = !inline && !!lang;

  const isMoreThan15lines = useMemo(() => {
    return String(children).split('\n').length > 15;
  }, [children]);

  const extraStyles = useMemo(() => {
    if (expand)
      return {
        maxHeight: '100vh',
        overflowY: 'auto'
      };
    return {
      maxHeight: '200px',
      overflowY: 'hidden'
    };
  }, [expand]);

  const style = useMemo(() => {
    return {
      ...atomDark,
      ...(props?.style ? props?.style : {})
    };
  }, [props.style]);

  const showMore = (isSql || isBash) && isCode && isMoreThan15lines;

  const handleToggleContentButton = () => {
    setExpand(p => !p);
    if (!expand) {
      onExpand?.();
    } else {
      onMinimize?.();
    }
  };

  if (!isCode) return <code>{children}</code>;

  return (
    <Fragment>
      <SyntaxHighlighter
        children={String(children).replace(/\n$/, '')}
        style={style}
        language={lang}
        PreTag="div"
        wrapLongLines={true}
        codeTagProps={{
          style: {
            maxWidth: '100%',
            fontSize: '11px',
            width: 'inherit',
            display: 'inline-block',
            overflowWrap: 'break-word',
            transition: 'max-height 2s ease',
            ...extraStyles
          }
        }}
        {...props}
      />
      <ConditionComponent condition={showMore}>
        <ShwoMoreButton onClick={handleToggleContentButton}>
          {expand ? 'Show less' : 'Show more'}
        </ShwoMoreButton>
      </ConditionComponent>
    </Fragment>
  );
};
