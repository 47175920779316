import { useEffect } from 'react';
import {
  IGetTableInfoPayload,
  IUpdateAssetPayload,
  TableProfileContextType
} from '../../../../utils/types';
import { useUserInfoSelector } from '../../../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../../../utils/core/getUserCompanyFromEmail';
import { useTableProfileData } from '../../../../../store/DataCatalog/selectors';
import { useDataCatalogGlobalStateActions } from '../../../../hooks/useDataCatalogGlobalStateActions';
import MixedPanelEvent from '../../../../../utils/analytics/mixPanel';

const defaultData = {
  data: null,
  loading: false,
  error: null
};

export const useTableProfile = (id: number): TableProfileContextType => {
  const user = useUserInfoSelector();
  const { getTableProfile, updateAsset } = useDataCatalogGlobalStateActions();
  const tableProfile = useTableProfileData(id) ?? defaultData;

  async function updateData(field, value) {
    const payload: IUpdateAssetPayload = {
      asset_id: id,
      asset_type: 'table',
      [field]: value
    };
    await updateAsset(payload).unwrap();
    MixedPanelEvent.dataCatalogProfileUpdateProperty({
      assetId: id,
      profileType: 'table',
      newData: { field: value }
    });
  }

  const createGetTableProfileReqPayload = (): IGetTableInfoPayload => {
    const company = getUserCompanyFromEmailUseCase(user?.email);
    return { company, table_id: id };
  };

  const fetchData = async () => {
    const payload = createGetTableProfileReqPayload();
    getTableProfile(payload);
  };

  useEffect(() => {
    if (!tableProfile.data) {
      fetchData();
    }
  }, [id]);

  return { state: tableProfile, updateData };
};
