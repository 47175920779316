import { NotAuthorizedBox } from './styled';
import { Typography } from '@mui/material';

export const NotAuthorizedView = () => {
  return (
    <NotAuthorizedBox>
      <Typography variant="body1">
        You are currently not authorized. Contact support
      </Typography>
    </NotAuthorizedBox>
  );
};
