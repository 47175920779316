import React from 'react';
import { Box, styled } from '@mui/material';
import Watson from '../../../../components/Icons/Watson';

export const BotsonAvatarContainer = styled(Box)(() => ({
  paddingLeft: '0.8px',
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(109.52deg, #7a0f9d 12.37%, #ef2489 95.48%)',
  boxSizing: 'border-box',
  flexGrow: 0,
  flexShrink: 0
}));
export const BotsonChatIcon = () => {
  return (
    <BotsonAvatarContainer>
      <Watson color="white" variant="small" />
    </BotsonAvatarContainer>
  );
};
