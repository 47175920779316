const ref = localStorage;

const get = (key: string) => {
    const $data = ref.getItem(key);
    try {
        if ($data) {
            return JSON.parse($data);
        }
    } catch (error) {
        return $data ?? undefined;
    }
};

const set = (key: string, data: any) => {
    let $data = data;
    if (typeof data !== 'string') {
        $data = JSON.stringify(data);
    }
    ref.setItem(key, $data);
};

const remove = (key: string) => {
    ref.removeItem(key);
};

export const LocalStorage = {
    ...ref,
    get,
    set,
    remove,
} as const;
