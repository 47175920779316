import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { authThunks } from './thunk';
import { AuthState, User } from './types';

const initialState: AuthState = {
  userInfo: null,
  isAuthenticated: false,
  domainStatus: 2
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action: PA<User>) => {
      state.userInfo = action.payload;
    },
    removeUserInfo: state => {
      state.userInfo = null;
    },
    setUserLoginStatus: (state, { payload }: PA<boolean>) => {
      state.isAuthenticated = payload;
    },
    authLogout: state => {
      state.isAuthenticated = false;
      state.userInfo = null;
    },
    setDomainAllowed: (state, { payload }: PA<number>) => {
      state.domainStatus = payload
    }
  },
  extraReducers: {
    [authThunks.index.fulfilled.type]: (state, { payload }: PA<User>) => {
      state.userInfo = payload;
    },
    [authThunks.index.rejected.type]: _ => {},
    [authThunks.userInfo.fulfilled.type]: (state, { payload }: PA<User>) => {
      state.userInfo = payload;
      state.isAuthenticated = true;
    },
    [authThunks.userAllowed.fulfilled.type]: (state, { payload }) => {
      state.domainStatus = payload;
    }
  }
});

export const { setUserInfo, removeUserInfo, setUserLoginStatus, authLogout, setDomainAllowed } =
  slice.actions;

export default slice.reducer;
