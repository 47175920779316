import { Typography, Stack, MenuItem } from '@mui/material';
import { ToggleButtonIcon } from './BotTypeIcon';
import { IAiBotTypeMeta, ToggleAiBotTypeFn } from '../../../types';
import { BotTypeDescLabel } from '../styled';
import { BotTypeIconBox } from './styled';

interface Props {
  onClick: ToggleAiBotTypeFn;
  selected: boolean;
  meta: IAiBotTypeMeta;
}
export const BotType = ({ meta, onClick, selected }: Props) => {
  const handleOnClick = (event: React.MouseEvent) =>
    onClick(event, meta.variant);
  return (
    <MenuItem
      data-type={meta.variant}
      selected={selected}
      onClick={handleOnClick}
    >
      <BotTypeIconBox>
        <ToggleButtonIcon variant={meta.variant} />
      </BotTypeIconBox>
      <Stack direction="column">
        <Typography variant="body2">{meta.label}</Typography>
        <BotTypeDescLabel>{meta.description}</BotTypeDescLabel>
      </Stack>
    </MenuItem>
  );
};
