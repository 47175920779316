import { useTypedSelector } from '..';

export const useAuthInfoSelector = () =>
  useTypedSelector(({ sherloqAuth }) => sherloqAuth);
export const useUserAuthenticatedState = () =>
  useTypedSelector(({ sherloqAuth }) => sherloqAuth.isAuthenticated);
export const useUserInfoSelector = () =>
  useTypedSelector(({ sherloqAuth }) => sherloqAuth.userInfo);
export const useDomainAllowed = () =>
  useTypedSelector(({ sherloqAuth }) => sherloqAuth.domainStatus);
