import { Box } from '@mui/material';
import Response from '../components/Response';
import { AiBotMessageType } from '../../types';

interface Props {
  data: AiBotMessageType;
}

export const AiBotResponseMessage = ({ data }: Props) => {
  return (
    <Box>
      <Response data={{ ...data, conversation_uid: data.conversationId }} />
    </Box>
  );
};
