import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, Box } from '@mui/material';
import {
  FilterDataBox,
  FilterDataContainer,
  ShowMoreBtn,
  TableHeaderBox
} from './components';
import {
  AssetNameCell,
  CalcCell,
  DashboardNameRow,
  DashboardsCell,
  DashbordOwnerCell,
  DefaultRow,
  DefaultTableRow,
  LastUsedRow,
  SqlCell,
  TableHeaderCell,
  TablesCell
} from './filterTableDataRows';
import { ConditionComponent } from '../../../../lib/utils';
import { DiscoveryType } from '../../interfaces';
import ShowMoreDataModal from '../../../../components/modals/ShowMoreDataModal';
import { useUserInfoSelector } from '../../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../../utils/core/getUserCompanyFromEmail';

interface Props {
  data: Record<string, any>[];
  type: DiscoveryType;
}

const TrimDataLimit = 5;

export default ({ data, type }: Props) => {
  const [trim, setTrim] = useState(TrimDataLimit);
  const [showModal, setShowModal] = useState(false);
  const [dataForModal, setDataForModal] = useState([] || '' || [{}]);
  const [dataType, setDataType] = useState('');
  const tableRef = useRef<HTMLDivElement>(null);
  const secondaryScrollContainerRef = useRef<HTMLDivElement>(null);
  const [tableContainerElemProps, setTableContainerElemProps] = useState<any>(
    {}
  );
  const user = useUserInfoSelector();
  let userCompany: string;
  if (user) {
    userCompany = getUserCompanyFromEmailUseCase(user.email);
  }

  const headers = useMemo(() => {
    return generateTableHeadersFromData(data, type);
  }, [data]);

  const showMoreBtnVisible = data.length > trim;

  const handleToggleTrim = () => {
    setTrim(p => p + TrimDataLimit);
  };

  const body = useMemo(() => {
    const canTrimData = data.length > trim;
    let bodyData: any[] = data;
    if (canTrimData && trim > 0) {
      bodyData = data.slice(0, trim);
    }
    return bodyData.map(row => {
      const cells = Object.keys(row);
      return (
        <DefaultTableRow>
          {cells.map(cellData => {
            let data = row[cellData];
            if (
              ['last_used', 'dashboard_created', 'dashboard_updated'].includes(
                cellData
              )
            ) {
              return <LastUsedRow data={data} />;
            } else if (cellData === 'name') {
              return (
                <AssetNameCell
                  name={data}
                  description={row['description']}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  setDataType={setDataType}
                />
              );
            } else if (
              ['dashboard_tables', 'top_users', 'fields'].includes(cellData)
            ) {
              return (
                <TablesCell
                  data={data}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  setDataType={setDataType}
                />
              );
            } else if (cellData === 'dashboard_id') {
              return (
                <SqlCell
                  dashboardName={row['dashboard_name']}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  id={data}
                  company={userCompany}
                  setDataType={setDataType}
                  show={row['dashboard_is_queries']}
                />
              );
            } else if (cellData === 'dashboard_name') {
              return (
                <DashboardNameRow
                  name={data}
                  url={row['dashboard_url']}
                  description={row['dashboard_description']}
                  type={row['dashboard_type']}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  setDataType={setDataType}
                />
              );
            } else if (cellData === 'dashboard_owner') {
              return <DashbordOwnerCell data={data} />;
            } else if (cellData === 'formula') {
              return (
                <CalcCell
                  data={data}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  setDataType={setDataType}
                  calcFieldName={row['calculated_field_name']}
                  dashboards={row['dashboards']}
                />
              );
            } else if (cellData === 'dashboards') {
              return (
                <DashboardsCell
                  data={data}
                  setShowModal={setShowModal}
                  setDataforModal={setDataForModal}
                  setDataType={setDataType}
                />
              );
            } else if (
              [
                'dashboard_url',
                'dashboard_description',
                'dashboard_is_queries',
                'description'
              ].includes(cellData)
            ) {
              return;
            }
            return <DefaultRow data={data} />;
          })}
        </DefaultTableRow>
      );
    });
  }, [data, trim, setDataForModal]);

  useLayoutEffect(() => {
    const tableContainer = tableRef.current;
    if (tableContainer) {
      setTableContainerElemProps({ width: tableContainer.scrollWidth });
    }
  }, []);

  const handleOnScroll = ev => {
    tableRef.current?.scrollTo({
      left: (ev.target as HTMLDivElement).scrollLeft
    });
  };

  const handleSyncScrollbars = ev => {
    secondaryScrollContainerRef.current?.scrollTo({
      left: (ev.target as HTMLDivElement).scrollLeft
    });
  };

  const handleSetShowModal = () => {
    setShowModal(false);
  };
  return (
    <FilterDataContainer>
      <Box
        sx={{
          height: '24px',
          overflowX: 'scroll',
          overflowY: 'hidden'
        }}
        ref={secondaryScrollContainerRef}
        onScroll={handleOnScroll}
      >
        <Box
          sx={{
            width: tableContainerElemProps.width
          }}
        ></Box>
      </Box>
      <FilterDataBox ref={tableRef} onScroll={handleSyncScrollbars}>
        <Table>
          <ShowMoreDataModal
            show={showModal}
            data={dataForModal}
            setClose={handleSetShowModal}
            dataType={dataType}
          />
          <TableHead>
            <TableRow sx={{ backgroundColor: 'grey.300' }}>
              {headers.map(header => {
                return <TableHeaderCell>{header}</TableHeaderCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </FilterDataBox>
      <ConditionComponent condition={showMoreBtnVisible}>
        <ShowMoreBtn onClick={handleToggleTrim} variant="text">
          show more
        </ShowMoreBtn>
      </ConditionComponent>
    </FilterDataContainer>
  );
};

function generateTableHeadersFromData(data: any[], type: DiscoveryType) {
  let headerMap = {};
  if (type === 'dashboard') {
    headerMap = {
      dashboard_id: 'SQL',
      dashboard_name: 'Name',
      dashboard_created: 'Time Creation',
      dashboard_updated: 'Last Updated',
      dashboard_tables: 'Tables',
      dashboard_views: 'Amount of views',
      dashboard_owner: 'Owner',
      dashboard_parent_folder: 'Folder',
      dashboard_type: 'Type'
    };
  } else if (type === 'calculated-field') {
    headerMap = {
      formula: 'Calculation',
      calculated_field_name: 'Metric name',
      dashboards: 'Dashboards',
      fields: 'Related fields'
    };
  } else {
    const createAssetTitle = () => {
      switch (type) {
        case 'table':
          return 'Table Name';
        case 'field':
          return 'Field Name';
      }
    };
    headerMap = {
      name: createAssetTitle(),
      number_of_uses: 'Num of uses in the last month',
      last_used: 'Last Queried',
      top_users: 'Queried by in the last month',
      schema: 'Schema'
    };
  }
  if (data.length > 0) {
    return Object.keys(data[0])
      .filter(
        key =>
          ![
            'dashboard_sql',
            'dashboard_url',
            'dashboard_description',
            'dashboard_is_queries',
            'description'
          ].includes(key)
      )
      .map(key => headerMap[key] || key);
  }
  return [];
}
