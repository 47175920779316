import ApiClient from '../../lib/utils/apiClient';
import {
  IGenerateDescriptionPayloadResponse,
  IGetFieldInfoPayload,
  IGetFieldInfoPayloadResponse,
  IGetRecentQueriesPayload,
  IGetRecentQueriesPayloadResponse,
  IGetTableInfoPayload,
  IGetTableInfoPayloadResponse,
  IUpdateAssetPayload
} from '../utils/types';
import { ApiPayloadTransformer } from './api.payload.transformer';

const getTableInfo = async (
  payload: IGetTableInfoPayload
): Promise<IGetTableInfoPayloadResponse> => {
  const path = '/analytics/table-info';
  const data = await ApiClient.post(path, payload, {
    isProtected: true
  });
  return data.data as IGetTableInfoPayloadResponse;
};

const getFieldInfo = async (
  payload: IGetFieldInfoPayload
): Promise<IGetFieldInfoPayloadResponse> => {
  const path = '/analytics/field-info';
  const data = await ApiClient.post(path, payload, {
    isProtected: true
  });
  return data.data as IGetFieldInfoPayloadResponse;
};

const updateAsset = async (obj: IUpdateAssetPayload): Promise<any> => {
  const path = '/analytics/update-asset-profile';
  const payload = ApiPayloadTransformer.updateAsset(obj);
  await ApiClient.post(path, payload, {
    isProtected: true
  });
};

const generateDescription = async (
  payload: IUpdateAssetPayload
): Promise<IGenerateDescriptionPayloadResponse> => {
  const path = '/analytics/generate-description';
  const data = await ApiClient.post(path, payload, {
    isProtected: true
  });
  return data.data as IGenerateDescriptionPayloadResponse;
};

const getRecentQueries = async (
  payload: IGetRecentQueriesPayload
): Promise<Array<IGetRecentQueriesPayloadResponse>> => {
  const path = '/analytics/recent-queries';
  const data = await ApiClient.post(path, payload, {
    isProtected: true
  });
  return data.data;
};

export const DataCatalogApiService = Object.freeze({
  getTableInfo,
  getFieldInfo,
  updateAsset,
  generateDescription,
  getRecentQueries
});
