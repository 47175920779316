/**
 * @deprecated
 * use UserCompanyVariant enum
 */
export type UserCompanyType =
  | 'sherloq'
  | 'appsflyer'
  | 'armis'
  | 'lightricks'
  | 'ludeo'
  | 'naturalint'
  | 'simply'
  | 'other';

export enum UserCompanyVariant {
  Sherloq = 'sherloq',
  AppsFlyer = 'appsflyer',
  Armis = 'armis',
  Lightricks = 'lightricks',
  Ludeo = 'ludeo',
  Naturalint = 'naturalint',
  Simply = 'simply',
  Other = 'other'
}
