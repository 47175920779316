import { Fragment } from 'react';
import { useAiBotMessages } from '../hooks/useAiBotMessages';
import { AiBotRootMessageRenderer } from './messenger/AiBotRootMessageRenderer';

export const AiBotMessagesRenderer = () => {
  const messages = useAiBotMessages();

  return (
    <Fragment>
      {messages.map(message => {
        return <AiBotRootMessageRenderer data={message} />;
      })}
    </Fragment>
  );
};
