import React from 'react';
import { useTheme } from '@mui/material';

interface Props {
  color?: string;
}

export const GenerateSqlIcon = ({ color: propsColor }: Props) => {
  const {
    palette: {
      primary: { main }
    }
  } = useTheme();
  const color = propsColor ?? main;
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 36 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3333 5V12.5C25.1631 12.4986 24.0058 12.7444 22.9371 13.2212C21.8684 13.6979 20.9124 14.395 20.1317 15.2667C18.7565 16.7944 17.997 18.7779 18 20.8333C18.0005 22.3084 18.3921 23.7569 19.1349 25.0312C19.8777 26.3056 20.9451 27.3602 22.2283 28.0875C20.01 28.7583 17.065 29.1667 13.8333 29.1667C6.93 29.1667 1.33334 27.3008 1.33334 25V5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33334 18.3333C1.33334 20.6342 6.93 22.5 13.8333 22.5C15.3392 22.5 16.7833 22.4117 18.12 22.2483M1.33334 11.6667C1.33334 13.9675 6.93 15.8333 13.8333 15.8333C16.13 15.8333 18.2825 15.6267 20.1317 15.2667M26.3333 5C26.3333 7.30084 20.7367 9.16667 13.8333 9.16667C6.93 9.16667 1.33334 7.30084 1.33334 5C1.33334 2.69917 6.93 0.833336 13.8333 0.833336C20.7367 0.833336 26.3333 2.69917 26.3333 5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6667 20.8333C34.6667 25.4358 30.9358 29.1667 26.3333 29.1667C24.8417 29.1667 23.44 28.775 22.2283 28.0875C20.9451 27.3602 19.8777 26.3056 19.1349 25.0312C18.3921 23.7569 18.0005 22.3084 18 20.8333C18 18.6933 18.8067 16.7417 20.1317 15.2667C20.9124 14.395 21.8684 13.6979 22.9371 13.2212C24.0058 12.7444 25.1631 12.4987 26.3333 12.5C30.9358 12.5 34.6667 16.2308 34.6667 20.8333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8333 19.1667L30.5 20.8333L28.8333 22.5M23.8333 22.5L22.1667 20.8333L23.8333 19.1667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
