import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';


const initialState: any = {
    show: false,
};

const index = createSlice({
    name: 'Show_Confetti',
    initialState,
    reducers: {
        toggleShowConfetti: (state, { payload }: PA<boolean>) => {
            state.show = payload;
        },
    },
});

export const { toggleShowConfetti } = index.actions;

export default index.reducer;
