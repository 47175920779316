import { UserCompanyType } from '../../../utils/types/user';
import { athena, bigQuery, dataGrip, mode, other, sherloq } from './data';

export const createUserSourceFilterOptionsUseCase = (
  company: UserCompanyType
) => {
  switch (company) {
    case 'appsflyer':
      return [athena, bigQuery, sherloq];
    case 'armis':
      return [athena, mode, sherloq];
    case 'lightricks':
      return [bigQuery, sherloq];
    case 'sherloq':
      return [athena, bigQuery, dataGrip, sherloq];
    default:
      return [other];
  }
};
