import React from 'react';
import {
  FilterContainer,
  SearchAndFilterContainer,
  SearchContainer,
  SortByContainer
} from './components';
import { Typography, Select, MenuItem } from '@mui/material';
import SearchSection from './Search/SearchSection';
import TuneIcon from '@mui/icons-material/Tune';
import { OrderBy } from '../components';

interface SearchAndFilterParams {
  onClickFilterIcon: () => void;
  onSearchChange: (val: string) => void;
  sortOption: OrderBy;
  setSortOption: (val: string) => void;
  searchString: string;
}

export const SearchAndFilter: React.FC<SearchAndFilterParams> = ({
  onClickFilterIcon,
  onSearchChange,
  sortOption,
  setSortOption,
  searchString
}) => {
  const handleSortChange = (event: any) => {
    setSortOption(event.target.value as string);
  };

  const searchHandler = (val: any) => {
    onSearchChange(val);
  };
  const sortOptions: { value: OrderBy; label: string }[] = [
    { value: 'mostUsed', label: 'Most Used' },
    { value: 'lastUsed', label: 'Last Used' }
  ];
  return (
    <SearchAndFilterContainer>
      <SearchContainer>
        <SearchSection
          defaultValue={searchString}
          onSearch={val => searchHandler(val)}
        />
      </SearchContainer>
      <FilterContainer onClick={onClickFilterIcon}>
        <Typography color={'#707070'}>Filters</Typography>
        <TuneIcon sx={{ color: '#707070' }} />
      </FilterContainer>
      <SortByContainer>
        <Typography color={'#707070'}>Sort by:</Typography>
        <Select
          value={sortOption}
          onChange={handleSortChange}
          displayEmpty
          inputProps={{ 'aria-label': sortOption }}
          sx={{ height: '40px' }}
        >
          {sortOptions.map(option => (
            <MenuItem sx={{ height: '30px' }} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </SortByContainer>
    </SearchAndFilterContainer>
  );
};
