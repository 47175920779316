import { useMemo } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import useIsAppsflyer from './useIsAppsflyer';
import useIsArmis from './useIsArmis';
import useIsLightricks from './useIsLightricks';
import useIsNaturalInt from './useIsNaturalInt';
import useIsSherloq from './useIsSherloq';
import useIsSherloqHelloUser from './useIsSherloqHelloUser';

export default () => {
  const user = useUserInfoSelector();
  const isNatural = useIsNaturalInt();
  const isArmis = useIsArmis();
  const isLightricks = useIsLightricks();
  const isSherloqHelloUser = useIsSherloqHelloUser();
  const isSherloq = useIsSherloq();
  const isAppsflyer = useIsAppsflyer();

  return useMemo(() => {
    let isBotsonDiscoveryV2 = false;
    if (isNatural || isSherloq || isSherloqHelloUser || isArmis || isLightricks || isAppsflyer) {
      isBotsonDiscoveryV2 = true;
    }
    return isBotsonDiscoveryV2;
  }, [user]);
};
