import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Footer, SideViewFooterButtonsContainer } from './components';

export const FilterActionButtons = ({
  onCancelSearch,
  onResetFilters,
  onSubmitSearch
}) => {
  return (
    <Footer>
      <Button onClick={onResetFilters}>
        <Typography color={'#7209B7'}>Reset Filters</Typography>
      </Button>
      <SideViewFooterButtonsContainer>
        <Button variant="outlined" onClick={onCancelSearch}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmitSearch}>
          Search
        </Button>
      </SideViewFooterButtonsContainer>
    </Footer>
  );
};
