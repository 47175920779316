import React from 'react';
import { styled, Box } from '@mui/material';

export const Footer = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto'
  };
});

export const SideViewFooterButtonsContainer = styled(Box)(() => {
  return {
    columnGap: '8px',
    display: 'flex'
  };
});
