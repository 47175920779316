import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  messageClipSize: '12px',
  shadow: {
    ring: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 15px 0px',
    primary: '0px 0px 15px rgba(0, 0, 0, 0.2)'
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '50px'
    },
    h2: {
      fontSize: '42px'
    },
    h3: {
      fontSize: '42px'
    },
    h4: {
      fontSize: '34px'
    },
    h5: {
      fontSize: '24px'
    },
    h6: {
      fontSize: '20px'
    },
    subtitle1: {
      fontSize: '16px'
    },
    subtitle2: {
      fontSize: '14px'
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
      fontStyle: 'normal',
      letterSpacing: '0.15px'
    },
    body2: {
      fontSize: '14px'
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      fontStyle: 'normal',
      letterSpacing: '0.2px',
      textTransform: 'none'
    },
    overline: {
      fontSize: '12px'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
          fontSize: '12px'
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        sx: { zIndex: 999999 }
      }
    },
    MuiDialog: {
      defaultProps: {
        sx: { zIndex: 3000 }
      }
    },
    MuiCssBaseline: {
      styleOverrides: theme => `
     .scroll-container {
      overflow-y: auto;
        ::-webkit-scrollbar {
          width: 7px !important;
       }
       ::-webkit-scrollbar-thumb {
         background-color: ${theme.palette.primary.main};
         border-radius: 3px;
         margin-right: 2px;
       }
     };
     .ace_highlighter {
      background-color: rgba(255,255,0,0.6) !important;
      position: absolute !important;
     }
      `
    }
  }
});
