import PageLoader from '../../../../lib/components/PageLoader';
import { AllFields } from './components/AllFields';
import { Description } from './components/Description';
import { Header } from './components/Header';
import { RecentQueries } from './components/RecentQueries';
import { UsageTables } from './components/UsageTables';
import { Container, ContentBox } from './components/atoms/styled';
import { ConditionComponent } from '../../../../lib/utils';
import { useTableProfileDataStore } from './hooks/useTableProfileDataStore';
import {
  TableProfileProvider,
  TableRecentQueriesProvider
} from './context/provider';
import { useEffect } from 'react';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useSubscribeToCopyEvents } from '../../../../lib/hooks';

const Component = () => {
  const { data, loading } = useTableProfileDataStore();
  if (loading || !data) {
    return <PageLoader />;
  }

  const handleCopyFromPage = (selectedText: string) => {
    MixedPanelEvent.queryManuallyCopied({
      page: 'table-profile',
      copiedText: selectedText
    });
  };
  const { ref } = useSubscribeToCopyEvents({
    onCopy: handleCopyFromPage,
    enable: Boolean(handleCopyFromPage)
  });

  useEffect(() => {
    if (data) {
      MixedPanelEvent.dataCatalogProfileClicked({
        type: 'table',
        assetName: data.table_name
      });
    }
  }, [data]);

  return (
    <Container ref={ref}>
      <ConditionComponent condition={!loading}>
        <Header data={data} />
        <ContentBox className="scroll-container">
          <Description />
          <AllFields fields={data?.all_fields} tableName={data.table_name} />
          <UsageTables
            users={data?.all_users}
            joins={data?.joins}
            assetId={data.table_id}
            assetType={'table'}
          />
          <TableRecentQueriesProvider
            text={`${data.db_name}.${data.table_name}`}
          >
            <RecentQueries />
          </TableRecentQueriesProvider>
        </ContentBox>
      </ConditionComponent>
    </Container>
  );
};

export const TableProfile = () => {
  return (
    <TableProfileProvider>
      <Component />
    </TableProfileProvider>
  );
};
