import React from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { useWatsonMessages } from '../hooks/useWatsonMessages';
import { Typography, Box, Button } from '@mui/material';
import { WatsonPromptType } from '../interfaces';
interface RegenerateProps {
  regenerateValue: string;
  builtPrompt?: boolean;
  regenerateQuestion?: string;
  promptType: WatsonPromptType;
}
const Regenerate = ({
  builtPrompt,
  regenerateQuestion,
  regenerateValue,
  promptType
}: RegenerateProps) => {
  const useWatson = useWatsonMessages();
  let sendMessage: (
    message: string,
    isBuiltPrompt?: boolean,
    promptType?: string,
    promptValue?: string
  ) => void;
  if (useWatson) {
    sendMessage = useWatsonMessages()[1];
  }

  const handleOnClick = () => {
    if (sendMessage) {
      sendMessage(regenerateQuestion, builtPrompt, promptType, regenerateValue);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        marginBottom: '5px'
      }}
    >
      <Button
        style={{
          cursor: 'pointer',
          border: '2px solid'
        }}
        onClick={handleOnClick}
        size="medium"
      >
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            columnGap: '3px'
          }}
        >
          <CachedIcon />
          <Typography
            variant="button"
            sx={{ paddingTop: '0px', textTransform: 'none' }}
          >
            Regenerate
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};
export default Regenerate;
