import { styled, TypographyProps, Typography } from '@mui/material';
import MuiVerifiedIcon from '@mui/icons-material/Verified';

export const MetaBox = styled('div')(({ theme }) => {
  const { grey } = theme.palette;
  return `
  display: flex;
  flex-wrap: wrap;
  align-items:flex-start;
  border-bottom: 1px solid ${grey['100']};
  row-gap: 24px;
  column-gap: 24px;
  padding-bottom: 12px;
  `;
});

export const MetaFieldBox = styled('div')(() => {
  return `
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex-shrink: 0;
  `;
});

export const MetaFieldLabel = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return `
  color: ${grey['500']};
  `;
});

export const MetaFieldValue = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2" />
))(() => ``);

export const MetaActions = styled('div')(() => {
  return `
  display: flex;
  align-items:center;
  margin-left: auto
  `;
});

export const VerifiedIcon = styled(MuiVerifiedIcon)(({ theme }) => {
  const { main: color } = theme.palette.success;
  return {
    color
  };
});
