import { toast } from 'react-toastify';
import { BrowserUtils } from '../browser';

type DefaultClipboardMenuAction = (
    clipboardText: string,
    message?: string,
    errorMessage?: string,
) => void;

const copy: DefaultClipboardMenuAction = async (clipboardText, message, errorMessage) => {
    try {
        await BrowserUtils.copyTextToClipboard(clipboardText);
        toast(message ?? 'Successfully copied to clipboard', { type: 'success' });
    } catch (error) {
        toast(errorMessage ?? 'Failed to copy to Clipboard', { type: 'error' });
    }
};

const share: DefaultClipboardMenuAction = (clipboardText, message, errorMessage) => {
    copy(
        clipboardText,
        message ?? 'Shared link successfully copied to clipboard',
        errorMessage ?? 'Failed to copy shared linl to clipbaord',
    );
};

export const MenuClipboardActions = Object.freeze({
    share,
    copy,
});
