export default class Platforms {
    private constructor() {}
    public static get platform() {
        const location = window.location;
        if (location.href.includes('athena')) {
            return 'athena';
        }
        if (location.href.includes('bigquery')) {
            return 'bigquery';
        }
        if (location.href.includes('snowflake')) {
            return 'snowflake';
        }
        if (location.hostname.includes('sherloqdata')) {
            return 'sherloq';
        }
        if (location.hostname.includes('app.mode')) {
            return 'modeAnalytics';
        }
        if (location.hostname.includes('github')) {
            return 'github';
        }
        if (location.hostname.includes('notion.so')) {
            return 'notion';
        }
        if (location.hostname.includes('cloud.databricks')) {
            return 'databricks';
        }
        if (location.hostname.includes('superset')) {
            return 'superset';
        }
        if (location.hostname.includes('looker.com')) {
            return 'looker';
        }
        if (location.hostname.includes('tableau')) {
            return 'tableau';
        }
        if (location.hostname.includes('atlassian.com')) {
            return 'atlassian';
        }
        if (location.hostname.includes('looker.com')) {
            return 'looker';
        }
        if (location.hostname.includes('kaggle')) {
            return 'kaggle';
        }
        if (location.hostname.includes('bizlooker')) {
            return 'bizlooker';
        }
        if (location.href.includes('localhost:8888')) {
            return 'jupyter';
        }

        return 'Manual';
    }
}

export const OPERATED_PLATFORMS = [
    'athena',
    'bigquery',
    'snowflake',
    'sherloq',
    'modeAnalytics',
    'github',
    'notion',
    'databricks',
    'superset',
    'looker',
    'tableau',
    'atlassian',
    'kaggle',
    'bizlooker',
    'jupyter',
];
