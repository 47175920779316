import { Logger } from '../../../utils/browser/logger';
import {
  AiBotGpt4QuestionMessage,
  AiBotGpt4ResponseMessage,
  IAiChatTypeProvider
} from '../../types';
import { Gpt4ApiService } from './api';
import { BaseGpt4Context } from './contexts';
import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import { useAiBotDataStoreActions } from '../../hooks/useAiBotDataStoreActions';
import { AiBotVariant } from '../../domain/chatTypes';
import { uniqueId } from 'lodash';

export const Gpt4Provider = (props: IAiChatTypeProvider) => {
  const ActionsContext = props.botAbstractActionsContext;
  const { company, sessionId, chatType } = useAiBotMeta();
  const { loadMessage, addToBotMessages, stopLoadingMessage, clearMessages } =
    useAiBotDataStoreActions();
  const sendMessage = async (message: any) => {
    loadMessage();
    const payload: AiBotGpt4QuestionMessage = {
      chatType: chatType as AiBotVariant.GPT4,
      message,
      variant: 'question',
      id: uniqueId(),
      conversationId: sessionId
    };
    addToBotMessages(payload);
    Logger.info('Gpt4', message);
    const res = await Gpt4ApiService.sendMessage({
      company,
      conversationId: sessionId,
      message
    });
    const responsePayload: AiBotGpt4ResponseMessage = {
      chatType: chatType as AiBotVariant.GPT4,
      message: res.answer,
      variant: 'response',
      id: uniqueId(),
      conversationId: sessionId,
      status: 'success'
    };
    stopLoadingMessage();
    addToBotMessages(responsePayload);
  };

  const reset = () => {
    clearMessages();
  };
  const actionsValue = { sendMessage, reset };

  return (
    <ActionsContext.Provider value={actionsValue}>
      <BaseGpt4Context.Provider value={null}>
        {props.children}
      </BaseGpt4Context.Provider>
    </ActionsContext.Provider>
  );
};
