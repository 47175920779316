import { styled, Box } from '@mui/material';
import { useDarkModeState } from '../../store/themeMode/selectors';

export const textColor = () => {
  const darkMode = useDarkModeState();
  if (darkMode) {
    return 'white';
  } else {
    return '#384250';
  }
};

export const DataCatalogHeader = styled('header')(() => {
  return {
    padding: '0 2px'
  };
});

export const DataCatalogResults = styled(Box)(() => {
  return {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box'
  };
});

export const SearchInputWrapper = styled('div')(() => {
  return {
    width: '100%',
    flexGrow: 'auto',
    height: '40px',
    border: '1px solid #d9d9d9',
    borderRadius: '30px',
    display: 'flex',
    fontFamily: 'rubik',
    '&:hover': {
      border: '1px solid #7209b7',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer !important'
    }
  };
});

export const StickyContainer = styled('div')(() => {
  return {
    position: 'sticky',
    top: '0',
    zIndex: '100'
  };
});

export const HeaderStickyComponent = styled(StickyContainer)(() => {
  return { width: '100%', top: '41px' };
});

export const EmptyResultsContainer = styled(Box)(() => {
  return { display: 'flex', alignItems: 'center', justifyContent: 'center' };
});

export type OrderBy = 'mostUsed' | 'lastUsed';

export type AssetType = 'table' | 'field';
