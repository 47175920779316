import { styled, Typography, TypographyProps } from '@mui/material';
export const TableHeadWrapper = styled('div')(() => {
  return `
  display: flex;
  width: 100%;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-right: 12px;
  `;
});

export const SectionContainer = styled('div')(
  () => `
width: 400px;
display: flex;
flex-direciton: column;
`
);

export const TableValueText = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(() => {
  return {
    color: 'red'
  };
});
