import moment from 'moment';
import { useSingleSearchQuerySelector } from '../../../store/newQueries/selectors';
import { useSingleSharedFoldersSelector } from '../../../store/sharedFolders/selectors';
import { useSingleSearchQueryHistorySelector } from '../../../store/newQueries/selectors';
import { useSingleQueryHistoryNewSelector } from '../../../store/queryHistoryNew/selectors';
import { useNewFolderSingleFolderContentState } from '../../../store/newFolderContent/selectors';

export const fetchContent = (
    selectorType: 'saved' | 'history' | 'search' | 'my-history' | 'public',
    id: string,
    parentId: string,
) => {
    if (selectorType === 'saved') {
        let data = useNewFolderSingleFolderContentState(id!, parentId);
        return {
            name: data?.query?.name || '',
            parent_name: data?.parent?.name || '',
            description: data?.query?.description || '',
            query: data?.query?.query || '',
            uid: data?.query?.uid || '',
            created_at: data?.query?.created_at || '',
            origin: data?.query?.origin || '',
            user_uid: '',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
        };
    }
    if (selectorType === 'history') {
        let data = useSingleSearchQueryHistorySelector(id!);
        return {
            name: '',
            parent_name: '' || '',
            description: '',
            query: data?.query || '',
            uid: data?.uid || '',
            created_at: data?.created_at || '',
            origin: data?.origin || '',
            user_uid: data?.user_uid || '',
            user_first_name: data?.first_name || '',
            user_last_name: data?.last_name || '',
            user_email: '',
        };
    }

    if (selectorType === 'public') {
        // TODO: Add public folders selector
        let data = useSingleSharedFoldersSelector(id!);
        return {
            name: '',
            parent_name: '' || '',
            description: '',
            // query: data?.query || '',
            uid: data?.uid || '',
            created_at: data?.created_at || '',
            // origin: data?.origin || '',
            user_uid: data?.user_id || '',
            user_first_name: data?.user_first_name || '',
            user_last_name: data?.user_last_name || '',
            user_email: '',
            is_shared: data?.is_shared,
        };
    }
    if (selectorType === 'my-history') {
        let data = useSingleQueryHistoryNewSelector(id!);
        return {
            name: '',
            parent_name: '' || '',
            description: '',
            query: data?.query || '',
            uid: data?.uid || '',
            created_at: data?.created_at || '',
            origin: data?.origin || '',
            user_uid: data?.user?.uid || '',
            user_first_name: data?.user?.firstname || '',
            user_last_name: data?.user?.lastname || '',
            user_email: '',
        };
    }
    if (selectorType === 'search') {
        let data = useSingleSearchQuerySelector(id!);
        return {
            name: data?.query_name || '',
            parent_name: data?.folder_name || '',
            description: data?.query_description || '',
            query: data?.query_query || '',
            uid: data?.query_uid || '',
            created_at: data?.created_at || '',
            origin: data?.origin || '',
            user_uid: data?.user_id || '',
            user_first_name: data?.first_name || '',
            user_last_name: data?.last_name || '',
            user_email: '',
        };
    }
};
export const localTime = (date: any, second: boolean = false) => {
    let d;
    if (typeof date == 'number') {
        d = new Date(date * 1000).getTime();
    } else {
        d = new Date(date).getTime();
    }
    let dateTemp = new Date(d);
    let formattedDate = dateTemp.toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    });
    let formattedTime = '';
    if (second) {
        formattedTime = dateTemp.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    } else {
        formattedTime = dateTemp.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
    }
    return `${formattedDate} ${formattedTime}`;
};

export const UTCtoLocal = (utcTimestamp: string) => {
    const date = new Date(utcTimestamp);
    const offset = date.getTimezoneOffset();
    const year = date.getFullYear().toString().substr(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + (date.getHours() - offset / 60)).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const period = hour >= '12' ? 'PM' : 'AM';
    const formattedDate = `${day}/${month}/${year} ${parseInt(hour) % 12}:${minute} ${period}`;
    return formattedDate;
};

export const shortenString = (value: string, len: number) => {
    if (value && value.length > len) {
        return value.substring(0, len).concat('...');
    }
    return value;
};
