import { useNavigate } from 'react-router-dom';
import {
  HeaderBox,
  InterpageNavigationControlSection,
  PrimaryMetaBox,
  Title,
  InterpageNavigationControlIconBox,
  TitleBox,
  TableNameTitle
} from '../atoms/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SecondaryMeta } from './SecondaryMeta';
import { VerifiedIcon } from './styled';
import { IGetFieldInfoPayloadResponse } from '../../../../../utils/types';
import { ViewChip } from '../../../../ViewToggle/components';

export const Header = ({ data }: { data: IGetFieldInfoPayloadResponse }) => {
  const navigator = useNavigate();
  return (
    <HeaderBox>
      <InterpageNavigationControlSection>
        <InterpageNavigationControlIconBox onClick={() => navigator(-1)}>
          <ArrowBackIcon />
        </InterpageNavigationControlIconBox>
      </InterpageNavigationControlSection>
      <PrimaryMetaBox>
        <TitleBox>
          <TableNameTitle
            onClick={() =>
              navigator(`/data-management/table-profile/${data?.table_id}`)
            }
          >{`${data?.table_name ?? ''}`}</TableNameTitle>
          <Title>{`.${data?.field_name}`}</Title>
        </TitleBox>
        <ViewChip
          backgroundColor="#81CDA9"
          value="Field"
          isActive={true}
          textColor={'white'}
        />
        {/* <VerifiedIcon /> */}
      </PrimaryMetaBox>
      <SecondaryMeta />
    </HeaderBox>
  );
};
