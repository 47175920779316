import { useLayoutEffect } from 'react';
import { usePageLayoutPropsState } from '../../../context/hooks';

export const useInitPageLayout = () => {
  const setRootPageLayoutProps = usePageLayoutPropsState()[1];

  useLayoutEffect(() => {
    setRootPageLayoutProps({
      bodyContainerProps: { style: { paddingLeft: 0, paddingRight: 0 } }
    });

    return function () {
      setRootPageLayoutProps({
        bodyContainerProps: { style: undefined }
      });
    };
  }, []);
};
