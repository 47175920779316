import React from 'react';

interface WatsonProps {
  color: string;
  variant?: 'small' | 'normal';
}

const Small = ({ color }: Pick<WatsonProps, 'color'>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62456 4.12501C9.39541 -1.375 1.60409 -1.375 1.37494 4.125C4.12481 4.58333 6.87466 4.58333 9.62456 4.12501Z"
        fill={color}
      />
      <path
        d="M10.6931 5.18455C10.4968 5.00765 10.1071 4.99628 9.8537 5.04165C6.8842 5.57675 4.1172 5.54125 1.14577 5.04165C0.6222 4.94179 -0.0050669 5.3247 3.08699e-05 5.8934C3.08699e-05 6.3306 0.285194 6.78535 0.68747 6.875C3.84719 7.58255 7.1517 7.5827 10.312 6.875C11.0122 6.72175 11.2456 5.67525 10.6931 5.18455Z"
        fill={color}
      />
      <path
        d="M5.29325 8.9787C5.34105 9.05155 5.4179 9.09465 5.49975 9.09465C5.5816 9.09465 5.65845 9.05155 5.7063 8.9787C6.20095 8.2245 6.73245 7.75885 7.56215 7.79165C8.39185 7.8245 8.36575 8.183 8.64475 8.4107C9.0831 8.76835 9.513 9.1191 10.2954 9.0553C10.4897 9.0489 10.6461 9.21905 10.5412 9.3958C10.0765 10.1786 9.14995 10.7853 8.2565 10.9389C7.1902 11.1223 6.156 10.8838 5.6831 10.3451C5.6355 10.2886 5.56765 10.2603 5.49975 10.2603C5.4319 10.2603 5.364 10.2886 5.3164 10.3451C4.84351 10.8838 3.80931 11.1223 2.743 10.9389C1.84955 10.7853 0.92299 10.1786 0.458311 9.3958C0.35337 9.21905 0.50982 9.0489 0.70412 9.0553C1.48652 9.1191 1.91642 8.76835 2.35475 8.4107C2.63376 8.183 2.97904 7.79165 3.43735 7.79165C4.0379 7.79165 4.79859 8.2245 5.29325 8.9787Z"
        fill={color}
      />
    </svg>
  );
};

const Watson = ({ color, variant = 'normal' }: WatsonProps) => {
  if (variant === 'small') return <Small color={color} />;
  return (
    <div style={{ position: 'relative', right: '-1px', scale: '0.95' }}>
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={color}
          d="M19.2491 8.25002C18.7908 -2.75001 3.20817 -2.75 2.74986 8.25001C8.2496 9.16666 13.7493 9.16666 19.2491 8.25002Z"
        />
        <path
          fill={color}
          d="M21.3861 10.3691C20.9936 10.0153 20.2142 9.99256 19.7074 10.0833C13.7684 11.1535 8.23439 11.0825 2.29154 10.0833C1.2444 9.88358 -0.0101338 10.6494 6.17399e-05 11.7868C6.17399e-05 12.6612 0.570387 13.5707 1.37494 13.75C7.69438 15.1651 14.3034 15.1654 20.624 13.75C22.0244 13.4435 22.4912 11.3505 21.3861 10.3691Z"
        />
        <path
          fill={color}
          d="M10.5865 17.9574C10.6821 18.1031 10.8358 18.1893 10.9995 18.1893C11.1632 18.1893 11.3169 18.1031 11.4126 17.9574C12.4019 16.449 13.4649 15.5177 15.1243 15.5833C16.7837 15.649 16.7315 16.366 17.2895 16.8214C18.1662 17.5367 19.026 18.2382 20.5908 18.1106C20.9794 18.0978 21.2922 18.4381 21.0823 18.7916C20.153 20.3571 18.2999 21.5705 16.513 21.8778C14.3804 22.2446 12.312 21.7675 11.3662 20.6901C11.271 20.5771 11.1353 20.5207 10.9995 20.5206C10.8638 20.5207 10.728 20.5771 10.6328 20.6901C9.68702 21.7675 7.61862 22.2446 5.486 21.8778C3.6991 21.5705 1.84598 20.3571 0.916622 18.7916C0.70674 18.4381 1.01964 18.0978 1.40824 18.1106C2.97305 18.2382 3.83284 17.5367 4.70951 16.8214C5.26753 16.366 5.95808 15.5833 6.8747 15.5833C8.07579 15.5833 9.59717 16.449 10.5865 17.9574Z"
        />
      </svg>
    </div>
  );
};

export default Watson;
