import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { indexOf } from 'lodash';
import { LoadingState } from '../../utils/types/general';
import { sharedFolderThunk } from './thunk';
import { SharedFolder, SharedFolderState } from './type';

const initialState: SharedFolderState = {
    list: [],
    loading: LoadingState.IDLE,
};

const index = createSlice({
    name: 'shared_folders',
    initialState,
    reducers: {
        addFolderToShared: (state, { payload }: PA<SharedFolder>) => {
            state.list.push(payload);
        },
        removeFolderFromShared: (state, { payload }: PA<string>) => {},
    },

    extraReducers: {
        [sharedFolderThunk.index.pending.type]: (state) => {
            state.loading = LoadingState.PENDING;
            state.list = [];
        },
        [sharedFolderThunk.index.fulfilled.type]: (state, { payload }: PA<SharedFolder[]>) => {
            state.loading = LoadingState.FULFILLED;
            state.list = payload;
        },
        [sharedFolderThunk.index.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
            state.list = [];
        },
    },
});

export const { addFolderToShared, removeFolderFromShared } = index.actions;

export default index.reducer;
