import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { OpenAiIcon } from '../../../../components/Icons/OpenAI';
import { gptIconContainerStyles } from '../../styles';
export const GptIcon = () => {
  const theme = useTheme();
  const color = useMemo(() => {
    const isDark = theme.palette.mode === 'dark';
    if (isDark) {
      return theme.palette.primary.main;
    }
    return theme.palette.secondary.main;
  }, [theme]);

  return (
    <Box sx={gptIconContainerStyles}>
      <OpenAiIcon color={color} />
    </Box>
  );
};
