import { ReactNode, useReducer } from 'react';
import {
  AiBotAbstractActionsContext,
  AiBotDataStoreActionsContext,
  AiBotDataStoreContext
} from './defs';
import { AiChatDataStoreReducer, InitialData } from './stateManagement/reducer';
import { createUserAiChatSetupController } from '../factory/createUserSetupController';
import { useAiBotConfigSetup } from '../hooks/useAiBotConfigSetup';
import { ProviderAdapter } from './ProviderAdapter';
import { IAiBotMeta } from '../types';
import { AiBotDataStoreActions } from './stateManagement/actions';

interface Props {
  children: ReactNode | ReactNode[];
}

export const AiChatRootProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(AiChatDataStoreReducer, InitialData);
  const configController = createUserAiChatSetupController(dispatch);
  useAiBotConfigSetup(configController);

  const setMeta = (meta: IAiBotMeta) => {
    dispatch({ type: AiBotDataStoreActions.SetMeta, payload: meta });
  };

  /***
   * @abstract to be implemented by all specific providers
   */
  const sendMessage: Function = (_: any) => {
    console.warn(
      `${state.chatType} does not have a provider or its provider has not implemented the sendMessage method`
    );
  };

  /***
   * @abstract to be implemented by all specific providers
   */
  const reset: Function = (_: any) => {
    console.warn(
      `${state.chatType} does not have a provider or its provider has not implemented the sendMessage method`
    );
  };

  return (
    <AiBotDataStoreContext.Provider value={state}>
      <AiBotDataStoreActionsContext.Provider value={dispatch}>
        <AiBotAbstractActionsContext.Provider value={{ sendMessage, reset }}>
          <ProviderAdapter
            botAbstractActionsContext={AiBotAbstractActionsContext}
            setBotMeta={setMeta}
          >
            {children}
          </ProviderAdapter>
        </AiBotAbstractActionsContext.Provider>
      </AiBotDataStoreActionsContext.Provider>
    </AiBotDataStoreContext.Provider>
  );
};
