import React from 'react';
import { styled, Input as MUIInput, InputProps } from '@mui/material';

export const Input = styled((props: InputProps) => (
  <MUIInput fullWidth multiline maxRows={3} disableUnderline {...props} />
))(({ theme }) => {
  const { focused, border, background } = (theme.palette as any).input;
  return {
    padding: 0,
    '.MuiInput-input': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: border,
      fontSize: '12px',
      backgroundColor: background,
      borderRadius: '5px',
      padding: '0 4px',
      ':focus': {
        borderColor: focused
      }
    }
  };
});
