import {
  ITableProfileUser,
  ITableProfileUserJoin
} from '../../../../../utils/types';
import { MostJoinedTables } from '../MostJoinedTables';
import { UsersUsage } from '../UsersUsage';
import { Container } from './styled';

export const UsageTables = ({
  users,
  joins,
  assetId,
  assetType
}: {
  users: Array<ITableProfileUser> | null;
  joins: Array<ITableProfileUserJoin> | null;
  assetId: number;
  assetType: string;
}) => {
  return (
    <Container>
      <UsersUsage users={users} />
      <MostJoinedTables joins={joins} assetId={assetId} assetType={assetType} />
    </Container>
  );
};
