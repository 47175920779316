import Logo from '../../../../assets/images/im_logo.png';
import React, { Fragment, useState } from 'react';
import { Box, Input, InputProps, IconButton } from '@mui/material';
import { SearchBoxContainer } from './stylex';

type PromptInputProps = Omit<InputProps, 'ref' | 'onChange' | 'onKeydown'> & {
  submit?: (body: string) => void;
};

export const AiBotInput = ({ submit, ...props }: PromptInputProps) => {
  const [value, setValue] = useState('');

  const submitInternal = () => {
    submit(value);
    setValue('');
  };

  const handleChangeEvent = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setValue(value);
  };

  const handleKeydownEvents = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnter = ev.key === 'Enter';
    const shiftPressed = ev.shiftKey;
    if (isEnter && !shiftPressed) {
      submitInternal();
      ev.preventDefault();
    }
  };

  return (
    <Fragment>
      <Box className="prompt-input-section">
        <SearchBoxContainer className="prompt-input-container joyride_boston_search_bar">
          <Input
            maxRows={3}
            className="input"
            multiline
            disableUnderline
            fullWidth
            autoFocus
            onChange={handleChangeEvent}
            onKeyDown={handleKeydownEvents}
            value={value}
            {...props}
          />
          <IconButton
            sx={{ padding: 0, marginLeft: 'auto' }}
            onClick={submitInternal}
          >
            <img src={Logo} alt="img" height="24px" />
          </IconButton>
        </SearchBoxContainer>
      </Box>
    </Fragment>
  );
};
