import { FolderSummary } from '../../store/folderSummary/type';

const findFolderById = (folderId: string, folders: FolderSummary[]): FolderSummary | null => {
    for (const folder of folders) {
        if (folder.uid === folderId) {
            return folder; 
        } else if (folder.sub_folders && folder.sub_folders.length > 0) {
            const foundFolder = findFolderById(folderId, folder.sub_folders); 
            if (foundFolder) {
                return foundFolder; 
            }
        }
    }
    return null; 
}

const flattenFolderHierarchyById = (folderId: string, folders: FolderSummary[], flattenedFolders: FolderSummary[] = []): FolderSummary[] => {
    const folder = findFolderById(folderId, folders); 
    if (!folder) {
        return flattenedFolders; 
    }
    if (folder.parent_uid) {
        flattenFolderHierarchyById(folder.parent_uid, folders, flattenedFolders); 
    }
    flattenedFolders.push(folder); 
    return flattenedFolders; 
};

export const getFolderFullPath = (folderId: string, folders: FolderSummary[], flattenedFolders: FolderSummary[] = []): string[] => {
    return flattenFolderHierarchyById(folderId, folders, flattenedFolders).map((item) => item.uid)
};