import { InfoIconText } from '../../../../components/styled';
import { Container, ContentBox } from './styled';
import { RowData } from './RowData';
import { useFieldRecentQueries } from '../../hooks/useFieldRecentQueries';
import { ConditionComponent } from '../../../../../../lib/utils';
import Loader from '../../../../../../lib/components/Loader/Loader';
import MixedPanelEvent from '../../../../../../utils/analytics/mixPanel';
import { useFieldProfileDataStore } from '../../hooks/useFieldProfileDataStore';

export const RecentQueries = ({ text }: { text: string }) => {
  const datastore = useFieldRecentQueries(text);
  const { data, loading } = datastore.state;

  const assetData = useFieldProfileDataStore();
  const fieldId = assetData.data.field_id;

  const handleQueryClick = (query: string) => {
    MixedPanelEvent.dataCatalogProfileQueryClicked({
      query: query,
      profileType: 'field',
      assetId: fieldId
    });
  };
  return (
    <Container>
      <ConditionComponent condition={!loading && data && data.length > 0}>
        <InfoIconText>Recent queries</InfoIconText>
      </ConditionComponent>
      <ConditionComponent condition={loading}>
        <Loader />
      </ConditionComponent>
      <ConditionComponent condition={!loading && data && data.length > 0}>
        <ContentBox>
          {data?.map((query, index) => {
            return (
              <RowData
                handleClick={() => handleQueryClick(query.query)}
                key={`${index}`}
                data={{
                  className: 'editor',
                  query: query.query,
                  query_uid: '',
                  query_name: query.query_created_at,
                  query_origin: query.query_origin,
                  query_created_at: query.query_created_at,
                  query_description: '',
                  query_owner_username: query.query_owner_username,
                  folderName: '',
                  folderId: '',
                  type: 'my-history',
                  pageType: 'query'
                }}
              />
            );
          })}
        </ContentBox>
      </ConditionComponent>
    </Container>
  );
};
