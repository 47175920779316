import { createContext, useContext } from 'react';
import { FolderMeta, QueryMeta } from './interface';

export const defaultSaveAsPayload: FolderMeta = {
    description: '',
    name: '',
    parentFolder: '',
    sharedMembers: [],
    visibility: 'personal',
};

export const defaultSaveAsQueryPayload: QueryMeta = {
    description: '',
    name: '',
    parentFolder: '',
    query: '',
};
export const HotSaveAsFLowContext = createContext<[any, any, any]>([defaultSaveAsQueryPayload, () => {}, () => {}]);
export const useHotSaveAsFlowState = () => {
    const state = useContext(HotSaveAsFLowContext);
    return [state[0], state[1]];
};

export const useCloseHotSaveAsFlow = () => {
    const state = useContext(HotSaveAsFLowContext);
    return state[2];
};
