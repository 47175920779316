import { createAsyncThunk } from '@reduxjs/toolkit';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { PaginatedQueryParams } from '../../utils/types/paginatedData';
import { showModalNotifier } from '../../lib/components/ModalNotifier';
import ApiClient from '../../lib/utils/apiClient';
import { folderSummaryThunk } from '../folderSummary/thunk';
import { FolderContent } from '../queries/types';
import { toast } from 'react-toastify';
import { CreateQueryFolderPayload, Folder } from './types';

const userQueryFolders = createAsyncThunk(
    'query-folders/userfolders',
    async (payload: PaginatedQueryParams | undefined, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.get<FolderContent[]>(
                `/query-folders/?limit=${payload?.limit}&page=${payload?.page}}`,
                {
                    isProtected: true,
                },
            );
            return fulfillWithValue(response.data.map((content) => content.child!));
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

const createQueryFolder = createAsyncThunk(
    'query-folders/create/',
    async (
        {
            withToast = true,
            withAnalytics = true,
            ...payload
        }: CreateQueryFolderPayload & { withToast?: boolean; withAnalytics?: boolean },
        { fulfillWithValue, rejectWithValue, dispatch },
    ) => {
        try {
            const response = await ApiClient.post<Folder>('/query-folders/v2', payload, {
                isProtected: true,
            });
            if (withToast) {
                toast('Your folder has been created successfully!', { type: 'success' });
            }
            if (withAnalytics) {
                MixedPanelEvent.queryFolderAction({ actionName: 'created', folderName: payload.name,
                    folderUid: '',
                    folderDescription: payload.description,
                    sharedWith: payload.members});
            }
            dispatch(folderSummaryThunk.index());
            return fulfillWithValue(response.data);
        } catch (error) {
            if (withToast) {
                toast('Error occurred', { type: 'error' });
            }
            MixedPanelEvent.queryFolderCreateErrorOccured(payload?.name);
            return rejectWithValue(error);
        }
    },
);

export const queryFoldersThunks = { userQueryFolders, createQueryFolder };
