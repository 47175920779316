import { useAppDispatch } from '../../store';
import { dataCatalogStateActions } from '../../store/DataCatalog';
import { DataCatalogThunks } from '../../store/DataCatalog/thunk';
import {
  IProfileRecentQueriesPayload,
  SearchData
} from '../../store/DataCatalog/type';
import {
  IGetFieldInfoPayload,
  IGetTableInfoPayload,
  IUpdateAssetPayload
} from '../utils/types';

export const useDataCatalogGlobalStateActions = () => {
  const dispatch = useAppDispatch();
  const getTableProfile = (payload: IGetTableInfoPayload) => {
    return dispatch(DataCatalogThunks.getTableProfile(payload));
  };

  const updateAsset = (payload: IUpdateAssetPayload) => {
    return dispatch(DataCatalogThunks.updateAsset(payload));
  };

  const getFieldProfile = (payload: IGetFieldInfoPayload) => {
    return dispatch(DataCatalogThunks.getFieldProfile(payload));
  };

  const setSearchResults = (payload: SearchData) => {
    dispatch(dataCatalogStateActions.setSearchResults(payload));
  };

  const setProfileRecentQueries = (payload: IProfileRecentQueriesPayload) => {
    dispatch(dataCatalogStateActions.setProfileRecentQueries(payload));
  };

  return {
    getTableProfile,
    updateAsset,
    getFieldProfile,
    setSearchResults,
    setProfileRecentQueries
  };
};
