import React, { useCallback, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { IGeneratedQueryWatsonMessage, IWatsonMessage } from '../../interfaces';
import { GeneratedQueryCodeBlock } from './codeBlock';
import { ExpandableSyntaxHighlighter } from '../atoms/expandableSyntaxHighlighter';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { Typography, Box } from '@mui/material';
import LikeDislikeCopyForQuery from '../LikeDislikeCopyForQuery';
import { RenderIf } from '../../../../lib/utils';

interface Props {
  data: IGeneratedQueryWatsonMessage;
}
export const GeneratedQueryResponse = ({ data }: Props) => {
  const expandState = useState(false);

  const Highlighter = useCallback(
    (props: CodeProps) => {
      return (
        <ExpandableSyntaxHighlighter expandState={expandState} {...props} />
      );
    },
    [expandState[0]]
  );
  return (
    <Box display={'flex'} flexDirection='row'>
      {RenderIf(data?.assets.length > 0 && !!data.body,<LikeDislikeCopyForQuery message={data.body} />)}      
      <ReactMarkdown
        children={data.body}
        components={{
          code: props => (
            <GeneratedQueryCodeBlock
              {...props}
              data={data as IGeneratedQueryWatsonMessage}
              expandableSyntaxHighlighterComponent={Highlighter}
            />
          ),
          p({ children }) {
            return (
              <Typography style={{ width: 'inherit', fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                {children}
              </Typography>
            );
          },
          em({ children }) {
            return <em className="important-text">{children}</em>;
          },
          pre({ children }) {
            return <div className="code-block-container">{children}</div>;
          }
        }}
      />
    </Box>
  );
};
