import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import Loader from '../../../lib/components/Loader/Loader';
import ListPageHeader from '../../../components/ListPageHeader';
import { sharedFolderThunk } from '../../../store/sharedFolders/thunk';
import QueryFolderListItem from '../../../components/QueryFolderListItem';
import { useSharedFoldersSelector } from '../../../store/sharedFolders/selectors';
import { EmptyResulsts } from '../../SearchResults/components/EmptyResults';
import { useInitPageLayout } from '../../Watson/hooks/useInitPageLayout';
import { ListBox, MainPage } from '../components/styled';
import { LoadingState } from '../../../utils/types/general';
import { useFolderSummarySelector } from '../../../store/folderSummary/selectors';

const Component = () => {
  const folderSummaryShared = useSharedFoldersSelector();
  const folderSummary = useFolderSummarySelector()
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false); // adding loading state because thunk.index is being called at several places way too many times which would cause this page to enter a loading state. Keep until that is fixed; ie all thunk.index calls are only made here.

  const noData =
    folderSummaryShared.loading === LoadingState.FULFILLED &&
    folderSummaryShared.list &&
    folderSummaryShared.list.length === 0;

  const firstTimeLoad = () => {
    setLoading(true);
    dispatch(sharedFolderThunk.index()).then(() => {
      setLoading(false);
    });
  };

  React.useEffect(() => {
    /*
    Previous implementation called folderSummaryThunk.index too. why?
    */
    firstTimeLoad();
  }, []);

  if (loading) return <Loader />;
  if (noData) return <EmptyResulsts />;

  return (
    <ListBox className="scroll-container">
      {folderSummaryShared.list?.filter(folder => (folder.name ?? '').toLowerCase().includes((folderSummary.filterText ?? '').toLowerCase())).map((folder, index) => (
        <QueryFolderListItem
          level={0}
          isSubFolder={false}
          folder={folder}
          accordionId={folder.uid}
          key={index}
          queryType={'shared'}
        />
      ))}
    </ListBox>
  );
};

const PublicQueries = () => {
  useInitPageLayout();

  return (
    <MainPage className="save-queries">
      <ListPageHeader />
      <Component />
    </MainPage>
  );
};

export default PublicQueries;
