import React from 'react';
import { Box } from '@mui/material';
import { TextFieldFilter } from './TextFieldFilter';
import { DashboardTypeFilter } from './dashboardFilters/DashboardType';

export const DashboardFilters = ({
  selectedDashboardName,
  handleDashboardNameSelection,
  selectedDashboardTable,
  handleDashboardTableSelection,
  selectedDashboardType,
  handleDashboardTypeFieldSelection,
  isDisabled
}) => {
  return (
    <Box>
      <TextFieldFilter
        selectedValue={selectedDashboardName}
        onSelectionChange={handleDashboardNameSelection}
        isDisabled={isDisabled}
        type={selectedDashboardType}
        label={'Name:'}
        marginRight={'132px'}
      />
      <TextFieldFilter
        selectedValue={selectedDashboardTable}
        onSelectionChange={handleDashboardTableSelection}
        isDisabled={isDisabled}
        type={'dashboard-tables'}
        label={'Used table name:'}
        subLabel={'Full or partial table name'}
        marginRight={'42px'}
      />
      <DashboardTypeFilter
        selectedValue={selectedDashboardType}
        onSelectionChange={handleDashboardTypeFieldSelection}
        isDisabled={isDisabled}
      />
    </Box>
  );
};
