import { formatDate } from '../../pages/Watson/components/DiscoveryData/filterTableDataRows';
import { FieldProfileDataStore, TableProfileDataStore } from './types';

function transformStateData(
  data: TableProfileDataStore['data'] | FieldProfileDataStore['data']
) {
  if (data.asset_created_date) {
    const date = data.asset_created_date;
    data.asset_created_date = formatDate(date);
  }
  if (data.asset_updated_date) {
    const date = data.asset_updated_date;
    data.asset_updated_date = formatDate(date);
  }
  return data;
}

export const ProfileDataTransformer = Object.freeze({
  transformStateData
});
