import { AIBotQuestion } from '../../types';
import Response from '../components/Response';

interface Props {
  data: AIBotQuestion;
}

export const AiBotQuestionAdapter = ({ data }: Props) => {
  return <Response data={{ ...data, conversation_uid: data.conversationId }} />;
};
