import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Link,
  Divider
} from '@mui/material';
import CloseIcon from '../Icons/CloseIcon';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { ConditionComponent } from '../../lib/utils';
import { formatSql } from '../../utils/formaters';
import QueryModalButtons from './QueryModalButtons';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

const ShowMoreDataModal = ({
  show,
  setClose,
  data,
  dataType,
  title = '',
  subtitle = ''
}: any) => {
  if (!show || (dataType === 'query' && data.length == 0)) {
    return null;
  }
  const darkMode = useDarkModeState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const handleCopy = () => {
    MixedPanelEvent.watsonDashboardSqlCopied({ data });
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1500);
  };
  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % data.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + data.length) % data.length);
  };

  return (
    // Shadow behind
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
      }}
    >
      {/* Modal Box */}
      <Box
        sx={{
          maxHeight: '80vh',
          width: '500px',
          backgroundColor: darkMode ? '#424242' : 'white',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '20vh',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
        onClick={e => e.stopPropagation()}
      >
        {/* Buttons Box */}
        <Box alignSelf={'flex-end'}>
          <IconButton onClick={setClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ConditionComponent condition={dataType === 'query'}>
          <QueryModalButtons
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            currentIndex={currentIndex}
            data={data}
            showCopySuccess={showCopySuccess}
            handleCopy={handleCopy}
          />
        </ConditionComponent>
        <ConditionComponent condition={dataType === 'suggestions-information'}>
          <Typography fontSize={16} sx={{ textDecorationLine: 'underline' }}>
            {title
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, str => str.toUpperCase())}
          </Typography>
        </ConditionComponent>
        <ConditionComponent condition={data && dataType === 'suggestions'}>
          <Box>
            <Typography fontSize={16} sx={{ textDecorationLine: 'underline' }}>
              {title}
            </Typography>
            <Typography fontSize={14}>
              For modifying go to <b>"Customized Suggestions"</b> tab
            </Typography>
          </Box>
        </ConditionComponent>

        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '70vh',
            padding: '20px'
          }}
        >
          <ConditionComponent condition={data && dataType === 'table'}>
            <List>
              {Array.isArray(data) &&
                data.map((item: any, index: any) => (
                  <ListItem key={index}>
                    <Typography variant="body2">{item}</Typography>
                  </ListItem>
                ))}
            </List>
          </ConditionComponent>
          <ConditionComponent condition={data && dataType === 'suggestions'}>
            {Array.isArray(data) &&
              data.map(
                (feature: {
                  description: string;
                  name: string;
                  suggestions: string[];
                }) => (
                  <Box>
                    <Typography fontWeight={'bold'} fontSize={16}>
                      {feature.name}
                    </Typography>
                    {Array.isArray(feature.suggestions) &&
                      feature.suggestions.map(suggestion => (
                        <Box>
                          <Typography marginBottom={'10px'} fontSize={12}>
                            {suggestion}
                          </Typography>
                          <Divider />
                        </Box>
                      ))}
                  </Box>
                )
              )}
          </ConditionComponent>
          <ConditionComponent condition={data && dataType === 'description'}>
            <Typography variant="body2">{data}</Typography>
          </ConditionComponent>
          <ConditionComponent
            condition={dataType === 'suggestions-information'}
          >
            <ConditionComponent
              condition={title === 'displayCoPilotSuggestions'}
            >
              <Box textAlign={'left'}>
                <Typography marginBottom={'25px'} variant="body2">
                  In this option, you can choose where you want to show Sherloq
                  suggestions.
                </Typography>
                <Typography marginBottom={'25px'} variant="body2">
                  <b>Auto-complete</b> means while writing into your IDE on the
                  'WHERE' or 'JOIN' clauses. The suggestion will be based on the
                  specific tables noted in the query before the clause.
                </Typography>
                <Typography variant="body2">
                  <b>"Post run"</b> refers to the suggestion from Sherloq that
                  appears after running a query. The suggestion will be
                  regarding the tables noted in the query run/console page and
                  will be shown as a pop-up
                </Typography>
              </Box>
            </ConditionComponent>
            <ConditionComponent condition={title === 'displayValues'}>
              <Box textAlign={'left'}>
                <Typography marginBottom={'25px'} variant="body2">
                  The default shown values are based on the common usage of all
                  your table filters <u>in your organization</u>. If you want to
                  adopt customized suggestions, add it in the{' '}
                  <u>"customize suggestion"</u>. <br /> <br />
                  <i>industry_type = ‘commerce’</i>
                </Typography>
                <Typography marginBottom={'25px'} variant="body2">
                  <b>No Display values</b> <br /> Displaying the field name that
                  is commonly filtered on the particular table: <br /> <br />
                  <i>industry_type</i>
                </Typography>
              </Box>
            </ConditionComponent>
            <ConditionComponent condition={title === 'displayPartitionFields'}>
              <Box textAlign={'left'}>
                <Typography marginBottom={'25px'} variant="body2">
                  This option allows you to see partition fields in the
                  suggestions even if they are not used in your organization.
                  For instance, if the partition field for order table is date,
                  Sherloq's suggestions will display as follows: <br /> <br />
                  <i>order.date = ‘YYYY-MM-DD’</i>
                </Typography>
                <Typography marginBottom={'25px'} variant="body2">
                  <b>No display partition fields</b> <br /> Only the partition
                  fields that your organization has used will be displayed in
                  the suggestions
                </Typography>
              </Box>
            </ConditionComponent>
            <ConditionComponent condition={title === 'customizedSuggestions'}>
              <Box textAlign={'left'}>
                <Typography marginBottom={'25px'} variant="body2">
                  In this section, you can add or edit your customized
                  preferences: <br /> <br />
                  1. <b>Add new customized suggestions</b> for the 'WHERE'
                  clause or post-run a query. Here’s an example of a customized
                  suggestion: <br /> <br />
                  <i>order.type_user IN ('electricity', 'nature') </i>
                </Typography>
                <Typography marginBottom={'25px'} variant="body2">
                  2. <b> Edit an existing suggestion</b>
                  For instance, if your current suggestion is
                  <u>order.user_id = 5332, </u>
                  you can modify it as follows: <br /> <br />
                  <i>order.user_id {'>'} 0</i>
                </Typography>
              </Box>
            </ConditionComponent>
            <ConditionComponent condition={title === 'allSuggestions'}>
              <Box textAlign={'left'}>
                <Typography marginBottom={'25px'} variant="body2">
                  In this section, you can view all of Sherloq's suggestions
                  based on your preferences. <br /> <br />
                  To add a new suggestion or edit an existing one, you can do so
                  in the <b>"Customized Suggestions"</b> tab. <br /> <br />
                  After modifying your preferences, our algorithm will update
                  within an hour. You will receive an email notification about
                  these updates.
                </Typography>
              </Box>
            </ConditionComponent>
          </ConditionComponent>
          <ConditionComponent condition={data && dataType === 'dashboard'}>
            <List>
              {Array.isArray(data) &&
                data.map((item: any, index: any) => (
                  <ListItem
                    key={index}
                    style={{
                      padding: 0,
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'baseline'
                    }}
                  >
                    <ConditionComponent condition={item.url}>
                      <Link
                        target="_blank"
                        href={item.url ?? ''}
                        variant="body2"
                      >
                        {item.name}
                      </Link>
                    </ConditionComponent>
                    <ConditionComponent condition={!item.url}>
                      <Typography variant="body2">{item.name}</Typography>
                    </ConditionComponent>
                    <Typography variant="body2">
                      Owner: {item?.owner?.split('@')[0]}
                    </Typography>
                    <ConditionComponent condition={item.views}>
                      <Typography variant="body2">{`# of views: ${item.views}`}</Typography>
                    </ConditionComponent>
                    <Typography variant="body2">
                      Folder: {item.project}
                    </Typography>
                  </ListItem>
                ))}
            </List>
          </ConditionComponent>
          <ConditionComponent
            condition={data && data.length > 0 && dataType === 'query'}
          >
            <SyntaxHighlighter
              children={formatSql(
                data && data.length > 0 && data[currentIndex].query
              )}
              style={{ ...atomDark }}
              language={'sql'}
              PreTag="div"
              wrapLongLines={true}
              codeTagProps={{
                style: {
                  maxWidth: '100%',
                  fontSize: '11px',
                  width: 'inherit',
                  display: 'inline-block',
                  overflowWrap: 'break-word',
                  transition: 'max-height 2s ease'
                }
              }}
            />
          </ConditionComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowMoreDataModal;
