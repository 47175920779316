import React from 'react';
import { Typography } from '@mui/material';
import { DiscoveryDataFeedbackBox } from './components';

/**
 * @summary custom message constants to be used to persist messages but with custom components. Must not be a react element. it must be a node
 */
export const DiscoveryDataFeedback = (
  <DiscoveryDataFeedbackBox>
    <Typography variant="body2" fontWeight={600}>
      Did we help you find what you were looking for?
    </Typography>
    <Typography variant="caption">
      This helps us learn and improve for the next time
    </Typography>
  </DiscoveryDataFeedbackBox>
);
