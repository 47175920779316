import React, { useMemo } from 'react';
import Star from '../../../components/Icons/Star';
import { Box, Button, Divider, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UserAddIcon from '../../../components/Icons/UserAddIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { isDatagrip } from '../../../utils/checkOrigin';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useDispatch } from 'react-redux';
import { useDarkModeState } from '../../../store/themeMode/selectors';
import { toggleDarkMode } from '../../../store/themeMode';
import { dotenv } from '../../../env';
import Watson from '../../../components/Icons/Watson';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../utils/core/getUserCompanyFromEmail';
import { ConditionComponent } from '../../utils';
import useIsNaturalInt from '../../../pages/Watson/hooks/useIsNaturalInt';
import useIsSherloq from '../../../pages/Watson/hooks/useIsSherloq';
import useIsBotsonDiscoveryV2 from '../../../pages/Watson/hooks/useIsBotsonDiscoveryV2';

interface Props {
  type?: 'saved' | 'shared' | 'history';
}

const Sidebar: React.FC<Props> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const darkMode = useDarkModeState();
  const { palette } = useTheme();
  const handleToggleDarkMode = (checked: boolean) => {
    setDarkModeState(checked);
    dispatch(toggleDarkMode(checked));
  };
  const isDesignPartner = useIsBotsonDiscoveryV2();

  const [darkModeState, setDarkModeState] = React.useState(darkMode);

  const activeRouteColor = useMemo(() => {
    const { primary } = palette;
    return primary.main;
  }, [palette]);
  const inactiveRouteColor = palette.text.primary;
  const user = useUserInfoSelector();
  let userCompany: string;
  if (user) {
    userCompany = getUserCompanyFromEmailUseCase(user.email);
  }
  const isNatural = useIsNaturalInt();
  const isSherloq = useIsSherloq();

  return (
    <>
      <Box
        className={`sherloq-sidebar`}
        style={{ background: isDatagrip() ? '#535552' : '#F1F1F1' }}
      >
        <Button
          className="joyride_repository_btn"
          style={{ marginTop: '16px', padding: '0', marginBottom: '16px' }}
          onClick={() => {
            navigate('/saved-queries');
            MixedPanelEvent.sidebarButtonClicked('saved-queries');
          }}
        >
          <Star
            color={
              location.pathname.includes('queries') ||
              location.pathname.includes('history')
                ? activeRouteColor
                : inactiveRouteColor
            }
          />
        </Button>
        <Button
          className="joyride_botson_btn"
          style={{ padding: '0', marginBottom: '16px' }}
          onClick={() => {
            if (window.location.href.includes('/watson')) {
              navigate(0);
            } else {
              navigate('/watson');
            }
            MixedPanelEvent.sidebarButtonClicked('watson');
          }}
        >
          <Watson
            color={
              location.pathname.includes('watson')
                ? activeRouteColor
                : inactiveRouteColor
            }
          />
        </Button>

        <ConditionComponent condition={isDesignPartner}>
          <Button
            style={{ padding: '0', marginBottom: '16px' }}
            onClick={() => {
              navigate('/data-management');
              MixedPanelEvent.sidebarButtonClicked('data-management');
            }}
          >
            <HomeIcon
              sx={{
                color: location.pathname.includes('data-management')
                  ? activeRouteColor
                  : inactiveRouteColor
              }}
            />
          </Button>
        </ConditionComponent>
        <Divider variant="middle" />
        <ConditionComponent condition={isNatural || isSherloq}>
          <Button
            style={{ marginTop: '16px', padding: '0' }}
            onClick={() => {
              navigate('/settings');
              MixedPanelEvent.sidebarButtonClicked('settings');
            }}
          >
            <SettingsIcon
              sx={{
                color: location.pathname.includes('settings')
                  ? activeRouteColor
                  : inactiveRouteColor
              }}
            />
          </Button>
        </ConditionComponent>
        <Button
          style={{ marginTop: '16px', padding: '0' }}
          onClick={() => {
            navigate('/profile');
            MixedPanelEvent.sidebarButtonClicked('profile');
          }}
        >
          <UserAddIcon
            color={
              location.pathname.includes('profile')
                ? activeRouteColor
                : inactiveRouteColor
            }
          />
        </Button>
        <Box
          style={{ marginLeft: '22px', marginTop: '36px', padding: '0' }}
          onClick={() => {}}
        >
          {dotenv.darkModeToggle === 'true' && (
            <DarkModeSwitch
              style={{ marginBottom: '2rem' }}
              checked={darkModeState}
              onChange={handleToggleDarkMode}
              size={20}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
