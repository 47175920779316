import { isAiBotQuestion } from '../../utils/typeGuards';
import { AiBotResponseAdapter } from './AiBotResponseAdapter';
import { AiBotQuestionAdapter } from './AiQuestionAdapter';
interface Props {
  data: any;
}
export const AiBotMessageRootDataAdapter = ({ data }: Props) => {
  const isQuestion = isAiBotQuestion(data);
  if (isQuestion) {
    return <AiBotQuestionAdapter data={data} />;
  }
  return <AiBotResponseAdapter data={data} />;
};
