import { useEffect } from 'react';
import {
  FieldProfileContextType,
  IGetFieldInfoPayload,
  IGetTableInfoPayloadResponse,
  IUpdateAssetPayload
} from '../../../../utils/types';
import { useUserInfoSelector } from '../../../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../../../utils/core/getUserCompanyFromEmail';
import { useFieldProfileData } from '../../../../../store/DataCatalog/selectors';
import { useDataCatalogGlobalStateActions } from '../../../../hooks/useDataCatalogGlobalStateActions';
import MixedPanelEvent from '../../../../../utils/analytics/mixPanel';

const defaultData = {
  data: null,
  loading: false,
  error: null
};

export const useFieldProfile = (id: number): FieldProfileContextType => {
  const user = useUserInfoSelector();
  const { getFieldProfile, updateAsset } = useDataCatalogGlobalStateActions();
  const dataStore = useFieldProfileData(id) ?? defaultData;

  const createUpdatePayload = (payload: Record<string, unknown>) => {
    const key = payload.key as string;
    const obj: IUpdateAssetPayload = {
      asset_id: id,
      asset_type: 'field',
      [key]: payload.value
    };
    return obj;
  };

  async function updateData<KeyType extends keyof IGetTableInfoPayloadResponse>(
    key: KeyType,
    value: IGetTableInfoPayloadResponse[KeyType]
  ) {
    const payload = createUpdatePayload({ key, value });
    await updateAsset(payload).unwrap();
    MixedPanelEvent.dataCatalogProfileUpdateProperty({
      assetId: id,
      profileType: 'field',
      newData: { field: value }
    });
  }

  const createGetFieldProfileReqPayload = (): IGetFieldInfoPayload => {
    const company = getUserCompanyFromEmailUseCase(user?.email);
    return { company, field_id: id };
  };

  const fetchData = async () => {
    const payload = createGetFieldProfileReqPayload();
    getFieldProfile(payload);
  };

  useEffect(() => {
    if (!dataStore.data) {
      fetchData();
    }
  }, [id]);

  return { state: dataStore, updateData };
};
