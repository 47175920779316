import {
  DefaultAssetIconContainer,
  DefaultTableContainer,
  DefaultTableName
} from './components';
import { ViewChip } from '../ViewToggle/components';
import { AssetDataContentRow } from './AssetDataContentRow';
import { ConditionComponent } from '../../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { PlatformIcon } from '../../../components/Icons/PlatformIcon';
import { Box } from '@mui/material';

export const ResultsView = ({ data, type, setDataForModal }) => {
  const navigate = useNavigate();
  const handleAssetProfileClick = () => {
    let url = '';
    if (type === 'table') {
      url = `/data-management/table-profile/${data.table_id}`;
    } else if (type === 'field') {
      url = `/data-management/field-profile/${data.field_id}`;
    }
    navigate(url);
  };
  return (
    <DefaultTableContainer onClick={handleAssetProfileClick}>
      <ConditionComponent condition={type === 'table'}>
        <DefaultAssetIconContainer>
          <PlatformIcon origin={data.source} size={'16px'} />
          <DefaultTableName>
            <ViewChip
              backgroundColor="#0075E720"
              value={`${data.db_name}.${data.table_name}`}
              isActive={true}
            />
          </DefaultTableName>
        </DefaultAssetIconContainer>
      </ConditionComponent>
      <ConditionComponent condition={type === 'field'}>
        <DefaultAssetIconContainer>
          <PlatformIcon origin={data.source} size={'16px'} />
          <DefaultTableName>
            <ViewChip
              backgroundColor="#81CDA9"
              value={`${data.table_name}.${data.field_name}`}
              isActive={true}
              textColor={'white'}
            />
          </DefaultTableName>
        </DefaultAssetIconContainer>
      </ConditionComponent>
      <AssetDataContentRow
        data={data}
        type={type}
        setDataForModal={setDataForModal}
      />
    </DefaultTableContainer>
  );
};
