import './index.scss';
import { sx } from '../../styles/MUI/styles';
import React, { useEffect, useState } from 'react';
import { CustomTooltip } from '../../atoms/CustomTooltip';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box, TextField, Button, Typography, Checkbox } from '@mui/material';
import { isDatagrip } from '../../utils/checkOrigin';
import { SelectFolderDropdown } from '../selectFolderDropdown';
import {
  addFolderDropdownButtonStyles,
  addFolderDropdownContainerStyles,
  addFolderDropdownFolderTextStyles,
  addFolderSubFolderSelectionContainerStyles,
  checkBoxContainerStyle,
  selectFolderDropdownContainerStyle
} from './styles';
import { RenderIf } from '../../lib/utils';
import { CancelButton } from './components/cancelButton';
import { ModalRoot } from './root';
import { DesignSystemMap } from '../../styles/MUI/designSystem';
import { TeamMembersSelector } from './components/teamMembersSelector';
import { ModalTypography } from './components/label';
import { Input } from '../Input';
import { TeamOptionsMenu } from './components/teamOptions';
import { useInternalTeamsSelector } from '../../store/internalTeams/selectors';
import { internalTeamThunk } from '../../store/internalTeams/thunk';
import { useAppDispatch } from '../../store';
import Loader from '../../lib/components/Loader/Loader';
import { LoadingState } from '../../utils/types/general';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { toast } from 'react-toastify';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { useUserInfoSelector } from '../../store/auth/selectors';

interface FolderModalProps {
  title: string;
  type: 'add' | 'edit' | 'share';
  folders: any;
  show: boolean;
  name: string;
  setName: (v: string) => void;
  description: string;
  setDescription: (v: string) => void;
  folderType: string;
  setFolderType: (v: string) => void;
  members: string[];
  setMembers: (v: any) => void;
  setClose: () => void;
  SaveFolder: (isSub: boolean) => void;
  isSubFolder: boolean;
  parentName: string;
  setParentId: (v: string) => void;
  selectedFolder?: string;
}

const FolderModal = ({
  title,
  type,
  folders,
  show,
  name,
  setName,
  description,
  setDescription,
  folderType,
  setFolderType,
  members,
  setMembers,
  setClose,
  SaveFolder,
  isSubFolder,
  setParentId,
  selectedFolder
}: FolderModalProps) => {
  const [isSub, setIsSub] = useState(isSubFolder);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [createTeamMode, setCreateTeamMode] = useState(false)
  const [teamName, setTeamName] = useState('')
  const { list: teams, loading } = useInternalTeamsSelector();
  const dispatch = useAppDispatch();
  const darkMode = useDarkModeState();
  const userInfo = useUserInfoSelector();
  const companyName = userInfo && userInfo.email ? userInfo.email.split('@')[1].split('.')[0] : ''
  const companyStr = companyName ? companyName.charAt(0).toUpperCase() + companyName.slice(1) : ''

  const spanStyle = {
    fontSize: '12px',
    fontWeight: '500',
    color: isDatagrip() ? 'white' : 'rgba(0, 0, 0, 0.8)'
  };
  const radioSize = { '& .MuiSvgIcon-root': { fontSize: '12px' } };

  const handleItemClick = (folder: any) => {
    setParentId(folder.uid);
  };

  const addMemberHandler = ev => {
    const { value: newMembers } = ev.target;
    setMembers(newMembers);
    if (newMembers.length > 0 && type === 'share') {
      setFolderType('shared')
    }
  };
  const handleRemoveMember = (value: string) => {
    setMembers(members => members.filter(member => member !== value));
  };

  const handleOnSaveFolder = () => {
    SaveFolder(isSub);
    if (type === 'share') {
      MixedPanelEvent.sharedFolderInviteMember(name, '', members)
    }
  };

  const onClickTeam = (teamId: number) => {
    const addedTeam = teams.find(team => teamId === team.id)
    const emails = [...members, ...addedTeam.members.map(member => member.user_email)]
    const uniqueEmails = [...new Set(emails)];
    setMembers(uniqueEmails);
    setAnchorEl2(null);
    MixedPanelEvent.folderTeamSelected({ teamName: addedTeam })
  }

  const onSaveTeam = () => {
    setCreateTeamMode(false)
    dispatch(internalTeamThunk.create({
      name: teamName,
      user_emails: members
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        toast(`Team ${(res.payload as { name: string }).name} has been saved successfully.`, { type: 'success' })
        MixedPanelEvent.folderTeamCreated({
          teamName,
          members
        })
        setTeamName('')
      } else {
        toast(`Failed to create a team. Please make sure all properties are valid.`, { type: 'error' })
      }
    }).catch(err => {
      toast(`Failed to create a team. Please make sure all properties are valid.`, { type: 'error' })
    })

  }

  useEffect(() => {
    if (type === 'add') {
      setName('');
      setDescription('');
    }
    if (type === 'share') {
      setFolderType('shared')
    }
  }, [show]);

  return (
    <ModalRoot onClose={setClose} open={show} variant="default">
      <ModalTypography className="title">{title}</ModalTypography>
      {type !== 'share' &&
        <>
          <br />
          <ModalTypography className="input-label">Folder name</ModalTypography>
          <Input
            minRows={2}
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <br />
          <ModalTypography className="input-label">
            Folder description
          </ModalTypography>
          <Input
            value={description}
            onChange={e => setDescription(e.target.value)}
            maxRows={4}
            minRows={4}
          />
        </>
      }

      {RenderIf(
        type === 'add',
        <div style={addFolderSubFolderSelectionContainerStyles}>
          <Box>
            <Box sx={checkBoxContainerStyle}>
              <Checkbox
                checked={isSub}
                onChange={() => {
                  setIsSub(!isSub);
                }}
                className="check-box"
              />
              <ModalTypography className="input-label">
                Save as subfolder under:
              </ModalTypography>
            </Box>
            {RenderIf(
              isSub,
              <Box sx={selectFolderDropdownContainerStyle}>
                <SelectFolderDropdown
                  folders={folders}
                  onChange={folder => handleItemClick(folder)}
                  label={null}
                  buttonProps={{
                    sx: addFolderDropdownButtonStyles,
                    disabled: !isSub
                  }}
                  textProps={{
                    sx: addFolderDropdownFolderTextStyles
                  }}
                  containerProps={{
                    sx: addFolderDropdownContainerStyles
                  }}
                  selected={selectedFolder}
                />
              </Box>
            )}
          </Box>
        </div>
      )}
      {!isSub ? (
        <>
          <br />
          {type !== 'share' &&
            <>
              <ModalTypography className="input-label">
                Folder visibility
              </ModalTypography>
              <RadioGroup
                row
                value={folderType}
                onChange={e => setFolderType(e.target.value)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="last-day"
                name="radio-buttons-group"
              >
                <CustomTooltip title="Only you will see this folder">
                  <FormControlLabel
                    value="personal"
                    control={<Radio sx={radioSize} />}
                    label={<span style={spanStyle}>Personal</span>}
                  />
                </CustomTooltip>
                <CustomTooltip title="Only you and invited teammates will see this folder">
                  <FormControlLabel
                    value="shared"
                    control={<Radio sx={radioSize} />}
                    label={<span style={spanStyle}>Shared</span>}
                  />
                </CustomTooltip>
                <CustomTooltip title="Everyone at your domain will see this folder">
                  <FormControlLabel
                    value="public"
                    control={<Radio sx={radioSize} />}
                    label={<span style={spanStyle}>Everyone in {companyStr}</span>}
                  />
                </CustomTooltip>
              </RadioGroup>
            </>
          }
              <TeamOptionsMenu
                onClickTeam={onClickTeam}
                teams={teams}
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={() => setAnchorEl2(null)}
              />
          {RenderIf(
            (folderType === 'shared' || type === 'share') && loading !== LoadingState.PENDING,
            <TeamMembersSelector
              onChange={addMemberHandler}
              onClickChip={handleRemoveMember}
              onAddTeamClick={e => {
                e.stopPropagation();
                if (!teams.length) {
                  toast('Your team list is empty. Please select members and create a team from them.', { type: 'info' })
                } else {
                  setAnchorEl2(e.currentTarget as any);
                }
              }}
              selectedMembers={members}
            />
          )}
          {RenderIf(
            folderType === 'shared' && !createTeamMode && loading !== LoadingState.PENDING,
            <CustomTooltip title="Create a team of the selected members">
              <Typography style={{ textAlign: 'right', textDecoration: 'underline', cursor: 'pointer', width: 100, marginLeft: 'auto', color: darkMode ? 'white' : '#707070' }} onClick={() => members.length === 0 ? toast('Please select member(s) to create a team.', { type: 'info' }) : setCreateTeamMode(true)} variant="caption" color='rgba(0,0,0,0.6)'>

                Create as a team
              </Typography>
            </CustomTooltip>
          )}
          {RenderIf(
            folderType === 'shared' && loading === LoadingState.PENDING,
            <Loader />
          )}
          {RenderIf(
            folderType === 'shared' && createTeamMode && loading !== LoadingState.PENDING,
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', columnGap: '10px', marginTop: '12px' }}>
              <Input
                // minRows={2}
                // fullWidth
                type='text'
                sx={{
                  width: '200px',
                }}
                value={teamName}
                onChange={e => setTeamName(e.target.value)}
                placeholder='Enter team name'
              />
              <Button
                sx={{
                  height: 'fit-content',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: 'input.border',
                  backgroundColor: 'input.background',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '2px',
                  width: 'fit-content',
                  minWidth: 0,
                  padding: '2px 8px'
                }}
                disabled={teamName.length === 0}
                onClick={onSaveTeam}
              >
                <Typography variant="caption" color={teamName.length === 0 ? (darkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)') : (darkMode ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.6)')}>
                  Save Team
                </Typography>
              </Button>
              <Button
                sx={{
                  height: 'fit-content',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: 'input.border',
                  backgroundColor: 'input.background',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '2px',
                  width: 'fit-content',
                  minWidth: 0,
                  padding: '2px 8px'
                }}
                onClick={() => setCreateTeamMode(false)}
              >
                <Typography variant="caption" color={darkMode ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.6)'}>
                  Cancel
                </Typography>
              </Button>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <br />
      <Box className="buttons-container">
        <CancelButton onClick={setClose} disabled={createTeamMode} />
        <Button
          disabled={createTeamMode || loading === LoadingState.PENDING}
          sx={sx.btn}
          variant="contained"
          onClick={handleOnSaveFolder}
          className="default-button"
        >
          Save
        </Button>
      </Box>
    </ModalRoot>
  );
};

export default FolderModal;
