import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';

const initialState: any = {
  env: ''
};

const index = createSlice({
  name: 'User_Env',
  initialState,
  reducers: {
    setEnv: (state, { payload }: PA<string>) => {
      state.env = payload;
    }
  }
});

export const { setEnv } = index.actions;

export default index.reducer;
