/* eslint-disable react/display-name */
import { Box, Button, Typography } from '@sherloq/design-system';
import React, { Component } from 'react';
import StackTrace from 'stacktrace-js';
import ErrorIcon from '../../../components/Icons/ErrorIcon';
import Layout from '../../../components/layout';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';

interface Props {
  refreshPage?: () => void;
  page?: string;
  children?: React.ReactNode;
}
class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    (ErrorBoundary.prototype as any).myProps = props;
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(pro: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    StackTrace.fromError(error).then(trace => {
      const primaryTrace = trace[0];
      MixedPanelEvent.oopsError(error.message, primaryTrace);
    });
  }

  reloadApp() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout showSideBar={true}>
          <Box className="shlq-fallback-body">
            <Typography className="shlq-fallback-header " variant="h1">
              Ooops...
            </Typography>
            <Typography className="shlq-fallback-desc" variant="p">
              Something weird just happened... <br /> We’re working on solving
              this issue, for now, please refresh this page
            </Typography>
            <ErrorIcon />
            <Button
              className="shlq-fallback-btn"
              variant="outlined"
              onClick={this.reloadApp}
            >
              Refresh
            </Button>
          </Box>
        </Layout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
