import React, { useCallback } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import SherloqChip from '../../../atoms/SherloqChip';
import { useTeamMembersSelector } from '../../../store/teamMembers/selectors';
import { queriedByListContainerStyle } from '../../../pages/SearchFilter/styles';

interface UseRenderTeamSelectorValuesParams {
  onRemove: (value: string) => void;
}

export const useRenderTeamSelectorValues = ({
  onRemove
}: UseRenderTeamSelectorValuesParams) => {
  const { list: teamMembers } = useTeamMembersSelector();
  const user = useUserInfoSelector();

  const getTeamMemberUsername = useCallback(
    (email: string) => {
      const user = teamMembers.find(member => member.email === email);
      if (user) {
        return `${user?.first_name} ${user.last_name}`;
      }
      return '';
    },
    [teamMembers]
  );

  const fn = useCallback(
    (values: string[]) => {
      return (
        <div style={queriedByListContainerStyle}>
          {(values as any[]).map(email => {
            const username = getTeamMemberUsername(email);
            return (
              <SherloqChip
                onClick={() => onRemove(email)}
                key={email}
                content={username}
              />
            );
          })}
        </div>
      );
    },
    [user]
  );
  return fn;
};
