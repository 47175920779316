import React from 'react';
import { styled, Stack, StackProps, Box } from '@mui/material';
export const DiscoveryFeedbackBox = styled(Box)(() => {
  return {
    width: '100%',
    flexDirection: 'column'
  };
});

export const DiscoveryFeedbackBtnsBox = styled((props: StackProps) => (
  <Stack direction={'row'} {...props} />
))(() => {
  return {
    columnGap: '8px',
    marginLeft: '32px'
  };
});

export const MessageWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start'
  };
});

export const DiscoveryDataFeedbackBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };
});
