import { Box, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CalculateIcon from '@mui/icons-material/Calculate';
import PercentIcon from '@mui/icons-material/Percent';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import useIsArmis from '../../../pages/Watson/hooks/useIsArmis';
import useIsLightricks from '../../../pages/Watson/hooks/useIsLightricks';
import { ConditionComponent } from '../../../lib/utils';
import { PromptButton } from '../../../pages/Watson/components/atoms/promptButton';
import { useDiscoveryAiBotActions } from './hooks/useDiscoveryActions';
import { DiscoveryType } from './types';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import useIsAppsflyer from '../../../pages/Watson/hooks/useIsAppsflyer';

export default () => {
  const isArmis = useIsArmis();
  const isLightricks = useIsLightricks();
  const isAppsflyer = useIsAppsflyer();
  const partialKeys = ['field', 'table', 'join', 'partition', 'query'];
  const fullKeys = [
    'field',
    'table',
    'dashboard',
    'join',
    'calculated-field',
    'partition',
    'where',
    'query'
  ];
  const keys = isLightricks || isArmis || isAppsflyer ? partialKeys : fullKeys;
  const titles = {
    'calculated-field': 'Calculated Fields',
    join: 'Join',
    dashboard: 'Dashboard',
    table: 'Table',
    field: 'Field',
    partition: 'Partition',
    where: 'Where Filters',
    query: 'Queries history'
  };
  const { chooseAsset } = useDiscoveryAiBotActions();
  const { sessionId: conversationId } = useAiBotMeta();
  const handleOnSelectAsset = (title: string) => {
    chooseAsset(title as DiscoveryType);
    MixedPanelEvent.watsonAssetTypeSent({
      question_type: title,
      conversation_uid: conversationId
    });
  };

  return (
    <Box
      sx={{
        gap: '1em',
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        marginLeft: '15px',
        marginTop: '5px'
      }}
      data-type="phase"
    >
      {keys.map((title, index) => (
        <PromptButton
          key={index}
          sx={{
            color: 'black',
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
          onClick={() => {
            handleOnSelectAsset(title);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '24px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ConditionComponent condition={title === 'table'}>
              <TableChartIcon
                sx={{
                  fill: '#7209b780'
                }}
              />
            </ConditionComponent>
            <ConditionComponent condition={title === 'field'}>
              <ViewColumnIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'join'}>
              <JoinFullIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'dashboard'}>
              <DashboardIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'calculated-field'}>
              <CalculateIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'partition'}>
              <PercentIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'where'}>
              <QuestionMarkIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
            <ConditionComponent condition={title === 'query'}>
              <ManageSearchIcon sx={{ fill: '#7209b780' }} />
            </ConditionComponent>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'left', paddingLeft: '8px' }}>
            {titles[title]}
            <ConditionComponent condition={title === 'calculated-field'}>
              <Typography marginTop={'-5px'} fontSize={12}>
                from a dashboard
              </Typography>
            </ConditionComponent>
          </Box>
        </PromptButton>
      ))}
    </Box>
  );
};
