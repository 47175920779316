import { Input, InputBox } from './styled';
import SearchIcon from '@mui/icons-material/Search';

export const SearchInput = ({
  onSearchChange
}: {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <InputBox>
      <SearchIcon />
      <Input
        placeholder="Search for a field in this table..."
        onChange={onSearchChange}
      />
    </InputBox>
  );
};
