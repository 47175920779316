import ApiClient from "../../../lib/utils/apiClient";

export const addTag = async (tag_name: string) => {
    return ApiClient.post(
        `tags/`,
        { tag_name },
        {
            isProtected: true,
        },
    );
};

export const getTags = async () => {
    return ApiClient.get(`tags/`, {
        isProtected: true,
    });
};

export const deleteTag = async (tag_id: number) => {
    return ApiClient.delete(`tags/${tag_id}`, {
        isProtected: true,
    });
};

export const addQueryTag = async (query_uid: string, tag_id: number) => {
    return ApiClient.post(
        `tags/query`,
        { tag_id, query_uid },
        {
            isProtected: true,
        },
    );
};

export const getQueryTags = async (query_uid: string) => {
    return ApiClient.get(`tags/query/${query_uid}`, {
        isProtected: true,
    });
};

export const deleteQueryTag = async (query_uid: string, tag_id: number) => {
    return ApiClient.delete(`tags/query/${tag_id}/${query_uid}`, {
        isProtected: true,
    });
};