import { useRef } from 'react';
import {
  Id,
  ToastContent,
  ToastOptions,
  UpdateOptions,
  toast
} from 'react-toastify';

export const useToastController = () => {
  const ref = useRef<Id>();
  const show = (content: ToastContent<unknown>, options?: ToastOptions<{}>) => {
    ref.current = toast(content, { hideProgressBar: true, ...options });
  };

  const update = (options: UpdateOptions) => {
    if (ref.current) {
      toast.update(ref.current, {
        hideProgressBar: true,
        isLoading: false,
        ...options
      });
    } else {
      const { render, ...rest } = options;
      show(render, rest);
    }
  };

  const hide = () => {
    toast.dismiss(ref.current);
  };

  return { show, update, hide };
};
