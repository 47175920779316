import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { indexOf } from 'lodash';
import { LoadingState } from '../../utils/types/general';
import { teamMemberThunk } from './thunk';
import { TeamMember, TeamMemberState } from './type';

const initialState: TeamMemberState = {
    list: [],
    loading: LoadingState.IDLE,
};

const index = createSlice({
    name: 'Team_Members',
    initialState,
    reducers: {
        addTeamMember: (state, { payload }: PA<TeamMember>) => {
            state.list.unshift(payload);
        },
    },

    extraReducers: {
        [teamMemberThunk.index.pending.type]: (state) => {
            state.loading = LoadingState.PENDING;
        },
        [teamMemberThunk.index.fulfilled.type]: (state, { payload }: PA<TeamMember[]>) => {
            state.loading = LoadingState.FULFILLED;
            state.list = payload;
        },
        [teamMemberThunk.index.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
            state.list = [];
        },
    },
});

export const { addTeamMember } = index.actions;

export default index.reducer;
