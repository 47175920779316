import { Fragment, ReactNode, useMemo } from 'react';
import { useAiBotMeta } from '../hooks/useAiBotMeta';
import { IAiChatTypeProvider } from '../types';
import { AiBotVariant } from '../domain/chatTypes';
import { Gpt4Provider } from '../bots/Gpt4/provider';
import { GenSqlProvider } from '../bots/GenSql/provider';
import { DiscoveryProvider } from '../bots/Discovery/provider';

interface Props extends IAiChatTypeProvider {
  children: ReactNode | ReactNode[];
}

export const ProviderAdapter = (props: Props) => {
  const { chatType } = useAiBotMeta();
  const Provider = useMemo(() => {
    if (chatType === AiBotVariant.GPT4) return <Gpt4Provider {...props} />;
    if (chatType === AiBotVariant.BotsonDiscovery)
      return <DiscoveryProvider {...props} />;
    if (chatType === AiBotVariant.BotsonGenSql)
      return <GenSqlProvider {...props} />;

    return <Fragment children={props.children} />;
  }, [chatType]);

  return Provider;
};
