import ApiClient from '../../../lib/utils/apiClient';

const selectFilters = async (
  message: string,
  uid: string,
  type: string,
  company: string,
  filters: {} = null
) => {
  const data = await ApiClient.post(
    '/ai/discover/search',
    {
      message,
      uid,
      type,
      company,
      filters
    },
    { isProtected: true }
  );
  return data.data;
};
export const DiscoverApiService = Object.freeze({
  selectFilters
});
