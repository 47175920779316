import { AiBotVariant } from '../../../Botson/domain/chatTypes';
import { WatsonChatType } from '../interfaces';
import { BotsonChatIcon } from './atoms/botsonChatIcon';
import { GptIcon } from './atoms/gptIcon';

interface Props {
  variant: AiBotVariant;
}

export const AiChatIconVariant = ({ variant }: Props) => {
  switch (variant) {
    case AiBotVariant.BotsonDiscovery:
      return <BotsonChatIcon />;
    case AiBotVariant.BotsonGenSql:
      return <BotsonChatIcon />;
    case AiBotVariant.GPT4:
      return <GptIcon />;
    default:
      return null;
  }
};
