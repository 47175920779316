import { styled, Box, BoxProps, SxProps } from '@mui/material';
interface Props extends BoxProps {
  variant: 'response' | 'question';
  isGpt?: boolean;
  isThread?: boolean;
  isfeedback?: boolean;
}

export const MessageContainer = styled(Box)<Props>(
  ({ theme, variant, isGpt = false, isThread = false, isfeedback = false }) => {
    const {
      palette: { mode },
      shadow
    } = theme;
    const isQuestion = variant === 'question';
    const isDark = mode === 'dark';

    const lightModeBackground = isQuestion ? '#EEEEEE' : '#FAFAFA';
    const backgroundColor = isDark
      ? theme.palette.background.paperSurface
      : lightModeBackground;
    const clipOffset = '-11px';

    const questionClip: SxProps = {
      borderRadius: isThread ? '10px 10px 10px 10px' : '10px 0 10px 10px',
      flexDirection: 'row-reverse',
      '::after': {
        display: isThread || isfeedback ? 'none' : '',
        content: '""',
        width: 0,
        height: 0,
        borderBottom: `${theme.messageClipSize} solid transparent`,
        borderLeft: `${theme.messageClipSize} solid ${theme.palette.background.paperSurface}`,
        borderLeftColor: backgroundColor,
        position: 'absolute',
        top: '0px',
        right: clipOffset
      },
      width: 'auto'
    };

    const responseClip: SxProps = {
      borderRadius: isThread ? '10px 10px 10px 10px' : '0 10px 10px 10px',
      marginLeft: theme.messageClipSize,
      '::before': {
        display: isThread || isfeedback ? 'none' : '',
        content: '""',
        borderBottom: `${theme.messageClipSize} solid transparent`,
        borderRight: `${theme.messageClipSize} solid ${theme.palette.background.paperSurface}`,
        borderRightColor: backgroundColor,
        position: 'absolute',
        top: '0px',
        left: clipOffset
      },
      width: 'auto'
    };
    return {
      border: 'none',
      backgroundColor,
      maxWidth: `calc(100vw - 140px)`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '6px 8px',
      columnGap: '12px',
      boxSizing: 'border-box',
      flexGrow: 0,
      left: variant === 'response' && isGpt ? '-5.5px' : 0,
      position: 'relative',
      color: theme.palette.text.primary,
      boxShadow: isfeedback ? 'none' : !isDark ? shadow.primary : 'none',
      ...(variant === 'question' ? questionClip : responseClip)
    };
  }
);
