import { TypographyProps } from '@mui/material';
import { InfoIcon, InfoIconText, InfoLabelBox } from './styled';

interface Props {
  children: string;
  textProps?: TypographyProps;
}

export const InfoLabel = ({ children, ...props }: Props) => {
  return (
    <InfoLabelBox>
      <InfoIconText {...props.textProps}>{children}</InfoIconText>
      {/* <InfoIcon /> */}
    </InfoLabelBox>
  );
};
