import {
  styled,
  TypographyProps,
  Typography,
  Button,
  Box
} from '@mui/material';
export const Container = styled('section')(() => {
  return `
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  `;
});

export const HeaderBox = styled('header')(() => {
  return `
  display: flex;
  flex-direction: column;
  `;
});

export const InterpageNavigationControlSection = styled('nav')(() => {
  return `
  height: 24px;
  width: 100%;
  `;
});

export const InterpageNavigationControlIconBox = styled(Box)(() => {
  return `
  cursor: pointer; 
  width:fit-content
  `;
});

export const PrimaryMetaBox = styled('div')(() => {
  return `
  display: flex;
  align-items:center;
  column-gap: 12px;
  margin-bottom: 18px;
  `;
});

export const Title = styled((props: TypographyProps) => (
  <Typography {...props} variant="h5" />
))(() => {
  return `
  `;
});

export const TableNameTitle = styled((props: TypographyProps) => (
  <Typography {...props} variant="h5" />
))(() => {
  return `
    cursor:pointer;
    text-decoration: underline;
  `;
});

export const TitleBox = styled('div')(() => {
  return `
  display:flex;
  flex-direction: row;
  `;
});

export const TagBox = styled('div')(() => {
  return `
  display: flex;
  height: 32px;
  width: 120px;
  background-color: red;
  `;
});

export const ContentBox = styled('div')(() => {
  return `
  flex:1;
  display: grid;
  margin-top: 48px;
  flex-wrap: wrap;
  overflow-y: auto;
  row-gap: 32px;
  column-gap: 32px;
  padding-right: 32px;
  grid-template-columns: 300px minmax(400px, 1fr) minmax(400px, 500px);
  @media(max-width: 1280px){ 
    grid-template-columns: 300px 1fr;
  }
  `;
});

export const GenerateAiDescriptionBtn = styled(Button)(({ theme }) => {
  const { primary, text } = theme.palette;

  return `
  background-color: ${primary.light};
  color: ${primary.main};
  border-radius: 6px;
  width: fit-content;
  font-size: 14px;
  display; flex;
  column-gap: 6px;
  :hover {
  background-color: ${primary.light};
  color: ${text.primary};
  }
  `;
});
