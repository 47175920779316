import React from 'react';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';
import { useRenderFilterSelectedByValues } from '../hooks/useRenderSelectedQueriedByValues';
import { useTeamMemberOptions } from '../hooks/useTeamMemberOptions';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { FilterLabel } from './filterLabel';
interface QueriedByFilterProps {
  value: any[];
  onChange;
  onClickSelectedChip?: any;
}

export const QueriedByFilter = ({
  value,
  onChange,
  onClickSelectedChip
}: QueriedByFilterProps) => {
  const user = useUserInfoSelector();
  const { options: teamMemberOptions, data: teamMembers } =
    useTeamMemberOptions(user);

  const renderSelectedQueriedBy = useRenderFilterSelectedByValues({
    user,
    last_queried_by: value,
    onRemove: onClickSelectedChip,
    teamMembers
  });

  return (
    <Box className="filter-item-container">
      <FilterLabel>Queried by</FilterLabel>

      <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        multiple
        value={value}
        onChange={onChange}
        renderValue={renderSelectedQueriedBy}
        sx={{
          flex: 1
        }}
      >
        {teamMemberOptions.map(({ label, value }) => {
          const { first_name, last_name } = user;
          let username = `${first_name} ${last_name}`;
          let displayLabel = label;
          if (label === username) displayLabel += ' (me)';
          return (
            <MenuItem key={value} value={value} style={{ height: '25px' }}>
              <Typography variant="caption">{displayLabel}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
