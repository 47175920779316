import { styled } from '@mui/material';

export const SearchInputWrapper = styled('div')(() => {
  return {
    width: '100%',
    flexGrow: 'auto',
    height: '40px',
    border: '1px solid #d9d9d9',
    borderRadius: '30px',
    display: 'flex',
    fontFamily: 'rubik',
    alignItems: 'center',
    padding: '0 10px',
    '&:hover': {
      border: '1px solid #7209b7',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer !important'
    }
  };
});

export const Icon = styled('img')(() => {
  return {
    height: '43px',
    width: '24px',
    paddingLeft: '16px'
  };
});

export const IconWrapper = styled('div')(() => {
  return { paddingRight: '0.5em', display: 'grid', placeItems: 'center' };
});
