import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import {
  DataCatalogHeader,
  DataCatalogResults,
  HeaderStickyComponent,
  OrderBy,
  AssetType,
  EmptyResultsContainer
} from './components';
import ShowMoreDataModal from '../../components/modals/ShowMoreDataModal';
import Loader from '../../lib/components/Loader/Loader';
import { ConditionComponent } from '../../lib/utils';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { FetchResults } from '../api/fetch';
import { EmptyResults } from '../assets/emptyResults';
import { ResultsView } from './ResultView';
import { SearchAndFilter } from './Search&Filter';
import { FilterSideView } from './Search&Filter/FilterSideView';
import { ViewToggleSection } from './ViewToggle';
import { useDataCatalog } from '../hooks/useDataCatalog';
import { ActionTypes } from '../context/reducer';
import { useDataCatalogGlobalStateActions } from '../hooks/useDataCatalogGlobalStateActions';
import { useDataCatalogSearchResults } from '../../store/DataCatalog/selectors';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
const DataCatalogUI = () => {
  const { state, dispatch } = useDataCatalog();
  const {
    isLoading,
    openFilterSideView,
    resultsView,
    orderBy,
    searchString,
    queriedIn
  } = state;
  const darkMode = useDarkModeState();
  const [showModal, setShowModal] = useState(false);
  const [dataForModal, setDataForModal] = useState([] || '' || [{}]);
  const { filters, results: data } = useDataCatalogSearchResults();
  const { setSearchResults } = useDataCatalogGlobalStateActions();

  const compareFilters = (fil1, fil2) => {
    const sameType = fil1.type === fil2.type;
    const sameSearchStr = fil1.searchString === fil2.searchString;
    const sameOrderBy = fil1.orderBy === fil2.orderBy;
    const sameQueriedIn = fil1.queriedIn === fil2.queriedIn;
    return sameType && sameSearchStr && sameOrderBy && sameQueriedIn;
  };

  const fetchData = async () => {
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload: true });
    const payload = {
      type: resultsView,
      orderBy,
      queriedIn,
      searchString
    };
    const sameFilers = compareFilters(filters, payload);
    if (!sameFilers) {
      const res = await FetchResults(payload);
      setSearchResults({ filters: payload, results: res.data ?? [] });
    }
    dispatch({ type: ActionTypes.SET_IS_LOADING, payload: false });
  };
  useEffect(() => {
    fetchData();
  }, [resultsView, orderBy, searchString]);

  const handleFilterIconClick = () => {
    dispatch({
      type: ActionTypes.SET_OPEN_FILTER_SIDE_VIEW,
      payload: !openFilterSideView
    });
  };

  const handleResultsViewChange = (value: string) => {
    dispatch({
      type: ActionTypes.SET_RESULTS_VIEW,
      payload: value as AssetType
    });
  };

  const handleOrderByChange = (value: OrderBy) => {
    dispatch({
      type: ActionTypes.SET_ORDER_BY,
      payload: value
    });
  };

  const handleSearchChange = (text: string) => {
    dispatch({ type: ActionTypes.SET_SEARCH_STRING, payload: text });
    MixedPanelEvent.dataCatalogSearch({
      search: text,
      filters: filters
    });
  };
  const handleSearchFromFiltersView = () => {
    dispatch({ type: ActionTypes.SET_OPEN_FILTER_SIDE_VIEW, payload: false });
    fetchData();
  };

  const handleResetFilters = () => {
    dispatch({ type: ActionTypes.SET_QUERIED_IN, payload: 365 });
  };

  const handleShowMoreModal = (data: string[]) => {
    setDataForModal(data);
    setShowModal(true);
  };

  return (
    <>
      <DataCatalogHeader>
        <Typography
          color={darkMode ? 'white' : ''}
          variant="h6"
          fontWeight={400}
        >
          Manage your metadata
        </Typography>
      </DataCatalogHeader>
      <HeaderStickyComponent>
        <SearchAndFilter
          sortOption={orderBy}
          setSortOption={handleOrderByChange}
          onSearchChange={handleSearchChange}
          onClickFilterIcon={handleFilterIconClick}
          searchString={searchString}
        />
        <ViewToggleSection
          resultsView={resultsView}
          changeResultsView={handleResultsViewChange}
        />
      </HeaderStickyComponent>
      <DataCatalogResults className="scroll-container">
        <ConditionComponent condition={isLoading}>
          <Loader />
        </ConditionComponent>
        <ConditionComponent condition={!isLoading && data.length > 0}>
          {data.map(assetData => (
            <ResultsView
              data={assetData}
              type={resultsView}
              setDataForModal={handleShowMoreModal}
            />
          ))}
        </ConditionComponent>
        <ConditionComponent condition={!isLoading && data.length == 0}>
          <EmptyResultsContainer>
            <EmptyResults />
          </EmptyResultsContainer>
        </ConditionComponent>
        <ShowMoreDataModal
          show={showModal}
          data={dataForModal}
          setClose={() => setShowModal(false)}
          dataType={'table'}
        />
      </DataCatalogResults>
      <ConditionComponent condition={openFilterSideView}>
        <FilterSideView
          queriedInValue={queriedIn}
          setQueriedInValue={(value: number) =>
            dispatch({ type: ActionTypes.SET_QUERIED_IN, payload: value })
          }
          handleFilterIconClick={handleFilterIconClick}
          onSubmitSearch={handleSearchFromFiltersView}
          onResetFilters={handleResetFilters}
        />
      </ConditionComponent>
    </>
  );
};

export default DataCatalogUI;
