import { queryFoldersThunks } from './thunk';
import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { Folder, QueryFolderState } from './types';
import moment from 'moment';
import { LoadingState } from '../../utils/types/general';

const initialState: QueryFolderState = {
    list: [],
    updatedAt: 0,
    createLoadingState: LoadingState.IDLE,
};

const slice = createSlice({
    name: 'queryFolders',
    initialState,
    reducers: {},
    extraReducers: {
        [queryFoldersThunks.userQueryFolders.pending.type]: (state) => {
            // state.fetchLoadingState = LoadingState.PENDING;
        },
        [queryFoldersThunks.userQueryFolders.fulfilled.type]: (
            state,
            { payload }: PA<Folder[]>,
        ) => {
            // state.fetchLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
            state.list = payload;
        },

        [queryFoldersThunks.userQueryFolders.rejected.type]: (state) => {
            // state.fetchLoadingState = LoadingState.REJECTED;
        },

        // create query folder

        [queryFoldersThunks.createQueryFolder.rejected.type]: (state) => {
            state.createLoadingState = LoadingState.PENDING;
        },
        [queryFoldersThunks.createQueryFolder.fulfilled.type]: (state, { payload }: PA<Folder>) => {
            state.createLoadingState = LoadingState.FULFILLED;
            state.list.unshift(payload);
            state.updatedAt = moment.now();
        },
        [queryFoldersThunks.createQueryFolder.rejected.type]: (state) => {
            state.createLoadingState = LoadingState.REJECTED;
        },

        // eod folder content
    },
});

export const {} = slice.actions;

export default slice.reducer;
