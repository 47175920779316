import { styled, Box, Typography } from '@mui/material';
import { textColor } from '../components';

const DefaultContainer = styled(Box)(() => {
  return {
    border: 'none',
    borderBottomWidth: '1px',
    borderBottomColor: ' #f1f1f1',
    borderBottomStyle: 'solid',
    display: 'flex',
    padding: '16px'
  };
});

export const DefaultTableContainer = styled(DefaultContainer)(({ theme }) => {
  const { grey, action } = theme.palette;

  return {
    flexDirection: 'column',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: action.hover
    }
  };
});

export const DefaultAssetIconContainer = styled(Box)(({ theme }) => {
  const { grey } = theme.palette;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    columnGap: '8px'
  };
});

export const DefaultTableName = styled(Typography)(() => {
  return {
    color: '#252525',
    marginTop: '16px',
    fontWeight: 'bold'
  };
});

export const DefaultTableContentContainer = styled(Box)(() => {
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 250px)',
    marginTop: '16px',
    rowGap: '16px',
    columnGap: '16px',

    '@media (min-width: 1200px)': {
      gridTemplateColumns: 'repeat(4, 250px)'
    },

    '@media (min-width: 1300px)': {
      gridTemplateColumns: 'repeat(5, 250px)'
    }
  };
});

export const TableDataContainer = styled(Box)(({ theme }) => {
  const { primary } = theme.palette;
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 12px',
    margin: '4px 0',
    borderRight: `1.5px solid ${primary.main}`,
    wordWrap: 'break-word'
  };
});

export const TableDataTitle = styled(Typography)(() => {
  return {
    color: textColor(),
    fontWeight: 'bold',
    marginBottom: '4px',
    fontSize: '14px'
  };
});

export const TableDataValue = styled(Typography)(() => {
  return {
    color: textColor(),
    fontSize: '18px'
  };
});

export const TableSourceContainer = styled(Box)(() => {
  return {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center'
  };
});
