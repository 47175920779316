import React from 'react';
import {
  MenuItem,
  FormControl,
} from '@mui/material';
import {
  DashboardFilterBox,
  DashboardFilterText,
  DashboardSelect,
  QueriedAtFilterTextBox
} from './components';

export const QueriedAtFilter = ({
  selectedValue,
  onSelectionChange,
  isDisabled
}) => {
  const filterOptions = [
    { label: '3 months ago', value: '90' },
    { label: 'Last month', value: '30' },
    { label: 'Last week', value: '7' },
    { label: 'Last day', value: '1' }
  ];

  const handleSelectionChange = event => {
    onSelectionChange(event.target.value);
  };

  return (
    <DashboardFilterBox>
      <QueriedAtFilterTextBox>
        <DashboardFilterText>Time execution: </DashboardFilterText>
      </QueriedAtFilterTextBox>
      <FormControl sx={{ minWidth: 200 }}>
        <DashboardSelect
          labelId="dashboard-type-select-label"
          id="dashboard-type-select"
          value={selectedValue}
          onChange={handleSelectionChange}
          disabled={isDisabled}
        >
          {filterOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {option.label}
            </MenuItem>
          ))}
        </DashboardSelect>
      </FormControl>
    </DashboardFilterBox>
  );
};

export default QueriedAtFilter;
