import { styled } from '@mui/material';
import { AccordionSummaryBox } from '../../QueryListPage/components/styled';
export const Summary = styled(AccordionSummaryBox)(({ theme }) => {
  const { grey } = theme.palette;
  return {
    alignItems: 'flex-start',
    marginBottom: '8px',
    '& .MuiAccordionSummary-content': {
      borderBottom: '1px solid transparent',
      borderColor: grey['100'],
      marginTop: 0,
      paddingBottom: '4px'
    }
  };
});
