export const RuleAccessibilityOptions = [
  {
    label: 'Contains',
    value: 'contains',
    state: false
  }
];
export const RuleFieldOptions = [
  {
    label: 'Query content',
    value: 'query',
    state: false
  },
  {
    label: 'Query name',
    value: 'name',
    state: false
  },
  {
    label: 'Query description',
    value: 'description',
    state: false
  }
];

export const DateQueriedFilterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Last Day', value: 'last-day' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'Last Month', value: 'last-month' }
];

export const athena = { label: 'Athena', value: 'athena' } as const;
export const bigQuery = { label: 'Big Query', value: 'bigquery' } as const;
export const mode = { label: 'Mode', value: 'mode' } as const;
export const sherloq = { label: 'Sherloq', value: 'sherloq' } as const;
export const dataGrip = { label: 'DataGrip', value: 'datagrip' } as const;
export const other = { label: 'Other', value: 'other' } as const;

export const defaultSearchFilter = {
  q: '',
  queried_in: '',
  in_folder: '-1',
  last_queried_by: [],
  rule: null,
  type: 'saved',
  tags: [],
  // v2
  source: '',
  folder: ''
};
