import WebAccessibleEvents from '../../../utils/events';

const dispatchToggleFrameWithRedirect = (redirectUrl: string) => {
    document.dispatchEvent(
        new CustomEvent(WebAccessibleEvents.DISPATCH_TOGGLE_FRAME_WITH_REDIRECT, {
            detail: {
                redirectUrl,
            },
        }),
    );
};

export const CustomEventDispatchManager = Object.freeze({
    dispatchToggleFrameWithRedirect,
});
