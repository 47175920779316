import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { dropdownMenuPaperStyle } from '../styles';

interface DropdownMenuListItemSubMenuContainerProps extends MenuProps {}

export const DropdownMenuListItemSubMenuContainer = React.forwardRef(
  (
    { children, ...props }: DropdownMenuListItemSubMenuContainerProps,
    ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    return (
      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        PaperProps={{
          style: dropdownMenuPaperStyle
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        {...props}
      >
        <div ref={ref} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    );
  }
);
