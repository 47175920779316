import { useMemo } from 'react';
import { useDarkModeState } from '../store/themeMode/selectors';
import { darkModeTheme, lightModeTheme } from './MUI/themes';

const useSherloqTheme = () => {
  const darkMode = useDarkModeState();
  const theme = useMemo(() => {
    return darkMode ? darkModeTheme : lightModeTheme;
  }, [darkMode]);

  return theme;
};

export default useSherloqTheme;
