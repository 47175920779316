import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import Loader from '../../../lib/components/Loader/Loader';
import ListPageHeader from '../../../components/ListPageHeader';
import { folderSummaryThunk } from '../../../store/folderSummary/thunk';
import QueryFolderListItem from '../../../components/QueryFolderListItem';
import { useFolderSummarySelector } from '../../../store/folderSummary/selectors';
import { useSubscribeToSaveQueryFromExtClient } from '../../../lib/hooks/query/useSubscribeToSaveQueryFromExtClient';
import { EmptyResulsts } from '../../SearchResults/components/EmptyResults';
import { ListBox, MainPage } from '../components/styled';
import { LoadingState } from '../../../utils/types/general';
import { useInitPageLayout } from '../../Watson/hooks/useInitPageLayout';
import { TrialModal } from '../../../components/modals/TrialModal';
import { useDomainAllowed } from '../../../store/auth/selectors';
import { internalTeamThunk } from '../../../store/internalTeams/thunk';

const foldersContainText = (folderList, filterText) => {
  if (folderList.filter(folder => (folder.name ?? '').toLowerCase().includes((filterText ?? '').toLowerCase())).length > 0) {
    return true
  } else {
    if (folderList.sub_folders.length > 0) {
      folderList.sub_folders.forEach(sf => {
        return foldersContainText(sf, filterText)
      })
    }
  }
}

const Component = () => {
  const dispatch = useAppDispatch();
  const folderSummary = useFolderSummarySelector();
  const [loading, setLoading] = useState(false); // adding loading state because thunk.index is being called at several places way too many times which would cause this page to enter a loading state. Keep until that is fixed; ie all folderSummaryThunk.index calls are only made here.
  const { ref: accordionRef } = useSubscribeToSaveQueryFromExtClient();
  const noData =
    folderSummary.loading === LoadingState.FULFILLED &&
    folderSummary.list &&
    folderSummary.list.length == 0;

  const firstTimeLoad = () => {
    setLoading(true);
    dispatch(folderSummaryThunk.index()).then(() => {
      setLoading(false);
    });
    dispatch(folderSummaryThunk.shortcuts())
    dispatch(internalTeamThunk.index())
  };

  React.useEffect(() => {
    firstTimeLoad();
  }, []);

  if (loading) return <Loader />;
  else if (noData) return <EmptyResulsts />;

  return (
    <ListBox className="scroll-container">
      {folderSummary.list.map(folder => {
        return (
          <QueryFolderListItem
            ref={accordionRef}
            level={0}
            isSubFolder={false}
            folder={folder}
            accordionId={folder.uid}
            key={folder.uid}
            queryType={folder.is_shared ? 'shared' : 'saved'}
          />
        );
      })}
    </ListBox>
  );
};

const SavedQueries = () => {
  useInitPageLayout();
  const domainStatus = useDomainAllowed()

  const onModalClick = () => {
    MixedPanelEvent.messageFreeTrialClicked()
    window.open('https://calendly.com/nadav-gutman/30min')
  }

  return (
    <MainPage className="save-queries">
      <TrialModal
        title={domainStatus === 0 ? "Welcome to Sherloq! 🔍" : "Your free trial has ended, thanks for using Sherloq 🙏"}
        description={domainStatus === 0 ? "We’re excited to get you onboard and start using our SQL repository. Book a slot and start your free trial today! 😊" : 
        "To continue using Sherloq, connect with our team to discuss your needs and our pricing plan."}
        open={domainStatus !== 2}
        variant="default"
        buttonLabel={domainStatus === 0 ? 'Start Free Trial' : 'Schedule a call'}
        onClick={onModalClick}
      />
      <ListPageHeader />
      <Component />
    </MainPage>
  );
};

export default SavedQueries;
