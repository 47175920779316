import { styled, Box } from '@mui/material';

export const SearchBoxContainer = styled(Box)(({ theme }) => {
  const { palette } = theme;
  const { mode, background } = palette;
  const isDark = mode === 'dark';
  return {
    border: `1px solid`,
    borderColor: isDark ? background.paperPopup : palette.darkBorder['main'],
    backgroundColor: isDark ? background.surfaceOverlay : background.paper,
    boxShadow:
      'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 15px 0px'
  };
});
