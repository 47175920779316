import React from 'react';
import { Box, Button } from '@mui/material';
import { RenderIf } from '../../../lib/utils';
import { sx } from '../../../styles/MUI/styles';
import Rules from './rules';
import { RuleAccessibilityOptions, RuleFieldOptions } from '../utils/data';
import { FilterLabel } from './filterLabel';

interface RulesFilterProps {
  value: any;
  showRules: boolean;
  onChangeRuleVisibility?: any;
  onChangeRuleAccessibility?: any;
  onChangeRuleField?: any;
  onChangeRuleValue?: any;
}
export const RulesFilter = ({
  showRules,
  onChangeRuleVisibility,
  value,
  onChangeRuleAccessibility,
  onChangeRuleField,
  onChangeRuleValue
}: RulesFilterProps) => {
  return (
    <Box className="sherloq-add-rule-container">
      <Box className="filter-item-container">
        <FilterLabel>Rules</FilterLabel>
        {RenderIf(
          !showRules,
          <Button
            sx={{ ...sx.textBtn12, marginLeft: '-8px' }}
            onClick={onChangeRuleVisibility}
            variant="text"
          >
            + Add rule
          </Button>
        )}
      </Box>
      <Rules
        visible={showRules}
        onChangeAccessibility={onChangeRuleAccessibility}
        onChangeRuleField={onChangeRuleField}
        onChangeRuleValue={onChangeRuleValue}
        data={{
          ruleAccessibility: value?.accessibility,
          ruleField: value?.field,
          ruleValue: value?.query,
          accessiblityOptions: RuleAccessibilityOptions,
          fieldOptions: RuleFieldOptions
        }}
      />
    </Box>
  );
};
