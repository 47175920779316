import { useContext } from 'react';
import { AiBotDataStoreContext } from '../../context/defs';
import { IAiBotInputProps } from '../../types';
import { AiBotVariant } from '../../domain/chatTypes';

interface InputProps {
  placeholder: string;
}

export const useAiBotInputProps = (): IAiBotInputProps & InputProps => {
  const meta = useContext(AiBotDataStoreContext);
  const placeHolder =
    meta.chatType === AiBotVariant.BotsonGenSql
      ? 'Generate SQL for ...'
      : 'Search with Botson';

  return { ...meta.inputProps, placeholder: placeHolder };
};
