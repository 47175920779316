export class AnalyticsWrapper {
    protected static authenticated: boolean;
    protected static _instance: any;

    static identify: (payload: any) => void;
    static init: () => void;
    static sendEvent: (...args: any[]) => Promise<void> | void;
    static reset: () => void;
    static getId: () => any;
    
}

export type AnalyticsWrapperType = typeof AnalyticsWrapper;
