import { Folder } from '../queryFolder/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { UpdateItemPayload } from '../../utils/types/general';
import {
  PaginatedDataType,
  PaginatedQueryParams
} from '../../utils/types/paginatedData';
import { toast } from 'react-toastify';
import ApiClient from '../../lib/utils/apiClient';
import {
  FolderContent,
  NewCreateQueryPayload,
  Query,
  QuerySearchResponse,
  UpdateQueryPayload
} from './types';
import { folderSummaryThunk } from '../folderSummary/thunk';
import { newQueryFolderContentThunks } from '../newFolderContent/thunk';

const index = createAsyncThunk(
  'queries/index',
  async (
    payload: PaginatedQueryParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      // queries/grouped?page=1&limit=20
      const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
        `/queries/grouped?limit=${payload.limit ?? 20}&page=${
          payload.page ?? 1
        }`,
        { isProtected: true }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);
const search = createAsyncThunk(
  'queries/search/v2',
  async (searchData: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await ApiClient.post<QuerySearchResponse[]>(
        `/queries/search/v2`,
        searchData,
        {
          isProtected: true
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

const create = createAsyncThunk(
  'queries/create/',
  async (
    {
      withAnalytics = true,
      ...payload
    }: NewCreateQueryPayload & { withToast?: boolean; withAnalytics?: boolean },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await ApiClient.post<
        FolderContent,
        NewCreateQueryPayload
      >('/queries/with-folder', payload, {
        isProtected: true
      });
      if (payload.withToast) {
        toast('Query successfully saved', { type: 'success' });
      }
      if (withAnalytics) {
        MixedPanelEvent.queryAction({
          actionName: 'created',
          query: response.data.query!
        });
      }
      dispatch(newQueryFolderContentThunks.index({ uid: payload.parent_id! }));
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error?.response?.status === 418 && error?.response?.data?.data) {
        if (payload.withToast) {
          toast(error.response.data.data, { type: 'error' });
        }
      } else {
        if (payload.withToast) {
          toast('Something went wrong, please try again...', { type: 'error' });
        }
        MixedPanelEvent.querySaveError(payload.query?.query);
      }
      return rejectWithValue(error);
    }
  }
);

const userQueryFolders = createAsyncThunk(
  'query-folders/userfolders',
  async (
    payload: PaginatedQueryParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
        `/query-folders/`,
        {
          isProtected: true
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

const removeQueryFolder = createAsyncThunk(
  'query-folders/remove',
  async (uid: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      await ApiClient.delete(
        `/query-folders/${uid}`,

        {
          isProtected: true
        }
      );
      MixedPanelEvent.queryAction({ actionName: 'deleted', query: { uid } });
      toast('Your folder was successfully deleted', { type: 'success' });
      dispatch(folderSummaryThunk.index());
      return fulfillWithValue(uid);
    } catch (error) {
      rejectWithValue('Error occurred');
    }
  }
);

const folderContent = createAsyncThunk(
  'query-folders/content',
  async (uid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
        `/query-folders/${uid}/content`,
        {
          isProtected: true
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

const update = createAsyncThunk(
  'queries/update',
  async (
    {
      payload,
      uid,
      source
    }: UpdateItemPayload<UpdateQueryPayload> & { source?: 'list' | 'none' },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await ApiClient.put<Query, UpdateQueryPayload>(
        `/queries/${uid}`,
        payload,
        {
          isProtected: true
        }
      );
      if (source === 'list') {
        MixedPanelEvent.queryInListAction({
          actionName: 'updated',
          query: response.data
        });
      } else {
        MixedPanelEvent.queryAction({
          actionName: 'updated',
          query: response.data
        });
      }

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

const updateFolder = createAsyncThunk(
  'folder/update',
  async (
    { payload, uid }: UpdateItemPayload<UpdateQueryPayload>,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await ApiClient.put<Query>(
        `/query-folders/v2/${uid}`,
        payload,
        {
          isProtected: true
        }
      );
      toast('Your folder was successfully edited', { type: 'success' });
      MixedPanelEvent.queryFolderAction({
        actionName: 'updated',
        folderName: payload.name,
        folderUid: payload.uid,
        folderDescription: payload.description,
        sharedWith: payload.members
      });
      dispatch(folderSummaryThunk.index());
      return fulfillWithValue(response.data);
    } catch (error) {
      toast('Something went wrong', { type: 'error' });
      return rejectWithValue('Error occurred');
    }
  }
);

const remove = createAsyncThunk(
  'queries/remove',
  async (uid: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      await ApiClient.delete(
        `/queries/${uid}`,

        {
          isProtected: true
        }
      );
      MixedPanelEvent.queryAction({ actionName: 'deleted', query: { uid } });
      toast('Your query was successfully deleted', { type: 'success' });
      dispatch(folderSummaryThunk.index());
      return fulfillWithValue(uid);
    } catch (error) {
      toast('Something went wrong', { type: 'error' });
      rejectWithValue('Error occurred');
    }
  }
);

export const queriesAsyncThunk = {
  search,
  create,
  removeQueryFolder,
  folderContent,
  userQueryFolders,
  update,
  remove,
  updateFolder,
  index
};
