import { useEffect } from 'react';
import { useUserInfoSelector } from '../../store/auth/selectors';
import { IAiChatSetupController } from '../types';
import { createAiChatConfiguration } from '../factory/createAiChatConfiguration';
import { User } from '../../store/auth/types';

export const useAiBotConfigSetup = (controller: IAiChatSetupController) => {
  const user = useUserInfoSelector();
  const setupUser = (user: User) => {
    controller.triggerBotInitialLoad();
    if (user?.email) {
      const config = createAiChatConfiguration(user);
      controller.setUserConfig(config);
      controller.stopBotInitialLoad();
    }
  };
  useEffect(() => {
    setupUser(user);
  }, [user]);
};
