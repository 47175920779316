import { useEffect, useState } from 'react';
import { TableRecentQueriesContextType } from '../../../../utils/types';
import { DataCatalogApiService } from '../../../../api/api.service';
import { useFieldProfileData } from '../../../../../store/DataCatalog/selectors';
import { useDataCatalogGlobalStateActions } from '../../../../hooks/useDataCatalogGlobalStateActions';
import { useParams } from 'react-router-dom';

export const useFieldRecentQueries = (
  text: string
): TableRecentQueriesContextType => {
  const [dataStore, setDatastore] = useState({
    loading: false,
    error: null
  });
  const startLoading = () => setDatastore(p => ({ ...p, loading: true }));
  const stopLoading = () => setDatastore(p => ({ ...p, loading: false }));
  const { id } = useParams();
  const { data: fieldProfileData } = useFieldProfileData(Number(id));
  const { setProfileRecentQueries } = useDataCatalogGlobalStateActions();
  const recentQueries = fieldProfileData?.recentQueries;

  const fetchData = async () => {
    startLoading();
    try {
      const payload = { text: text };
      const data = await DataCatalogApiService.getRecentQueries(payload);
      setProfileRecentQueries({
        asset: 'field',
        data,
        id: Number(id)
      });
    } catch (error) {
      //
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (!recentQueries) {
      fetchData();
    }
  }, [text]);

  return { state: { ...dataStore, data: recentQueries } };
};
