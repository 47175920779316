import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetFieldInfoPayload,
  IGetTableInfoPayload,
  IUpdateAssetPayload
} from '../../data-catalog/utils/types';
import { DataCatalogApiService } from '../../data-catalog/api/api.service';
import { ProfileDataTransformer } from '../../data-catalog/utils/ProfileDataTransformer';
import { Logger } from '../../utils/browser/logger';
import { IProfileRecentQueriesPayload, SearchFilter } from './type';

const getTableProfile = createAsyncThunk(
  '/data-catalog/table-profile',
  async (
    payload: IGetTableInfoPayload,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await DataCatalogApiService.getTableInfo(payload);
      const data = ProfileDataTransformer.transformStateData(res);
      return fulfillWithValue(data);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const updateAsset = createAsyncThunk(
  '/data-catalog/update-table-profile',
  async (
    payload: IUpdateAssetPayload,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      await DataCatalogApiService.updateAsset(payload);
      const data = ProfileDataTransformer.transformStateData(
        payload as any
      ) as Partial<IGetTableInfoPayload>;
      Logger.info(data);
      return fulfillWithValue(data);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const getFieldProfile = createAsyncThunk(
  '/data-catalog/field-profile',
  async (
    payload: IGetFieldInfoPayload,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await DataCatalogApiService.getFieldInfo(payload);
      const data = ProfileDataTransformer.transformStateData(res);
      return fulfillWithValue(data);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const DataCatalogThunks = {
  getTableProfile,
  updateAsset,
  getFieldProfile
};
