import { styled, TypographyProps, Typography } from '@mui/material';
import { SectionContainer } from '../styled';

export const Container = styled(SectionContainer)(() => {
  return {
    height: '55%',
    overflowY: 'auto',
    '@media(max-width: 1280px)': {
      width: '100%'
    }
  };
});

export const SubheaderText = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(({ theme }) => {
  const { primary } = theme.palette;
  return `
color: ${primary.dark};
  `;
});

export const RowDataBox = styled('div')(({ theme }) => {
  return `
  display: flex;
  padding: 4px 0px;
  padding-left: 2px;
  padding-right: 12px;
  justify-content: space-between;
  `;
});

export const RowDataLeft = styled('div')(
  () => `
display: flex;
flex-direction: column; 
width: fit-content;
`
);

export const RowDataRight = styled('div')(
  () => `
display: flex;
align-items:center;
justify-content: center;
width: fit-content;
`
);
