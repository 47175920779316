import React, { useState } from 'react';
import {
  Typography,
  TableCell,
  Button,
  List,
  ListItem,
  Link,
  Box,
  TableRow,
  CircularProgress
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DefaultRowBox, LastUsedRowBox, QueriedByRowBox } from './components';
import { DiscoveryChooseFilters } from '../../api/fetch';
import { ConditionComponent } from '../../../../lib/utils';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';

interface RowProps {
  data: string;
}

interface QueriedByRowProps {
  data: string[];
}

interface LastUsedRowProps {
  data: string;
}

const ShowMoreButton = ({ data, handleShowMoreClick }: any) => {
  return (
    <ConditionComponent condition={data && data.length > 1}>
      <Button onClick={handleShowMoreClick}>show more</Button>
    </ConditionComponent>
  );
};
export const LastUsedRow = ({ data }: LastUsedRowProps) => {
  if (data === null) {
    return (
      <TableCell align="center">
        <Typography variant="body2">-</Typography>
      </TableCell>
    );
  }
  const numDaysOffset = getNumDaysOffset(data);
  const formattedDate = formatDate(data);
  return (
    <TableCell align="center">
      <LastUsedRowBox>
        <Typography variant="body2" fontWeight={'bold'}>
          {numDaysOffset} days ago
        </Typography>
        <Typography variant="body2">{formattedDate}</Typography>
      </LastUsedRowBox>
    </TableCell>
  );
};

export const QueriedByRow = ({ data }: QueriedByRowProps) => {
  return (
    <TableCell>
      <QueriedByRowBox>{data}</QueriedByRowBox>
    </TableCell>
  );
};

export const AssetNameCell = ({
  name,
  description,
  setDataforModal,
  setShowModal,
  setDataType
}: any) => {
  const handleShowMoreClick = () => {
    setDataforModal(description);
    setDataType('description');
    setShowModal(true);
  };
  return (
    <TableCell align="left">
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Typography variant="body2">{name}</Typography>
        <ConditionComponent condition={description !== ''}>
          <CustomTooltip title="About" placement="top">
            <Box>
              <InfoOutlinedIcon
                onClick={handleShowMoreClick}
                sx={{ height: '0.75em', width: '0.75em', cursor: 'pointer' }}
              />
            </Box>
          </CustomTooltip>
        </ConditionComponent>
      </Box>
    </TableCell>
  );
};

export const DashbordOwnerCell = ({ data }: any) => {
  return (
    <TableCell align="center">
      <Typography variant="body2">{data.split('@')[0]}</Typography>
    </TableCell>
  );
};

export const TablesCell = ({
  data,
  setShowModal,
  setDataforModal,
  setDataType
}: any) => {
  const topItems = data.slice(0, 1);
  const handleShowMoreClick = () => {
    setDataforModal(data);
    setDataType('table');
    setShowModal(true);
  };
  return (
    <TableCell align="center">
      <ConditionComponent condition={topItems.length > 0}>
        <List style={{ padding: 0 }}>
          {topItems.map((item: string, index: number) => (
            <ListItem
              key={index}
              style={{ padding: 0, justifyContent: 'center' }}
            >
              <Typography variant="body2">{item}</Typography>
            </ListItem>
          ))}
        </List>
        <ShowMoreButton data={data} handleShowMoreClick={handleShowMoreClick} />
      </ConditionComponent>
      <ConditionComponent condition={topItems.length == 0}>
        <Typography variant="body2">-</Typography>
      </ConditionComponent>
    </TableCell>
  );
};

export const SqlCell = ({
  setShowModal,
  setDataforModal,
  id,
  company,
  setDataType,
  show,
  dashboardName
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleShowMoreClick = async () => {
    setIsLoading(true);
    MixedPanelEvent.watsonDashboardSqlButtonClick({ dashboardName });
    try {
      const res = await DiscoveryChooseFilters(
        '',
        '',
        'dashboard-query',
        company,
        { dashboard_id: id }
      );
      setDataType('query');
      setDataforModal(res.data);
      setShowModal(true);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <TableCell align="center">
      <ConditionComponent condition={show && !isLoading}>
        <Button onClick={handleShowMoreClick}>See here</Button>
      </ConditionComponent>
      <ConditionComponent condition={isLoading}>
        <CircularProgress size={20} />
      </ConditionComponent>
      <ConditionComponent condition={!show && !isLoading}>-</ConditionComponent>
    </TableCell>
  );
};

export const CalcCell = ({
  data,
  setShowModal,
  setDataforModal,
  setDataType,
  dashboards,
  calcFieldName
}: any) => {
  const handleShowMoreClick = async () => {
    MixedPanelEvent.watsonCalculatedFieldClick({
      dashboards,
      calcFieldName
    });
    setDataType('query');
    setDataforModal([{ query: data }]);
    setShowModal(true);
  };
  return (
    <TableCell align="center">
      <Button onClick={handleShowMoreClick}>See here</Button>
    </TableCell>
  );
};

export const DashboardNameRow = ({
  name,
  url,
  description,
  type,
  setDataforModal,
  setShowModal,
  setDataType
}: any) => {
  const handleShowMoreClick = () => {
    setDataforModal(description);
    setDataType('description');
    setShowModal(true);
  };
  const handleUrlClick = () => {
    MixedPanelEvent.watsonDashboardUrlClick({ url });
  };
  return (
    <TableCell align="left">
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <ConditionComponent condition={type === 'view'}>
          <Typography variant="body2">{name}</Typography>
        </ConditionComponent>
        <ConditionComponent condition={type === 'dashboard'}>
          <Link
            onClick={handleUrlClick}
            target="_blank"
            href={url}
            variant="body2"
          >
            {name}
          </Link>
        </ConditionComponent>
        <ConditionComponent condition={description !== ''}>
          <CustomTooltip title="About" placement="top">
            <Box>
              <InfoOutlinedIcon
                onClick={handleShowMoreClick}
                sx={{ height: '0.75em', width: '0.75em', cursor: 'pointer' }}
              />
            </Box>
          </CustomTooltip>
        </ConditionComponent>
      </Box>
    </TableCell>
  );
};

export const DashboardsCell = ({
  data,
  setShowModal,
  setDataforModal,
  setDataType
}: any) => {
  const topItems = data.slice(0, 1);
  const handleShowMoreClick = () => {
    setDataforModal(data);
    setDataType('dashboard');
    setShowModal(true);
  };
  return (
    <TableCell align="center">
      <ConditionComponent condition={topItems.length > 0}>
        <List style={{ padding: 0 }}>
          {topItems.map((item, index) => (
            <ListItem
              key={index}
              style={{
                padding: 0,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline'
              }}
            >
              <ConditionComponent condition={item.url}>
                <Link target="_blank" href={item.url ?? ''} variant="body2">
                  {item.name}
                </Link>
              </ConditionComponent>
              <ConditionComponent condition={!item.url}>
                <Typography variant="body2">{item.name}</Typography>
              </ConditionComponent>
              <Typography variant="body2">
                Owner: {item.owner.split('@')[0]}
              </Typography>
              <ConditionComponent condition={item.views}>
                <Typography variant="body2">{`# of views: ${item.views}`}</Typography>
              </ConditionComponent>

              <Typography variant="body2">Folder: {item.project}</Typography>
            </ListItem>
          ))}
        </List>
        <ShowMoreButton data={data} handleShowMoreClick={handleShowMoreClick} />
      </ConditionComponent>
      <ConditionComponent condition={topItems.length == 0}>
        <Typography variant="body2">-</Typography>
      </ConditionComponent>
    </TableCell>
  );
};

export const DefaultTableRow = ({ children }: any) => {
  return (
    <TableRow sx={{ '& .MuiTableCell-body': { padding: '5px 10px' } }}>
      {children}
    </TableRow>
  );
};
export const DefaultRow = ({ data }: RowProps) => {
  return (
    <TableCell align="center">
      <DefaultRowBox>
        <Typography
          component={'p'}
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center'
          }}
        >
          {data}
        </Typography>
      </DefaultRowBox>
    </TableCell>
  );
};

export const TableHeaderCell = ({
  children
}: {
  children: string | number;
}) => {
  return (
    <TableCell align="left">
      <Box
        sx={{
          height: '20px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Typography
          fontWeight={'bold'}
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'nowrap'
          }}
          variant="caption"
        >
          {children}
        </Typography>
      </Box>
    </TableCell>
  );
};

export function formatDate(dateStr: string) {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function getNumDaysOffset(dateStr: string) {
  const date = new Date(dateStr).getTime();
  const now = new Date().getTime();
  const offsetDate = now - date;
  const numDaysOffset = offsetDate / 86400000;
  return Math.floor(numDaysOffset);
}
