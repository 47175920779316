import React from 'react';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';
import { FilterLabel } from './filterLabel';
import { useFolderSummarySelector } from '../../../store/folderSummary/selectors';
import { SelectFolderDropdown } from '../../../components/selectFolderDropdown';
import { selectFolderDropdownContainerStyle, addFolderDropdownButtonStyles, addFolderDropdownFolderTextStyles, addFolderDropdownContainerStyles } from '../../../components/modals/styles';
import { unionBy } from 'lodash';
import { useSharedFoldersSelector } from '../../../store/sharedFolders/selectors';

interface SelectedFolderProps {
  value: string;
  onChange?: any;
}

export const SelectedFolder = ({
  value,
  onChange
}: SelectedFolderProps) => {
    const folderSummary = useFolderSummarySelector();
    const folderSummaryShared = useSharedFoldersSelector();
    const folders = unionBy(folderSummary.list, folderSummaryShared.list, 'uid');
    const handleItemClick = (folder: any) => {
        // setParentId(folder.uid);
        onChange(folder?.uid)
      };
  return (
    <Box className="filter-item-container" >
      <FilterLabel>In Folder</FilterLabel>
      {/* <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        value={value}
        defaultValue=""
        onChange={onChange}
        sx={{
          flex: 1
        }}
      > */}
        <Box sx={selectFolderDropdownContainerStyle}>
                <SelectFolderDropdown
                  folders={[{
                    ...folders[0],
                    name: 'All Folders',
                    uid: '-1',
                    sub_folders: []
                  }, ...folders]}
                  onChange={folder => handleItemClick(folder)}
                  label={null}
                  buttonProps={{
                    sx: addFolderDropdownButtonStyles,
                    // disabled: !isSub
                  }}
                  textProps={{
                    sx: addFolderDropdownFolderTextStyles
                  }}
                  containerProps={{
                    sx: addFolderDropdownContainerStyles
                  }}
                  selected={value}
                //   showAllOption
                />
              </Box>
        {/* {SelectedFolderOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} style={{ height: '25px' }}>
            <Typography variant="caption">{label}</Typography>
          </MenuItem>
        ))} */}
      {/* </Select> */}
    </Box>
  );
};
