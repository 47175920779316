import { createContext } from 'react';
import {
  WatsonHidePromptsContextType,
  WatsonMessageContextType,
  WatsonMetaContextType,
  WatsonPromptContextType,
  WatsonPromptKeywordContextType
} from '../interfaces';

export const WatsonPromptsContext =
  createContext<WatsonPromptContextType>(null);

export const WatsonPromptKeywordContext =
  createContext<WatsonPromptKeywordContextType>(null);

export const WatsonMessagingContext =
  createContext<WatsonMessageContextType>(null);

export const WatsonMetaContext = createContext<WatsonMetaContextType>(null);
export const WatsonHidePromptsContext =
  createContext<WatsonHidePromptsContextType>(null);
