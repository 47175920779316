import { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ConditionComponent } from '../../../../lib/utils';
import { DiscoveryType } from '../../interfaces';
import { DashboardFilters } from './DashboardFilters';
import { TableFilters } from './TableFilters';
import { FieldFilters } from './FieldFilters';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import { TypeAnimation } from 'react-type-animation';
import { useDarkModeState } from '../../../../store/themeMode/selectors';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { CalculatedFieldFilters } from './CalculatedFieldFilters';
import { QueryFilters } from './QueryFilters';
import { useDiscoveryAiBotActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryActions';

export const Filters = ({
  type,
  filters,
  showFiltersOnMount = false,
  summary,
  conversationId
}: {
  type: DiscoveryType;
  filters: {};
  showFiltersOnMount?: boolean;
  summary?: string;
  conversationId?: string;
}) => {
  const darkMode = useDarkModeState();
  const { chooseFilter } = useDiscoveryAiBotActions();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedTable, setSelectedTable] = useState<string>(
    filters && filters['table_name'] ? filters['table_name'] : ''
  );
  const [selectedField, setSelectedField] = useState<string>(
    filters && filters['field_name'] ? filters['field_name'] : ''
  );
  const [selectedQueriedBy, setSelectedQueriedBy] = useState<string>(
    filters && filters['queried_by'] ? filters['queried_by'] : ''
  );
  const [selectedSchema, setSelectedSchema] = useState<string>(
    filters && filters['db_name'] ? filters['db_name'] : ''
  );
  const [selectedDashboardName, setSelectedDashboardName] = useState<string>(
    filters && filters['dashboard_name'] ? filters['dashboard_name'] : ''
  );
  const [selectedDashboardTable, setSelectedDashboardTable] = useState<string>(
    filters && filters['dashboard_tables'] ? filters['dashboard_tables'][0] : ''
  );
  const [selectedDashboardField, setSelectedDashboardField] = useState<string>(
    filters && filters['dashboard_fields'] ? filters['dashboard_fields'][0] : ''
  );

  const [selectedDashboardType, setSelectedDashboardType] =
    useState('dashboard');

  const [selectedIsPartitioned, setSelectedIsPartitioned] = useState(
    filters && filters['partitioned'] ? filters['partitioned'] : ''
  );
  const [selectedCalculatedField, setSelectedCalculatedField] =
    useState<string>(
      filters && filters['calculated_field_name']
        ? filters['calculated_field_name']
        : ''
    );
  const [selectedQueryContains, setSelectedQueryContains] = useState<string>(
    filters && filters['query_contains'] ? filters['query_contains'] : ''
  );

  const [selectedTimeExecuted, setSelectedTimeExecuted] = useState<string>(
    filters && filters['query_executed_at']
      ? filters['query_executed_at']
      : '90'
  );
  const [selectedRelevantTables, setSelectedRelevantTables] = useState<string>(
    filters && filters['relevant_tables'] ? filters['relevant_tables'] : ''
  );

  const handleSchemaSelection = (value: any) => {
    setSelectedSchema(value);
  };

  const handleTableSelection = (value: string) => {
    setSelectedTable(value);
  };

  const handleFieldSelection = (value: string) => {
    setSelectedField(value);
  };

  const handleQueriedBySelection = (value: string) => {
    setSelectedQueriedBy(value);
  };
  const handleDashboardNameSelection = (value: string) => {
    setSelectedDashboardName(value);
  };
  const handleDashboardTableSelection = (value: string) => {
    setSelectedDashboardTable(value);
  };
  const handleDashboardFieldSelection = (value: string) => {
    setSelectedDashboardField(value);
  };
  const handleIsPartitionedFieldSelection = (value: string) => {
    setSelectedIsPartitioned(value);
  };
  const handleDashboardTypeFieldSelection = (value: string) => {
    setSelectedDashboardType(value);
  };
  const handleCalculatedFieldSelection = (value: string) => {
    setSelectedCalculatedField(value);
  };
  const handleQueryContainsSelection = (value: string) => {
    setSelectedQueryContains(value);
  };
  const handleTimeExectuedSelection = (value: string) => {
    setSelectedTimeExecuted(value);
  };
  const handleRelevantTablesSelection = (value: string) => {
    setSelectedRelevantTables(value);
  };

  const areAllFieldsEmpty = () => {
    return (
      !selectedTable &&
      !selectedField &&
      !selectedQueriedBy &&
      !selectedSchema &&
      !selectedDashboardName &&
      !selectedDashboardTable &&
      !selectedIsPartitioned &&
      !selectedDashboardType &&
      !selectedCalculatedField &&
      !selectedQueryContains &&
      !selectedRelevantTables &&
      !selectedTimeExecuted
    );
  };

  const handleSubmitClick = async () => {
    setIsButtonDisabled(true);
    let dynamicFilters: { [key: string]: any } = {};
    if (selectedSchema) dynamicFilters['db_name'] = selectedSchema;
    if (selectedTable) dynamicFilters['table_name'] = selectedTable;
    if (selectedField) dynamicFilters['field_name'] = selectedField;
    if (selectedQueriedBy) dynamicFilters['queried_by'] = selectedQueriedBy;
    if (selectedDashboardName)
      dynamicFilters['dashboard_name'] = selectedDashboardName;
    if (selectedDashboardTable)
      dynamicFilters['dashboard_tables'] = [selectedDashboardTable];
    if (selectedIsPartitioned)
      dynamicFilters['partitioned'] = selectedIsPartitioned;
    if (selectedDashboardType && type === 'dashboard')
      dynamicFilters['dashboard_type'] = selectedDashboardType;
    if (selectedCalculatedField)
      dynamicFilters['calculated_field_name'] = selectedCalculatedField;
    if (selectedQueryContains)
      dynamicFilters['query_contains'] = selectedQueryContains;
    if (selectedTimeExecuted)
      dynamicFilters['query_executed_at'] = selectedTimeExecuted;
    if (selectedRelevantTables)
      dynamicFilters['query_tables'] = selectedRelevantTables;

    chooseFilter(type, dynamicFilters);
    MixedPanelEvent.watsonFilterSubmit({
      conversation_uid: conversationId,
      question_type: type,
      filters: dynamicFilters
    });
  };
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [showFilters, setShowFilters] = useState(showFiltersOnMount);
  const animationContainerRef = useRef(null);
  const highlightWords = () => {
    const container = animationContainerRef.current;
    if (container) {
      let innerHTML = container.innerHTML;
      const appliedFilters = Object.values(filters) as string[];
      appliedFilters
        .filter(a => typeof a === 'string')
        .forEach(filterValue => {
          if (filterValue) {
            const regex = new RegExp(`(${filterValue})`, 'g');
            innerHTML = innerHTML.replace(regex, '<strong>$1</strong>');
          }
        });
      container.innerHTML = innerHTML;
    }
  };
  useEffect(() => {
    if (isAnimationDone) {
      highlightWords();
    }
  }, [isAnimationDone, filters]);

  const handleEditInfoClick = () => {
    setShowFilters(true);
    MixedPanelEvent.watsonFilterEditInfo({
      conversation_uid: conversationId,
      question_type: type
    });
  };
  const handleFiltersExtractionEvent = () => {
    MixedPanelEvent.watsonExtractedFilters({
      conversation_uid: conversationId,
      question_type: type,
      filter_extractions: filters
    });
  };
  return (
    <Box>
      <ConditionComponent condition={!!showFiltersOnMount}>
        <Typography
          fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          fontSize={'1rem'}
        >
          {summary}
        </Typography>
      </ConditionComponent>
      <ConditionComponent condition={!showFiltersOnMount}>
        <div
          ref={animationContainerRef}
          style={{
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '1rem'
          }}
        >
          <TypeAnimation
            speed={80}
            sequence={[
              summary,
              () => setIsAnimationDone(true),
              handleFiltersExtractionEvent
            ]}
            wrapper="div"
            cursor={false}
            repeat={0}
          />
        </div>
      </ConditionComponent>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px'
        }}
      >
        <ConditionComponent condition={showFilters}>
          <ConditionComponent condition={type === 'table'}>
            <TableFilters
              selectedTable={selectedTable}
              handleTableSelection={handleTableSelection}
              selectedField={selectedField}
              handleFieldSelection={handleFieldSelection}
              selectedSchema={selectedSchema}
              handleSchemaSelection={handleSchemaSelection}
              selectedQueriedBy={selectedQueriedBy}
              handleQueriedBySelection={handleQueriedBySelection}
              selectedDashboardName={selectedDashboardName}
              handleDashboardNameSelection={handleDashboardNameSelection}
              isDisabled={isButtonDisabled}
            />
          </ConditionComponent>
          <ConditionComponent condition={['field', 'partition'].includes(type)}>
            <FieldFilters
              selectedTable={selectedTable}
              handleTableSelection={handleTableSelection}
              selectedField={selectedField}
              handleFieldSelection={handleFieldSelection}
              selectedSchema={selectedSchema}
              handleSchemaSelection={handleSchemaSelection}
              selectedQueriedBy={selectedQueriedBy}
              handleQueriedBySelection={handleQueriedBySelection}
              isDisabled={isButtonDisabled}
              selectedIsPartitioned={selectedIsPartitioned}
              handleIsPartitionedFieldSelection={
                handleIsPartitionedFieldSelection
              }
            />
          </ConditionComponent>
          <ConditionComponent condition={type === 'calculated-field'}>
            <CalculatedFieldFilters
              selectedCalculatedField={selectedCalculatedField}
              handleCalculatedFieldSelection={handleCalculatedFieldSelection}
              selectedField={selectedField}
              handleFieldSelection={handleFieldSelection}
              selectedDashboardName={selectedDashboardName}
              handleDashboardNameSelection={handleDashboardNameSelection}
              isDisabled={isButtonDisabled}
            />
          </ConditionComponent>
          <ConditionComponent condition={type === 'dashboard'}>
            <DashboardFilters
              selectedDashboardName={selectedDashboardName}
              handleDashboardNameSelection={handleDashboardNameSelection}
              selectedDashboardTable={selectedDashboardTable}
              handleDashboardTableSelection={handleDashboardTableSelection}
              selectedDashboardType={selectedDashboardType}
              handleDashboardTypeFieldSelection={
                handleDashboardTypeFieldSelection
              }
              isDisabled={isButtonDisabled}
            />
          </ConditionComponent>
          <ConditionComponent condition={type === 'query'}>
            <QueryFilters
              selectedQueryContains={selectedQueryContains}
              handleQueryContainsSelection={handleQueryContainsSelection}
              selectedTimeExecuted={selectedTimeExecuted}
              handleTimeExectuedSelection={handleTimeExectuedSelection}
              selectedQueriedBy={selectedQueriedBy}
              handleQueriedBySelection={handleQueriedBySelection}
              selectedRelevantTables={selectedRelevantTables}
              handleRelevantTablesSelection={handleRelevantTablesSelection}
              isDisabled={isButtonDisabled}
            />
          </ConditionComponent>
        </ConditionComponent>
        <ConditionComponent condition={isAnimationDone || showFilters}>
          <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
            <Box
              sx={{ width: 'auto', alignSelf: 'end', textTransform: 'none' }}
            >
              <Button
                fullWidth={true}
                variant="outlined"
                onClick={handleEditInfoClick}
                disabled={showFilters}
              >
                <Typography
                  fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
                  fontSize={16}
                  style={{ color: darkMode ? 'white' : '#7209B7', padding: 0 }}
                >
                  Edit info
                </Typography>
              </Button>
            </Box>
            <CustomTooltip
              title={
                areAllFieldsEmpty() && !setShowFilters ? (
                  <Typography>
                    Required to fill out at least one filter
                  </Typography>
                ) : (
                  ''
                )
              }
              placement="top"
            >
              <Box
                sx={{ width: '20%', alignSelf: 'end', textTransform: 'none' }}
              >
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={handleSubmitClick}
                  disabled={areAllFieldsEmpty() || isButtonDisabled}
                >
                  <Typography
                    fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
                    fontSize={16}
                    style={{ color: 'white', padding: 0 }}
                  >
                    Submit
                  </Typography>
                </Button>
              </Box>
            </CustomTooltip>
          </Box>
        </ConditionComponent>
      </Box>
    </Box>
  );
};
