import { Fragment, useMemo, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AiBotVariant } from '../../../domain/chatTypes';
import {
  AiBotTypeSelectorFn,
  IAiBotTypeMeta,
  ToggleAiBotTypeFn
} from '../../../types';
import { ToggleButtonIcon } from './BotTypeIcon';
import { MenuContainer } from './menuContainer';
import { BotType } from './BotType';
import NotifyToggleModal from './notifyToggleModal';
import { ChatToggleContainer } from './container';

interface Props {
  handleChatTypeToggle: AiBotTypeSelectorFn;
  initalValue: AiBotVariant;
  options: IAiBotTypeMeta[];
}

const ChatToggle = ({ handleChatTypeToggle, initalValue, options }: Props) => {
  const [value, setValue] = useState<AiBotVariant>(initalValue);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>(null);
  const selectedVariantMeta = useMemo(() => {
    return options.find(itm => itm.variant === value);
  }, [options, value]);
  const [toggleAlertAnchor, setToggleAlertAnchor] = useState(null);

  const handleOpenMenu = () => {
    setMenuAnchor(buttonRef.current);
  };

  const handleCloseMenu = () => setMenuAnchor(null);

  const handleOnSelectMenu: ToggleAiBotTypeFn = (ev, variant) => {
    if (value === variant) {
      handleCloseMenu();
    } else {
      const target = (ev.target as HTMLElement).parentElement.parentElement;
      setToggleAlertAnchor(target);
    }
  };

  const onConfirmToggle = () => {
    const type = (toggleAlertAnchor as HTMLElement).getAttribute(
      'data-type'
    ) as any;
    onCancelToggle();
    handleCloseMenu();
    if (type) {
      setValue(type);
      handleChatTypeToggle(type);
    }
  };
  const onCancelToggle = () => {
    setToggleAlertAnchor(null);
  };

  return (
    <Fragment>
      <ChatToggleContainer ref={buttonRef} onClick={handleOpenMenu}>
        <Stack direction={'row'} columnGap={'4px'} alignItems={'center'}>
          <ToggleButtonIcon variant={value} />
          <Stack direction={'column'}>
            <Typography variant="body2" sx={{ textTransform: 'none' }}>
              {selectedVariantMeta.label}
            </Typography>
          </Stack>
        </Stack>
        <ExpandMoreIcon fontSize="medium" sx={{ marginLeft: '12px' }} />
      </ChatToggleContainer>
      <MenuContainer
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        {options.map(itm => (
          <BotType
            meta={itm}
            onClick={handleOnSelectMenu}
            selected={itm.variant === value}
          />
        ))}
      </MenuContainer>

      <NotifyToggleModal
        anchorEl={toggleAlertAnchor}
        open={!!toggleAlertAnchor}
        onConfirm={onConfirmToggle}
        onCancel={onCancelToggle}
        onClose={onCancelToggle}
        style={{ zIndex: 9999999999 }}
      />
    </Fragment>
  );
};

export default ChatToggle;
