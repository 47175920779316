import { useTypedSelector } from '..';

export const useTableProfileData = (id: number) =>
  useTypedSelector(({ dataCatalog }) => dataCatalog.tableProfile?.[id]);

export const useFieldProfileData = (id: number) =>
  useTypedSelector(({ dataCatalog }) => dataCatalog.fieldProfile?.[id]);

export const useDataCatalogSearchResults = () => {
  return useTypedSelector(({ dataCatalog }) => dataCatalog.search);
};
