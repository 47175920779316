import React from 'react';
import { styled, FormControl, FormControlLabel } from '@mui/material';

export const Container = styled(FormControl)(() => {
  return {
    marginBottom: '24px !important'
  };
});

export const RadioButtonContainer = styled(FormControlLabel)(() => {
  return {
    display: 'flex',
    height: '32px',
    border: '1px solid #d9d9d9',
    borderRadius: '3px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '8px',
    boxSizing: 'border-box',
    marginRight: '8px !important',
    marginLeft: '0 !important'
  };
});
