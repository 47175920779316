import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Box, BoxProps } from '@mui/material';
import Header, { HeaderProps } from '../../lib/components/Header';
import Sidebar from '../../lib/components/Sidebar';
import SearchFilterComponent from '../../pages/SearchFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutBody } from './components/layout-body';
import { usePageLayoutPropsState } from '../../context/hooks';
import { ConditionComponent } from '../../lib/utils';
import { useUserAuthenticatedState } from '../../store/auth/selectors';
import { useInitAuthenticatedUserResources } from '../../hooks/auth/useInitAuthenticatedUserResources';
import { useShowConfettiState } from '../../store/confetti/selectors';
import { useAppDispatch } from '../../store';
import { toggleShowConfetti } from '../../store/confetti';
import Joyride, {
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
  LIFECYCLE,
  ACTIONS
} from 'react-joyride';
import ApiClient from '../../lib/utils/apiClient';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

interface JoyrideState {
  joyrideRun: boolean;
  joyrideSteps: Step[];
}


export interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  bodyContainerProps?: BoxProps & { 'data-theme'?: string };
  showSideBar?: boolean;
  headerProps?: HeaderProps;
}

const Layout: React.FC<Props> = ({
  children,
  bodyContainerProps,
  showSideBar = true,
  headerProps,
  ...props
}) => {
  const pageLayoutProps = usePageLayoutPropsState()[0];
  const location = useLocation();
  const isLoggedIn = useUserAuthenticatedState();
  const showConfetti = useShowConfettiState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [{ joyrideRun, joyrideSteps }, setJoyrideState] = useState<JoyrideState>({
    joyrideRun: false,
    joyrideSteps: []
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index, action, lifecycle, type} = data;
    
    if (type === EVENTS.TOUR_START) {
      MixedPanelEvent.joyrideOnboardingStart();
    }
    else if (lifecycle == LIFECYCLE.COMPLETE){
      if (action == ACTIONS.NEXT){
        MixedPanelEvent.joyrideOnboardingClickNext(index);
      }
      else if(action == ACTIONS.PREV){
        MixedPanelEvent.joyrideOnboardingClickPrev(index);
      }
      else if(action == ACTIONS.SKIP){
        MixedPanelEvent.joyrideOnboardingClickSkip(index);
      }
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if(index == 4 && action == 'update'){
      navigate('/saved-queries');
    }
    if (finishedStatuses.includes(status)) {
      setJoyrideState(prevState => ({
        ...prevState,
        joyrideRun: false,
      }));
    }

    ApiClient.put(
      'accounts/users/dont_show_suggestions',
      { type: 'joyrides', suggestion: 'onboarding' },
      {
        isProtected: true
      }
    );
  };

  const checkShowOnboarding = async () => {
    ApiClient.get('accounts/users/features/joyrides ', {
      isProtected: true
    }).then(res => {
      const userJoyride = res?.data?.config?.data || [];
      const onbordingJoyride = userJoyride.find(
        (item: any) => item.name === 'onboarding'
      );
      if (onbordingJoyride) {
        setJoyrideState(prevState => ({
          ...prevState,
          joyrideRun: true,
          joyrideSteps: [
            {
              content: (
                <>
                    
                  <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    <b>Welcome to Sherloq 🔍 </b>
                  </h1>
                  <img
                      style={{ height: '200px' }}
                      src={require('../../sherloq_welcome.png')}
                    />
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Happy to have you onboard with us! <br/>
                    <br/>
                    We'll start with a 5 step product tour to show you our most used features.<br/>
                  </p>
                </>
              ),
              placement: 'center',
              target: '.joyride_botson_btn',
              showProgress: false,
              disableBeacon: true,
              
              locale: { next: "Let's Go!" },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            },
            {
              content: (
                <>
                  <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Step 1/5: <b>Botson Chatbot</b> 🤖
                  </h1>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    
                    You can think of Botson like ChatGPT but for your internal database.<br/>
                    <br/>
                    You can ask questions such as: <br/>
                    <i>“What is the SQL behind dashboard (dashboard_name)"</i><br/>
                    or<br/>
                    <i>“Find all fields in table (table_name)"</i>.
                  </p>
                </>
              ),
              placement: 'bottom',
              target: '.joyride_boston_search_bar',
              showProgress: false,
              disableBeacon: true,
              
              locale: { skip: 'Finish Tour' },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            },
            {
              content: (
                <>
                <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Step 2/5: <b>SQL Co-pilot</b> 🚀
                  </h1>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Our co-pilot helps you remember what you're supposed to use exactly when you need it. <br/>
                    <br/>
                    Give it a shot!<br/>
                    In your query editor type: <br/>
                    <i>"select * from (table_name) where "</i><br/>
                    and see what we can do.
                  </p>
                </>
              ),
              placement: 'center',
              target: 'body',
              showProgress: false,
              disableBeacon: true,
              
              locale: { skip: 'Finish Tour' },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            },
            {
              content: (
                <>
                  <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'left'
                    }}
                  >
                    Step 3/5
                  </h1>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'left'
                    }}
                  >
                    Click here to see our SQL Repository
                  </p>
                </>
              ),
              placement: 'right',
              target: '.joyride_repository_btn',
              showProgress: false,
              disableBeacon: true,
              
              locale: { skip: 'Finish Tour' },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            },
            {
              content: (
                <>
                 <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Step 4/5: <b>SQL Repository</b> 💫
                  </h1>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    This is the one place for all your day-to-day SQL and snippets of code.<br/>
                    <br/>
                    You can add and tag queries,<br/>
                    create new folders and share them with your team,<br/>
                    and even edit existing queries to create versions of them.<br/>
                    <br/>
                  </p>
                </>
              ),
              placement: 'center',
              target: 'body',
              showProgress: false,
              disableBeacon: true,
              
              locale: { skip: 'Finish Tour' },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            },
            {
              content: (
                <>
                  <h1
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 500,
                      fontSize: '20px',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Step 5/5: <b>All Done!</b> 🎉
                  </h1>
                  <p
                    style={{
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      fontWeight: 300,
                      fontSize: '16px',
                      lineHeight: '130%',
                      color: 'rgba(0, 0, 0, 0.8)',
                      textAlign: 'center'
                    }}
                  >
                    Other than that, we're here for you!<br/>
                    <br/>
                    Feel free to reach out to us on Slack / or email us at <br/>
                    hello@sherloqdata.io.
                  </p>
                </>
              ),
              placement: 'center',
              target: 'body',
              showProgress: false,
              disableBeacon: true,
              
              locale: { last: 'Finish Tour' },
              styles: {
                options: { width: '500px' },
                buttonNext: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'white',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '5px',
                  backgroundColor: '#0075E7'
                },
                buttonBack: {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 300,
                  fontSize: '16px',
                  lineHeight: '130%',
                  color: 'black'
                }
              }
            }
          ]
        }));
      }
    });
  };

  useInitAuthenticatedUserResources();
  // React.useEffect(() => {
  //   if (isLoggedIn) {
  //     checkShowOnboarding();
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    setTimeout(() => dispatch(toggleShowConfetti(false)), 4000);
  }, [showConfetti]);
  return (
    <Box className={`layout`} {...(props ?? pageLayoutProps)}>
      <Header {...(headerProps ?? pageLayoutProps.headerProps)} isLoginPage={location.pathname === '/'}/>
      <LayoutBody className="layout-container">
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={joyrideRun}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyrideSteps}
            styles={{
              options: {
                zIndex: 1000000000
              }
            }}
          />
        <ConditionComponent condition={isLoggedIn}>
          <ConditionComponent condition={showSideBar}>
            <Sidebar />
          </ConditionComponent>
          <SearchFilterComponent />
        </ConditionComponent>
        <LayoutBody
          className="body joyride_body"
          {...(bodyContainerProps ?? pageLayoutProps.bodyContainerProps)}
          style={{
            ...(bodyContainerProps?.style ||
              pageLayoutProps.bodyContainerProps?.style)
          }}
        >
          <ConditionComponent condition={showConfetti}>
            <Confetti numberOfPieces={200} />
          </ConditionComponent>
          {children}
        </LayoutBody>
      </LayoutBody>
    </Box>
  );
};

export default Layout;
