import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { ModalRoot, ModalRootProps } from './root';
import { sx } from '../../styles/MUI/styles';
import { ModalTypography } from './components/label';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

interface TrialModalProps
  extends Pick<ModalRootProps, 'open' | 'onClose' | 'variant'> {
  title: string;
  description: string;
  buttonLabel?: string;
  onClick?: onClickHandler<HTMLButtonElement>;
}

export const TrialModal = ({
  description,
  title,
  buttonLabel = 'Got it',
  onClick,
  ...props
}: TrialModalProps) => {

  useEffect(() => {
    MixedPanelEvent.messageFreeTrialAppear()
  }, [])

  return (
    <ModalRoot {...props} blurredBG >
      <ModalTypography className="title">{title}</ModalTypography>
      <Box className="content">
        <ModalTypography className="sub-title">{description}</ModalTypography>
        <Box className="buttons-container">
          <Button
            sx={sx.btn}
            variant="contained"
            onClick={onClick}
            className={`${
              props.variant === 'error' ? 'error-button' : 'default-button'
            }`}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </ModalRoot>
  );
};
