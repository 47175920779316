import React from 'react';
import { Box } from '@mui/material';

import { Button } from '@mui/material';
import { ModalRoot, ModalRootProps } from '../../../components/modals/root';

interface HotSaveAsLayoutProps
  extends Omit<ModalRootProps, 'variant' | 'open'> {
  footer?: React.ReactNode;
  onCancel?: () => void;
  onSave?: () => void;
}

const defaultButtonSx = {
  width: '78px',
  textTransform: 'none',
  borderRadius: '4px'
};
const cancelButtonSx = {
  ...defaultButtonSx,
  color: '#a9a9a9',
  border: '1px solid #a9a9a9'
};
const saveButtonSx = {
  ...defaultButtonSx,
  color: '#fff',
  backgroundColor: '#0075E7'
};

export const HotSaveAsLayout = ({
  children,
  footer: Footer,
  onSave,
  onCancel,
  ...props
}: HotSaveAsLayoutProps) => {
  return (
    <ModalRoot open onClose={onCancel} variant="default" {...props}>
      <Box>{children}</Box>
      <Box className="hot_save_as_container--footer">
        <div className=".hot_save_as_container--footer footer-node">
          {Footer}
        </div>
        <div className="hot_save_as_container--footer default-btns">
          <Button onClick={onCancel} sx={cancelButtonSx} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onSave} sx={saveButtonSx} variant="contained">
            Save
          </Button>
        </div>
      </Box>
    </ModalRoot>
  );
};
