import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { sx } from '../../styles/MUI/styles';
import WebAccessibleEvents from '../../utils/events';
import { CustomTooltip } from '../../atoms/CustomTooltip';
import { IconButton, InputBase, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggleSearchFiltersModal } from '../../context/hooks';
import { CloseRounded, SearchRounded, Tune } from '@mui/icons-material';
import { SearchActions } from '../../store/searchAndFilter';
import { useSearchAndFilterState } from '../../store/searchAndFilter/selector';
import { debounce } from 'lodash';
import { Container, Form } from './components';
import { useDarkModeState } from '../../store/themeMode/selectors';

const SearchQueryComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchAndFilter = useSearchAndFilterState()!;
  const [searchStr, setSearchStr] = useState('');
  const toggleSearchFilters = useToggleSearchFiltersModal()!;
  const darkMode = useDarkModeState();

  const updateSearchStr = useCallback(
    debounce((str: string) => {
      dispatch(SearchActions.setSearchStr(str));
    }, 300),
    [searchAndFilter]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, value?: string) => {
    let $value = e && e.target ? e.target.value : (value ?? e.target.value);
    document.dispatchEvent(
      new CustomEvent(WebAccessibleEvents.DISPATCH_SEARCH_QUERY_TEXT, {
        detail: $value
      })
    );
    setSearchStr($value);
    updateSearchStr($value);
    if (!location.pathname.includes('search')) {
      submitSearch($value);
    }
    if ($value === '') {
      navigate('/saved-queries')
    }
  };

  const handleEnterKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchStr.trim()) {
        search();
      }
    } else if (e.key === 'Escape') {
      onChange(null, '')
    }
  };

  const clearValue = () => {
    onChange(undefined, '');
  };

  const search = () => {
    submitSearch(searchStr);
  };

  const submitSearch = (query: string) => {
    if (!query) return;
    const preSearchRoute = !location.pathname.includes('search')
      ? location.pathname
      : (location.state as any)?.preSearchRoute;
    const searchType = searchAndFilter.in_folder == '-1' ? searchAndFilter.type : 'saved'
    navigate(`/search/${searchType}`, {
      state: {
        keyword: query,
        preSearchRoute
      }
    });
  };

  useEffect(() => {
    const state = location.state;
    if (state?.keyword) {
      dispatch(SearchActions.setSearchStr(state.keyword));
      setSearchStr(state.keyword);
    }
  }, []);

  return (
    <Container>
      <Form>
        <CustomTooltip title="Search" placement="bottom-start">
          <IconButton aria-label="menu" onClick={search}>
            <SearchRounded sx={darkMode ? sx.sx5dark : sx.sx5} />
          </IconButton>
        </CustomTooltip>
        <InputBase
          autoComplete="off"
          sx={{ ml: 0.5, flex: 1, fontSize: '12px' }}
          id="searchString1"
          onInput={onChange}
          onKeyDown={handleEnterKeyDown}
          value={searchStr}
          placeholder="Search name, tag or query"
        />
        <CustomTooltip title="Search filters" placement="bottom-start">
          <IconButton type="button" aria-label="search">
            <Tune
              sx={darkMode ? sx.sx5dark : sx.sx5}
              onClick={() => {
                toggleSearchFilters();
              }}
            />
          </IconButton>
        </CustomTooltip>
        {searchStr && (
          <IconButton color="primary" aria-label="directions">
            <CloseRounded sx={darkMode ? sx.sx5dark : sx.sx5} onClick={clearValue} />
          </IconButton>
        )}
      </Form>
    </Container>
  );
};

export default SearchQueryComponent;
