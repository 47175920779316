import { debounce, uniqueId } from 'lodash';
import { Logger } from '../../../utils/browser/logger';
import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import { AIBotQuestion, IAiChatTypeProvider } from '../../types';
import { AiBotVariant } from '../../domain/chatTypes';
import { useAiBotDataStoreActions } from '../../hooks/useAiBotDataStoreActions';
import { useAiBotMessages } from '../../hooks/useAiBotMessages';
import { AiDiscoveryBotResponseMessage, DiscoveryType } from './types';
import { buildAssetChooseMessage } from './utils/buildAssetChooseMessage';
import { DiscoverApiService } from './api.service';
import { DiscoveryAiBotActionsContext } from './contexts';
import { buildFilterMessage } from './utils/buildFilterMessage';
import { useAiBotEntryMessage } from '../../hooks/useAiBotEntryMessage';

export const DiscoveryProvider = (props: IAiChatTypeProvider) => {
  const ActionsContext = props.botAbstractActionsContext;
  const { sessionId, chatType, company } = useAiBotMeta();
  const messages = useAiBotMessages();
  const {
    loadMessage,
    addToBotMessages,
    stopLoadingMessage,
    hideInput,
    updateVariantMeta,
    clearMessages
  } = useAiBotDataStoreActions();
  const message = useAiBotEntryMessage();

  const lazyAddToMessages = debounce((payload: any, cb: Function) => {
    addToBotMessages(payload);
    stopLoadingMessage();
    cb();
  }, 1200);

  const createQuestionMessagePayload = (message: string) => {
    const payload: AIBotQuestion = {
      message,
      conversationId: sessionId,
      chatType: AiBotVariant.BotsonDiscovery,
      id: uniqueId(),
      variant: 'question'
    };
    return payload;
  };

  const sendUserMessage = (message: string) => {
    const triggerMessagePayload = createQuestionMessagePayload(message);
    addToBotMessages(triggerMessagePayload);
  };

  const openAssetChoosePhase = () =>
    updateVariantMeta({ discoveryPhase: 'asset-choose' });

  const sendAssetChooseMessage = () => {
    const assetChooseResponse =
      'Hey, just making sure I understand what you’re looking for';
    const payload: AiDiscoveryBotResponseMessage = {
      chatType: chatType as AiBotVariant.BotsonDiscovery,
      conversationId: sessionId,
      id: uniqueId(),
      message: assetChooseResponse,
      status: 'success',
      variant: 'response',
      isThread: false
    };
    lazyAddToMessages(payload, openAssetChoosePhase);
  };

  const handleTriggerMessage = (message: string) => {
    sendUserMessage(message);
    loadMessage();
    hideInput();
    sendAssetChooseMessage();
  };

  const sendMessage = (message: string) => {
    const isTriggerMessage = messages.length === 0;
    if (isTriggerMessage) {
      Logger.info({ isTriggerMessage, message });
      handleTriggerMessage(message);
    }
  };

  function createResponseMessageFromApiResult(
    data: any
  ): AiDiscoveryBotResponseMessage {
    return {
      discoveryAssets: data.results,
      discoveryFilters: data.filters,
      discoverySummary: data.summary,
      status: 'success',
      chatType: chatType as AiBotVariant.BotsonDiscovery,
      conversationId: sessionId,
      id: uniqueId(),
      message: '',
      variant: 'response'
    };
  }

  const chooseAsset = async (type: DiscoveryType) => {
    loadMessage();
    const messageBody = buildAssetChooseMessage(type);
    const questionPayload = createQuestionMessagePayload(messageBody);
    addToBotMessages(questionPayload);
    updateVariantMeta({ discoveryPhase: 'none' });
    const data = await DiscoverApiService.selectFilters(
      message.message,
      sessionId,
      type,
      company,
      null
    );
    Logger.info(data);
    const responseMessage = createResponseMessageFromApiResult(data);
    addToBotMessages(responseMessage);
    updateVariantMeta({ discoveryType: type, discoveryPhase: 'filter-choose' });
    stopLoadingMessage();
  };

  const chooseFilter = async (type: DiscoveryType, filters: {}) => {
    loadMessage();
    const messageBody = buildFilterMessage(filters, type);
    const questionPayload = createQuestionMessagePayload(messageBody);
    addToBotMessages(questionPayload);
    const data = await DiscoverApiService.selectFilters(
      message.message,
      sessionId,
      type,
      company,
      filters
    );
    const responseMessage = createResponseMessageFromApiResult(data);
    addToBotMessages(responseMessage);
    updateVariantMeta({ discoveryType: type, discoveryPhase: 'filter-choose' });
    stopLoadingMessage();
  };

  const reloadFilters = async (presetFilters: Record<string, any>) => {
    loadMessage();
    const filterAssetMessage: AiDiscoveryBotResponseMessage = {
      message: '',
      variant: 'response',
      status: 'success',
      chatType: AiBotVariant.BotsonDiscovery,
      discoveryAssets: null,
      discoveryFilters: presetFilters,
      showFiltersOnMount: true,
      conversationId: sessionId,
      id: uniqueId()
    };
    addToBotMessages(filterAssetMessage);
    stopLoadingMessage();
  };

  const reset = () => {
    updateVariantMeta({ discoveryPhase: null });
    clearMessages();
  };

  const actionsValue = { sendMessage, reset };
  return (
    <ActionsContext.Provider value={actionsValue as any}>
      <DiscoveryAiBotActionsContext.Provider
        value={{ chooseAsset, chooseFilter, reloadFilters }}
      >
        {props.children}
      </DiscoveryAiBotActionsContext.Provider>
    </ActionsContext.Provider>
  );
};
