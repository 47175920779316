import { useMemo, useState } from 'react';
import { GenerateAiDescriptionButton } from '../atoms/GenerateAiDescriptionBtn';
import CommentIcon from '@mui/icons-material/Comment';
import {
  useTableProfileDataStore,
  useUpdateTableProfileData
} from '../../hooks/useTableProfileDataStore';
import { ConditionComponent } from '../../../../../../lib/utils';
import { useToastController } from '../../../../../../utils/hooks/useToastController';
import { DataCatalogApiService } from '../../../../../api/api.service';
import {
  DescContent,
  DescHeading,
  DescInput,
  DescSaveBtn,
  DescrContainer,
  DescrHeader
} from '../../../components/DescriptionStyled';
import MixedPanelEvent from '../../../../../../utils/analytics/mixPanel';

interface Props {}

export const Description = (props: Props) => {
  const { data } = useTableProfileDataStore();
  const updateField = useUpdateTableProfileData();
  const [desc, setDescr] = useState(data?.asset_description ?? '');
  const [active, setActive] = useState(false);
  const { show: showToast, update: updateToast } = useToastController();

  const showSave = () => {
    setActive(true);
  };
  const hideSave = () => {
    setActive(true);
  };

  const showSaveBtn = Boolean(active);

  const handleUpdateDesc = (ev: any) => {
    const value = ev.target.value;
    setDescr(value);
  };

  const updateProfileDesc = async () => {
    if (!desc) {
      return showToast('Cannot save empty description', { type: 'warning' });
    }
    showToast('Updating', { isLoading: true });
    const trimmedDesc = desc.trim();
    await updateField('asset_description', trimmedDesc);
    MixedPanelEvent.dataCatalogProfileDescriptionSaveClick({
      profileType: 'table',
      assetId: data.table_id,
      assetName: data.table_name,
      newDescription: trimmedDesc
    });
    updateToast({
      render: 'Updated description successfully',
      type: 'success',
      autoClose: 1200
    });
    hideSave();
  };

  const generateDescription = async () => {
    showToast('Generating description', { isLoading: true });
    try {
      const results = await DataCatalogApiService.generateDescription({
        asset_id: data.table_id,
        asset_type: 'table'
      });
      setDescr(results.description);
      MixedPanelEvent.dataCatalogProfileGenerateDescriptionClick({
        profileType: 'table',
        newDescription: results.description,
        assetId: data.table_id,
        assetName: data.table_name
      });
      updateToast({
        render: 'Description updated',
        type: 'success',
        autoClose: 1200
      });
    } catch (error) {
      updateToast({
        render: 'Failed to generate description. Try again',
        type: 'error',
        autoClose: 1200
      });
    }
  };

  const rows = useMemo(() => {
    if (desc) return 30;
    return undefined;
  }, [props, desc]);

  return (
    <DescrContainer>
      <DescrHeader>
        <CommentIcon />
        <DescHeading>Description</DescHeading>
        <ConditionComponent condition={showSaveBtn}>
          <DescSaveBtn variant="contained" onClick={updateProfileDesc}>
            Save
          </DescSaveBtn>
        </ConditionComponent>
      </DescrHeader>
      <DescContent>
        <DescInput
          placeholder="Simply type any note or comment..."
          rows={rows}
          onChange={handleUpdateDesc}
          value={desc}
          onFocus={showSave}
        />
        <ConditionComponent condition={!desc}>
          <GenerateAiDescriptionButton onClick={generateDescription} />
        </ConditionComponent>
      </DescContent>
    </DescrContainer>
  );
};
