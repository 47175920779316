import React from 'react';
import { Box } from '@mui/material';
import { BounceLoader } from 'react-spinners';

const PageLoader = () => {
    return (
        <Box className="page-loader">
            <BounceLoader size={48} color="#7209B7" />
        </Box>
    );
};

export default PageLoader;
