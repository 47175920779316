import { Logger } from '../../utils/browser/logger';
import { AiBotDataStoreActions } from '../context/stateManagement/actions';
import { AiBotVariant } from '../domain/chatTypes';
import { AiBotMessageType, AiBotVariantMeta, IAiBotPrompt } from '../types';
import { useAiBotDataStoreActionsDispatch } from './useAiBotDataStoreActionsDispatch';

export const useAiBotDataStoreActions = () => {
  const dispatch = useAiBotDataStoreActionsDispatch();

  const loadMessage = () => {
    dispatch({ type: AiBotDataStoreActions.LoadMessage, payload: true });
  };

  const stopLoadingMessage = () => {
    dispatch({ type: AiBotDataStoreActions.LoadMessage, payload: false });
  };

  const addToBotMessages = (payload: AiBotMessageType) => {
    dispatch({
      type: AiBotDataStoreActions.AddMessage,
      payload
    });
  };
  const selectPrompt = (payload: IAiBotPrompt) => {
    dispatch({
      type: AiBotDataStoreActions.SelectPrompt,
      payload
    });
  };

  const resetPrompt = () => {
    dispatch({
      type: AiBotDataStoreActions.SelectPrompt,
      payload: null
    });
  };

  const hideInput = () => {
    dispatch({
      type: AiBotDataStoreActions.HideInput,
      payload: true
    });
  };

  const setVariantMeta = (meta: AiBotVariantMeta) => {
    dispatch({
      type: AiBotDataStoreActions.SetVariantMeta,
      payload: meta
    });
  };

  const updateVariantMeta = (meta: AiBotVariantMeta) => {
    dispatch({
      type: AiBotDataStoreActions.UpdateVariantMeta,
      payload: meta
    });
  };

  const clearMessages = () => {
    dispatch({
      type: AiBotDataStoreActions.ClearMessages
    });
  };

  const changeBotType = (variant: AiBotVariant) => {
    dispatch({
      type: AiBotDataStoreActions.ToggleBotType,
      payload: variant
    });
  };

  return {
    changeBotType,
    loadMessage,
    addToBotMessages,
    stopLoadingMessage,
    selectPrompt,
    resetPrompt,
    hideInput,
    setVariantMeta,
    updateVariantMeta,
    clearMessages
  };
};
