import { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
// import { useWatsonMessages } from '../../hooks/useWatsonMessages';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import { JoinFieldFilter } from './JoinTextFilter';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useAiBotMeta } from '../../../../Botson/hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../../../Botson/hooks/useAiBotVariantMeta';
import { useDiscoveryAiBotActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryActions';
import { AiDiscoveryBotResponseMessage } from '../../../../Botson/bots/Discovery/types';
// import { useAiBotMessages } from '../../../../Botson/hooks/useAiBotMessages';
// import { useDiscoveryBotMessage } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryBotMessage';
import { useAiBotEntryMessage } from '../../../../Botson/hooks/useAiBotEntryMessage';

interface Props {
  filters: AiDiscoveryBotResponseMessage['discoveryFilters'];
}

export const JoinMessage = ({ filters }: Props) => {
  const { sessionId: conversation_uid } = useAiBotMeta();
  const { discoveryType } = useAiBotVariantMeta();
  const { chooseFilter } = useDiscoveryAiBotActions();
  const message = useAiBotEntryMessage();

  const [firstTable, setFirstTable] = useState(
    filters && filters['src'] ? filters['src'] : ''
  );
  const [secondTable, setSecondTable] = useState(
    filters && filters['dst'] ? filters['dst'] : ''
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleFirstTableChange = (value: any) => {
    setFirstTable(value);
  };

  const handleSecondTableChange = (value: any) => {
    setSecondTable(value);
  };
  const isOneFieldEmpty = () => {
    return !firstTable || !secondTable;
  };
  const handleSubmitClick = () => {
    setIsButtonDisabled(true);
    let filters = {
      src: firstTable,
      dst: secondTable
    };
    MixedPanelEvent.watsonFilterSubmit({
      conversation_uid,
      question_type: discoveryType,
      tables: { first_table: firstTable, second_table: secondTable }
    });
    chooseFilter('join', filters);
  };

  useEffect(() => {
    MixedPanelEvent.watsonJoinQuestionExtraction({
      conversation_uid,
      question_type: discoveryType,
      question_message: message.message,
      tables_extraction: {
        first_table: filters && filters['src'] ? filters['src'] : '',
        second_table: filters && filters['dst'] ? filters['dst'] : ''
      }
    });
  }, [filters]);

  return (
    <Box>
      <Typography>Cool.</Typography>
      <Typography>
        Let me know what your specific tables to JOIN between them?
      </Typography>
      <Box
        marginTop={'10px'}
        gap={'15px'}
        display={'flex'}
        flexDirection={'column'}
      >
        <JoinFieldFilter
          selectedValue={firstTable}
          onSelectionChange={handleFirstTableChange}
          isDisabled={isButtonDisabled}
          type={'table'}
          label={'First Table'}
          placeholderText={'schema.table'}
          width={'100%'}
        />
        <JoinFieldFilter
          selectedValue={secondTable}
          onSelectionChange={handleSecondTableChange}
          isDisabled={isButtonDisabled}
          type={'table'}
          label={'Second Table'}
          placeholderText={'schema.table'}
          width={'100%'}
        />
        <CustomTooltip
          title={
            isOneFieldEmpty() ? (
              <Typography>Required to fill out both tables</Typography>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <Box>
            <Button
              sx={{
                fontSize: '15px',
                textTransform: 'none',
                height: '100%'
              }}
              variant="contained"
              onClick={handleSubmitClick}
              disabled={isOneFieldEmpty() || isButtonDisabled}
            >
              Submit
            </Button>
          </Box>
        </CustomTooltip>
      </Box>
    </Box>
  );
};
