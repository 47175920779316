import React from 'react';
import { IWatsonGeneratedQueryResponse } from '../../interfaces';
import { Stack, Typography } from '@mui/material';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { ConditionComponent } from '../../../../lib/utils';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

interface GeneratedQueryAssetProps {
  data: IWatsonGeneratedQueryResponse['assets'][number];
}

export const GeneratedQueryAsset = ({ data }: GeneratedQueryAssetProps) => {
  return (
    <Stack
      direction={'column'}
      borderBottom={'1px solid #d9d9d970'}
      marginRight={'4px'}
    >
      <Typography
        variant="overline"
        style={{ paddingTop: 0 }}
        lineHeight="24px"
      >
        {data.name}
      </Typography>
      {/* <Stack direction="row" height={'16px'}>
        <ConditionComponent condition={data.asset_type === 'table'}>
          <TableChartOutlinedIcon
            sx={{ fontSize: '14px', marginRight: '2px', color: 'primary.dark' }}
          />
        </ConditionComponent>
        <ConditionComponent condition={data.asset_type === 'document'}>
          <ArticleOutlinedIcon
            sx={{ fontSize: '16px', marginRight: '2px', color: 'primary.dark' }}
          />
        </ConditionComponent>
        <ConditionComponent condition={data.asset_type === 'dashboard'}>
          <GridViewOutlinedIcon
            sx={{ fontSize: '16px', marginRight: '2px', color: 'primary.dark' }}
          />
        </ConditionComponent>
        <Typography
          variant="caption"
          sx={{ textTransform: 'capitalize', color: 'primary.dark' }}
        >
          {data.asset_type}
        </Typography>
      </Stack> */}
    </Stack>
  );
};
