import { uniqueId } from 'lodash';
import { DiscoveryDataFeedback } from '../../../../pages/Watson/components/customMessages';
import { AiBotVariant } from '../../../domain/chatTypes';
import { useAiBotMeta } from '../../../hooks/useAiBotMeta';
import { AiDiscoveryBotResponseMessage, DiscoveryPhase } from '../types';
import { useAiBotDataStoreActions } from '../../../hooks/useAiBotDataStoreActions';

export const useDiscoveryBotPhaseResponseActions = () => {
  const { sessionId: conversationId } = useAiBotMeta();
  const { addToBotMessages, updateVariantMeta } = useAiBotDataStoreActions();

  const displayDataFeedback = (discoveryPhasePayload: any) => {
    const message: AiDiscoveryBotResponseMessage = {
      message: '',
      variant: 'response',
      status: 'success',
      chatType: AiBotVariant.BotsonDiscovery,
      conversationId,
      children: DiscoveryDataFeedback,
      id: uniqueId()
    };
    addToBotMessages(message);
    updateVariantMeta({
      discoveryPhase: 'feedback',
      discoveryPhasePayload
    });
  };

  const disableFeedbackState = () => {
    updateVariantMeta({
      disableEditFilters: true
    });
  };

  const setDiscoveryPhase = (discoveryPhase: Nullable<DiscoveryPhase>) => {
    updateVariantMeta({
      discoveryPhase: discoveryPhase ?? ('' as any)
    });
  };

  return {
    displayDataFeedback,
    disableFeedbackState,
    setDiscoveryPhase
  };
};
