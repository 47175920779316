import mixedPanel, { Mixpanel } from 'mixpanel-browser';
import { AnalyticsWrapper } from './WrapperClass';
import { LocalStorage } from '../../LocalStorage';
import { dotenv } from '../../../env';

export default class CustomMixedPanel extends AnalyticsWrapper {
    private static async _initialize() {
        this._instance = mixedPanel.init(
            dotenv.mixPanelToken,
            {
                debug: dotenv.appEnv === 'development',
                loaded: () => {
                    if (dotenv.appEnv === 'development') {
                        console.log('MixPanel Init');
                    }
                },
            },
            dotenv.appName,
        );
    }

    private static get instance() {
        if (!this._instance) {
            this.init();
        }
        return this._instance as Mixpanel;
    }

    public static identify(user: any) {
        this.instance.identify(user.email);
        this.instance.people.set(user);
        this.authenticated = true;
    }

    public static async init() {
        this._initialize();
    }

    public static async sendEvent(event: string, payload: any) {
        const modifiedPayload = { ...payload };
        const user = LocalStorage.get('sherloq-auth-user');
        if (user && !this.authenticated) {
            this.identify(user);
        }
        this.instance.track(event, modifiedPayload);
    }

    public static reset = () => {
        this.instance.reset();
        this.authenticated = false;
    };

    public static getId = () => {
        return this.instance.get_distinct_id()
    };
}
