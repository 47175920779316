import { styled } from '@mui/material';
const containerStyles = { paddingTop: 0, paddingLeft: 0, paddingRight: 0 };
const searchPromptBulletStyles = {
  color: '#0075e7',
  fontSize: '8px',
  minHeight: '28px'
};
const defaultParamInputStyles = {
  width: 'fit-content',
  minWidth: 'min-content',
  margin: '0 4px',
  height: '20px',
  boxSizing: 'border-box' as const,
  fontSize: '12px',
  display: 'inline-flex',
  padding: '0 4px',
  borderRadius: '3px'
};

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 18px',
  boxSizing: 'border-box',
  height: '48px'
};

const gptIconContainerStyles = {
  width: '25px',
  height: '25px',
  top: '-5.5px',
  left: '-5.5px',
  position: 'relative'
};

export {
  containerStyles,
  headerStyles,
  searchPromptBulletStyles,
  defaultParamInputStyles,
  gptIconContainerStyles
};

export const BotSearchInputBox = styled('div')(() => ({
  position: 'absolute',
  bottom: '64px',
  width: '100%'
}));

export const ThreadMessageInset = styled('div')(({ theme }) => {
  const { primary } = theme.palette;
  return {
    zIndex: 1,
    border: 'solid 2px',
    borderColor: primary.dark,
    position: 'absolute',
    left: '76px',
    top: '-20px',
    bottom: '-23px'
  };
});
