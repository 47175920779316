import React, { useRef, useState } from 'react';
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Paper,
  InputBase,
  Button
} from '@mui/material';
import { AddRounded, HighlightOff } from '@mui/icons-material';
import { HotSaveAsLayout } from './layout';
import { DesignSystemMap } from '../../../styles/MUI/designSystem';
import { sx } from '../../../styles/MUI/styles';
import { RenderIf } from '../../../lib/utils';
import { useAppDispatch } from '../../../store';
import { queryFoldersThunks } from '../../../store/queryFolder/thunk';
import {
  ToastContent,
  ToastOptions,
  UpdateOptions,
  toast
} from 'react-toastify';
import { FolderMeta } from '../interface';
import { SelectFolderDropdown } from '../../../components/selectFolderDropdown';
import { useFolderTree } from '../../../lib/hooks/useFolderTree';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { FolderSummary } from '../../../store/folderSummary/type';
import { ModalTypography } from '../../../components/modals/components/label';

interface NewFolderProps {
  onSave?: (payload: FolderMeta) => void;
  onCancel?: () => void;
  withAsyncCreate?: boolean;
}

/**
 *
 * @param withAsyncCreate will trigger the folder creation within the modal component.
 * @returns
 * With
 */
export const NewFolderRedirectModal = ({
  onSave,
  onCancel,
  withAsyncCreate
}: NewFolderProps) => {
  const user = useUserInfoSelector();
  const [withParent, setWithParent] = useState(false);
  const toastRef = useRef<any>(null);
  const showToast = (content: ToastContent, options?: ToastOptions) => {
    toastRef.current = toast(content, options);
  };
  const folders = useFolderTree();

  const updateToast = (
    content: UpdateOptions | ToastContent,
    options?: ToastOptions
  ) => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        render: content as ToastContent,
        isLoading: false,
        autoClose: 2000
      });
    } else {
      toast(content as ToastContent, options);
    }
  };

  const [memberInput, setMemberInput] = useState('');
  const [folderMeta, setFolderMeta] = useState<FolderMeta>({
    description: '',
    name: '',
    visibility: 'personal',
    parentFolder: '',
    sharedMembers: []
  });
  const dispatch = useAppDispatch();

  const handleOnChangeMemberInput = e => {
    const val = e.target.value;
    setMemberInput(val);
  };

  const handleAddMember = () => {
    try {
      checkIfCanAddMember(memberInput, folderMeta.sharedMembers, user.email);
      folderMeta.sharedMembers.push(memberInput);
      setFolderMeta({ ...folderMeta });
      setMemberInput('');
    } catch (error: any) {
      showToast(error.message);
    }
  };

  const handleOnPressEnterOnMemberInput = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddMember();
    }
  };

  const handleRemoveMember = e => {
    const val = e.target.value;
    const sharedMembers = folderMeta.sharedMembers.filter(mem => mem !== val);
    setFolderMeta(p => ({ ...p, sharedMembers }));
  };

  const handleOnSave = async () => {
    try {
      if (withAsyncCreate) {
        checkIfFolderPayloadIsValid(folderMeta);
        showToast('Creating folder', { isLoading: true });
        const payload = {
          name: folderMeta.name,
          description: folderMeta.description,
          is_shared: folderMeta.visibility !== 'personal' ? true : false,
          parent_id: withParent ? folderMeta?.parentFolder : '',
          members:
            folderMeta.visibility === 'shared'
              ? [user.email, ...folderMeta.sharedMembers]
              : [],
          withToast: false,
          withAnalytics: false
        };
        const res = await dispatch(
          queryFoldersThunks.createQueryFolder(payload)
        ).unwrap();
        MixedPanelEvent.registerSaveAsNewFolder({
          folderName: folderMeta.name
        });
        updateToast(`Folder created successfully`);
        const parentFolder = res.uid;
        onSave({ ...folderMeta, parentFolder });
      }
    } catch (error: any) {
      updateToast(`Operation failed. ${error.message}`, {
        isLoading: false,
        autoClose: 2000,
        type: 'error'
      });
    }
  };

  const handleOnChangeName = e => {
    const name = e.target.value;
    setFolderMeta(p => ({ ...p, name }));
  };
  const handleOnChangeDesc = e => {
    const description = e.target.value;

    setFolderMeta(p => ({ ...p, description }));
  };
  const handleOnChangeParentFolder = (folder: FolderSummary) => {
    const id = folder.uid;
    setFolderMeta(p => {
      if (withParent) {
        return { ...p, parentFolder: id };
      }
      return { ...p };
    });
  };
  const handleOnChangeVisibility = e => {
    const visibility = e.target.value;
    setFolderMeta(p => ({ ...p, visibility }));
  };
  const handleOnChangeWithParent = () => {
    setWithParent(p => !p);
  };

  const handleCancelNewFolderSaveAs = () => {
    onCancel?.();
    MixedPanelEvent.registerSaveAsNewFolderCancel();
  };

  return (
    <HotSaveAsLayout
      onSave={handleOnSave}
      onCancel={handleCancelNewFolderSaveAs}
    >
      <Box>
        <ModalTypography variant="subtitle1">Add new folder</ModalTypography>
        <ModalTypography variant="caption">
          Keep your queries organized with folders
        </ModalTypography>
      </Box>
      <Box sx={{ marginTop: '24px' }}>
        <ModalTypography variant="subtitle2">Folder name *</ModalTypography>
        <TextField
          sx={{ fontSize: '12px' }}
          inputProps={{
            style: DesignSystemMap.modalInput.defaultInput
          }}
          value={folderMeta.name}
          onChange={handleOnChangeName}
          placeholder="eg. New Users"
        />
      </Box>
      <Box sx={{ marginTop: '16px' }}>
        <ModalTypography variant="subtitle2">
          Folder Description (recommended)
        </ModalTypography>
        <TextField
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              padding: '8px'
            }
          }}
          value={folderMeta.description}
          onChange={handleOnChangeDesc}
          inputProps={{ style: { fontSize: '12px' } }}
          multiline
          rows={3}
          placeholder="What this folder is all about..."
        />
      </Box>
      <Box sx={{ marginTop: '8px', display: 'flex', flexDirection: 'column' }}>
        <FormControl>
          <FormControlLabel
            value={withParent}
            onChange={handleOnChangeWithParent}
            control={<Checkbox size="small" />}
            label={
              <ModalTypography variant="caption">
                Save as sub folder under
              </ModalTypography>
            }
          />
        </FormControl>
        {RenderIf(
          withParent,
          <SelectFolderDropdown
            folders={folders as any}
            onChange={handleOnChangeParentFolder}
            selected={folderMeta.parentFolder}
            key={`${folderMeta.parentFolder}-${folders.length}`}
            buttonProps={{
              disabled: !withParent,
              sx: {
                height: '40px',
                width: '250px',
                display: 'flex',
                justifyContent: 'space-between',
                border: '1px solid #00000060',
                '&.Mui-disabled': {
                  backgroundColor: '#f1f1f1'
                }
              }
            }}
          />
        )}
      </Box>

      {RenderIf(
        !withParent,
        <Box sx={{ marginTop: '16px' }}>
          <FormControl>
            <FormLabel sx={{ fontSize: '14px', color: 'text.primary' }}>
              Folder Visibility
            </FormLabel>
            <RadioGroup
              onChange={handleOnChangeVisibility}
              value={folderMeta.visibility}
              row
              sx={{ display: 'flex', columnGap: '8px' }}
            >
              <FormControlLabel
                value={'personal'}
                sx={{ margin: 0, paddingLeft: 0 }}
                control={
                  <Radio
                    size="small"
                    sx={{ width: '20px', height: '20px', marginRight: '8px' }}
                  />
                }
                label={
                  <ModalTypography variant="caption">Personal</ModalTypography>
                }
              />
              <FormControlLabel
                value={'shared'}
                control={<Radio size="small" />}
                label={
                  <ModalTypography variant="caption">Shared</ModalTypography>
                }
              />
              <FormControlLabel
                value={'public'}
                control={<Radio size="small" />}
                label={
                  <ModalTypography variant="caption">Public</ModalTypography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      {RenderIf(
        folderMeta.visibility === 'shared',
        <React.Fragment>
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 290,
              borderRadius: '5px'
            }}
          >
            <InputBase
              autoComplete="off"
              sx={{ ml: 0.5, flex: 1, fontSize: '12px' }}
              id="searchString1"
              onInput={handleOnChangeMemberInput}
              onKeyDown={handleOnPressEnterOnMemberInput}
              value={memberInput}
              placeholder="Add member"
            />
            <AddRounded
              sx={sx.sx5}
              style={{ cursor: 'pointer' }}
              onClick={handleAddMember}
            />
          </Paper>
          <br />
          <Box style={{ display: 'flex', flexFlow: 'wrap' }}>
            {folderMeta.sharedMembers.map((v: any) => (
              <Button
                key={v}
                onClick={handleRemoveMember}
                variant="contained"
                sx={{
                  padding: '5px',
                  height: '20px',
                  borderRadius: '8px',
                  margin: '5px',
                  fontSize: '12px',
                  textTransform: 'none',
                  background: 'rgba(0, 0, 0, 0.4)'
                }}
                endIcon={<HighlightOff fontSize="small" />}
              >
                {v}
              </Button>
            ))}
          </Box>
        </React.Fragment>
      )}
    </HotSaveAsLayout>
  );
};

function checkIfCanAddMember(member: string, members: string[], email: string) {
  if (member === email) throw new Error('Enter another user in your domain');
  const userDomain = getUserDomainFromEmail(email);
  const alreadyExists = members.find(mem => mem === member);
  if (alreadyExists) throw new Error('Member already exists');
  const memberDomain = getUserDomainFromEmail(member);
  if (memberDomain !== userDomain)
    throw new Error('You can only add users from your domain');
}

function getUserDomainFromEmail(email: string) {
  if (!email) null;
  return email.split('@')[1];
}

function checkIfFolderPayloadIsValid(folderMeta: FolderMeta) {
  if (!folderMeta.name) throw new Error('Provide a folder name');
  if (folderMeta.name.toLocaleLowerCase() === 'default')
    throw new Error('Folder name cannot be "default"');
}
