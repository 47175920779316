import React, { useState } from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import { DiscoveryFiltersPrep } from '../../api/fetch';
import { useUserInfoSelector } from '../../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../../utils/core/getUserCompanyFromEmail';

export const TextFieldFilter = ({
  selectedValue,
  onSelectionChange,
  isDisabled,
  label,
  subLabel = '',
  placeholderText = 'Optional',
  width = '200px',
  marginRight = '25px',
  type,
  allowSpecialChars = false
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const user = useUserInfoSelector();
  let userCompany: string;
  if (user) {
    userCompany = getUserCompanyFromEmailUseCase(user.email);
  }

  const validateInput = (inputValue: string) => {
    if (allowSpecialChars) {
      return true;
    }
    const regex = /[,".]/;
    if (regex.test(inputValue)) {
      setError(true);
      setHelperText('Input cannot contain the following characters: , . "');
      return false;
    } else {
      setError(false);
      setHelperText('');
      return true;
    }
  };
  const handleSearch = async (value: string) => {
    if (type) {
      setLoading(true);
      try {
        const response = await DiscoveryFiltersPrep(type, userCompany, value);
        if (response && response.data) {
          setSearchResults(response.data);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleInputChange = (event: any, value: string, reason: string) => {
    if (reason === 'input') {
      const isValid = validateInput(value);
      if (isValid) {
        onSelectionChange(value);
        handleSearch(value);
      }
      setOpen(true);
    } else if (reason === 'reset') {
      setOpen(false);
    }
  };
  const handleBlur = event => {
    const value = event.target.value;
    const isValid = validateInput(value);
    if (isValid) {
      onSelectionChange(value);
    }
    setError(false);
    setHelperText('');
    setOpen(false);
  };

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
      <Box
        sx={{ lineHeight: 'normal' }}
        marginRight={marginRight}
        marginBottom={'20px'}
      >
        <Typography
          fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          fontSize={18}
          sx={{ marginBottom: '0' }}
        >
          {label}{' '}
        </Typography>
        <Typography
          fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          fontSize={12}
          whiteSpace={'nowrap'}
        >
          {subLabel}
        </Typography>
      </Box>
      <Autocomplete
        disabled={isDisabled}
        id={`${label}-field`}
        options={searchResults}
        sx={{
          width,
          marginRight: '20px',
          alignContent: 'center',
          '& .MuiOutlinedInput-root': {
            alignContent: 'center'
          }
        }}
        value={selectedValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const { inputValue } = params;
          return options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          const isValid = validateInput(newValue);
          if (isValid) onSelectionChange(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.875rem'
              },
              '& .MuiOutlinedInput-root': {
                height: '40px'
              }
            }}
            onBlur={handleBlur}
            placeholder={placeholderText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      <FormHelperText
        sx={{
          visibility: error ? 'visible' : 'hidden',
          height: '1em'
        }}
        error
      >
        {helperText || ' '}
      </FormHelperText>
    </Box>
  );
};
