import posthog, { PostHog } from 'posthog-js';
import { PersistedGlobalState } from '../../../store/persistedStoreRef';
import { dotenv } from '../../../env';
import { AnalyticsWrapper } from './WrapperClass';

export class Posthog extends AnalyticsWrapper {
  public static init = async () => {
    this._instance = (await posthog.init(
      dotenv.posthogKey,
      {
        api_host: dotenv.posthogApiHost,
        autocapture: false,
        debug: dotenv.appEnv === 'development',
        capture_pageleave: false,
        capture_pageview: false,
        loaded: () => {
          if (dotenv.appEnv === 'development') {
            console.log('Posthog init');
          }
        }
      },
      dotenv.appName
    )) as PostHog;
  };

  private static get instance() {
    if (!this._instance) {
      this.init();
      return this._instance;
    }
    return this._instance as PostHog;
  }

  public static sendEvent = (event: string, payload?: any) => {
    const user = PersistedGlobalState.getUser();
    if (user && !this.authenticated) {
      this.identify(user);
    }
    this.instance.capture(event, payload);
  };

  public static reset = () => {
    this.instance.reset(true);
    this.authenticated = false;
  };

  public static identify(payload: any) {
    this.instance.identify(payload.email);
    this.instance.people.set(payload);
    this.authenticated = true;
  }

  public static getId = () => {
    return this.instance.get_distinct_id();
  };
}
