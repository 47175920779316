import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/types/general';
import { internalTeamThunk } from './thunk';
import { InternalTeam, InternalTeamsState } from './type';

const initialState: InternalTeamsState = {
    list: [],
    loading: LoadingState.FULFILLED,
};

const index = createSlice({
    name: 'Internal_Teams',
    initialState,
    reducers: {
    },

    extraReducers: {
        [internalTeamThunk.index.pending.type]: (state) => {
            state.list = [];
            state.loading = LoadingState.PENDING;
        },
        [internalTeamThunk.index.fulfilled.type]: (state, { payload }: PA<InternalTeam[]>) => {
            state.loading = LoadingState.FULFILLED;
            state.list = payload;
        },
        [internalTeamThunk.index.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
            state.list = [];
        },
        [internalTeamThunk.create.pending.type]: (state) => {
            state.loading = LoadingState.PENDING;
        },
        [internalTeamThunk.create.fulfilled.type]: (state, { payload }: PA<InternalTeam>) => {
            state.loading = LoadingState.FULFILLED;
            state.list.push(payload);
        },
        [internalTeamThunk.create.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
        },
    },
});

export default index.reducer;
