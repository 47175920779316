import React from 'react';

interface FolderPublicIconProps {
    size: string;
    darkMode: boolean
}

const FolderPublicIcon = ({ size, darkMode }: FolderPublicIconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64166 3.33337C8.08332 3.33337 8.50832 3.50837 8.82499 3.82504L9.99999 5.00004H16.6667C17.5833 5.00004 18.3333 5.75004 18.3333 6.66671V15C18.3333 15.9167 17.5833 16.6667 16.6667 16.6667H3.33332C2.41666 16.6667 1.66666 15.9167 1.66666 15L1.67499 5.00004C1.67499 4.08337 2.41666 3.33337 3.33332 3.33337H7.64166ZM12.5 9C13.5617 9 14.4454 9.80937 14.6438 10.8875C15.4021 10.9437 16 11.6125 16 12.4375C16 13.3 15.3467 14 14.5417 14H10.75C9.78458 14 9 13.1594 9 12.125C9 11.1594 9.6825 10.3625 10.5604 10.2625C10.925 9.5125 11.6571 9 12.5 9Z"
                fill={darkMode ? "white" : "black"}
                fillOpacity="0.8"
            />
        </svg>
    );
};

export default FolderPublicIcon;
