import { FolderSummary } from '../store/folderSummary/type';
import { User } from '../store/newQueryHistoryContent/type';
import { TeamMember } from '../store/teamMembers/type';
import moment from 'moment';
import nlp from 'compromise';
var snowballFactory = require('snowball-stemmers');

const stemmer = snowballFactory.newStemmer('english');

// Tokenize the text
function tokenize(text: string): string[] {
  return nlp(text).terms().out('array');
}

function stem(tokens: string[]): string[] {
  return tokens.map(token => stemmer.stem(token));
}

const stopWords = new Set([
  'a', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any', 'are', "aren't", 'as', 'at', 
  'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', "can't", 'cannot', 'could', 
  "couldn't", 'did', "didn't", 'do', 'does', "doesn't", 'doing', "don't", 'down', 'during', 'each', 'few', 'for', 
  'from', 'further', 'had', "hadn't", 'has', "hasn't", 'have', "haven't", 'having', 'he', "he'd", "he'll", "he's", 
  'her', 'here', "here's", 'hers', 'herself', 'him', 'himself', 'his', 'how', "how's", 'i', "i'd", "i'll", "i'm", 
  "i've", 'if', 'in', 'into', 'is', "isn't", 'it', "it's", 'its', 'itself', "let's", 'me', 'more', 'most', "mustn't", 
  'my', 'myself', 'no', 'nor', 'not', 'of', 'off', 'on', 'once', 'only', 'or', 'other', 'ought', 'our', 'ours', 
  'ourselves', 'out', 'over', 'own', 'same', "shan't", 'she', "she'd", "she'll", "she's", 'should', "shouldn't", 
  'so', 'some', 'such', 'than', 'that', "that's", 'the', 'their', 'theirs', 'them', 'themselves', 'then', 'there', 
  "there's", 'these', 'they', "they'd", "they'll", "they're", "they've", 'this', 'those', 'through', 'to', 'too', 
  'under', 'until', 'up', 'very', 'was', "wasn't", 'we', "we'd", "we'll", "we're", "we've", 'were', "weren't", 'what', 
  "what's", 'when', "when's", 'where', "where's", 'which', 'while', 'who', "who's", 'whom', 'why', "why's", 'with', 
  "won't", 'would', "wouldn't", 'you', "you'd", "you'll", "you're", "you've", 'your', 'yours', 'yourself', 
  'yourselves'
]);

// Remove stop words
function removeStopWords(tokens: string[]): string[] {
  return tokens.filter(token => !stopWords.has(token.toLowerCase()));
}

export function addStemsToText(text: string): string {
  const tokens = tokenize(text);
  const filteredTokens = removeStopWords(tokens);
  const stemmedTokens = stem(filteredTokens);

  // Create a new string with the original text and the stems
  const resultTokens = filteredTokens.map((token, index) => `${token} ${stemmedTokens[index]} `);
  return resultTokens.join(' ');
}


export const assignColors = (members: TeamMember[], colors: any, user: any) => {
  if (members.length === 0) {
    return [];
  }
  const userColorStore: any = {};
  userColorStore[user?.uid!] = '#0075E7';
  let lastColorUsed = 0;
  const parsedFolders: any = [];
  members.forEach((member: TeamMember, index: any) => {
    let updatedFolder = {};
    const createdBy = member.uid;
    if (userColorStore[createdBy] !== undefined) {
      const userColor = userColorStore[createdBy];
      updatedFolder = { ...member, color: userColor };
    } else {
      let userColor;
      // first time
      userColor = colors[lastColorUsed];
      if (!userColor) {
        lastColorUsed = 0;
        userColor = colors[0];
      }
      lastColorUsed++;
      updatedFolder = { ...member, color: userColor };
      userColorStore[createdBy] = userColor;
    }
    parsedFolders.push(updatedFolder);
  });
  return parsedFolders;
};

export function isNotCorporateEmail(email: string) {
  const unCorporateEmail = ['gmail.com', 'yahoo.com'];
  const domain = email.split('@')[1];
  return unCorporateEmail.includes(domain);
}

export const getDefaultFolder = (folders: FolderSummary[]) => {
  const defaultFolder = folders.find(folder => folder.name === 'Default');
  if (defaultFolder) {
    return defaultFolder.uid;
  } else {
    return folders[0]?.uid;
  }
};

export const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.toDateString();
  return moment(yesterday.toDateString()).format('DD-MM-YYYY');
};

export const getUserName = (userId: string, users: TeamMember[]) => {
  const user = users.find(user => user.uid === userId);
  if (user) {
    return user.first_name + ' ' + user.last_name;
  }
  return null;
};

export const getQueriedInString = (queriedDate: string) => {
  if (queriedDate === 'last-day') {
    return { label: 'Last Day', value: 'last-day' };
  }
  if (queriedDate === 'last-week') {
    return { label: 'Last Week', value: 'last-week' };
  }
  if (queriedDate === 'last-month') {
    return { label: 'Last Month', value: 'last-month' };
  }
  return null;
};

export const decodeHTMLEntities = (text: string) => {
  var entities = [
    ['amp', '&'],
    ['apos', "'"],
    ['#x27', "'"],
    ['#x2F', '/'],
    ['#39', "'"],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"']
  ];

  for (var i = 0; i < entities.length; ++i)
    text = text.replace(
      new RegExp('&' + entities[i][0] + ';', 'g'),
      entities[i][1]
    );

  return text;
};

export function selectFolderFromFoldersByUUID(uid, folders: any[]) {
  if (!Array.isArray(folders) || !uid) return undefined;
  let queue = [...folders];
  let result = undefined;
  while (queue.length > 0) {
    const currentFolder = queue.shift();
    if (currentFolder.uid === uid) {
      result = currentFolder;
      break;
    } else {
      if (currentFolder.sub_folders) {
        queue = queue.concat(currentFolder.sub_folders);
      }
    }
  }
  return result;
}

export const getDomainWithoutSubdomain = () => {
  const location = window.location.toString();
  const urlParts = new URL(location).hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export const getLastSeenString = (date: Date): string => {
  const now = new Date();

  // Parse the input date as UTC
  const utcDate = new Date(date);
  
  // Calculate the local timezone offset in milliseconds
  const timezoneOffsetInMs = now.getTimezoneOffset() * 60 * 1000;

  // Adjust the difference in milliseconds by the timezone offset
  const diffInMs = now.getTime() - (utcDate.getTime() - timezoneOffsetInMs);
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInMinutes < 5) {
    return "Just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  } else if (diffInMonths < 1) {
    const weeks = Math.floor(diffInDays / 7);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else {
    return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
  }
}

export function hashStringToColor(username: string): string {
  const colors = [
      '#FF69B4', // Hot Pink
      '#40E0D0', // Turquoise
      '#FFD700', // Gold
      '#FF4500', // Orange Red
      '#7B68EE', // Medium Slate Blue
      '#DA70D6', // Orchid
      '#32CD32', // Lime Green
      '#FF1493', // Deep Pink
      '#B0E0E6', // Powder Blue
      '#ADFF2F', // Green Yellow
      '#FF6347', // Tomato (Replaced)
      '#FFB6C1', // Light Pink
      '#FFDEAD', // Navajo White
      '#9370DB'  // Medium Purple
  ];

  // Simple hash function to convert string to a number
  let hash = 0;
  for (let i = 0; i < username.length; i++) {
      hash = username.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
  }

  // Map hash to one of the predefined colors
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}