import { AiBotVariant } from '../../domain/chatTypes';
import {
  IAiBotMeta,
  AiSetupConfig,
  AiBotMessageType,
  IAiBotPrompt,
  AiBotVariantMeta
} from '../../types';

export enum AiBotDataStoreActions {
  SetLoading = 'set loading',
  SetUserConfig = 'set user config',
  SetMeta = 'set meta',
  HideInput = 'hide input',
  ToggleBotType = 'toggle bot-type',
  AddMessage = 'add message',
  HidePrompts = 'hide prompts',
  LoadMessage = 'load message',
  SelectPrompt = 'select message',
  SetVariantMeta = 'set variant meta',
  UpdateVariantMeta = 'update variant meta',
  ClearMessages = 'clear meassages'
}

export interface AiSetLoadingAction {
  type: AiBotDataStoreActions.SetLoading;
  payload: boolean;
}

export interface AiClearMessagesActions {
  type: AiBotDataStoreActions.ClearMessages;
}

export interface AiSetVariantMetaAction {
  type: AiBotDataStoreActions.SetVariantMeta;
  payload: AiBotVariantMeta;
}

export interface AiSelectPromptAction {
  type: AiBotDataStoreActions.SelectPrompt;
  payload: Nullable<IAiBotPrompt>;
}

export interface AiSetUserConfigAction {
  type: AiBotDataStoreActions.SetUserConfig;
  payload: AiSetupConfig;
}

export interface AiSetMetaAction {
  type: AiBotDataStoreActions.SetMeta;
  payload: IAiBotMeta;
}

export interface AiSetBotTypeAction {
  type: AiBotDataStoreActions.ToggleBotType;
  payload: AiBotVariant;
}

export interface AiBotToggleInputAction {
  type: AiBotDataStoreActions.HideInput;
  payload: boolean;
}

export interface AiBotAddMessageAction {
  type: AiBotDataStoreActions.AddMessage;
  payload: AiBotMessageType;
}

export interface AiBotTogglePromptsVisibilityAction {
  type: AiBotDataStoreActions.HidePrompts;
  payload: boolean;
}
export interface AiBotLoadMessageAction {
  type: AiBotDataStoreActions.LoadMessage;
  payload: boolean;
}
export interface AiBotUpdateVariantMetaAction {
  type: AiBotDataStoreActions.UpdateVariantMeta;
  payload: AiBotVariantMeta;
}

export type AiChatStateManagementAction =
  | AiSetLoadingAction
  | AiSetUserConfigAction
  | AiSetMetaAction
  | AiBotToggleInputAction
  | AiSetBotTypeAction
  | AiBotAddMessageAction
  | AiBotTogglePromptsVisibilityAction
  | AiBotLoadMessageAction
  | AiSelectPromptAction
  | AiSetVariantMetaAction
  | AiBotUpdateVariantMetaAction
  | AiClearMessagesActions;
