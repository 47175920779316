import ApiClient from '../../lib/utils/apiClient';
import { AssetType, OrderBy } from '../ui/components';

interface AssetResultsRequest {
  orderBy: OrderBy;
  queriedIn: number;
  type: AssetType;
  searchString: string;
}
export const FetchResults = async ({
  type,
  orderBy,
  queriedIn,
  searchString = ''
}: AssetResultsRequest) => {
  let params = { order_by: orderBy, queried_in: queriedIn };
  if (type === 'table') {
    params['table_name'] = searchString;
  } else if (type === 'field') {
    params['field_name'] = searchString;
  }
  return ApiClient.post(`/analytics/search-${type}s`, params, {
    isProtected: true
  });
};
