import { useTheme } from '@mui/material';
import { ElectricBoltOutlined } from '@mui/icons-material';
import { AiBotVariant } from '../../../domain/chatTypes';
import Watson from '../../../../components/Icons/Watson';
import { GenerateSqlIcon } from '../../../../components/Icons/generateSql';
import { Minimize } from './minimize';

interface ToggleButtonIconProps {
  variant: AiBotVariant;
}

export const ToggleButtonIcon = ({ variant }: ToggleButtonIconProps) => {
  const {
    palette: {
      primary: { main },
      success: { main: gptColor }
    }
  } = useTheme();

  switch (variant) {
    case AiBotVariant.BotsonDiscovery:
      return (
        <Minimize style={{ top: '4px' }} factor="1">
          <Watson color={main} />
        </Minimize>
      );
    case 'gpt4':
      return <ElectricBoltOutlined sx={{ color: gptColor }} fontSize="small" />;
    default:
      return <GenerateSqlIcon />;
  }
};
