import React from 'react';
import {
  Box,
  Input,
  InputProps,
  IconButton,
  useAutocomplete,
  TextField,
  InputBase
} from '@mui/material';
import { Icon, IconWrapper, SearchInputWrapper } from './components';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchInput {
  inputProps?: React.HtmlHTMLAttributes<HTMLInputElement>;
  className?: string;
  onSearch?: (query: string) => void;
  placeholder?: string;
  isClearable?: boolean;
  defaultValue?: string;
}

const SearchSection: React.FC<SearchInput> = ({
  inputProps,
  className,
  onSearch,
  placeholder,
  isClearable,
  defaultValue
}) => {
  const [value, setValue] = React.useState(defaultValue ?? '');
  const [isHovered, setIshovered] = React.useState(false);
  const handleSetHover = (state: boolean) => {
    setIshovered(state);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearch(e.target.value);
  };

  const clear = () => {
    setValue('');
  };

  return (
    <SearchInputWrapper>
      <SearchIcon />
      <InputBase
        id="searchString"
        onChange={onChange}
        value={value}
        className="input"
        placeholder={placeholder}
        autoComplete="off"
        fullWidth={true}
        sx={{ border: 'none' }}
      />
    </SearchInputWrapper>
  );
};

export default SearchSection;
