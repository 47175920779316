import { Box } from '@mui/material';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  rule: (...args: any[]) => boolean;
  element: React.ReactElement;
  path?: string;
  suspended?: boolean;
}

export const DynamicProtectedRoute = ({
  element,
  rule,
  suspended = true,
  path
}: Props) => {
  const isAuthorised = rule();
  if (!isAuthorised) {
    return <Navigate to="/*" state={{ next: path }} />;
  }

  if (!suspended) {
    return element;
  }
  return (
    <Suspense
      fallback={<Box style={{ background: 'white', zIndex: 10000000 }} />}
    >
      {element}
    </Suspense>
  );
};
