import { PlatformIcon } from '../../../../../../components/Icons/PlatformIcon';
import { IRepositoryFolderQuery } from '../../../../../../pages/QueryListPage/interface';
import {
  AccordionPreviewBottom,
  AccordionPreviewBox,
  BottomText,
  BottomTextDark
} from './styled';
import { Typography } from '@mui/material';

interface Props {
  data: IRepositoryFolderQuery;
}

export const AccordionPreview = ({ data }: Props) => {
  return (
    <AccordionPreviewBox>
      <Typography variant="body2">History Query</Typography>
      <AccordionPreviewBottom>
        <PlatformIcon origin={data.query_origin} size="16px" />
        <BottomText>
          Created by{' '}
          <BottomTextDark>
            {'  '}
            {data.query_owner_username} {data.query_created_at}
          </BottomTextDark>
        </BottomText>
      </AccordionPreviewBottom>
    </AccordionPreviewBox>
  );
};
