import { useContext } from 'react';
import { AiBotDataStoreContext } from '../context/defs';
import { getChatTypesMeta } from '../utils/getChatTypesMeta';
import { useUserInfoSelector } from '../../store/auth/selectors';

export const useAiBotTypes = () => {
  const user = useUserInfoSelector();
  const { availableChatTypes } = useContext(AiBotDataStoreContext).userConfig;
  const meta = getChatTypesMeta(availableChatTypes, user.email);
  return meta;
};
