import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginatedDataType } from '../../utils/types/paginatedData';
import { toast } from 'react-toastify';
import ApiClient from '../../lib/utils/apiClient';
import {
    CreateQueryHistoryPayload,
    FetchQueryHistoryPayload,
    QueryHistory,
    SearchQueryHistoryRequest,
} from './type';

const index = createAsyncThunk(
    'query-history/index',
    async (payload: FetchQueryHistoryPayload, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.get<PaginatedDataType<QueryHistory>>(
                `/query-histories/?limit=${payload.limit}&page=${payload.page}`,
                {
                    isProtected: true,
                },
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

const create = createAsyncThunk(
    'query-history/create',
    async (payload: CreateQueryHistoryPayload, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.post('/query-histories/', payload, {
                isProtected: true,
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

const remove = createAsyncThunk(
    'query-history/remove',
    async (uid: string, { fulfillWithValue, rejectWithValue }) => {
        try {
            await ApiClient.delete(`/query-histories/${uid}`, {
                isProtected: true,
            });
            toast('History deleted', { type: 'success' });
            return fulfillWithValue(uid);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);
const search = createAsyncThunk(
    'query-histories/search',
    async (search: SearchQueryHistoryRequest, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.post<QueryHistory[]>(
                `/query-histories/search`,
                search,
                {
                    isProtected: true,
                },
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

export const queryHistoryThunks = { index, create, remove, search };
