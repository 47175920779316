import React, { useState } from 'react';
import { KeyboardEventHandler } from 'react';
import { Form, FormInput, SubmitButton } from './components';

interface SubmitFormProps {
  submitForm: (value: string) => void;
  loading: boolean;
}

export const SubmitSubjectForm = ({ submitForm, loading }: SubmitFormProps) => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  const handleSubmitValue = () => {
    if (!value) {
      setError(true);
    } else {
      submitForm(value);
    }
  };

  const handleonSubmitWithEnter: KeyboardEventHandler<
    HTMLInputElement
  > = ev => {
    const isEnter = ev.key === 'Enter';
    if (isEnter) {
      ev.stopPropagation();
    }
  };

  const handleOnChangeText = ev => {
    const val = ev.target.value;
    setValue(val);
    setError(false);
  };

  return (
    <Form>
      <FormInput
        autoFocus
        multiline
        minRows={4}
        maxRows={8}
        value={value}
        fullWidth
        disableUnderline
        error={error}
        onChange={handleOnChangeText}
        placeholder="Enter details or query"
        onKeyDown={handleonSubmitWithEnter}
        disabled={loading}
      />
      <SubmitButton
        disabled={loading}
        type="submit"
        onClick={handleSubmitValue}
      >
        {loading ? 'loading' : 'submit'}
      </SubmitButton>
    </Form>
  );
};
