import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { sx } from '../../../styles/MUI/styles';
import { isDatagrip } from '../../../utils/checkOrigin';

export const CancelButton = (props: Omit<ButtonProps, 'sx, variant'>) => {
  return (
    <Button
      sx={
        isDatagrip() ? sx.disabledOutlinedBtn14dark : sx.disabledOutlinedBtn14
      }
      variant="outlined"
      {...props}
    >
      Cancel
    </Button>
  );
};
