import { useCallback, useEffect, useRef } from 'react';

interface SubscribeToCopyEventPayload {
  onCopy?: (copiedText: string) => void | Promise<void>;
  enable?: boolean;
}

export const useSubscribeToCopyEvents = (
  options?: SubscribeToCopyEventPayload
) => {
  const ref = useRef<HTMLElement>(null);
  const subscribeToCopiedText = useCallback((_: ClipboardEvent) => {
    const selectedText = window.getSelection().toString();
    options?.onCopy(selectedText);
  }, []);
  useEffect(() => {
    if (ref.current) {
      ref.current?.addEventListener('copy', subscribeToCopiedText);
    }

    return () => {
      if (ref.current) {
        ref.current?.removeEventListener('copy', subscribeToCopiedText);
      }
    };
  }, []);

  return { ref };
};
