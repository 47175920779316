export const RuleAccessibilityOptions = [
  {
    label: 'Contains',
    value: 'contains',
    state: false
  },
  {
    label: 'Bigger than',
    value: 'bigger',
    state: false
  },
  {
    label: 'Lower than',
    value: 'lower',
    state: false
  },
  {
    label: 'is',
    value: 'is',
    state: false
  }
];
export const RuleFieldOptions = [
  {
    label: 'Table name',
    value: 'table',
    state: false
  },
  {
    label: 'Databases name',
    value: 'db',
    state: false
  },
  {
    label: 'Contained a field name',
    value: 'field',
    state: false
  },
  {
    label: 'Num of uses in the last month',
    value: 'usage',
    state: false
  },
  {
    label: 'Source',
    value: 'source',
    state: false
  }
];

export const defaultAssetFilterRule = Object.freeze({
  column: '',
  condition: '',
  value: ''
});
