import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ViewToggleContainer } from './components';

export const ViewToggleSection = ({ resultsView, changeResultsView }) => {
  const handleViewChange = (event: any, newView: string) => {
    if (newView !== null) {
      changeResultsView(newView);
    }
  };
  return (
    <ViewToggleContainer>
      <ToggleButtonGroup
        color="primary"
        value={resultsView}
        exclusive
        onChange={handleViewChange}
      >
        <ToggleButton
          sx={{ textTransform: 'none', fontSize: '16px' }}
          value="table"
        >
          Tables
        </ToggleButton>
        <ToggleButton
          sx={{ textTransform: 'none', fontSize: '16px' }}
          value="field"
        >
          Fields
        </ToggleButton>
      </ToggleButtonGroup>
    </ViewToggleContainer>
  );
};
