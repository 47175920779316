import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { indexOf } from 'lodash';
import { LoadingState } from '../../utils/types/general';
import { folderSummaryThunk } from './thunk';
import { FolderSummary, FolderSummaryState } from './type';

const initialState: FolderSummaryState = {
  list: [],
  loading: LoadingState.IDLE,
  shortcuts: [],
  filterText: ''
};

const index = createSlice({
  name: 'Folder_summary',
  initialState,
  reducers: {
    addFolderToSummary: (state, { payload }: PA<FolderSummary>) => {
      state.list.unshift(payload);
    },
    removeFolderFromSummary: (state, { payload }: PA<string>) => {},
    moveQueryReducer: (
      state,
      { payload }: PA<{ newParentId: string; oldParentId: string }>
    ) => {
      const oldParentIdIndex = state.list.findIndex(
        item => item.uid === payload.oldParentId
      );
      const newParentIdIndex = state.list.findIndex(
        item => item.uid === payload.newParentId
      );
    },
    changeFilterText: (state, { payload }: PA<string>) => {
      state.filterText = payload
    }
  },

  extraReducers: {
    [folderSummaryThunk.index.pending.type]: state => {
      state.loading = LoadingState.PENDING;
    },
    [folderSummaryThunk.index.fulfilled.type]: (
      state,
      { payload }: PA<FolderSummary[]>
    ) => {
      state.loading = LoadingState.FULFILLED;
      state.list = payload;
    },
    [folderSummaryThunk.index.rejected.type]: state => {
      state.loading = LoadingState.REJECTED;
      state.list = [];
    },
    [folderSummaryThunk.shortcuts.fulfilled.type]: (
      state,
      { payload }: PA<FolderSummary[]>
    ) => {
      state.shortcuts = payload;
    },
  }
});

export const { addFolderToSummary, removeFolderFromSummary, moveQueryReducer, changeFilterText } =
  index.actions;

export default index.reducer;
