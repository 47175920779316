import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { QueryHistorySummary } from './type';

const index = createAsyncThunk(
    'query-histories/summary',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.get<QueryHistorySummary[]>(
                `/query-histories/summary`,
                {
                    isProtected: true,
                },
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

export const QueryHistorySummaryThunk = { index };
