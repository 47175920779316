import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { shortenString } from '../../../pages/Editor/helpers/helpers';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
interface Props {
  value: string;
  onClick: (e: any) => void;
}
export const QueryName = ({ value, onClick }: Props) => {
  const { displayValue, showToolTip } = useMemo(() => {
    return {
      showToolTip: value ? value.length > 30 : false,
      displayValue: shortenString(`${value}`, 120)
    };
  }, [value]);

  const node = (
    <Typography variant="body1" sx={{ 'display': 'inline', ':hover': { color: 'primary.main' }, 'cursor': 'text' }} onClick={onClick}>
      {displayValue}
    </Typography>
  );

  if (showToolTip) {
    return (
      <CustomTooltip title={value} placement="top-start">
        {node}
      </CustomTooltip>
    );
  }

  return node;
};
