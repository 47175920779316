import { Logger } from '../../../utils/browser/logger';
import { IAiChatTypeProvider } from '../../types';
import { BaseGenSqlContext } from './contexs';

export const GenSqlProvider = (props: IAiChatTypeProvider) => {
  const ActionsContext = props.botAbstractActionsContext;
  const sendMessage = (message: any) => {
    ///
    Logger.info('GenSql sending message', message);
  };

  const reset = (message: any) => {
    ///
    Logger.info('GenSql Resetting');
  };
  const actionsValue = { sendMessage, reset };
  return (
    <ActionsContext.Provider value={actionsValue}>
      <BaseGenSqlContext.Provider value={null}>
        {props.children}
      </BaseGenSqlContext.Provider>
    </ActionsContext.Provider>
  );
};
