import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { BadgePosition } from './type';

const initialState: BadgePosition = {
    position: 400,
};

const index = createSlice({
    name: 'Badge_Position',
    initialState,
    reducers: {
        changeBadgePosition: (state, { payload }: PA<number>) => {
            state.position = payload;
        },
    },
});

export const { changeBadgePosition } = index.actions;

export default index.reducer;
