import { useState } from 'react';
import { useQueryDeletion } from './useQueryDeletion';
import { toast } from 'react-toastify';
import { queriesAsyncThunk } from '../../../store/queries/queries.thunk';

export const useDeleteQuery = () => {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => setOpenModal(!openModal);

    const { deleteQuery } = useQueryDeletion();

    return {
        openModal,
        toggleModal,
        deleteQuery,
    };
};
