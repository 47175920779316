const copyTextToClipboard = (data?: string) => {
    window.navigator.clipboard.writeText(data!);
};
export const logout = () => {
    localStorage.clear();
    location.reload();
};
export const BrowserUtils = Object.freeze({
    copyTextToClipboard,
});

export function redirectToNewPage(route: string) {
    const a = document.createElement('a');
    a.setAttribute('href', route);
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noreferrer noopener');
    a.click();
}
