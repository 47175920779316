import { format as sqlFormat, SqlLanguage } from 'sql-formatter';
import { toast } from 'react-toastify';

//allowed sources = bigquery,db2,hive,mariadb,mysql,n1ql,plsql,postgresql,redshift,singlestoredb,snowflake,spark,sql,sqlite,trino,tsql
const getLanguageName = (source?: string) => {
    switch (source) {
        case 'athena':
            return 'trino';
        case 'snowflake':
        case 'bigquery':
            return source;
        default:
            return 'sql';
    }
};

export const formatSql = (data: string, source?: string) => {
    try {
        return sqlFormat(data, { language: getLanguageName(source) as SqlLanguage });
    } catch {
        return data;
    }
};
