import { WatsonContainer } from '../../pages/Watson/components/container';
import { ScrollToBottomButton } from '../../pages/Watson/components/scrollToBottomButton';
import { useInitPageLayout } from '../../pages/Watson/hooks/useInitPageLayout';
import { AiBotMessagesRenderer } from './AiBotMessagesRenderer';
import { BotSearchInputManager } from './BotSearchInputManager';
import { useBotContainerDataTheme } from './hooks/useBotContainerDataTheme';
import { useAiBotMeta } from '../hooks/useAiBotMeta';
import PageLoader from '../../lib/components/PageLoader';
import { useUserAiBotConfig } from '../hooks/useUserAiBotConfig';
import { NotAuthorizedView } from './components/NotAuthorized';
import {
  ContentSectionBox,
  MessagesBox,
  MessagingContainer
} from './components/styled';
import { BotLoader } from './BotLoader';
import { BotPhaseAdapter } from './BotPhasesAdapter';
import { Header } from './components/header';

export const AiBotUI = () => {
  const dataTheme = useBotContainerDataTheme();
  const meta = useAiBotMeta();
  const { authorized } = useUserAiBotConfig();
  useInitPageLayout();

  if (meta.pageLoading || !meta.botInitialized) {
    return <PageLoader />;
  }

  if (meta.botInitialized && !authorized) {
    return <NotAuthorizedView />;
  }

  return (
    <WatsonContainer id="sherloq-watson" data-theme={dataTheme} tabIndex={0}>
      <Header />
      <ContentSectionBox>
        <MessagingContainer className="scroll-container">
          <MessagesBox>
            <AiBotMessagesRenderer />
            <BotPhaseAdapter />
            <BotLoader />
          </MessagesBox>
        </MessagingContainer>
        <BotSearchInputManager />
        <ScrollToBottomButton />
      </ContentSectionBox>
    </WatsonContainer>
  );
};
