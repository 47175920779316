import React, { useMemo } from 'react';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { FilterLabel } from '../../../pages/SearchFilter/components/filterLabel';
import { useRenderTeamSelectorValues } from '../hooks/useRenderTeamSelectorValues';
import { useTeamMembersSelector } from '../../../store/teamMembers/selectors';

interface TeamMembersSelector {
  selectedMembers: any[];
  onChange: (ev: SelectChangeEvent<any[]>) => void;
  onAddTeamClick: (ev) => void;
  onClickChip: (val: any) => void;
}

export const TeamMembersSelector = ({
  onChange,
  onClickChip,
  onAddTeamClick,
  selectedMembers
}: TeamMembersSelector) => {
  const user = useUserInfoSelector();
  const { list: teamMembers } = useTeamMembersSelector();
  const teamMemberOptions = useMemo(() => {
    return teamMembers
      .filter(member => member.uid !== user.uid)
      .map(member => {
        const label = `${member.first_name} ${member.last_name}`;
        const value = member.email;
        return { label, value };
      });
  }, [teamMembers]);
  const renderValues = useRenderTeamSelectorValues({ onRemove: onClickChip });

  return (
    <Box className="filter-item-container">
      <FilterLabel>Select Members or <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onAddTeamClick}>add from a team</span></FilterLabel>
      <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        multiple
        value={selectedMembers}
        onChange={onChange}
        renderValue={renderValues}
        sx={{
          flex: 1
        }}
      >
        {teamMemberOptions.map(({ label, value }) => {
          return (
            <MenuItem key={value} value={value} style={{ height: '25px' }}>
              <Typography variant="caption">{label}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
