import ListPageHeader from '../../components/ListPageHeader';
import { ListBox, MainPage } from '../QueryListPage/components/styled';
import { Typography, Box } from '@mui/material';
import { useInitPageLayout } from '../Watson/hooks/useInitPageLayout';
import { useState } from 'react';
import { ConditionComponent } from '../../lib/utils';
import Loader from '../../lib/components/Loader/Loader';

export default () => {
  const [loading, setLoading] = useState(true);

  useInitPageLayout();
  return (
    <MainPage className="save-queries">
      <ListPageHeader />
      <ListBox
        sx={{
          marginTop: '12px',
          overflow: 'hidden',
          flex: 1
        }}
      >
        <Box sx={{ height: '48px' }}>
          <Typography variant="h6">Tables</Typography>
        </Box>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ConditionComponent condition={loading}>
            <Loader />
          </ConditionComponent>
          <Box
            sx={{
              flex: 1,
              height: 'calc(100vh - 218px)',
              marginTop: '-138px',
              marginLeft: '-44px'
            }}
          >
            <iframe
              src="https://docs.google.com/spreadsheets/d/1WPihrzOufuYua1kK74jF9-xqJH2bBTcP1HgGBX-XedU/edit#gid=0"
              style={{
                flex: 1,
                border: 'none'
              }}
              width={'100%'}
              height={'100%'}
              onLoad={() => setLoading(false)}
              hidden={loading}
            />
          </Box>
        </div>
      </ListBox>
    </MainPage>
  );
};
