import WebAccessibleEvents from '../../../utils/events';

const toggleFrameWithRedirectListener = (cb: (redirectUrl: string) => void) => {
    document.addEventListener(
        WebAccessibleEvents.DISPATCH_TOGGLE_FRAME_WITH_REDIRECT,
        (args: any) => {
            const { redirectUrl } = args.detail;
            cb(redirectUrl);
        },
    );
};

export const CustomEventsListenerManager = Object.freeze({
    toggleFrameWithRedirectListener,
});
