import React from 'react';
import {
  styled,
  Button as MUIButton,
  ButtonProps,
  IconButton,
  IconButtonProps
} from '@mui/material';

interface NavRouteButton extends ButtonProps {
  isActive;
}

export const Button = styled(MUIButton)<NavRouteButton>(
  ({ theme, isActive }) => {
    const { main } = theme.palette.primary;
    return {
      borderBottom: isActive ? `2px solid ${main}` : '1px solid transparent',
      borderRadius: '0px',
      padding: '2px 6px'
    };
  }
);

export const Header = styled('header')(() => {
  return {
    padding: '0 24px'
  };
});

export const RightAligned = styled('div')(() => {
  return {
    marginLeft: 'auti',
    display: 'flex',
    alignItems: 'center'
  };
});

export const RepositoryStatesBtnsBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '12px',
    padding: '0px',
    height: '34px',
    borderRadius: '4px',
    columnGap: '4px'
  };
});

export const RepoDisplayToggleIconBtn = styled(
  (props: IconButtonProps & { selected: boolean }) => <IconButton {...props} />
)(({ selected, theme }) => {
  const {
    primary: { main, light },
    background,
    grey
  } = theme.palette;
  const backgroundColor = selected ? main : light;
  return {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor,
    color: selected ? background.default : '#000',
    ':hover': {
      color: grey['300'],
      backgroundColor
    }
  };
});

export const NewFolderBtn = styled(MUIButton)(() => {
  return {
    display: 'flex',
    color: '#fff',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    alignItems: 'center',
    columnGap: '4px'
  };
});
