import React from 'react';
import {
  styled,
  AccordionDetails,
  Accordion,
  AccordionSummary
} from '@mui/material';
export const MainPage = styled('main')(({ theme }) => {
  const { paper: backgroundColor } = theme.palette.background;
  return {
    backgroundColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  };
});

export const ListBox = styled('section')(() => {
  return {
    marginTop: '16px',
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    marginBottom: '12px',
    padding: '0 24px'
  };
});

export const IconBox = styled(
  (props: React.HTMLAttributes<HTMLDivElement> & { expanded: boolean }) => (
    <div {...props} />
  )
)(({ expanded }) => {
  return {
    transform: expanded ? 'rotate(0.25turn)' : 'rotate(0turn)',
    transition: 'transform 0.2s',
    marginRight: '4px'
  };
});

export const AccordionSummaryBox = styled(AccordionSummary)(({ theme }) => {
  const { grey } = theme.palette;
  return {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    height: '32px',
    boxSizing: 'border-box',
    boxShadow: theme.shadow.ring,
    margin: '6px 0',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center'
    },
    ':hover': {
      backgroundColor: grey['100']
    }
  };
});

export const AccordionDetailsBox = styled(AccordionDetails)(() => {
  return {
    padding: 0,
    paddingLeft: '8px'
  };
});

export const AccordionBox = styled(Accordion)(() => {
  return {
    boxShadow: 'none',
    borderBottom: 'none',
    '::before': {
      content: 'none'
    }
  };
});
