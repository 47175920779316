import React from 'react';
import { Select } from '@mui/material';
import { Box, Input, Stack, MenuItem } from '@mui/material';
import { sx } from '../../../styles/MUI/styles';
import { RenderIf } from '../../../lib/utils';
interface RulesProps {
  visible: boolean;
  onChangeRuleField?: any;
  onChangeAccessibility?: any;
  onChangeRuleValue?: any;
  data: {
    ruleAccessibility: string;
    ruleValue: string;
    ruleField: string;
    fieldOptions: any[];
    accessiblityOptions: any[];
  };
}

const Rules = ({
  data,
  visible: showRules,
  onChangeAccessibility,
  onChangeRuleField,
  onChangeRuleValue
}: RulesProps) => {
  return (
    <React.Fragment>
      <Box className="sherloq-add-rule-content">
        {RenderIf(
          showRules,
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            sx={{ marginTop: '16px' }}
          >
            <Box className="sherloq-rule-select1">
              <Select
                sx={{
                  width: '100px',
                  fontSize: '12px',
                  height: '28px'
                }}
                value={data?.ruleField ?? ''}
                onChange={onChangeRuleField}
                defaultValue="query"
              >
                {data?.fieldOptions.map((v: any) => (
                  <MenuItem key={v.value} sx={sx.sx3} value={v.value}>
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="sherloq-rule-select2">
              <Select
                value={data?.ruleAccessibility ?? ''}
                onChange={onChangeAccessibility}
                defaultValue="contains"
                sx={{
                  width: '100px',
                  fontSize: '12px',
                  height: '28px'
                }}
              >
                {data?.accessiblityOptions.map((v: any) => (
                  <MenuItem key={v.value} sx={sx.sx3} value={v.value}>
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="sherloq-rule-input">
              <Input
                color="primary"
                name="search-rule-string"
                value={data?.ruleValue ?? ''}
                onChange={onChangeRuleValue}
                placeholder="String to search..."
                sx={{
                  fontSize: '12px'
                }}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Rules;
