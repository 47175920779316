import React from 'react';
import { MenuItem, Typography, Box, Stack, BoxProps } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import { RenderIf } from '../../../lib/utils';
import { FolderIcon } from './folderIcon';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import { FolderType } from '../../../store/folderSummary/type';
import {
  dropdownFolderTypeIconStyles,
  dropdownItemSummaryTooltipStyles,
  dropdownListItemSummaryContainer,
  dropdownListItemSummaryMenuItemContainer,
  dropdownListItemSummaryMenuItemIconStyles,
  dropdownListItemSummaryMenuItemStackStyles
} from '../styles';

interface DrodownFolderItemDataType {
  folderType: FolderType;
  label: string;
  hasContent: boolean;
}

interface DropdownFolderProps extends BoxProps {
  data: DrodownFolderItemDataType;
}

export const DropdownMenuListItemSummary = React.forwardRef(
  (
    { data, ...props }: DropdownFolderProps,
    ref: React.MutableRefObject<HTMLLIElement>
  ) => {
    const showTooltip = data?.label?.length > 32;
    return (
      <Box sx={dropdownListItemSummaryContainer} {...props}>
        <CustomTooltip
          placement="top-start"
          title={showTooltip ? data?.label : ''}
          PopperProps={{
            style: dropdownItemSummaryTooltipStyles
          }}
        >
          <MenuItem sx={dropdownListItemSummaryMenuItemContainer} ref={ref}>
            <Stack
              direction="row"
              sx={dropdownListItemSummaryMenuItemStackStyles}
            >
              <Box sx={dropdownListItemSummaryMenuItemIconStyles}>
                <FolderIcon type={data?.folderType} />
              </Box>
              <Typography noWrap variant="body2">
                {data?.label}
              </Typography>
            </Stack>

            {RenderIf(
              data?.hasContent,
              <Box sx={dropdownListItemSummaryMenuItemIconStyles}>
                <ArrowRight sx={dropdownFolderTypeIconStyles} />
              </Box>
            )}
          </MenuItem>
        </CustomTooltip>
      </Box>
    );
  }
);
