import {
  MetaActions,
  MetaBox,
  MetaEditIcon,
  MetaFieldBox,
  MetaFieldInnerBox,
  MetaFieldLabel,
  MetaFieldValue
} from './styled';
import ShareIcon from '@mui/icons-material/Share';
import { formatDate } from '../../../../../../pages/Watson/components/DiscoveryData/filterTableDataRows';
import { Fragment, useRef, useState } from 'react';
import { EditdDate } from '../../../components/ProfileMetaModals/EditCreatedDateMetaModal';
import { EditUpdateFrequencyView } from '../../../components/ProfileMetaModals/EditFrequency';
import { EditOwner } from '../../../components/ProfileMetaModals/EditOwner';
import { toast } from 'react-toastify';
import {
  useTableProfileDataStore,
  useUpdateTableProfileData
} from '../../hooks/useTableProfileDataStore';

type EditType = 'created-date' | 'data-freshnesss-date' | 'frequency' | 'owner';

export const SecondaryMeta = () => {
  const [meta, setCurrentMeta] = useState<Nullable<EditType>>(null);
  const anchorRef = useRef<HTMLElement>(null);
  const { data } = useTableProfileDataStore();
  const updateField = useUpdateTableProfileData();

  const editMeta = (ev: React.MouseEvent, variant: EditType) => {
    setCurrentMeta(variant);
    anchorRef.current = ev.target as HTMLElement;
  };

  const closeModal = () => {
    setCurrentMeta(null);
    anchorRef.current = null;
  };

  const editFrequency = (ev: React.MouseEvent) => {
    editMeta(ev, 'frequency');
  };

  const editOwner = (ev: React.MouseEvent) => {
    editMeta(ev, 'owner');
  };

  const editDataFreshness = (ev: React.MouseEvent) => {
    editMeta(ev, 'data-freshnesss-date');
  };

  const onUpdateFieldSuccess = () => {
    toast('The property has been updated', { type: 'success' });
  };

  const updateCreatedDate = async (date: string) => {
    closeModal();
    await updateField('asset_created_date', new Date(date) as any, date);
    onUpdateFieldSuccess();
  };

  const updateDateFreshness = async (date: string) => {
    closeModal();
    await updateField('asset_updated_date', new Date(date) as any, date);
    onUpdateFieldSuccess();
  };

  const updateFrequency = async (days: number) => {
    closeModal();
    await updateField('asset_update_frequency_in_days', days);
    onUpdateFieldSuccess();
  };

  const updateOwner = async (owner: string) => {
    closeModal();
    await updateField('asset_owner', owner);
    onUpdateFieldSuccess();
  };

  return (
    <Fragment>
      <MetaBox>
        <MetaFieldBox>
          <MetaFieldLabel>Monthly references</MetaFieldLabel>
          <MetaFieldValue>{data?.num_of_references ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Last queried</MetaFieldLabel>
          <MetaFieldValue>
            {`${formatDate(data.last_reference_datetime)} by ${
              data.last_username
            }`}
          </MetaFieldValue>
          <MetaFieldValue>{data?.last_username}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Owner</MetaFieldLabel>
          <MetaFieldInnerBox>
            <MetaFieldValue>{data?.asset_owner ?? '-'}</MetaFieldValue>
            <MetaEditIcon onClick={editOwner} />
          </MetaFieldInnerBox>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Source</MetaFieldLabel>
          <MetaFieldValue>{data?.source ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Created date</MetaFieldLabel>
          <MetaFieldInnerBox>
            <MetaFieldValue>{data?.asset_created_date ?? '-'}</MetaFieldValue>
            <MetaEditIcon onClick={ev => editMeta(ev, 'created-date')} />
          </MetaFieldInnerBox>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Data freshness date</MetaFieldLabel>
          <MetaFieldInnerBox>
            <MetaFieldValue>{data?.asset_updated_date ?? '-'}</MetaFieldValue>
            <MetaEditIcon onClick={editDataFreshness} />
          </MetaFieldInnerBox>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Update frequency</MetaFieldLabel>
          <MetaFieldInnerBox>
            <MetaFieldValue>
              Each {data?.asset_update_frequency_in_days} days
            </MetaFieldValue>
            <MetaEditIcon onClick={editFrequency} />
          </MetaFieldInnerBox>
        </MetaFieldBox>
        <MetaActions>
          {/* <ShareIcon /> */}
        </MetaActions>
      </MetaBox>
      <EditdDate
        open={meta === 'created-date'}
        anchorEl={anchorRef.current}
        onClose={closeModal}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        label="Created date"
        submit={updateCreatedDate}
        data={data?.asset_created_date}
      />
      <EditdDate
        open={meta === 'data-freshnesss-date'}
        anchorEl={anchorRef.current}
        onClose={closeModal}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        label="Data freshness date"
        submit={updateDateFreshness}
        data={data?.asset_updated_date}
      />
      <EditUpdateFrequencyView
        open={meta === 'frequency'}
        anchorEl={anchorRef.current}
        onClose={closeModal}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        submit={updateFrequency}
      />
      <EditOwner
        open={meta === 'owner'}
        anchorEl={anchorRef.current}
        onClose={closeModal}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        submit={updateOwner}
        data={data?.asset_owner}
      />
    </Fragment>
  );
};
