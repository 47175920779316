import './index.scss';
import App from './App';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import UIContextProvider from './context';
import { BrowserRouter as Router } from 'react-router-dom';
import store, { storePersister } from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SherloqThemProvider } from '@sherloq/design-system';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <div className="App">
        <div id="side-bar-root">
          <Provider store={store}>
            <PersistGate persistor={storePersister}>
              <UIContextProvider>
                <SherloqThemProvider>
                  <Router>
                    <App />
                  </Router>
                </SherloqThemProvider>
              </UIContextProvider>
            </PersistGate>
          </Provider>
        </div>
      </div>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
