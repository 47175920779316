import { getUserCompanyFromEmailUseCase } from '../../utils/core/getUserCompanyFromEmail';
import { AiBotVariant } from '../domain/chatTypes';
import { IAiBotTypeMeta } from '../types';

const genSqlLabel = 'Botson AI';
const discoveryLabel = 'Botson Discovery';
const gptLabel = 'GPT-4';
const gptDesc = 'Ask what you would ask ChatGPT';
const genSqlDesc = 'Generate SQL Queries';

export function getChatTypesMeta(
  availableChatTypes: AiBotVariant[],
  email: string
): IAiBotTypeMeta[] {
  const company = getUserCompanyFromEmailUseCase(email);
  let discoverDesc = `Ask questions about your data`;
  if (company) {
    discoverDesc = `Ask questions about ${company}'s data`;
  }

  return availableChatTypes.map(type => {
    if (type === AiBotVariant.GPT4) {
      return {
        label: gptLabel,
        variant: type,
        description: gptDesc
      };
    }
    if (type === AiBotVariant.BotsonDiscovery) {
      return {
        label: discoveryLabel,
        variant: type,
        description: discoverDesc
      };
    }
    return {
      label: genSqlLabel,
      variant: type,
      description: genSqlDesc
    };
  });
}
