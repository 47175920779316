import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/types/general';
import { PaginatedDataType } from '../../utils/types/paginatedData';
import { queryHistoryThunks } from './thunk';
import { QueryHistory, QueryHistoryState } from './type';

const initialState: QueryHistoryState = {
    list: [],
    updatedAt: -1,
    createLoading: LoadingState.IDLE,
    fetchLoading: LoadingState.IDLE,
    deleteLoading: LoadingState.IDLE,
    meta: {
        count: -1,
        last: -1,
        page: -1,
        page_size: -1,
    },
};

const slice = createSlice({
    name: 'query-history',
    initialState,
    reducers: {},
    extraReducers: {
        //Fetch actions
        [queryHistoryThunks.index.pending.type]: (state) => {
            state.fetchLoading = LoadingState.PENDING;
        },
        [queryHistoryThunks.index.fulfilled.type]: (
            state,
            { payload }: PA<PaginatedDataType<QueryHistory>>,
        ) => {
            state.fetchLoading = LoadingState.FULFILLED;
            state.meta = payload.meta;
            if (payload.meta.page === 1) {
                state.list = payload.list;
            } else {
                state.list = state.list.concat(payload.list);
            }
        },
        [queryHistoryThunks.index.rejected.type]: (state) => {
            state.fetchLoading = LoadingState.REJECTED;
        },

        //Create actions
        [queryHistoryThunks.create.pending.type]: (state) => {
            state.createLoading = LoadingState.PENDING;
        },
        [queryHistoryThunks.create.fulfilled.type]: (state, { payload }: PA<QueryHistory>) => {
            state.createLoading = LoadingState.FULFILLED;
            state.list.push(payload);
        },
        [queryHistoryThunks.create.rejected.type]: (state) => {
            state.createLoading = LoadingState.REJECTED;
        },

        //Search actions
        [queryHistoryThunks.search.pending.type]: (state) => {
            state.fetchLoading = LoadingState.PENDING;
        },
        [queryHistoryThunks.search.fulfilled.type]: (state, { payload }: PA<QueryHistory[]>) => {
            state.fetchLoading = LoadingState.FULFILLED;
            state.list = payload;
        },
        [queryHistoryThunks.search.rejected.type]: (state) => {
            state.fetchLoading = LoadingState.REJECTED;
        },

        //Delete actions
        [queryHistoryThunks.remove.pending.type]: (state) => {
            state.deleteLoading = LoadingState.PENDING;
        },
        [queryHistoryThunks.remove.fulfilled.type]: (state, { payload }: PA<string>) => {
            state.deleteLoading = LoadingState.FULFILLED;
            const index = state.list.findIndex((item) => item.uid === payload);

            if (index !== -1) {
                state.list.splice(index, 1);
            }
        },
        [queryHistoryThunks.remove.rejected.type]: (state) => {
            state.deleteLoading = LoadingState.REJECTED;
        },
    },
});

export const {} = slice.actions;

export default slice.reducer;
