import { styled, Box } from '@mui/material';
export const WatsonContainer = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    flex: 1,
    flexDirection: 'column',
    paddingRight: 0,
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    ':focus': {
      border: 'none',
      outlineWidth: 0
    }
  };
});
