import React, { Fragment } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { IGeneratedQueryWatsonMessage } from '../../interfaces';
import { GeneratedQueryAsset } from './asset';
import { Stack, Box } from '@mui/material';
import LikeDislikeForAssets from '../LikeDislikeForAssets';

interface Props extends CodeProps {
  data: IGeneratedQueryWatsonMessage;
  expandableSyntaxHighlighterComponent?: React.ComponentType<any>;
}

export const GeneratedQueryCodeBlock = ({
  data,
  expandableSyntaxHighlighterComponent: Component,
  ...props
}: Props) => {
  return (
    <Fragment>
      <Box position={'relative'}>
        <Component {...props} />
      </Box>
      <Stack direction="column" rowGap={'8px'}>
        {data.assets?.map((asset, idx) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={idx}>
            <GeneratedQueryAsset data={asset} key={idx} />
            <LikeDislikeForAssets message={data.body} asset={asset} />
          </Box>
        ))}
      </Stack>
    </Fragment>
  );
};
