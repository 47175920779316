import { useMemo } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../utils/core/getUserCompanyFromEmail';

export default () => {
  const user = useUserInfoSelector();
  return useMemo(() => {
    let company: string;
    let isSherloqHelloUser = false;
    if (user && user.email) {
      isSherloqHelloUser = user.email === 'hello@sherloqdata.com' || user.email === 'hello@sherloqdata.io'
    }
    return isSherloqHelloUser;
  }, [user]);
};
