import { sx } from '../../styles/MUI/styles';
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ModalRoot } from './root';
import { DesignSystemMap } from '../../styles/MUI/designSystem';
import { CancelButton } from './components/cancelButton';
import { ModalTypography } from './components/label';

interface DeleteModalProps {
  title: string;
  subTitle: string;
  show: boolean;
  setClose: () => void;
  handleDelete: () => void;
}
DesignSystemMap;
const DeleteModal = ({
  title,
  subTitle,
  show,
  setClose,
  handleDelete
}: DeleteModalProps) => {
  return (
    <ModalRoot variant="error" onClose={setClose} open={show}>
      <ModalTypography className="title">{title}</ModalTypography>
      <Box className="content">
        <ModalTypography className="sub-title">{subTitle}</ModalTypography>
        <Box className="buttons-container">
          <CancelButton onClick={setClose} />
          <Button
            sx={sx.btn}
            variant="contained"
            onClick={handleDelete}
            className="error-button"
          >
            Delete
          </Button>
        </Box>
      </Box>
    </ModalRoot>
  );
};

export default DeleteModal;
