import React, { useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { SelectFolderDropdown } from '../../../components/selectFolderDropdown';
import { useFolderTree } from '../../../lib/hooks/useFolderTree';
import { useHotSaveAsFlowState } from '../context';
import { FolderSummary } from '../../../store/folderSummary/type';
import { ModalTypography } from '../../../components/modals/components/label';
import { folderFilterTextStyles } from '../../SearchFilter/styles';
import { selectFolderDropdownComponent } from '../../../components/modals/styles';
import { ISaveAsError } from '../interface';
import { RenderIf } from '../../../lib/utils';

const inputStyle = {
  minHeight: '40px',
  height: '40px',
  padding: 0,
  paddingLeft: '8px',
  fontSize: '12px'
};

interface SaveAsNewProps {
  onChangeName?: (e?: any) => void;
  onChangeDesc?: (e?: any) => void;
  meta: any;
  errors?: ISaveAsError;
}

export const SaveAsNew = ({
  onChangeDesc,
  onChangeName,
  meta,
  errors
}: SaveAsNewProps) => {
  const folders = useFolderTree();
  const [payload, setPayload] = useHotSaveAsFlowState();

  const handleOnSelectFolder = (folder: FolderSummary) => {
    const id = folder.uid;
    setPayload(p => ({ ...p, parentFolder: id }));
  };

  useEffect(() => {
    if (!payload.parentFolder) {
      const parentFolder = folders.find(
        folder => folder.name === 'Default'
      ).uid;
      setPayload(p => ({ ...p, parentFolder }));
    }
  }, [folders, payload]);

  return (
    <div>
      <Box>
        <ModalTypography variant="subtitle2">Query name *</ModalTypography>
        <TextField
          value={meta.name}
          sx={{ fontSize: '12px' }}
          inputProps={{
            style: inputStyle
          }}
          onChange={onChangeName}
          placeholder="eg. New Users"
          error={errors?.isDuplicateError}
        />
        {RenderIf(
          errors?.isDuplicateError,
          <ModalTypography
            sx={{ display: 'block', color: 'error.main', paddingTop: '2px' }}
            variant="caption"
          >
            Query name already exists
          </ModalTypography>
        )}
      </Box>

      <Box sx={{ marginTop: '16px' }}>
        <ModalTypography variant="subtitle2">
          Query Description (recommended)
        </ModalTypography>
        <TextField
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              padding: '8px'
            }
          }}
          value={meta.description}
          onChange={onChangeDesc}
          inputProps={{ style: { fontSize: '12px' } }}
          multiline
          rows={3}
          placeholder="Add description or we will generate one..."
        />
      </Box>
      <Box sx={{ marginTop: '16px' }}>
        <SelectFolderDropdown
          folders={folders}
          onChange={handleOnSelectFolder}
          selected={payload.parentFolder}
          labelProps={{ className: 'input-label' }}
          buttonProps={{ sx: selectFolderDropdownComponent }}
          textProps={{
            style: folderFilterTextStyles
          }}
        />
      </Box>
    </div>
  );
};
