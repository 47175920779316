import React, { Fragment, useState } from 'react';
import InviteFeedbackModal from '../../../../components/modals/InviteFeedbackModal';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { toast } from 'react-toastify';
import { useUserInfoSelector } from '../../../../store/auth/selectors';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import { Box, Typography, Button } from '@mui/material';

export default () => {
  const [text, setText] = useState('');
  const [showIDEModal, setShowIDEModal] = useState(false);
  const userInfoSelector = useUserInfoSelector();

  const compatibleIDEHandler = () => {
    setShowIDEModal(false);
    setText('');
    MixedPanelEvent.queryHistoryIdeRequest({
      ide: text,
      invitor: `${userInfoSelector?.first_name} ' ' ${userInfoSelector?.last_name}`
    });
    toast('Thanks for your feedback (:', { type: 'success' });
  };

  return (
    <Fragment>
      <Box style={{ textAlign: 'center' }}>
        <br />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px'
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Here you can see all the queries you’ve run. <br />
            Don’t see it? if you don’t have one of our
            <CustomTooltip
              title={'Athena, BigQuery, Snowflake, Mode ...'}
              placement="top"
            >
              <span
                style={{
                  borderBottom: '1px solid black',
                  color: 'black',
                  margin: '0px 3px'
                }}
              >
                {' '}
                compatible{' '}
              </span>
            </CustomTooltip>
            <br /> query editors, please let us know what query editor you’re
            using
          </Typography>
        </Box>
        <Button
          variant="text"
          sx={{ fontSize: '14px', textTransform: 'none', height: '16px' }}
          onClick={() => setShowIDEModal(true)}
        >
          here.
        </Button>
      </Box>
      <InviteFeedbackModal
        title="Which query editor do you use ?"
        subTitle="All feedbacks are highly appreciated and help us fine tune Sherloq to be the best product you need..."
        show={showIDEModal}
        setClose={() => setShowIDEModal(false)}
        handlerSubmit={compatibleIDEHandler}
        text={text}
        setText={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setText(e.target.value)
        }
        placeholder="My query editor is"
      />
    </Fragment>
  );
};
