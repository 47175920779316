import DiscoveryPhaseAdapter from '../bots/Discovery/PhaseAdapter';
import { AiBotVariant } from '../domain/chatTypes';
import { useAiBotMeta } from '../hooks/useAiBotMeta';

export const BotPhaseAdapter = () => {
  const { chatType } = useAiBotMeta();
  if (chatType === AiBotVariant.BotsonDiscovery) {
    return <DiscoveryPhaseAdapter />;
  }
  return null;
};
