import { sx } from '../../styles/MUI/styles';
import { isDatagrip } from '../../utils/checkOrigin';

const selectFolderButtonStyles = {
  border: '1px solid #d9d9d9',
  padding: 0,
  backgroundColor: '#fff',
  paddingLeft: '2px'
};

const makeSelectFolderStyles = darkMode => {
  const styles = {
    ...(darkMode ? sx.sx1dark : sx.sx1),
    fontSize: '12px',
    maxWidth: '120px'
  };
  return styles;
};

const editorFolderDetailsContainer = {
  marginBottom: '0px',
  display: 'flex',
  alignItems: 'center'
};

const editorFooterContainer = {
  marginTop: 'auto',
  paddingBottom: '24px'
};

const editorAdvancedFeaturesContainer = {
  height: '20px'
};

const editorQueryDetailContainerStyles = {
  padding: '24px'
};

const editorLayoutContainerStyles = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0
};

export {
  selectFolderButtonStyles,
  makeSelectFolderStyles,
  editorFolderDetailsContainer,
  editorFooterContainer,
  editorAdvancedFeaturesContainer,
  editorQueryDetailContainerStyles,
  editorLayoutContainerStyles
};
