import React from 'react';
import { Box } from '@mui/material';
import { TextFieldFilter } from './TextFieldFilter';
import QueriedAtFilter from './queriedAtFilter';

export const QueryFilters = ({
  selectedQueryContains,
  handleQueryContainsSelection,
  selectedTimeExecuted,
  handleTimeExectuedSelection,
  selectedQueriedBy,
  handleQueriedBySelection,
  selectedRelevantTables,
  handleRelevantTablesSelection,
  isDisabled
}) => {
  return (
    <Box>
      <TextFieldFilter
        selectedValue={selectedQueryContains}
        onSelectionChange={handleQueryContainsSelection}
        isDisabled={isDisabled}
        type={''}
        label={'Query contains:'}
        allowSpecialChars={true}
      />
      <QueriedAtFilter
        selectedValue={selectedTimeExecuted}
        onSelectionChange={handleTimeExectuedSelection}
        isDisabled={isDisabled}
      />
      <TextFieldFilter
        selectedValue={selectedQueriedBy}
        onSelectionChange={handleQueriedBySelection}
        isDisabled={isDisabled}
        type={'user'}
        label={'User executed:'}
        allowSpecialChars={true}
      />
      {/* <TextFieldFilter
        selectedValue={selectedRelevantTables}
        onSelectionChange={handleRelevantTablesSelection}
        isDisabled={true}
        type={''}
        label={'Relevant tables:'}
      /> */}
    </Box>
  );
};
