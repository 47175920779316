import React, { ReactNode, useState } from 'react';
import { Typography } from '@mui/material';
import { useAppRepositoryDisplayMode } from '../../../store/sherloqAppConfig/selector';
import { IRepositoryFolderQuery } from '../interface';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import QueryItem from '../../../components/QueryItem';
import { ConditionComponent } from '../../../lib/utils';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  AccordionBox,
  AccordionDetailsBox,
  AccordionSummaryBox,
  IconBox
} from './styled';
import { shortenString } from '../../Editor/helpers/helpers';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import { RepositoryDisplayModeType } from '../../../store/sherloqAppConfig/type';
interface RepositoryFolderQuery {
  data: IRepositoryFolderQuery;
  state?: RepositoryDisplayModeType;
  listPreview?: React.ComponentType<any>;
  handleClick?: () => void;
}

export default function ({
  data,
  state: defaultState,
  listPreview: ListPreview,
  handleClick
}: RepositoryFolderQuery) {
  const state = defaultState ?? useAppRepositoryDisplayMode()[0];
  const [opened, setOpened] = useState(false);
  const handleChange = () => {
    if (!opened) {
      handleClick();
    }
    setOpened(p => !p);
  };
  const expanded = state === 'thumbnail' ? true : opened;

  return (
    <AccordionBox disableGutters expanded={expanded} onChange={handleChange}>
      <ConditionComponent condition={state === 'list'}>
        <AccordionSummaryBox expandIcon={null}>
          <IconBox expanded={expanded}>
            <ConditionComponent condition={expanded}>
              <PlayArrowIcon />
            </ConditionComponent>
            <ConditionComponent condition={!expanded}>
              <PlayArrowOutlinedIcon
                sx={{ top: '4px', position: 'relative' }}
              />
            </ConditionComponent>
          </IconBox>
          <ConditionComponent condition={!ListPreview}>
            <CustomTooltip title={data.query_name} placement="top-start">
              <Typography
                component={'p'}
                variant="body2"
                sx={{ height: '20px', overflow: 'hidden' }}
              >
                {shortenString(data.query_name, 120)}
              </Typography>
            </CustomTooltip>
          </ConditionComponent>
          <ConditionComponent condition={!!ListPreview}>
            <ListPreview data={data} />
          </ConditionComponent>
        </AccordionSummaryBox>
      </ConditionComponent>

      <AccordionDetailsBox>
        <QueryItem {...data} />
      </AccordionDetailsBox>
    </AccordionBox>
  );
}
