import '../pages/Watson/index.scss';
import { AiChatRootProvider } from './context/AiChatRootProvider';
import { AiBotUI } from './ui';

const AiBot = () => {
  return (
    <AiChatRootProvider>
      <AiBotUI />
    </AiChatRootProvider>
  );
};

export default AiBot;
