import { useEffect, useState } from 'react';
import { getTags } from '../../components/Tags/API/fetch';

export const useInitTags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    getTags().then(res => {
      setTags(res.data.tags);
    });
  }, []);

  return {
    tags
  };
};
