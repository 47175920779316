export function parseDescription(desc: string) {
    const features = { isAuto: false, val: '' };
    const isAutoGenerated = desc?.startsWith('AutoGenerated');
    if (isAutoGenerated) {
        const asStr = desc.split('AutoGenerated | ')?.[1];
        features.isAuto = true;
        features.val = asStr;
    } else features.val = desc;
    return features;
}
