import React, { useState } from 'react';
import { InfoLabel } from '../../../../components/InfoLabel';
import { TableHeadWrapper } from '../styled';
import { RowData } from './RowData';
import { Container, SubheaderText } from './styled';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITableProfileUser } from '../../../../../utils/types';
import { ConditionComponent } from '../../../../../../lib/utils';

export const UsersUsage = ({ users }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <TableContainer className="scroll-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadWrapper>
                <InfoLabel>Users usage</InfoLabel>
                <SubheaderText># uses</SubheaderText>
                <IconButton
                  onClick={handleToggleOpen}
                  style={{
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </TableHeadWrapper>
            </TableRow>
          </TableHead>
          <ConditionComponent condition={isOpen}>
            <TableBody>
              {users?.map((userData: ITableProfileUser) => (
                <RowData data={userData} />
              ))}
            </TableBody>
          </ConditionComponent>
        </Table>
      </TableContainer>
    </Container>
  );
};
