import { styled } from '@mui/material';

const Row = styled('div')(() => {
  return { display: 'flex' };
});

export const SearchAndFilterContainer = styled(Row)(() => {
  return { columnGap: '36px', marginTop: '25px' };
});

export const FilterContainer = styled(Row)(() => {
  return { columnGap: '10px', alignItems: 'center', cursor: 'pointer' };
});

export const SortByContainer = styled(Row)(() => {
  return { columnGap: '10px', alignItems: 'center' };
});

export const SearchContainer = styled(Row)(() => {
  return { width: '450px' };
});

export const ClickableIcon = styled(Row)(() => {
  return {
    '&:hover': {
      cursor: 'pointer'
    }
  };
});
