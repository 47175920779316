import { Button } from '@mui/material';
import { DiscoveryFeedbackBtnsBox } from '../../../pages/Watson/components/components';
import { AiBotVariantMeta } from '../../types';
import { useAiBotActions } from '../../hooks/useAiBotActions';

interface Props {
  meta: AiBotVariantMeta;
}

export default ({ meta }: Props) => {
  const { reset } = useAiBotActions();
  const handleOnClickFeedback = async (val: 'restart') => {
    reset();
  };

  return (
    <DiscoveryFeedbackBtnsBox data-type={'phase'}>
      <Button
        variant="contained"
        onClick={() => handleOnClickFeedback('restart')}
      >
        Restart and ask a new question
      </Button>
    </DiscoveryFeedbackBtnsBox>
  );
};
