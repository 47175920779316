import { Reducer } from 'react';
import { AiChatDataStoreState } from '../../types';
import { AiBotDataStoreActions, AiChatStateManagementAction } from './actions';
import { AiBotVariant } from '../../domain/chatTypes';
import { UserCompanyVariant } from '../../../utils/types/user';

export const InitialData: AiChatDataStoreState = {
  pageLoading: false,
  botInitialized: false,
  chatType: AiBotVariant.BotsonDiscovery,
  inputProps: {
    hidden: false
  },
  promptProps: { hidden: false, value: null },
  sessionId: '',
  messages: [],
  messageRequestController: null,
  loadingMessage: false,
  variantMeta: {},
  userConfig: {
    availableChatTypes: [],
    authorized: false,
    company: UserCompanyVariant.Other
  }
};

export const AiChatDataStoreReducer: Reducer<
  AiChatDataStoreState,
  AiChatStateManagementAction
> = (oldState, action) => {
  if (action.type === AiBotDataStoreActions.SetLoading) {
    const state = { ...oldState };
    state.pageLoading = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.HideInput) {
    const state = { ...oldState };
    state.inputProps.hidden = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.SetUserConfig) {
    const state = { ...oldState };
    const { sessionId, ...payload } = action.payload;
    state.userConfig = payload;
    state.botInitialized = true;
    state.sessionId = sessionId;
    return state;
  }
  if (action.type === AiBotDataStoreActions.ToggleBotType) {
    const state = { ...oldState };
    state.chatType = action.payload;
    state.variantMeta = {}; // reset bot and variant dependent state to initial value
    return state;
  }
  if (action.type === AiBotDataStoreActions.HidePrompts) {
    const state = { ...oldState };
    state.promptProps.hidden = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.LoadMessage) {
    const state = { ...oldState };
    state.loadingMessage = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.AddMessage) {
    const state = { ...oldState };
    state.messages = [...state.messages, action.payload];
    return state;
  }
  if (action.type === AiBotDataStoreActions.SelectPrompt) {
    const state = { ...oldState };
    state.promptProps.value = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.SetVariantMeta) {
    const state = { ...oldState };
    state.variantMeta = action.payload;
    return state;
  }
  if (action.type === AiBotDataStoreActions.UpdateVariantMeta) {
    const state = { ...oldState };
    state.variantMeta = { ...state.variantMeta, ...action.payload };
    return state;
  }
  if (action.type === AiBotDataStoreActions.ClearMessages) {
    const state = { ...oldState };
    state.messages = [];
    const inputIsHidden = state.inputProps.hidden;
    if (inputIsHidden) {
      state.inputProps.hidden = false;
    }
    return state;
  }
  return oldState;
};
