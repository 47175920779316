import { useMemo } from 'react';
import { useDarkModeState } from '../../../store/themeMode/selectors';

export const useBotContainerDataTheme = () => {
  const darkMode = useDarkModeState();
  return useMemo(() => {
    if (darkMode) return 'dark';
    return 'light';
  }, [darkMode]);
};
