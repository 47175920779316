import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { defaultTheme } from './default.theme';

const lightMode = createTheme({
  palette: {
    grey: {
      '100': '#eaeaea',
      '200': '#d7d7d7',
      '300': '#d5d5d5'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.8)',
      secondary: '#00000070',
      disabled: 'rgba(0, 0, 0, 0.3)',
      darkBgPrimary: '#fff'
    },
    secondary: {
      main: 'rgba(0, 117, 231, 1)',
      dark: 'rgba(3, 105, 204, 1)',
      light: 'rgba(0, 117, 231, 0.5)',
      '200': 'rgba(0, 117, 231, 0.3)',
      '100': 'rgba(0, 117, 231, 0.1)'
    },
    primary: {
      main: '#7209B7',
      dark: '#58088C',
      light: '#7209B730',
      '200': 'rgba(0, 117, 231, 0.3)',
      '100': 'rgba(0, 117, 231, 0.1)'
    },
    error: {
      main: '#D32F2F',
      dark: '#D32F2F',
      light: '#D32F2F'
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d'
    },
    success: {
      main: '#4caf50',
      dark: '#388e3c',
      light: '#81c784'
    },
    background: {
      paper: '#fff',
      default: '#fff',
      paperPopup: '#fff',
      paperSurface: '#f9f9f9'
    },
    input: {
      background: '#f9f9f9',
      border: '#d9d9d959',
      focused: '#a9a9a990'
    },
    darkBorder: {
      main: '#00000010',
      light: '#d9d9d9',
      dark: '#222222'
    }
  }
});

export const lightModeTheme = createTheme(deepmerge(defaultTheme, lightMode));
