import { Box, Typography, Button } from '@mui/material';
import unionBy from 'lodash/unionBy';
import { useAppDispatch } from '../../store';
import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import QueryItem from '../../components/QueryItem';
import { getTags } from '../../components/Tags/API/fetch';
import { FolderSummary } from '../../store/folderSummary/type';
import QueryFolderListItem from '../../components/QueryFolderListItem';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import { newQueryFolderContentThunks } from '../../store/newFolderContent/thunk';
import { useQueryFoldersSelectorState } from '../../store/queryFolder/selectors';
import { useNewFolderContentState } from '../../store/newFolderContent/selectors';
import { LoadingState } from '../../utils/types/general';
import { ContentMetaStateBox } from '../../components/QueryFolderListItem/components/styled';
import { ConditionComponent } from '../../lib/utils';
import RepositoryQuery from '../QueryListPage/components/RepositoryQuery';
import { Logger } from '../../utils/browser/logger';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { sx } from '../../styles/MUI/styles';

interface Props {
  folderId: string;
  queryType: string;
  folderName: string;
  data?: any;
  isFolderEditor?: Boolean;
  setIsFolderEditor?: any;
}

const QueryFolderContentPage: React.FC<Props> = ({
  folderId,
  folderName,
  queryType,
  setIsFolderEditor,
  isFolderEditor
}) => {
  const dispatch = useAppDispatch();
  const [tags, setTags] = useState([]);
  const folderSummary = useFolderSummarySelector();
  const folderSummaryShared = useSharedFoldersSelector();
  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const userQueryFoldersList = useQueryFoldersSelectorState();
  const folderContent = useNewFolderContentState(folderId!);
  const folders = unionBy(folderSummary.list, folderSummaryShared.list, 'uid');
  const loadingFolderContent = Boolean(
    !folderContent || folderContent?.loading === LoadingState.PENDING
  );

  const noData = !loadingFolderContent && folderContent?.list.length === 0;

  const showMore = useMemo(() => {
    if (folderContent?.meta) {
      return folderContent.meta.page < folderContent.meta.last;
    }
    return false;
  }, [folderContent]);

  const fetchContent = async (page: number) => {
    dispatch(
      newQueryFolderContentThunks.index({ limit: 10, page, uid: folderId })
    );
  };

  useEffect(() => {
    getTags().then(res => {
      setTags(res.data.tags);
    });
  }, [userQueryFoldersList, folderSummary]);

  const checkIsSharedRoot = (fid: string): [string, number] => {
    let level: number = 0;
    let shared_type: string = 'personal';
    folders.map(f => {
      if (f.uid === fid) {
        shared_type = f.shared_type;
        level = 1;
      }
      f.sub_folders.map((sf: FolderSummary) => {
        if (sf.uid === fid) {
          shared_type = f.shared_type;
          level = 2;
        }
        sf.sub_folders.map((ssf: FolderSummary) => {
          if (ssf.uid === fid) {
            shared_type = f.shared_type;
            level = 3;
          }
        });
      });
    });
    return [shared_type, level];
  };

  const handleQueryClick = (query: string) => {
    MixedPanelEvent.savedQueryListViewClicked({
      query: query
    });
  };

  const isSharedRoot = checkIsSharedRoot(folderId);

  const handleLoadMore = (_: any) => {
    const newPage = folderContent.meta.page + 1;
    fetchContent(newPage);
  };

  if (noData) {
    return (
      <>
        { !isFolderEditor && <Button onClick={() => setIsFolderEditor(true)} variant='outlined' color='primary' sx={sx.HeaderBtn} style={{ width: '100%', margin: '0 auto 8px', display: 'block' }}>Add New Query</Button> }
        {/* <ContentMetaStateBox>
          <Typography variant="caption">Folder is empty</Typography>
        </ContentMetaStateBox> */}
      </>
    );
  }
  return (
    <Box className="list-contents-items" ref={scrollParentRef}>
      {/* { !isFolderEditor && <Button onClick={() => setIsFolderEditor(true)} variant='outlined' color='primary' sx={sx.HeaderBtn} style={{ width: '100%', height: '28px', margin: '0 0 8px', display: 'block' }}>Add New Query</Button> } */}
      {/* <ConditionComponent
        condition={folderContent?.loading === LoadingState.REFETCHING}
      >
        <ContentMetaStateBox>
          <Typography variant="caption">Refreshing data</Typography>
        </ContentMetaStateBox>
      </ConditionComponent> */}
      {folderContent?.list.map(item =>
        item.type === 'query' ? (
          <RepositoryQuery
            handleClick={() => handleQueryClick(item.query?.query)}
            key={`${item.query.uid}-${item.query.updated_at}`}
            data={{
              query: item.query?.query || '',
              query_uid: item.query?.uid || '',
              query_name: item.query?.name || '',
              query_origin: item.query?.origin || '',
              query_created_at: item.query?.created_at || '',
              query_description: item.query?.description || '',
              query_owner_username: `${item.query?.user?.first_name} ${item.query?.user?.last_name}`,
              tags: tags,
              queryTags: item.query?.tags_relationship || [],
              folderName: folderName!,
              folderId: folderId,
              type: queryType,
              user_uid: item.query?.user?.uid || ''
            }}
          />
        ) : item.type === 'folder' ? (
          <QueryFolderListItem
            isSubFolder={true}
            key={item.child.uid}
            folder={{
              created_at: item.created_at,
              id: item.child?.id || '',
              shared_type: isSharedRoot[0],
              uid: item.child?.uid || '',
              members: [],
              user_id: item.child?.user?.uid || '',
              name: item.child?.name || '',
              description: item.child?.description || '',
              sub_folders: item.child?.sub_folders || [],
              user_first_name: '',
              user_last_name: '',
              user_email: '',
              parent_uid: item.parent!.uid,
              parent_id: item.parent!.id,
              folder_name: '',
              is_shared: item.child?.is_shared!
            }}
            accordionId={item.child?.uid}
            queryType={isSharedRoot[0]}
            level={isSharedRoot[1]}
          />
        ) : null
      )}
      <ConditionComponent condition={loadingFolderContent}>
        <ContentMetaStateBox>
          <Typography variant="caption">Loading</Typography>
        </ContentMetaStateBox>
      </ConditionComponent>
      <ConditionComponent condition={showMore}>
        <Button variant="text" onClick={handleLoadMore}>
          show more
        </Button>
      </ConditionComponent>
    </Box>
  );
};

export default QueryFolderContentPage;
