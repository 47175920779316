import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';

const initialState: any = {
    isDarkMode: false
};

const index = createSlice({
    name: 'Theme_Mode',
    initialState,
    reducers: {
        toggleDarkMode: (state, {payload}: PA<boolean>) => {
            state.isDarkMode = payload;
        }
    },
});

export const { toggleDarkMode } = index.actions;

export default index.reducer;
