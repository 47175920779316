import React from 'react';
import { Box } from '@mui/material';
import { PartitionFilter } from './fieldFilters/Partitionfilter';
import { TextFieldFilter } from './TextFieldFilter';

export const CalculatedFieldFilters = ({
  selectedCalculatedField,
  handleCalculatedFieldSelection,
  selectedDashboardName,
  handleDashboardNameSelection,
  selectedField,
  handleFieldSelection,
  isDisabled
}) => {
  return (
    <Box>
      <TextFieldFilter
        selectedValue={selectedCalculatedField}
        onSelectionChange={handleCalculatedFieldSelection}
        isDisabled={isDisabled}
        type={'calculated-field'}
        label={'Metric / Calculated field name'}
        subLabel={'Full or partial field name'}
      />
      <TextFieldFilter
        selectedValue={selectedDashboardName}
        onSelectionChange={handleDashboardNameSelection}
        isDisabled={isDisabled}
        type={'dashboard'}
        label={'Dashboard:'}
        subLabel={'full or partial name'}
        marginRight={'165px'}
      />
      <TextFieldFilter
        selectedValue={selectedField}
        onSelectionChange={handleFieldSelection}
        isDisabled={isDisabled}
        type={'field'}
        label={'Related field:'}
        marginRight={'160px'}
      />
    </Box>
  );
};
