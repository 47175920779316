import React, { useState } from 'react';
import { InfoLabel } from '../../../../components/InfoLabel';
import { TableHeadWrapper } from '../styled';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RowData } from './RowData';
import { ITableProfileUserJoin } from '../../../../../utils/types';
import { MostJoinedContainer } from '../../../components/MostJoinedStyledComponents';
import { ConditionComponent } from '../../../../../../lib/utils';

export const MostJoinedTables = ({
  joins,
  assetId,
  assetType
}: {
  joins: Array<ITableProfileUserJoin>;
  assetId: number;
  assetType: string;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <MostJoinedContainer>
      <TableContainer className="scroll-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadWrapper>
                <InfoLabel>Most Joined tables</InfoLabel>
                <IconButton
                  onClick={handleToggleOpen}
                  style={{
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </TableHeadWrapper>
            </TableRow>
          </TableHead>
          <ConditionComponent condition={isOpen}>
            <TableBody>
              {joins?.map(joinData => (
                <RowData
                  data={joinData}
                  assetId={assetId}
                  assetType={assetType}
                />
              ))}
            </TableBody>
          </ConditionComponent>
        </Table>
      </TableContainer>
    </MostJoinedContainer>
  );
};
