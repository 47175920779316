import React from 'react';
import { Box, Menu, MenuProps, MenuItem } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';
import { sx } from '../../../styles/MUI/styles';
import { InternalTeam } from '../../../store/internalTeams/type';

interface QueryOptionsProps extends MenuProps {
    onClickTeam: (teamId: number) => void;
    teams: InternalTeam[]
}

export const TeamOptionsMenu = ({
  onClickTeam,
  teams,
  ...props
}: QueryOptionsProps) => {
  return (
    <Box className="query-detail-action-container">
      <Menu {...props}>
      {teams.map(team => 
        <MenuItem
            sx={isDatagrip() ? sx.sx3dark : sx.sx3}
            onClick={() => onClickTeam(team.id)}
        >
        {team.name}
      </MenuItem>
      )
      }

      </Menu>
    </Box>
  );
};
