import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import QueryFeedbackModal from '../../../components/modals/QueryFeedbackModal';

interface LikeDislikeForQueryProps {
  message: string;
}
type FeedbackState = 'liked' | 'disliked' | 'none';

const LikeDislikeForQuery = ({ message }: LikeDislikeForQueryProps) => {
  const [feedbackState, setFeedbackState] = useState<FeedbackState>('none');
  const [dislikeModal, setDislikeModal] = useState(false);
  const likeActive = feedbackState === 'liked' ? 'active-liked' : '';
  const unlikeActive = feedbackState === 'disliked' ? 'active-disliked' : '';
  const disabled = Boolean(likeActive || unlikeActive);
  const [copyText, setCopyText] = useState('Copy');

  const LikeClicked = () => {
    MixedPanelEvent.watsonQueryFeedbackLike({
      message: message,
      chat_type: 'none'
    });
    setFeedbackState('liked');
  };

  const DislikeClicked = () => {
    setDislikeModal(true);
    MixedPanelEvent.watsonQueryFeedbackDislike({
      message: message,
      chat_type: 'none'
    });
    setFeedbackState('disliked');
  };

  const handleSubmitFeedback = (checkboxValues: {}, comment: string) => {
    setDislikeModal(false);
    const checkedValues = Object.keys(checkboxValues)
      .filter(key => checkboxValues[key])
      .join(', ');
    MixedPanelEvent.watsonQueryFeedbackDislikeInfo({
      message: message,
      chat_type: 'none',
      text: checkedValues,
      comment
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(29, 31, 33)',
        borderRadius: '10px 0 0 10px',
        height: `calc(100% - 20px)`,
        marginRight: '-2px',
        marginTop: '0.5em'
      }}
    >
      <Button
        disabled={disabled}
        className={`feedback-asset-button`}
        onClick={LikeClicked}
      >
        <ThumbUpIcon className={`icon ${likeActive}`} color={'success'} />
      </Button>
      <Button
        disabled={disabled}
        className={`feedback-asset-button`}
        onClick={DislikeClicked}
      >
        <ThumbDownIcon color={'error'} className={`icon ${unlikeActive}`} />
      </Button>
      <Button className={`feedback-asset-button`} onClick={() => {}}>
        <CopyToClipboard
          text={message.replace(/.*?(SELECT)/s, '$1').replace(/`/g, '')}
          onCopy={() => {
            setCopyText('Copied!');
            setTimeout(() => setCopyText('Copy'), 800);
          }}
        >
          <CustomTooltip title={copyText} placement="right">
            <ContentCopyIcon className={'icon'} sx={{ fontSize: '16px' }} />
          </CustomTooltip>
        </CopyToClipboard>
      </Button>
      <QueryFeedbackModal
        show={dislikeModal}
        setClose={() => setDislikeModal(false)}
        handlerSubmit={handleSubmitFeedback}
      />
    </Box>
  );
};

export default LikeDislikeForQuery;
