import React from 'react';
import { TooltipProps } from '@mui/material/Tooltip';

import { CustomTooltip } from '.';
export const CustomTooltipWithWrapper = ({
  children,
  ...props
}: TooltipProps) => {
  return (
    <CustomTooltip {...props}>
      <div>{children}</div>
    </CustomTooltip>
  );
};
