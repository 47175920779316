import React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';

export interface AnimatedBoxProps extends HTMLMotionProps<'div'> {}

const AnimatedBox: React.FC<AnimatedBoxProps> = ({ children, ...props }) => {
    return <motion.div {...props}>{children}</motion.div>;
};

export default AnimatedBox;
