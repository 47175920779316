import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { PaginatedDataType } from '../../utils/types/paginatedData';
import ApiClient from '../../lib/utils/apiClient';
import { addContent } from '../newFolderContent';
import { FolderContent } from '../queries/types';
import { MoveQueryPayload } from '../newFolderContent/types';

const index = createAsyncThunk(
    'query-folders/folder-content',
    async (uid: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
                `/query-folders/${uid}/content`,
                {
                    isProtected: true,
                },
            );
            dispatch(
                addContent({
                    parent_uid: uid,
                    contents: response.data.list,
                }),
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);
const move = createAsyncThunk(
    'queries/move/',
    async (payload: MoveQueryPayload, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.put<FolderContent, MoveQueryPayload>(
                '/query-folders/content/update',
                payload,
                {
                    isProtected: true,
                },
            );
            toast('Query moved successfully', { type: 'success' });
            MixedPanelEvent.queryMove(payload, response.data?.query?.query);
            return fulfillWithValue(response.data);
        } catch (error) {
            if (error?.response?.status === 418 && error?.response?.data?.data) {
                toast(error.response.data.data, { type: 'error' });
            } else {
                toast('Something went wrong, please try again...', { type: 'error' });
            }
            return rejectWithValue('Error occurred');
        }
    },
);
export const queryFolderContentThunks = { index, move };
