import { combineReducers } from '@reduxjs/toolkit';
import sherloqQueries from './queries';
import sherloqQueryHistory from './queryHistory';
import sherloqQueryFolders from './queryFolder';
import sherloqNewFolderContent from './newFolderContent';
import sherloqFolderSummary from './folderSummary';
import sherloqQueryHistorySummary from './queryHistorySummary';
import sherloqQueryHistoryNew from './queryHistoryNew';
import sherloqNewQueryHistoryContent from './newQueryHistoryContent';
import sherloqSharedFolders from './sharedFolders';
import sherloqNewQueries from './newQueries';
import sherloqAuth from './auth';
import sherloqTeamMembers from './teamMembers';
import sherloqInternalTeams from './internalTeams';
import sherloqDockPosition from './dockPosition';
import sherloqBadgePosition from './badgePosition';
import sherloqUserOrigin from './userOrigin';
import sherloqDarkModeToggle from './themeMode';
import saveAsReducer from './saveAs';
import searchAndFilterReducer from './searchAndFilter';
import sherloqUserEnv from './userEnvSelect';
import sherloqShowConfetti from './confetti';
import sherloqAppConfig from './sherloqAppConfig';
import sherloqUserSettings from './userSettings';
import DataCatalog from './DataCatalog';

const rootReducer = combineReducers({
  sherloqQueries,
  sherloqQueryHistory,
  sherloqQueryFolders,
  sherloqNewFolderContent,
  sherloqFolderSummary,
  sherloqQueryHistorySummary,
  sherloqQueryHistoryNew,
  sherloqNewQueryHistoryContent,
  sherloqSharedFolders,
  sherloqNewQueries,
  sherloqAuth,
  sherloqTeamMembers,
  sherloqInternalTeams,
  sherloqDockPosition,
  sherloqBadgePosition,
  sherloqUserOrigin,
  sherloqDarkModeToggle,
  saveAsReducer: saveAsReducer,
  searchAndFilter: searchAndFilterReducer,
  sherloqUserEnv,
  sherloqShowConfetti,
  sherloqAppConfig,
  sherloqUserSettings,
  dataCatalog: DataCatalog
});
export default rootReducer;
