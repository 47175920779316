import { useParams } from 'react-router-dom';
import { useFieldProfile } from '../hooks/useFieldProfile';
import { FieldProfileContext } from './definition';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode | ReactNode[];
}

export const FieldProfileProvider = (props: Props) => {
  const { id } = useParams<Record<string, string>>();
  const fieldProfile = useFieldProfile(Number(id));

  return (
    <FieldProfileContext.Provider value={fieldProfile}>
      {props.children}
    </FieldProfileContext.Provider>
  );
};
