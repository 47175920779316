import { dotenv } from '../../env';

export class Logger {
  private static inDebugMode = dotenv.appEnv === 'development';
  static info(...args: any[]) {
    if (this.inDebugMode) {
      console.log('[Sherloq::debug] ', ...args);
    }
  }
}
