import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { SearchAndFilterPayload } from './interface';

const initialState: SearchAndFilterPayload = {
  last_queried_by: [],
  q: '',
  queried_in: '',
  in_folder: '-1',
  rule: null,
  tags: [],
  type: 'all',
  folder: '',
  source: ''
};

const slice = createSlice({
  name: 'search and filter',
  initialState,
  reducers: {
    updateSearchType: (
      state,
      { payload }: PA<Partial<SearchAndFilterPayload['type']>>
    ) => {
      state.type = payload;
    },
    setFilters: (state, { payload }: PA<Partial<SearchAndFilterPayload>>) => {
      state.last_queried_by = payload.last_queried_by;
      state.q = payload.q;
      state.queried_in = payload.queried_in;
      state.in_folder = payload.in_folder;
      state.rule = payload.rule;
      state.tags = payload.tags;
      state.type = payload.type;
      state.folder = payload.folder;
      state.source = payload.source;
    },
    setSearchStr: (state, { payload }: PA<string>) => {
      state.q = payload;
    },
    setTags: (state, { payload }: PA<any[]>) => {
      state.tags = payload;
    },
    resetFilters: state => {
      const payload = initialState;
      state.last_queried_by = payload.last_queried_by;
      state.queried_in = payload.queried_in;
      state.in_folder = payload.in_folder;
      state.rule = payload.rule;
      state.tags = payload.tags;
      state.source = payload.source;
      state.folder = payload.folder;
    },
    updateType: (state, { payload }: PA<SearchAndFilterPayload['type']>) => {
      state.type = payload;
    }
  }
});

const { actions, reducer: searchAndFilterReducer } = slice;
export { actions as SearchActions };
export default searchAndFilterReducer;
