import { getUserDomainFromEmail, getUserCompanyFromDomain } from './userUtils';

export const getUserCompanyFromEmailUseCase = (email: string) => {
  return getUserCompanyFromDomain(getUserDomainFromEmail(email));
};


export const isSherloqUser = (email: string) => {
  return getUserCompanyFromEmailUseCase(email) === 'sherloq';
}
