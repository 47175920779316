import React from 'react';
import TableauIcon from './Tableau';
import ModeIcon from './Platforms/Mode';
import KaggleIcon from './Platforms/Kaggle';
import AthenaIcon from './Platforms/Athena';
import GithubIcon from './Platforms/Github';
import NotionIcon from './Platforms/Notion';
import SherloqIcon from './Platforms/Sherloq';
import BigQueryIcon from './Platforms/BigQuery';
import SnowflakeIcon from './Platforms/Snowflake';
import DatabricksIcon from './Platforms/Databricks';
import DataGripIcon from './Platforms/DataGrip';
import PostgresIcon from './Platforms/Postgres';
import PrestoIcon from './Platforms/Presto';

interface PlatformIconProps {
  origin: string;
  size: string;
}

export const PlatformIcon = ({ origin, size }: PlatformIconProps) => {
  return (
    <>
      {origin === 'athena' ? (
        <AthenaIcon size={size} />
      ) : origin === 'bigquery' ? (
        <BigQueryIcon size={size} />
      ) : origin === 'snowflake' ? (
        <SnowflakeIcon size={size} />
      ) : origin === 'modeAnalytics' ? (
        <ModeIcon size={size} />
      ) : origin === 'github' ? (
        <GithubIcon size={size} />
      ) : origin === 'tableau' ? (
        <TableauIcon size={size} />
      ) : origin === 'notion' ? (
        <NotionIcon size={size} />
      ) : origin === 'databricks' ? (
        <DatabricksIcon size={size} />
      ) : origin === 'kaggle' ? (
        <KaggleIcon size={size} />
      ) : origin === 'datagrip' ? (
        <DataGripIcon size={size} />
      ) : origin === 'postgres' ? (
        <PostgresIcon size={size} />
      ) : origin === 'presto' ? (
        <PrestoIcon size={size} />
      ) : (
        <SherloqIcon size={size} />
      )}
    </>
  );
};
