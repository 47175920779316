import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { indexOf } from 'lodash';
import { LoadingState } from '../../utils/types/general';
import { newQueryThunk } from './thunk';
import { NewQuery, NewQueryState, QuerySearchResponse } from './type';

const initialState: NewQueryState = {
    list: [],
    loading: false,
};

const index = createSlice({
    name: 'new_queries',
    initialState,
    reducers: {
        addFolderToShared: (state, { payload }: PA<NewQuery>) => {},
        removeFolderFromShared: (state, { payload }: PA<string>) => {},
    },

    extraReducers: {
        [newQueryThunk.search.pending.type]: (state) => {
            state.list = [];
            state.loading = true;
        },
        [newQueryThunk.search.fulfilled.type]: (state, { payload }: PA<QuerySearchResponse[]>) => {
            state.loading = false;
            state.list = payload;
        },
        [newQueryThunk.search.rejected.type]: (state) => {
            state.list = [];
            state.loading = false;
        },
    },
});

export const { addFolderToShared, removeFolderFromShared } = index.actions;

export default index.reducer;
