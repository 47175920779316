import { IUpdateAssetPayload } from '../utils/types';

function updateAsset(payload: IUpdateAssetPayload) {
  if (payload.asset_created_date) {
    payload.asset_created_date = new Date(payload.asset_created_date);
  }

  return payload;
}

export const ApiPayloadTransformer = Object.freeze({
  updateAsset
});
