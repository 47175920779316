import React from 'react';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import { CachedRounded, RestartAlt } from '@mui/icons-material';
import { ReloadButton } from './atoms/reloadButton';
import { useWatsonMessages } from '../hooks/useWatsonMessages';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { useGenSqlPhaseController } from '../gen-sql/hooks/GenSqlProviderHooks';

interface Props {
  onClick: () => void;
}

export const ReloadChat = ({ onClick }: Props) => {
  return (
    <Box className="reload-section">
      <CustomTooltip title="Restart conversation">
        <ReloadButton onClick={onClick}>
          <RestartAlt
            sx={{
              color: 'inherit',
              fontSize: '20px'
            }}
          />
        </ReloadButton>
      </CustomTooltip>
    </Box>
  );
};
