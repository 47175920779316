import React from 'react';
import { ModalRoot, ModalRootProps } from './root';
import { Box, Button } from '@mui/material';
import { SelectFolderDropdown } from '../selectFolderDropdown';
import { CancelButton } from './components/cancelButton';
import { sx } from '../../styles/MUI/styles';
import { folderFilterTextStyles } from '../../pages/SearchFilter/styles';
import { selectFolderDropdownComponent } from './styles';
import { ModalTypography } from './components/label';
import { Typography } from '../../atoms/Typography';

interface MoveQueryModalProps extends Pick<ModalRootProps, 'onClose' | 'open'> {
  dropdownProps?: Omit<any, 'options' | 'value' | 'onChange'>;
  folders?: any;
  value?: any;
  onChange: any;
  defaultValue: string;
  handleSubmit: Function;
}

export const MoveQueryModal: React.FC<MoveQueryModalProps> = ({
  folders,
  value,
  onChange,
  ...props
}: MoveQueryModalProps) => {
  const handleOnClose = ev => props?.onClose(ev, 'backdropClick');
  return (
    <ModalRoot variant="default" {...props}>
      <Typography className="title">Move query to...</Typography>
      <Box className="content">
        <Typography className="sub-title">
          Keep your queries organized with folders
        </Typography>
        <br />
        <SelectFolderDropdown
          selected={value}
          onChange={onChange}
          folders={folders}
          labelProps={{ className: 'input-label' }}
          buttonProps={{ sx: selectFolderDropdownComponent }}
          textProps={{
            style: folderFilterTextStyles
          }}
        />
        <footer>
          <Box className="footer-sidenote">
            <ModalTypography className="tiny">
              Note:
              <br />
              Shared folders are visible to the entire team
            </ModalTypography>
          </Box>
          <Box className="buttons-container">
            <CancelButton onClick={handleOnClose} />
            <Button
              sx={sx.btn}
              variant="contained"
              onClick={() => props.handleSubmit()}
              className="default-button"
            >
              Save
            </Button>
          </Box>
        </footer>
      </Box>
    </ModalRoot>
  );
};
