import React, { Fragment, useMemo, useState } from 'react';
import { Typography, Button } from '@mui/material';

import { useQueryHistoriesNewStateSelector } from '../../../store/queryHistoryNew/selectors';
import { ContentMetaStateBox } from '../../QueryFolderListItem/components/styled';
import { LoadingState } from '../../../utils/types/general';
import { ConditionComponent } from '../../../lib/utils';
import WithDisplayModeHOC from '../../../pages/QueryListPage/components/RepositoryQuery';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';

const MaxItems = 10;

export default () => {
  const queryHistories = useQueryHistoriesNewStateSelector();
  const [limit, setLimit] = useState(MaxItems);
  const noData =
    queryHistories.loading === LoadingState.FULFILLED &&
    queryHistories.list.length === 0;
  const showMore = queryHistories.list.length > limit;

  const data = useMemo(() => {
    if (!noData) {
      return queryHistories.list.slice(0, limit);
    }
    return [];
  }, [limit, queryHistories, noData]);

  const handleShowMore = () => {
    setLimit(p => p + MaxItems);
  };

  const handleQueryClick = (query: string) => {
    MixedPanelEvent.queryHistoryListViewClicked({
      query: query
    });
  };

  if (queryHistories.isLoading) {
    return (
      <ContentMetaStateBox>
        <Typography variant="caption">Loading</Typography>
      </ContentMetaStateBox>
    );
  }

  if (noData) {
    // must never trigger but here as a fallback
    return (
      <ContentMetaStateBox>
        <Typography variant="caption">No data</Typography>
      </ContentMetaStateBox>
    );
  }

  return (
    <Fragment>
      {data.map(query => (
        <WithDisplayModeHOC
          handleClick={() => handleQueryClick(query.query)}
          key={`${query.uid}`}
          data={{
            className: 'editor',
            query: query.query,
            query_uid: query.uid,
            query_name: query.created_at,
            query_origin: query.origin,
            query_created_at: query.created_at,
            query_description: '',
            query_owner_username: `${query.user?.firstname} ${query.user?.lastname}`,
            folderName: '',
            folderId: '',
            type: 'my-history',
            pageType: 'query'
          }}
        />
      ))}
      <ConditionComponent condition={showMore}>
        <Button variant="text" onClick={handleShowMore}>
          show more
        </Button>
      </ConditionComponent>
    </Fragment>
  );
};
