import { useMemo } from 'react';
import { useFolderTree } from '../../../lib/hooks/useFolderTree';
import { selectFolderFromFoldersByUUID } from '../../../utils/helpers';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { QueryType } from '../../../adapters/query/interface';

interface Params {
  parentFolderId: string;
  type: string;
  query_owner: string;
}

export const useQueryItemType = ({
  parentFolderId,
  type,
  query_owner
}: Params): QueryType => {
  const folders = useFolderTree();
  const user = useUserInfoSelector();
  const isHistory = type.includes('history');

  const queryFolderType = useMemo(() => {
    const queryParent = selectFolderFromFoldersByUUID(parentFolderId, folders);
    if (queryParent) {
      return queryParent.shared_type;
    }
  }, [parentFolderId, folders]);

  const queryType = useMemo<QueryType>(() => {
    if (isHistory) return 'history';
    const username = `${user?.first_name} ${user?.last_name}`;
    const queryCreatedByUser = username === query_owner;
    if (queryCreatedByUser) return 'personal';
    if (queryFolderType === 'shared') return 'shared';
    return 'public';
  }, [user, isHistory, queryFolderType]);

  return queryType;
};
