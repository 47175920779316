import { SxProps } from '@mui/material';

const selectedFolderContainerStyles: SxProps = {
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '6px'
};

const dropdownFolderTypeIconStyles: SxProps = {
  color: '#000',
  height: '20px',
  width: '20px'
};

const dropdownZindex = 9999;
const dropdownItemSummaryIndex = 99999;

const dropdownMenuPaperStyle = {
  maxWidth: '260px',
  maxHeight: '400px'
};

const dropdownListItemSummaryContainer = {
  zIndex: 99999,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: dropdownMenuPaperStyle.maxWidth,
  padding: 0
};

const dropdownListItemSummaryMenuItemContainer = {
  width: dropdownMenuPaperStyle.maxWidth,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px'
};

const dropdownListItemSummaryMenuItemStackStyles = {
  width: `calc(${dropdownMenuPaperStyle.maxWidth} - 40px)`,
  columnGap: '4px',
  alignItems: 'center'
};

const dropdownItemSummaryTooltipStyles = {
  zIndex: 999999
};

const dropdownListItemSummaryMenuItemIconStyles = {
  // flexShrink: 0,
  alignItems: 'center',
  display: 'flex',
  position: 'relative'
};

const selectedFolderIconStypes = {
  height: '16px',
  width: '16px'
};

const selectedFolderTextDefaultStyles: any = {
  textOverflow: 'ellipsis',
  top: '1px',
  position: 'relative',
  textTransform: 'none'
};

export {
  selectedFolderContainerStyles,
  dropdownFolderTypeIconStyles,
  dropdownZindex,
  dropdownListItemSummaryContainer,
  dropdownItemSummaryIndex,
  dropdownItemSummaryTooltipStyles,
  dropdownListItemSummaryMenuItemContainer,
  dropdownListItemSummaryMenuItemStackStyles,
  dropdownListItemSummaryMenuItemIconStyles,
  dropdownMenuPaperStyle,
  selectedFolderIconStypes,
  selectedFolderTextDefaultStyles
};
