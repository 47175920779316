import unionBy from 'lodash/unionBy';
import { toast } from 'react-toastify';
import { sx } from '../../styles/MUI/styles';
import { localTime } from './helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { formatSql } from '../../utils/formaters';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomTooltip } from '../../atoms/CustomTooltip';
import { getFolderFullPath } from '../../lib/utils/folderPath';
import CustomSqlEditor from '../../components/CustomSqlEditor';
import { NewCreateQueryPayload } from '../../store/queries/types';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import {
  ContentCopyRounded,
  FormatAlignLeftRounded
} from '@mui/icons-material';
import { JoinLeftRounded, AutoFixHighRounded } from '@mui/icons-material';
import {
  Button,
  Menu,
  Typography,
  Stack,
  Divider,
  Box,
  TextField
} from '@mui/material';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { SelectFolderDropdown } from '../../components/selectFolderDropdown';
import { editorFolderDetailsContainer, makeSelectFolderStyles } from './styles';
import createNewQueryFactory from '../../adapters/query/createNewQuery';
import { Input } from '../../components/Input';
import ApiClient from '../../lib/utils/apiClient';

interface AddEditorProps {
  defFolderName: string;
  defFolderID: string;
  isFromFolder: boolean;
  setIsFolderEditor?: any;
}

const WITH_DESCRIPTION_MESSAGE = 'Saving query';
const WITHOUT_DESCRIPTION_MESSAGE =
  'Generating auto description & Saving query';

const AddEditor = ({
  defFolderName,
  defFolderID,
  isFromFolder,
  setIsFolderEditor
}: AddEditorProps) => {
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null); // unknown states
  const [anchorEl2, setAnchorEl2] = useState<any>(null);
  const [folderID, setFolderID] = useState(defFolderID);
  const [selectedQuery, setSelectedQuery] = useState('');
  const userQueryFoldersList = useFolderSummarySelector();
  const [description, setDescription] = useState<string>();
  const [selectedDirName, setSelectedDirName] = useState(defFolderName); // unknown states
  const [queryName, setQueryName] = useState(localTime(new Date(), true));

  const folderSummary = useFolderSummarySelector();
  const folderSummaryShared = useSharedFoldersSelector();
  const folders = unionBy(folderSummary.list, folderSummaryShared.list, 'uid');
  const darkMode = useDarkModeState();

  const saveQueryFromEditor = async () => {
    if (!isClick && selectedQuery !== '') {
      try {
        setIsClick(true);
        const toastMessage = description
          ? WITH_DESCRIPTION_MESSAGE
          : WITHOUT_DESCRIPTION_MESSAGE;
        toast(toastMessage, { isLoading: true });
        const requestBody: NewCreateQueryPayload = {
          parent_id: folderID,
          folder: undefined,
          query: {
            name: queryName ?? '',
            description: description ?? '',
            query: selectedQuery
          }
        };
        await createNewQueryFactory(requestBody);
        toast.dismiss();
        toast('Successfully created new query', {
          type: 'success',
          autoClose: 1000
        });
        setIsClick(false);
        if (isFromFolder) {
          setIsFolderEditor(false);
        } else {
          sessionStorage.setItem(
            'fpath',
            JSON.stringify(getFolderFullPath(folderID, folders, []))
          );
          navigate('/saved-queries');
        }
        MixedPanelEvent.editorSaveQueryAction(selectedQuery);
      } catch (error) {
        toast.dismiss();
        toast(error.message, { type: 'error' });
      }
    }
  };
  const generateQueryOptimize = () => {
    if (editMode && selectedQuery != '') {
      MixedPanelEvent.editorOptimizeQueryAction();
      toast('Optimizing query feature will be available in next release', {
        type: 'info',
        autoClose: 5000
      });
    }
  };
  const findQuerySimilar = () => {
    if (editMode && selectedQuery != '') {
      MixedPanelEvent.editorSimilarQueryAction();
      toast('Similar queries feature will be available in next release', {
        type: 'info',
        autoClose: 5000
      });
    }
  };
  const setSelectedFolder = (f: any) => {
    setAnchorEl(null);
    setFolderID(f.uid);
    setSelectedDirName(f.name);
  };

  useEffect(() => {
    if (defFolderID === '') {
      userQueryFoldersList.list.map((folder: { name: any; uid: any }) => {
        if (folder.name === 'Default') {
          setFolderID(folder.uid);
          setSelectedDirName(folder.name);
        }
      });
    }
  }, []);

  return (
    <>
      <Box className="editor-header-params"></Box>
      <Box className={isFromFolder ? 'addEditorFolderWrap' : ''}>
        <Box className="action-section-wrapper">
          {isFromFolder ? (
            <></>
          ) : (
            <Stack
              className="action-section"
              direction="row"
              alignItems="center"
              borderColor={'input.border'}
              marginTop={'10px'}
              gap={1}
            >
              <CopyToClipboard
                text={selectedQuery}
                onCopy={() => {
                  MixedPanelEvent.editorCopyQueryAction(selectedQuery);
                  toast('Query successfully copied to clipboard ', {
                    type: 'success'
                  });
                }}
              >
                <CustomTooltip title="Copy query" placement="top">
                  <ContentCopyRounded sx={darkMode ? sx.sx1dark : sx.sx1} />
                </CustomTooltip>
              </CopyToClipboard>
              <Divider sx={{ height: '16px' }} orientation="vertical" />
              <CustomTooltip title="Similar queries" placement="top">
                <JoinLeftRounded
                  sx={editMode && selectedQuery != '' ? sx.sx1 : sx.sx2}
                  onClick={findQuerySimilar}
                />
              </CustomTooltip>
              <CustomTooltip title="Optimize query" placement="top">
                <AutoFixHighRounded
                  sx={editMode && selectedQuery != '' ? sx.sx1 : sx.sx2}
                  onClick={generateQueryOptimize}
                />
              </CustomTooltip>
              <Divider sx={{ height: '16px' }} orientation="vertical" />
              <CustomTooltip title="Format query" placement="top">
                <FormatAlignLeftRounded
                  sx={darkMode ? sx.sx1dark : sx.sx1}
                  onClick={() => {
                    MixedPanelEvent.editorFormatQueryAction(selectedQuery);
                    let formmated = formatSql(selectedQuery);
                    if (formmated === selectedQuery)
                      toast('Nothing has been formatted', {
                        type: 'warning'
                      });
                    else setSelectedQuery(formmated);
                  }}
                />
              </CustomTooltip>
            </Stack>
          )}
          {isFromFolder ? (
            <Box style={{ width: '100%' }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '6px'
                }}
              >
                <Input
                  value={queryName}
                  onChange={e => setQueryName(e.target.value)}
                  placeholder="Query name"
                />
              </Box>
              <Input
                size="small"
                onChange={e => setDescription(e.target.value)}
                placeholder="Add description or we can generate one for you"
              />
            </Box>
          ) : (
            <></>
          )}
          {!isFromFolder && (
            <Button
              sx={{ height: '26px', fontSize: '12px', textTransform: 'none' }}
              color="primary"
              variant="contained"
              onClick={saveQueryFromEditor}
              disabled={selectedQuery === '' || isClick}
            >
              Save
            </Button>
          )}
        </Box>
        <Box
          className="custom-editor-wrapper"
          onClick={() => setEditMode(true)}
        >
          {isFromFolder ? (
            <></>
          ) : (
            <Box
              className="addEditorHeaderMain"
              sx={{
                background: darkMode ? 'rgb(83, 85, 82)' : '#F1F1F1',
                padding: '0 8px'
              }}
            >
              <Box style={editorFolderDetailsContainer}>
                <SelectFolderDropdown
                  folders={userQueryFoldersList.list}
                  onChange={folder => setSelectedFolder(folder)}
                  label={null}
                  selectDefaultOnMount
                  textProps={{
                    sx: makeSelectFolderStyles(darkMode)
                  }}
                  buttonProps={{
                    sx: {
                      backgroundColor: 'input.background',
                      height: '26px',
                      border: '1px solid',
                      borderColor: 'input.border',
                      boxSizing: 'border-box'
                    }
                  }}
                />
                <Typography
                  sx={{ padding: '10px', color: darkMode ? 'white' : '' }}
                  variant="body2"
                >
                  /
                </Typography>
                <Input
                  inputProps={{
                    style: {
                      height: '26px',
                      boxSizing: 'border-box'
                    }
                  }}
                  value={queryName}
                  onChange={e => setQueryName(e.target.value)}
                  placeholder="Query name"
                />
              </Box>
              {
                <Button
                  sx={{
                    textTransform: 'none',
                    fontSize: '11px',
                    paddingTop: '0px',
                    color: darkMode ? 'white' : ''
                  }}
                  variant="text"
                  id="descriptionMenu"
                  aria-controls={!!anchorEl2 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={!!anchorEl2 ? 'true' : undefined}
                  onClick={event => setAnchorEl2(event.currentTarget)}
                >
                  {description || '+ Add description'}
                </Button>
              }
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={!!anchorEl2}
                onClose={() => setAnchorEl2(null)}
                MenuListProps={{
                  'aria-labelledby': 'descriptionMenu'
                }}
              >
                <TextField
                  inputProps={{
                    style: { fontSize: 12, width: '300px' }
                  }}
                  label="Write your description"
                  focused={true}
                  placeholder={'Description'}
                  fullWidth
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  multiline={true}
                  rows={2}
                  maxRows={4}
                />
              </Menu>
            </Box>
          )}
          <CustomSqlEditor
            placeholder="Type or Paste your query..."
            liveAutocompletion={true}
            onChange={v => setSelectedQuery(v)}
            readOnly={!editMode}
            value={selectedQuery}
            height={isFromFolder ? '100px' : `${window.innerHeight - 270}px`}
            width="100%"
            variant={'custom-editor'}
            className="query-detail-editor"
          />
        </Box>
        {isFromFolder && (
          <Button
            sx={{
              height: '26px',
              fontSize: '12px',
              textTransform: 'none',
              marginTop: '8px'
            }}
            color="primary"
            variant="contained"
            disabled={selectedQuery === '' || isClick}
            onClick={saveQueryFromEditor}
          >
            Save
          </Button>
        )}
        {isFromFolder && (
          <Button
            sx={{
              height: '26px',
              fontSize: '12px',
              textTransform: 'none',
              marginTop: '8px',
              marginLeft: '8px'
            }}
            color="primary"
            variant="outlined"
            onClick={() => setIsFolderEditor(false)}
          >
            Cancel
          </Button>
        )}
      </Box>
    </>
  );
};

export default AddEditor;
