import { useTypedSelector } from '..';

//Select all query histories
export const useQueryHistoriesNewStateSelector = () =>
    useTypedSelector(({ sherloqQueryHistoryNew }) => sherloqQueryHistoryNew);

export const useSingleQueryHistoryNewSelector = (uid: string) =>
    useTypedSelector(({ sherloqQueryHistoryNew }) =>
        sherloqQueryHistoryNew.list.find((item) => item.uid === uid),
    );
export const useFetchQueryHistoriesNewLoadingState = () =>
    useTypedSelector(({ sherloqQueryHistoryNew }) => sherloqQueryHistoryNew?.isLoading);
