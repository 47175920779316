import React, { useRef, useState } from 'react';
import { HotSaveAsLayout } from './layout';
import { Button } from '@mui/material';
import { returnIfWithNull } from '../../../lib/utils';
import { SaveAsNew } from './SaveAsNew';
import {
  defaultSaveAsQueryPayload,
  useCloseHotSaveAsFlow,
  useHotSaveAsFlowState
} from '../context';
import { ISaveAsError, QueryMeta } from '../interface';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { documentQueryVersion } from '../../Editor/api/fetch';
import { NewCreateQueryPayload } from '../../../store/queries/types';
import { queriesAsyncThunk } from '../../../store/queries/queries.thunk';
import {
  ToastContent,
  ToastOptions,
  UpdateOptions,
  toast
} from 'react-toastify';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { ModalTypography } from '../../../components/modals/components/label';
import { isDuplicateQueryNameError } from '../getIsDuplicateQueryNameError';

type HotSaveAsRootSubPageType = 'saveas-new' | 'saveas-version';

interface RootProps {
  nagivateToNewFolder?: () => void;
}

export const Root = ({ nagivateToNewFolder }: RootProps) => {
  const [tabIndex, setTabIndex] =
    useState<HotSaveAsRootSubPageType>('saveas-new');
  const closeHotSaveAsFlow = useCloseHotSaveAsFlow();
  const [error, setError] = useState<ISaveAsError | null>(null);
  const toastRef = useRef<any>(null);
  const showToast = (content: ToastContent, options?: ToastOptions) => {
    toastRef.current = toast(content, options);
  };

  const updateToast = (
    content: UpdateOptions | ToastContent,
    options?: ToastOptions
  ) => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        render: content as ToastContent,
        isLoading: false,
        autoClose: 2000,
        ...options
      });
    } else {
      toastRef.current = toast(content as ToastContent, options);
    }
  };
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const payload = useHotSaveAsFlowState()[0];
  const [queryPayload, setQueryPayload] = useState<QueryMeta>(
    defaultSaveAsQueryPayload
  );

  const onSave = async () => {
    const query = payload.query;
    try {
      let desc = queryPayload.description;
      if (!queryPayload.description) {
        showToast('Generating description to', { isLoading: true });
        const { data } = await documentQueryVersion(query);
        desc = data.res;
      }
      let requestBody: NewCreateQueryPayload = {
        parent_id: payload.parentFolder,
        folder: undefined,
        query: {
          name: queryPayload.name,
          description: desc,
          query
        }
      };
      //validate
      validateSaveQueryPayload(requestBody);
      updateToast('Saving query', { isLoading: true });
      await dispatch(
        queriesAsyncThunk.create({
          ...requestBody,
          withToast: false,
          withAnalytics: false
        })
      ).unwrap();
      MixedPanelEvent.registerSaveAs({ query: requestBody.query.query });
      updateToast('Query Created', { type: 'success' });
      closeHotSaveAsFlow();
      nav('/saved-queries');
    } catch (error: any) {
      if (isDuplicateQueryNameError(error)) {
        setError(e => ({ ...e, isDuplicateError: true }));
        updateToast(`Query name already exists in folder`, { type: 'error' });
      } else {
        updateToast(`Operation Failed! ${error.message}`, { type: 'error' });
      }
    }
  };
  const handleOnChangeName = (e: any) => {
    const name = e.target.value;

    setQueryPayload(p => ({ ...p, name }));
  };

  const handleOnChangeDesc = (e: any) => {
    const description = e.target.value;
    setQueryPayload(p => ({ ...p, description }));
  };

  const handleOnCancel = () => {
    closeHotSaveAsFlow();
  };

  const redirectToNewFolder = () => {
    MixedPanelEvent.registerSaveAsRedirectToNewFolder();
    nagivateToNewFolder?.();
  };

  return (
    <HotSaveAsLayout
      onCancel={handleOnCancel}
      onSave={onSave}
      footer={returnIfWithNull(
        tabIndex === 'saveas-new',
        <Button
          onClick={redirectToNewFolder}
          variant="text"
          sx={{
            color: '#0075E7',
            textTransform: 'none',
            marginTop: 'auto',
            display: 'felx',
            padding: 0
          }}
        >
          Save in new folder
        </Button>
      )}
    >
      <ModalTypography variant="subtitle1">Save query</ModalTypography>
      <div className="hot_save_as_root--content">
        <div className="hot_save_as_root--tab_content">
          <SaveAsNew
            meta={queryPayload}
            onChangeDesc={handleOnChangeDesc}
            onChangeName={handleOnChangeName}
            errors={error}
          />
        </div>
      </div>
    </HotSaveAsLayout>
  );
};

function validateSaveQueryPayload(payload: NewCreateQueryPayload) {
  if (!payload.query.query) throw new Error('Enter a query');
  if (!payload.query.name) throw new Error('Provide a query name');
  if (!payload.parent_id) throw new Error('Select a folder');
}
