import { createAsyncThunk } from '@reduxjs/toolkit';
import { FolderContent } from '../queries/types';

export interface CreateContentResponse {
    contents: FolderContent[];
    parent_uid: string;
}

const index = createAsyncThunk(
    'auth/index',
    async (parent_uid: string): Promise<CreateContentResponse> => {
        return Promise.resolve({ contents: [], parent_uid });
    },
);

export const newContentThunks = { index };
