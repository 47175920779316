import { styled } from '@mui/material';

export const Container = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    height: '550px'
  };
});
