import React from 'react';
import {
  ToggleDockStatePositionContext,
  ToggleFrameVisibleContext,
  ToggleSearchPanelVisibleContext,
  UIStateContext,
  ToggleSearchModalContext,
  ToggleSaveQueryModalContext,
  SetFrameVisibleContext,
  ToggleSearchFiltersContext,
  GetSearchFiltersContext,
  RootPageLayoutPropsStateContext
} from './contexts';

export const useUiState = () => React.useContext(UIStateContext);
export const useToggleDockPosition = () =>
  React.useContext(ToggleDockStatePositionContext);
export const useToggleFrame = () => React.useContext(ToggleFrameVisibleContext);
export const useToggleSearchPanel = () =>
  React.useContext(ToggleSearchPanelVisibleContext);
export const useToggleSearchModal = () =>
  React.useContext(ToggleSearchModalContext);
export const useToggleSaveQueryModal = () =>
  React.useContext(ToggleSaveQueryModalContext);
export const useOpenFrame = () => React.useContext(SetFrameVisibleContext);
export const useToggleSearchFiltersModal = () =>
  React.useContext(ToggleSearchFiltersContext);
export const useSearchFiltersContext = () =>
  React.useContext(GetSearchFiltersContext);
export const usePageLayoutPropsState = () =>
  React.useContext(RootPageLayoutPropsStateContext);
