import { Box, Typography } from '@mui/material';
import PostFeedbackPhase from '../../bots/Discovery/PostFeedbackPhase';

export const ErrorMessage = () => {
  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
      sx={{ rowGap: '12px' }}
    >
      <Typography>
        It seems like something went wrong, Let’s give it a fresh start
      </Typography>
      <PostFeedbackPhase meta={{}} />
    </Box>
  );
};
