import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { UTCtoLocal } from '../helpers/helpers'
import { getLastSeenString, hashStringToColor } from '../../../utils/helpers'
import { CustomTooltip } from '../../../atoms/CustomTooltip'
import UserInitialsComponent from '../../../components/UserInitialsComponent'
import { useDarkModeState } from '../../../store/themeMode/selectors'

const MemberActions = ({ querySeen, querySeenUsers, edited, content, queryName }) => {
  const [showMembersActions, setShowMembersActions] = useState(false)
  const darkMode = useDarkModeState();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowMembersActions(false);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        setShowMembersActions(false);;
      }
    }

    if (showMembersActions) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown as any);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown as any);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown as any);
    };
  }, [showMembersActions]);

  return (
    <Box className="query-history-actions" onClick={(e) => { e.preventDefault(); e.stopPropagation() }} ref={popupRef} >
      {querySeen && querySeen.length > 0 ? <CustomTooltip title={`Last updated at ${UTCtoLocal(edited?.created_at)} by ${(edited?.user.first_name) + ' ' + (edited?.user.last_name)}`}>
        <div className='edited-title'>
          {querySeen && querySeen.length > 0 ? `Edited ${getLastSeenString(new Date(edited?.created_at))}` : ''}
        </div>
      </CustomTooltip> : ''}
      <div className={`query-members-container ${darkMode ? 'dark-mode ' : ''} ${querySeen && querySeen.length > 0 ? '' : 'no-data'}`} onClick={() => { setShowMembersActions(!showMembersActions) }}>
        {querySeenUsers && querySeenUsers.length > 0 ? querySeenUsers.map((qs, i) => (
          i < 5 && <div className='query-member' style={{ zIndex: querySeenUsers.length - i }}>
            <UserInitialsComponent
              firstName={qs.first_name}
              lastName={qs.last_name}
              width="28px"
              height="28px"
              fontSize="12px"
              fontWeight={300}
              color={hashStringToColor(qs.first_name)}
            />
          </div>

        )) : ''}
        <div className='actions-title'>
          {querySeen && querySeen.length > 0 ? `${querySeen.length}` : ''}
          <span> activities</span>
        </div>
      </div>

      {showMembersActions && <div className={`member-actions-popup ${darkMode ? 'dark-mode' : ''}`} >
        <div className='close-button' onClick={() => { setShowMembersActions(false) }}>
          X
        </div>
        <div className="title">
          Total {querySeen.length} activities:
        </div>
        {querySeenUsers && querySeenUsers.length > 0 ? querySeenUsers.map((qsu, i) => (
          <div className='query-member-actions'>
            <UserInitialsComponent
              firstName={qsu.first_name}
              lastName={qsu.last_name}
              width="28px"
              height="28px"
              fontSize="12px"
              fontWeight={300}
              color={hashStringToColor(qsu.first_name)}
            />
            <div>
              <div className='text'>
                <b>{querySeen.filter(qs => qs.user.first_name === qsu.first_name && qs.user.last_name === qsu.last_name).length}</b> by {qsu.first_name} {qsu.last_name}
              </div>
              <div className='text'>
                Last activity {getLastSeenString(querySeen.filter(qs => qs.user.first_name === qsu.first_name && qs.user.last_name === qsu.last_name)[0].created_at)}
              </div>
            </div>
          </div>

        )) : ''}
        {/* <div className="subtitle">
          Last updated by {(edited?.user.first_name) + ' ' + (edited?.user.last_name)} {getLastSeenString(edited?.created_at)}
        </div> */}
        <div className="subtitle">
          * activities are active events made by users, such as view, copy, share, edit etc.
        </div>
      </div>}
    </Box>
  )
}

export default MemberActions