import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { TeamMember } from './type';

const index = createAsyncThunk(
    'shared-folders/team/members',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.get<TeamMember[]>(`/shared-folders/team/members`, {
                isProtected: true,
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(String(error));
        }
    },
);

export const teamMemberThunk = { index };
