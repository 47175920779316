import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useUserAuthenticatedState } from '../store/auth/selectors';

export interface PublicRoutesProps {
  element: React.ReactElement;
}

const PublicRoutes: React.FC<PublicRoutesProps> = ({ element }) => {
  const isLoggedIn = useUserAuthenticatedState();
  const location = useLocation();
  if (isLoggedIn) {
    return <Navigate to={(location.state as any)?.next ?? '/saved-queries'} />;
  }
  return element;
};

export default PublicRoutes;
