import React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import {
  DashboardFilterBox,
  DashboardFilterText,
  DashboardFilterTextBox,
  DashboardSelect
} from '../components';

export const DashboardTypeFilter = ({
  selectedValue,
  onSelectionChange,
  isDisabled
}) => {
  const filterOptions = [
    { label: 'Dashboards', value: 'dashboard' },
    { label: 'Views', value: 'view' }
  ];

  const handleSelectionChange = event => {
    onSelectionChange(event.target.value);
  };

  return (
    <DashboardFilterBox>
      <DashboardFilterTextBox>
        <DashboardFilterText>Dashboard / View: </DashboardFilterText>
      </DashboardFilterTextBox>
      <FormControl sx={{ minWidth: 200 }}>
        <DashboardSelect
          labelId="dashboard-type-select-label"
          id="dashboard-type-select"
          value={selectedValue}
          onChange={handleSelectionChange}
          disabled={isDisabled}
        >
          {filterOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {option.label}
            </MenuItem>
          ))}
        </DashboardSelect>
      </FormControl>
    </DashboardFilterBox>
  );
};

export default DashboardTypeFilter;
