import { styled, Box, BoxProps } from '@mui/material';

interface ModalContainerProps extends BoxProps {
  variant: 'default' | 'error';
}

export const ModalContainer = styled(Box)<ModalContainerProps>(
  ({ theme, variant }) => {
    const {
      text: { primary },
      error: { main },
      primary: { main: defaultColor }
    } = theme.palette;
    const borderBottomColor = variant === 'error' ? main : defaultColor;
    return {
      backgroundColor: theme.palette.background.paper,
      width: '450px',
      boxSizing: 'border-box',
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1 auto',
      borderBottomWidth: '5px',
      borderBottomStyle: 'solid',
      borderRadius: '5px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: primary,
      borderBottomColor,
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'fit-content'
    };
  }
);
