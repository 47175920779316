import { Button, styled } from '@mui/material';

export const ReloadButton = styled(Button)(({ theme }) => {
  const { primary, mode } = theme.palette;
  const isDark = mode === 'dark';
  const bg = isDark ? primary.dark : primary.main;
  return {
    backgroundColor: bg,
    border: '2px solid',
    borderColor: 'transparent',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: bg,
      color: bg
    }
  };
});
