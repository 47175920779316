import { ReactNode, useReducer } from 'react';
import { DataCatalogContext } from './defs';
import { InitialData, DataCatalogStoreReducer } from './reducer';

interface Props {
  children: ReactNode | ReactNode[];
}

export const DataCatalogProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(DataCatalogStoreReducer, InitialData);

  return (
    <DataCatalogContext.Provider value={{ state, dispatch }}>
      {children}
    </DataCatalogContext.Provider>
  );
};
