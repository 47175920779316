import React from 'react';
import { FolderType } from '../../../store/folderSummary/type';
import { Folder, FolderSharedRounded } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

import { dropdownFolderTypeIconStyles } from '../styles';
import FolderPublicIcon from '../../Icons/FolderPublicIcon';

interface FolderIconProps {
  type: FolderType;
  styles?: SvgIconProps['sx'];
}

export const FolderIcon = ({ type, styles }: FolderIconProps) => {
  switch (type) {
    case 'personal':
      return (
        <Folder sx={{ ...dropdownFolderTypeIconStyles, ...(styles as any) }} />
      );
    case 'shared':
      return (
        <FolderSharedRounded
          sx={{ ...dropdownFolderTypeIconStyles, ...(styles as any) }}
        />
      );
    default:
      return <FolderPublicIcon size={(styles as any)?.fontSize ?? '20px'} darkMode={false} />;
  }
};
