import React from 'react';
import { Box, IconButton } from '@mui/material';
import { ConditionComponent, redirectToNewPage } from '../../utils';
import { OpenInNewRounded } from '@mui/icons-material';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import SherloqIcon from '../../../components/Icons/SherloqIcon';
import SearchQueryComponent from '../../../pages/SearchQueryComponent';
import { MultipleStopRounded } from '@mui/icons-material';
import { useToggleDockPosition, useUiState } from '../../../context/hooks';
import MinimizeOutlinedIcon from '@mui/icons-material/MinimizeOutlined';
import { useEnvs } from '../../../hooks/useEnvs';
import { useUserAuthenticatedState } from '../../../store/auth/selectors';
import { ExtensionIcon } from '../../../pages/SearchQueryComponent/components';

export interface HeaderProps {
  showDockIcon?: boolean;
  showClose?: boolean;
  isLoginPage?: boolean;
}
interface Props extends React.HTMLAttributes<HTMLDivElement>, HeaderProps {}

const Header: React.FC<Props> = ({ showDockIcon = true, showClose = true, isLoginPage = false }) => {
  const { framePosition } = useUiState()!;
  const toggleFramePosition = useToggleDockPosition();
  const { isExtension } = useEnvs();
  const isLoggedIn = useUserAuthenticatedState();
  const isDataManagementScreen =
    window.location.href.includes('data-management');

  const extToggleFrame = () => {
    parent.postMessage({ sherloq_command_to_ext: 'closeFrame' }, '*');
    toggleFramePosition();
  };
  const extToggleDock = () => {
    parent.postMessage({ sherloq_command_to_ext: 'toggleDockPosition' }, '*');
    toggleFramePosition();
  };

  const dockToolTip = framePosition === 'right' ? 'Dock Left' : 'Dock right';

  const handleRedirectToWorkspaces = () => {
    redirectToNewPage(`https://web.sherloqdata.com/`);
  };

  return (
    <>
      <Box className={`sherloq-header ${isLoginPage ? 'login-page-header' : ''}`}>
        <p />
        <Box width={'100px'}>
          <SherloqIcon />
        </Box>
        <ConditionComponent condition={isLoggedIn && !isDataManagementScreen}>
          <SearchQueryComponent />
        </ConditionComponent>
        <ConditionComponent condition={isExtension}>
          <Box
            style={{
              width: 'auto',
              display: 'flex',
              justifyContent: 'space-evenly',
              marginLeft: 'auto'
            }}
          >
            <CustomTooltip title="Open full screen" placement="bottom-start">
              <ExtensionIcon onClick={handleRedirectToWorkspaces}>
                <OpenInNewRounded
                  sx={{
                    color: 'white',
                    fontSize: '18px'
                  }}
                />
              </ExtensionIcon>
            </CustomTooltip>

            {showDockIcon && (
              <CustomTooltip title={dockToolTip} placement="bottom-start">
                <ExtensionIcon onClick={extToggleDock}>
                  <MultipleStopRounded
                    sx={{
                      color: 'white',
                      fontSize: '18px'
                    }}
                  />
                </ExtensionIcon>
              </CustomTooltip>
            )}
            {showClose && (
              <CustomTooltip
                title="Minimize"
                placement="bottom-start"
                style={{ marginRight: '8px' }}
              >
                <IconButton onClick={extToggleFrame}>
                  <MinimizeOutlinedIcon
                    sx={{
                      color: 'white',
                      fontSize: '18px',
                      marginTop: '-12px'
                    }}
                  />
                </IconButton>
              </CustomTooltip>
            )}
          </Box>
        </ConditionComponent>
      </Box>
    </>
  );
};

export default Header;
