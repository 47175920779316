import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { PaginatedDataType } from '../../utils/types/paginatedData';
import ApiClient from '../../lib/utils/apiClient';
import { FolderContent } from '../queries/types';
import { MoveQueryPayload, MoveQuerySlicePayload } from './types';
import { Logger } from '../../utils/browser/logger';

interface IFetchFolderContentRequestParams {
  uid: string;
  page?: number;
  limit?: number;
}

const index = createAsyncThunk(
  'query-folders/folder-content',
  async (
    params: IFetchFolderContentRequestParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { limit = 10, page = 1, uid } = params;
    try {
      const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
        `/query-folders/${uid}/content`,
        {
          isProtected: true,
          params: { page, limit }
        }
      );
      return fulfillWithValue({
        contents: response.data.list,
        parent_uid: uid,
        meta: response.data.meta
      });
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);
const move = createAsyncThunk(
  'queries/move/',
  async (payload: MoveQueryPayload, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await ApiClient.put<FolderContent, MoveQueryPayload>(
        '/query-folders/content/update',
        payload,
        {
          isProtected: true
        }
      );
      toast.success('Query moved successfully');
      MixedPanelEvent.queryMove(payload, response.data?.query?.query);

      const data: MoveQuerySlicePayload = {
        content: response.data,
        newParentId: response.data.parent?.uid!,
        oldParentId: payload.parentId,
        queryId: payload.query_id
      };
      return fulfillWithValue(data);
    } catch (error) {
      toast.error('Something went wrong, please try again...');
      return rejectWithValue('Error occurred');
    }
  }
);

const refetch = createAsyncThunk(
  'refetch/folder-content',
  async (
    params: IFetchFolderContentRequestParams,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { limit = 10, page = 1, uid } = params;
    try {
      const response = await ApiClient.get<PaginatedDataType<FolderContent>>(
        `/query-folders/${uid}/content`,
        {
          isProtected: true,
          params: { page, limit }
        }
      );
      return fulfillWithValue({
        contents: response.data.list,
        parent_uid: uid,
        meta: response.data.meta
      });
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

export const newQueryFolderContentThunks = { index, move, refetch };
