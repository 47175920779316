import { styled, MenuItem, Accordion } from '@mui/material';

export const MenuOptionItem = styled(MenuItem)(({ theme }) => {
  const { mode, primary } = theme.palette;
  const darkMode = mode === 'dark';
  return {
    fontSize: 12,
    color: darkMode ? 'white' : '#252631',
    '&:hover': { color: primary.main }
  };
});

export const AccordionBody = styled(Accordion)(() => {
  return {
    padding: '0px'!,
    border: 'none'!,
    boxShadow: 'none'!,
    margin: 'none'!,
    backgroundImage: 'none',
    backgroundColor: 'none'
  };
});

export const ContentMetaStateBox = styled('div')(() => {
  return {
    height: '30px',
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center'
  };
});
