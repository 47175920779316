import { useAppDispatch } from '../../../store';
import React, { useState, useEffect } from 'react';
import Loader from '../../../lib/components/Loader/Loader';
import ListPageHeader from '../../../components/ListPageHeader';
import QueryHistoryListItem from '../../../components/QueryHistoryListItem';
import { QueryHistorySummaryThunk } from '../../../store/queryHistorySummary/thunk';
import { useQueryHistorySummarySelector } from '../../../store/queryHistorySummary/selectors';
import { useInitPageLayout } from '../../Watson/hooks/useInitPageLayout';
import { ListBox, MainPage } from '../components/styled';
import { LoadingState } from '../../../utils/types/general';
import EmptyHistory from './components/EmptyHistory';
import { useFolderSummarySelector } from '../../../store/folderSummary/selectors';

const Component = () => {
  const dispatch = useAppDispatch();
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [loading, setLoading] = useState(false); // adding loading state. places where the thunk is called is unknown. yet to inspect
  const folderSummary = useFolderSummarySelector()

  const queryHistorySummary = useQueryHistorySummarySelector();
  const noData =
    queryHistorySummary.loading === LoadingState.FULFILLED &&
    queryHistorySummary.list.length === 0;

  const firstTimeLoad = () => {
    setLoading(true);
    dispatch(QueryHistorySummaryThunk.index()).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    firstTimeLoad();
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }
  if (noData) {
    return <EmptyHistory />;
  }

  return (
    <ListBox className="scroll-container">
      {queryHistorySummary.list?.filter(history => (history.day ?? '').toLowerCase().includes((folderSummary.filterText ?? '').toLowerCase())).map((history, index) => (
        <QueryHistoryListItem
          history={history}
          accordionId={history.day}
          key={index}
          queryType="history"
          openAccordionId={openAccordionId}
          setOpenAccordionId={setOpenAccordionId}
        />
      ))}
    </ListBox>
  );
};

const QueryHistory = () => {
  useInitPageLayout();

  return (
    <MainPage className="save-queries">
      <ListPageHeader />
      <Component />
    </MainPage>
  );
};

export default QueryHistory;
