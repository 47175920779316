import React from 'react';
import { Typography, styled } from '@mui/material';
import { useUserNameAndInitials } from '../../hooks/useUserInitials';
import { BotsonAvatarContainer } from './botsonChatIcon';

const Container = styled(BotsonAvatarContainer)(({ theme }) => {
  const { mode, primary, secondary } = theme.palette;
  const isDark = mode === 'dark';
  return {
    background: isDark ? primary.main : secondary.main,
    color: '#fff',
    padding: 0,
    marginLeft: '20px'
  };
});

export const UserMessageAvatar = () => {
  const { userInitials } = useUserNameAndInitials();

  return (
    <Container>
      <Typography fontWeight={'bold'} fontSize={10}>
        {userInitials}
      </Typography>
    </Container>
  );
};
