import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/types/general';
import { CreateContentResponse, newContentThunks } from './thunk';
import { NewFolderContentType } from './type';

const initialState: NewFolderContentType = {
    data: {},
};

const index = createSlice({
    name: 'query-history-content-new',
    initialState,
    reducers: {
        addContent: (state, { payload }: PA<CreateContentResponse>) => {
            if (!(payload.parent_uid in state.data)) {
                state.data[payload.parent_uid] = { loading: LoadingState.IDLE, list: [] };
            }
            state.data[payload.parent_uid].list = payload.contents.map((a) => a.query);
        },
    },
    extraReducers: {
        [newContentThunks.index.pending.type]: (state, { payload }: PA<CreateContentResponse>) => {
            if (state.data[payload?.parent_uid] && !(payload.parent_uid in state.data)) {
                state.data[payload.parent_uid] = { loading: LoadingState.IDLE, list: [] };
            }
            if (state.data[payload?.parent_uid]) {
                state.data[payload.parent_uid].loading = LoadingState.PENDING;
            }
        },
        [newContentThunks.index.fulfilled.type]: (
            state,
            { payload }: PA<CreateContentResponse>,
        ) => {
            if (state.data[payload?.parent_uid]) {
                state.data[payload.parent_uid].loading = LoadingState.FULFILLED;
                state.data[payload.parent_uid].list = payload.contents.map((a) => a.query);
            }
        },
        [newContentThunks.index.rejected.type]: (state, { payload }: PA<CreateContentResponse>) => {
            if (state.data[payload?.parent_uid]) {
                state.data[payload?.parent_uid].loading = LoadingState.REJECTED;
            }
        },
    },
});

export const { addContent } = index.actions;

export default index.reducer;
