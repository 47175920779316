/**
 * A frozen object to create reusable styles. Organize them by components
 */

const modalText = {
  inputLabel: {
    fontSize: '14px',
    color: '#00000060'
  }
};

const modalInput = {
  defaultInput: {
    minHeight: '40px',
    height: '40px',
    padding: 0,
    paddingLeft: '8px',
    fontSize: '12px'
  }
};

export const DesignSystemMap = {
  modalText,
  modalInput
} as const;
