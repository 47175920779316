import { Typography, RadioGroup, Radio } from '@mui/material';
import { Container, RadioButtonContainer } from './components';

export const QueriedInFilter = ({ value, onChange }) => {
  return (
    <Container>
      <Typography
        fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
        color={'#707070'}
      >
        Queried in
      </Typography>
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        <RadioButtonContainer
          value={365}
          control={<Radio />}
          label={<Typography>All</Typography>}
        />
        <RadioButtonContainer
          value={1}
          control={<Radio />}
          label={<Typography>Last day</Typography>}
        />
        <RadioButtonContainer
          value={7}
          control={<Radio />}
          label={<Typography>Last week</Typography>}
        />
        <RadioButtonContainer
          value={30}
          control={<Radio />}
          label={<Typography>Last month</Typography>}
        />
      </RadioGroup>
    </Container>
  );
};
