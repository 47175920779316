import { useEffect, useMemo, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import {
  DiscosveryDataBox,
  EditFiltersBtn,
  FiiltersHeader,
  FiltersBox,
  FiltersContainer
} from './components';
import { Typography } from '@mui/material';
import FilterTableData from './filterTableData';
import Filter from './filter';
import EditIcon from '@mui/icons-material/Edit';
import {
  IBotsonDiscoveryMessage,
  ICustomMessageComponent
} from '../../interfaces';
import { useWatsonMeta } from '../../hooks/useWatsonMeta';
import { ConditionComponent } from '../../../../lib/utils';
import Empty from './empty';
import { useDiscoverFilterFns } from '../../hooks/useDiscoveryFilterFnsContextHooks';
import { useWatsonMessages } from '../../hooks/useWatsonMessages';
import { DiscoveryDataFeedback } from '../customMessages';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import HistoryQueries from './historyQueries';
import { AiDiscoveryBotResponseMessage } from '../../../../Botson/bots/Discovery/types';
import { useDiscoveryAiBotActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryActions';
import { useAiBotDataStoreActions } from '../../../../Botson/hooks/useAiBotDataStoreActions';
import { useAiBotMeta } from '../../../../Botson/hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../../../Botson/hooks/useAiBotVariantMeta';
import { AiBotMessageType } from '../../../../Botson/types';
import { AiBotVariant } from '../../../../Botson/domain/chatTypes';
import { uniqueId } from 'lodash';
import { useAiBotEntryMessage } from '../../../../Botson/hooks/useAiBotEntryMessage';

interface Props {
  data: AiDiscoveryBotResponseMessage;
}

export default ({ data }: Props) => {
  // const { reloadDataFilters } = useDiscoverFilterFns();
  // const { discoveryType, conversationId, ...meta } = useWatsonMeta()[0];
  // const setMeta = useWatsonMeta()[1];
  // const messages = useWatsonMessages()[0];
  // const addMessage = useWatsonMessages()[5];
  const { chooseAsset, reloadFilters } = useDiscoveryAiBotActions();
  const { clearMessages, addToBotMessages, updateVariantMeta } =
    useAiBotDataStoreActions();
  const { sessionId: conversation_uid, chatType } = useAiBotMeta();
  const { discoveryType, discoveryPhasePayload } = useAiBotVariantMeta();
  const entryMessage = useAiBotEntryMessage();

  const filters = useMemo(() => {
    return generateResultsFilters(data);
  }, [data]);

  const handleOnEditFilters = () => {
    // setMeta(p => ({
    //   ...p,
    //   discoveryPhase: 'none',
    //   discoveryPhasePayload: {
    //     ...(p?.discoveryPhasePayload ?? {}),
    //     disableEditFilters: true
    //   }
    // }));
    // reloadDataFilters(data.discoveryFilters);
    updateVariantMeta({
      discoveryPhase: 'none',
      disableEditFilters: true
    });
    reloadFilters(data.discoveryFilters);

    MixedPanelEvent.watsonEditFilters({
      conversation_uid,
      question_type: discoveryType
    });
  };

  const displayDataFeedback = () => {
    const message: AiBotMessageType = {
      message: '',
      variant: 'response',
      status: 'success',
      chatType: AiBotVariant.BotsonDiscovery,
      children: DiscoveryDataFeedback,
      conversationId: conversation_uid,
      id: uniqueId()
    };
    addToBotMessages(message);
    // setMeta(p => ({
    //   ...p,
    //   discoveryPhase: 'feedback',
    //   discoveryPhasePayload: data
    // }));
    updateVariantMeta({
      discoveryPhase: 'feedback',
      discoveryPhasePayload: data
    });
  };

  const [isAnimationDone, setIsAnimationDone] = useState(false);
  useEffect(() => {
    if (isAnimationDone) {
      displayDataFeedback();
    }
  }, [isAnimationDone]);
  const handleFiltersExtractionEvent = () => {
    MixedPanelEvent.watsonDataTableResults({
      conversation_uid,
      question_type: discoveryType,
      filters: data.discoveryFilters,
      question_message: entryMessage.message,
      // question_message: messages[0].body,
      answer: {
        text_message: data.discoverySummary,
        table_results: data.discoveryAssets
          ? data.discoveryAssets.slice(0, 5)
          : []
      }
    });
  };
  return (
    <DiscosveryDataBox>
      <TypeAnimation
        speed={70}
        sequence={[
          data?.discoverySummary ??
            `I found ${data.discoveryAssets?.length} table(s)`,
          () => setIsAnimationDone(true),
          handleFiltersExtractionEvent
        ]}
        wrapper="div"
        cursor={false}
        repeat={0}
        style={{
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: '1rem'
        }}
      />
      <ConditionComponent condition={isAnimationDone}>
        <FiltersContainer>
          <FiiltersHeader>
            <Typography variant="caption" sx={{ textTransform: 'none' }}>
              Applied Filters
            </Typography>
          </FiiltersHeader>

          <FiltersBox>
            {filters.map(filter => {
              const filterValue = data.discoveryFilters[filter];
              return (
                <Filter key={filter}>
                  {filter}: {String(filterValue)}
                </Filter>
              );
            })}
            <EditFiltersBtn
              disabled={Boolean(discoveryPhasePayload?.disableEditFilters)}
              onClick={handleOnEditFilters}
            >
              <EditIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
              Edit Filters
            </EditFiltersBtn>
          </FiltersBox>
        </FiltersContainer>
        <ConditionComponent condition={data.discoveryAssets.length === 0}>
          <Empty>No data available</Empty>
        </ConditionComponent>
        <ConditionComponent
          condition={
            data.discoveryAssets.length > 0 && discoveryType !== 'query'
          }
        >
          <FilterTableData data={data.discoveryAssets} type={discoveryType} />
        </ConditionComponent>
        <ConditionComponent
          condition={
            data.discoveryAssets.length > 0 && discoveryType === 'query'
          }
        >
          <HistoryQueries data={data.discoveryAssets} />
        </ConditionComponent>
      </ConditionComponent>
    </DiscosveryDataBox>
  );
};

function generateResultsFilters(data: any) {
  return Object.keys(data.discoveryFilters).filter(
    val => Boolean(data.discoveryFilters[val]) && val !== 'last_days'
  );
}
