import React from 'react';
import { Button, styled, Input, Box } from '@mui/material';

const preventDefaultEvent = ev => ev?.preventDefault();
type Props = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

const Form = styled(({ children, ...props }: Props) => (
  <form onSubmit={preventDefaultEvent} {...props}>
    {children}
  </form>
))(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px',
    width: '100%',
    '@media(min-width: 600px)': {
      marginLeft: '8px'
    }
  };
});

const SubmitButton = styled(Button)(({ theme }) => {
  const { secondary, primary } = theme.palette;
  const isDark = theme.palette.mode === 'dark';
  return {
    fontSize: 10,
    textTransform: 'none',
    color: isDark ? '#fff' : secondary.main,
    marginLeft: 'auto',
    backgroundColor: isDark ? primary.main : secondary.light,
    height: '24px',
    fontWeight: 400,
    ':hover': {
      backgroundColor: isDark ? primary.light : secondary.main,
      color: '#fff'
    }
  };
});

const FormInput = styled(Input)(({ theme, error: isError }) => {
  const { error, background } = theme.palette;
  return {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isError ? error.main : '#a9a9a930',
    backgroundColor: background.default,
    fontSize: 14,
    padding: '4px',
    borderRadius: '4px'
  };
});

const Container = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    rowGap: '4px'
  };
});

export { Form, FormInput, SubmitButton, Container };
