import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const CustomTooltip = styled(({ className, PopperProps, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        enterDelay={500}
        classes={{ popper: className }}
        PopperProps={
            PopperProps ?? {
                style: {
                    zIndex: 9000,
                    opacity: 0.9,
                },
            }
        }
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        paddingTop: '8px',
        paddingBottom: '8px',
        backgroundColor: '#252525',
        color: '#ffffff',
        opacity: 0.9,
        borderRadius: '2px',
        fontSize: '12px',
        fontWeight: 300,
        lineHeight: '14.22px',
        fontStyle: 'normal',
        //   height: '30px'
    },
}));
