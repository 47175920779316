import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../store';
import { useFolderSummarySelector } from '../../../../store/folderSummary/selectors';
import { AiBotMessageType } from '../../../types';
import { NewCreateQueryPayload } from '../../../../store/queries/types';
import { localTime } from '../../../../pages/Editor/helpers/helpers';
import { queriesAsyncThunk } from '../../../../store/queries/queries.thunk';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { UnknownSubjectResponse } from '../../../../pages/Watson/components/unknownSubject';
import { AiBotVariant } from '../../../domain/chatTypes';
import { GeneratedQueryResponse } from '../../../../pages/Watson/components/generateQuery/responseMessage';
import Regenerate from '../../../../pages/Watson/components/Regenerate';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { ResponseCodeBlock } from '../../../../pages/Watson/components/atoms/responseCodeBlock';

interface ResponseProps {
  data: any;
}

const Response = ({ data }: ResponseProps) => {
  const { conversationId } = data;
  const dispatch = useAppDispatch();
  const folderList = useFolderSummarySelector();
  const expandState = useState(false);

  const saveQuery = async (code: string) => {
    let fid: string = folderList.list[0].uid;
    let fname: string = folderList.list[0].name;
    folderList.list.map((folder: { name: any; uid: any }) => {
      if (folder.name === 'Default') {
        fid = folder.uid;
        fname = folder.name;
      }
    });
    let requestBody: NewCreateQueryPayload = {
      parent_id: fid,
      folder: undefined,
      query: {
        name: `WATSON ${localTime(new Date(), true) as string}`,
        description: `Watson Snippet`,
        query: code
      }
    };
    await dispatch(queriesAsyncThunk.create(requestBody)).then(() => {
      toast(`Saved in ${fname} folder`, { type: 'success' });
      MixedPanelEvent.watsonSnippetSaved({
        message: code,
        conversation_uid: conversationId,
        chat_type: 'none'
      });
    });
  };

  if ((data as any)?.subject) {
    return (
      <UnknownSubjectResponse
        data={{ body: data.message, subject: (data as any).subject }}
      />
    );
  }
  if (data?.chatType === AiBotVariant.BotsonGenSql) {
    return <GeneratedQueryResponse data={data as unknown as any} />;
  }

  const parts = data.message.split('sherloq-regenerate-');
  const elements = [];
  parts.forEach((part: any, index: any) => {
    if (index !== 0) {
      const subParts = part.split('\n');
      const tableName = subParts[0];
      elements.push(
        <Regenerate
          key={index + tableName}
          regenerateValue={tableName}
          regenerateQuestion={data?.regenerateQuestion}
          builtPrompt={data?.builtPrompt}
          promptType={data?.promptType}
        />
      );
      elements.push(
        <ReactMarkdown
          key={index}
          children={subParts.slice(1).join('\n')}
          components={{
            code: props => (
              <ResponseCodeBlock
                {...props}
                data={data}
                onSave={saveQuery}
                expandState={expandState}
              />
            ),
            p({ children }) {
              return (
                <Typography
                  style={{
                    width: 'inherit',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                  }}
                >
                  {children}
                </Typography>
              );
            },
            em({ children }) {
              return <em className="important-text">{children}</em>;
            },
            pre({ children }) {
              return <div className="code-block-container">{children}</div>;
            }
          }}
        />
      );
    } else {
      elements.push(
        <ReactMarkdown
          key={index}
          children={part}
          components={{
            p({ children }) {
              return (
                <Typography
                  style={{
                    width: 'inherit',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                  }}
                >
                  {children}
                </Typography>
              );
            },
            em({ children }) {
              return <em className="important-text">{children}</em>;
            },
            pre({ children }) {
              return <div className="code-block-container">{children}</div>;
            },
            code: props => (
              <ResponseCodeBlock
                {...props}
                onSave={saveQuery}
                data={data as any}
                expandState={expandState}
              />
            )
          }}
        />
      );
    }
  });
  return <div>{elements}</div>;
};

export default Response;
