import { styled, Typography, TypographyProps } from '@mui/material';
import MuiInfoIcon from '@mui/icons-material/Info';

export const AssetChipBox = styled(
  (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />
)(({ color, theme }) => {
  const { grey } = theme.palette;
  return `
  padding: 4px;
  border-radius: 100px;
  display: flex;
  align-items:center;
  justify-content: center;
  background-color : ${color};
  font-size: 12px;
  color: ${grey[600]};
  `;
});

export const InfoLabelBox = styled('div')(() => {
  return `
  display: flex;
  width: fit-content;
  align-items:center;
  `;
});

export const InfoIconText = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2" />
))(() => {
  return `
  font-weight: 600;
  display: inline;
  `;
});

export const InfoIcon = styled(MuiInfoIcon)(({ theme }) => {
  const { grey } = theme.palette;
  return `
  color: ${grey['500']};
  margin-left: 6px;
  font-size: 18px;
  `;
});
