import { User } from '../../../store/auth/types';
import { MoveQueryPayload } from '../../../store/newFolderContent/types';
import {
  ActionOnQueryFolderPayload,
  ActionOnQueryPayload,
  ButtonEventType,
  ExtensionFeedback,
  Invitation,
  PlatformIdChangedPayload,
  QuerySearchPayload,
  IDERequest,
  IQueryManuallyCopiedEventPayload,
  IPageCopyEventPayload,
  IBotsonPromptClickedEventPayload,
  IUnknownSubjectEventPayload,
  WatsonMessageEvent,
  ShowMoreResponseButtonEvent,
  WatsonAssetFeedbackEvent,
  GenSqlFetchTablesSucessEventPayload,
  GenSqlFetchFiltersSuccessPayload,
  GenSqlSaveBusinessTermEventPayload,
  GenSqlQueryGeneratedSuccessEventPayload,
  GenSqlFeedbackLikeEventPayload,
  GenSqlFeedbackDislikeEventPayload,
  GenSqlFeedbackRestartEventPayload,
  GenSqlFeedbackEditFiltersEventPayload,
  GenSqlSubmitDislikeInfoEventPayload
} from './types';
import { Posthog } from './posthog';
import { SherloqAnalytics } from './sherloq';
import store from '../../../store';
import { AnalyticsWrapperType } from './WrapperClass';
import { PersistedGlobalState } from '../../../store/persistedStoreRef';
import { dotenv } from '../../../env';
import CustomMixedPanel from './mixpanel';
import { Logger } from '../../browser/logger';

export default class MixedPanelEvent {
  private static _extCurrentUrl: string;
  private static Wrappers: AnalyticsWrapperType[] = [
    CustomMixedPanel,
    Posthog,
    SherloqAnalytics
  ];

  public static async init() {
    try {
      await this._initWrappers();
      const user = PersistedGlobalState.getUser();
      if (user) {
        this.identify(user);
      }
    } catch (error) {
      //
    }
  }

  public static identify(payload: any) {
    this._identifyWrappers(payload);
  }

  public static setExtCurrentUrl(url: string) {
    this._extCurrentUrl = url;
  }

  private static get canSendEvent() {
    return dotenv.appEnv !== 'development';
  }

  private static addPlatformOrigin() {
    const state = store.getState();
    if (state && state.sherloqUserOrigin && state.sherloqUserOrigin.origin) {
      return state.sherloqUserOrigin.origin;
    }
    return 'web';
  }

  private static sendEvent(name: string, data: any = {}) {
    const newData = {
      ...data,
      ext_current_url: this._extCurrentUrl,
      platform: this.addPlatformOrigin()
    };
    const user = PersistedGlobalState.getUser();
    if (user && user?.email) {
      newData['user_email'] = user.email;
    }

    if (this.canSendEvent) {
      this._emitWrapperEvents(name, newData);
    }
  }

  public static botsonPromptClicked(payload: IBotsonPromptClickedEventPayload) {
    this.sendEvent('extension_watson_prompt_clicked', payload);
  }

  public static searchQuery(payload: QuerySearchPayload) {
    this.sendEvent('extension_query_search', payload);
  }

  public static searchQueryResults(payload: QuerySearchPayload) {
    this.sendEvent('extension_query_search_results', payload);
  }

  public static searchFromFolder() {
    this.sendEvent('extension_search_from_folder');
  }

  public static userLogin(payload: User) {
    this.sendEvent('extension_login_success', payload);
  }

  public static userLogout(payload: User) {
    this.sendEvent('extension_logout', payload);
  }

  public static buttonClicked(payload: ButtonEventType) {
    this.sendEvent(`extension_button_${payload.buttonName}_clicked`, payload);
  }

  public static sendFeedback(payload: ExtensionFeedback) {
    this.sendEvent(`extension_feedback_sent`, payload);
  }

  public static sendInvitation(payload: Invitation) {
    this.sendEvent(`extension_team_invitation_sent`, payload);
  }

  public static queryAction(payload: ActionOnQueryPayload) {
    switch (payload.actionName) {
      case 'created':
        this.sendEvent('extension_query_created', payload.query);
        break;
      case 'updated':
        this.sendEvent('extension_query_updated', payload.query);
        break;
      case 'deleted':
        this.sendEvent('extension_query_deleted', payload.query);
        break;

      default:
        break;
    }
  }

  public static queryInListAction(payload: ActionOnQueryPayload) {
    switch (payload.actionName) {
      case 'updated':
        this.sendEvent('extension_query_updated_in_list', payload.query);
        break;
      default:
        break;
    }
  }

  public static queryMove(payload: MoveQueryPayload, query: string) {
    this.sendEvent('extension_query_moved', { ...payload, query });
  }

  public static queryFolderAction(payload: ActionOnQueryFolderPayload) {
    switch (payload.actionName) {
      case 'created':
        this.sendEvent('extension_folder_created', payload.folderName);
        break;
      case 'updated':
        this.sendEvent('extension_folder_updated', { folderName: payload.folderName, folderUid: payload.folderUid, description: payload.folderDescription, shared_with: payload.sharedWith });
        break;
      case 'deleted':
        this.sendEvent('extension_folder_deleted', payload.folderName);
        break;

      default:
        break;
    }
  }

  public static queryFolderCreateErrorOccured(folderName) {
    this.sendEvent('extension_error_occured_new_folder', folderName);
  }

  public static editorFormatQueryAction(query: string) {
    this.sendEvent('extension_editor_format_query_action', { query });
  }
  public static editorOptimizeQueryAction() {
    this.sendEvent('extension_editor_optimize_query_action');
  }
  public static editorListItemLinkAction(query: string, query_uid: string) {
    this.sendEvent('extension_query_list_item_link_generated', { query, query_uid });
  }
  public static editorQueryLinkAction(query: string, query_uid: string) {
    this.sendEvent('extension_query_link_generated', { query, query_uid });
  }
  public static editorCopyQueryAction(query: string) {
    this.sendEvent('extension_editor_copy_query_action', { query });
  }
  public static editorSimilarQueryAction() {
    this.sendEvent('extension_editor_similar_query_action');
  }
  public static editorUpdateQueryAction(query: string) {
    this.sendEvent('extension_editor_update_query_action', { query });
  }
  public static editorSaveQueryAction(query: string) {
    this.sendEvent('extension_editor_save_query_action', { query });
  }
  public static clickOnFolderListItem(folderName: string) {
    this.sendEvent('extension_folder_clicked', { folderName });
  }

  public static setQueryAsSnippet(query: string) {
    this.sendEvent('extension_query_add_to_snippets', { query });
  }

  public static deleteQueryFromSnippets(query: string) {
    this.sendEvent('extension_query_delete_from_snippets', { query });
  }

  public static importButtonClickedGithub() {
    this.sendEvent('extension_import_queries_github');
  }

  public static importButtonClickedCsv() {
    this.sendEvent('extension_import_queries_csv');
  }

  public static submitImportButtonClickedCsv() {
    this.sendEvent('extension_import_queries_csv_sent');
  }

  public static importButtonClickedOther() {
    this.sendEvent('extension_import_queries_other');
  }

  public static submitImportButtonClickedOther(toolName: string) {
    this.sendEvent('extension_import_queries_other_sent', { toolName });
  }

  public static queryHistoryIdeRequest(payload: IDERequest) {
    this.sendEvent('extension_query_history_ide_request', payload);
  }
  public static queryHistoryCopyQuery(query: string) {
    this.sendEvent('extension_query_history_copy_query_action', { query });
  }

  public static queryHistoryAccordionClicked(folderName: string) {
    this.sendEvent('extension_query_history_accordion_button_clicked', {
      folderName
    });
  }

  public static queryHistoryClicked(query: string) {
    this.sendEvent('extension_query_history_clicked', { query });
  }

  public static emitPlatformIdChanged(payload: PlatformIdChangedPayload) {
    this.sendEvent('extension_platform_id_error', payload);
  }

  public static addTagAction(tagName: string) {
    this.sendEvent('extension_tag_add', { tagName });
  }
  public static deleteQueryTagAction(tagName: string) {
    this.sendEvent('extension_tag_delete', { tagName });
  }

  public static addQueryTagAction(tagName: string) {
    this.sendEvent('extension_query_tag_add', { tagName });
  }

  public static sharedFolderInviteMember(folderName: string, folderUid: string, members: string[]) {
    this.sendEvent('extension_shared_folder_permissions', {
      invitedEmail: members,
      folderName,
      folderUid
    });
  }
  public static shareFolderClicked(folderName: string, folderUid: string) {
    this.sendEvent('extension_folder_link_generated', {
      folderName,
      folderUid
    });
  }

  public static queryClicked(query: string) {
    this.sendEvent('extension_query_clicked', { query });
  }
  public static queryCommentsClicked(comments: string) {
    this.sendEvent('extension_query_comments_clicked', { comments });
  }
  public static querySaveError(query: string) {
    this.sendEvent('extension_save_error', { query });
  }
  public static queryCopyClicked(query: string) {
    this.sendEvent('extension_query_list_item_copy', { query });
  }
  public static queryDiffClicked(query: string) {
    this.sendEvent('extension_query_diff', { query });
  }
  public static queryCommentUpdate(comments: string, query: string) {
    this.sendEvent('extension_query_comments_updated', {
      updatedComment: comments,
      query: query
    });
  }
  public static queryVersionCommentUpdate(comments: string, query: string) {
    this.sendEvent('extension_query_version_comments_updated', {
      updatedComment: comments,
      query: query
    });
  }

  public static joyrideSnippetsStart() {
    this.sendEvent('extension_joyride_snippets_start');
  }

  public static joyrideOnboardingStart() {
    this.sendEvent('extension_joyride_onboarding_start');
  }
  public static joyrideOnboardingClickNext(index: number) {
    this.sendEvent('extension_joyride_onboarding_click_next', { index: index });
  }
  public static joyrideOnboardingClickPrev(index: number) {
    this.sendEvent('extension_joyride_onboarding_click_prev', { index: index });
  }
  public static joyrideOnboardingClickSkip(index: number) {
    this.sendEvent('extension_joyride_onboarding_click_skip', { index: index });
  }

  public static oopsError(error: string, trace?: any) {
    this.sendEvent('extension_oops_error', { error, trace: trace ?? {} });
  }

  public static panelOpen() {
    this.sendEvent('extension_panel_open');
  }
  public static tabClicked(tabName: string) {
    this.sendEvent('extension_tab_clicked', { tabName });
  }

  public static searchResultsTabClicked(tabName: string) {
    this.sendEvent('extension_serach_results_tab_clicked', { tabName });
  }

  public static sidebarButtonClicked(buttonName: string) {
    this.sendEvent('extension_sidebar_button_clicked', { buttonName });
  }

  public static registerSaveAsRedirectToNewFolder() {
    this.sendEvent('extension_saveas_redirect_to_new_folder_button_clicked');
  }

  public static registerSaveAsCancel() {
    this.sendEvent('extension_saveas_cancel');
  }

  public static registerSaveAsNewFolderCancel() {
    this.sendEvent('extension_saveas_new_folder_cancel');
  }

  public static registerSaveAs(payload: any) {
    this.sendEvent('extension_saveas', payload);
  }

  public static registerSaveAsNewFolder(payload: any) {
    this.sendEvent('extension_saveas_new_folder', payload);
  }

  public static registerGmailBlocked() {
    this.sendEvent('extension_login_with_gmail_blocked');
  }

  public static watsonQuestionSent(payload: WatsonMessageEvent) {
    this.sendEvent(`extension_watson_question_sent`, payload);
  }

  public static watsonAnswerSent(payload: WatsonMessageEvent) {
    this.sendEvent(`extension_watson_answer_sent`, payload);
  }

  public static watsonMessageLiked(
    payload: WatsonMessageEvent | GenSqlFeedbackLikeEventPayload
  ) {
    Logger.info('feedback like event', payload);
    this.sendEvent(`extension_watson_feedback_like`, payload);
  }

  public static watsonMessageDisliked(
    payload: WatsonMessageEvent | GenSqlFeedbackDislikeEventPayload
  ) {
    Logger.info('feedback dislike event', payload);

    this.sendEvent(`extension_watson_feedback_dislike`, payload);
  }

  public static watsonQueryFeedbackLike(payload: WatsonMessageEvent) {
    this.sendEvent(`extension_watson_feedback_query_like`, payload);
  }

  public static watsonQueryFeedbackDislike(payload: WatsonMessageEvent) {
    this.sendEvent(`extension_watson_feedback_query_dislike`, payload);
  }

  public static watsonAssetFeedbackLike(payload: WatsonAssetFeedbackEvent) {
    this.sendEvent(`extension_watson_feedback_asset_like`, payload);
  }

  public static watsonAssetFeedbackDislike(payload: WatsonAssetFeedbackEvent) {
    this.sendEvent(`extension_watson_feedback_asset_dislike`, payload);
  }
  public static watsonAssetFeedbackDislikeInfo(
    payload: WatsonAssetFeedbackEvent
  ) {
    this.sendEvent(`extension_watson_feedback_asset_dislike_info`, payload);
  }

  public static watsonQueryFeedbackDislikeInfo(
    payload: WatsonAssetFeedbackEvent
  ) {
    this.sendEvent(`extension_watson_feedback_query_dislike_info`, payload);
  }

  public static watsonCopyCode(payload: WatsonMessageEvent) {
    this.sendEvent(`extnesion_watson_copy_code`, payload);
  }

  public static watsonSnippetSaved(payload: WatsonMessageEvent) {
    this.sendEvent(`extension_watson_snippet_saved`, payload);
  }

  public static watsonUserStage(stage: string) {
    this.sendEvent(`extension_watson_user_stage`, { stage });
  }

  public static watsonTabOpened() {
    this.sendEvent(`extension_watson_tab_opened`);
  }

  public static watsonMessageDislikedInfo(text: string) {
    this.sendEvent(`extension_watson_feedback_dislike_info`, { text });
  }

  public static watsonShowMoreResponseClicked(
    payload: ShowMoreResponseButtonEvent
  ) {
    this.sendEvent(`extension_watson_show_more_response`, payload);
  }

  public static queryManuallyCopied(
    payload: IQueryManuallyCopiedEventPayload | IPageCopyEventPayload
  ) {
    this.sendEvent(`extension_copy_from_sherloq`, payload);
  }

  public static submittedUnknownWatsonSubject(
    payload: IUnknownSubjectEventPayload
  ) {
    this.sendEvent(`extension_watson_unknown_subject`, payload);
  }

  public static watsonRestartConversation() {
    this.sendEvent(`extension_watson_restart`);
  }

  public static watsonFeedbackRestartConversation(
    payload: GenSqlFeedbackRestartEventPayload | any
  ) {
    Logger.info('chat restarted feedback ', payload);
    this.sendEvent(`extension_watson_feedback_restart`, payload);
  }

  public static watsonAssetTypeSent(payload: any) {
    this.sendEvent(`extension_watson_question_type_sent`, payload);
  }

  public static watsonExtractedFilters(payload: any) {
    this.sendEvent(`extension_watson_question_extraction`, payload);
  }

  public static watsonFilterSubmit(payload: any) {
    this.sendEvent(`extension_watson_question_filter_info_submit`, payload);
  }

  public static watsonFilterEditInfo(payload: any) {
    this.sendEvent(`extension_watson_question_edit_filter_info`, payload);
  }

  public static watsonEditFilters(payload: any) {
    this.sendEvent(`extension_watson_answer_sent_edit_filter`, payload);
  }

  public static watsonDataTableResults(payload: any) {
    this.sendEvent(`extension_watson_answer_sent_data_results`, payload);
  }

  public static watsonJoinQuestionExtraction(payload: any) {
    this.sendEvent(`extension_watson_question_extraction`, payload);
  }

  public static watsonWhereQuestionExtraction(payload: any) {
    this.sendEvent(`extension_watson_question_extraction`, payload);
  }

  public static watsonNewJoinButtonClick(payload: any) {
    this.sendEvent(`extension_watson_question_new_join`, payload);
  }

  public static watsonDashboardSqlButtonClick(payload: any) {
    this.sendEvent(`extension_sherloq_dashboard_sql_button_clicked`, payload);
  }
  public static watsonDashboardSqlCopied(payload: any) {
    this.sendEvent(
      `extension_sherloq_dashboard_sql_button_copied_clicked`,
      payload
    );
  }
  public static watsonDashboardUrlClick(payload: any) {
    this.sendEvent(`extension_sherloq_dashboard_url_clicked`, payload);
  }
  public static watsonCalculatedFieldClick(payload: any) {
    this.sendEvent(`extension_sherloq_calculated_field_clicked`, payload);
  }
  public static displayViewToggleClick(payload: any) {
    this.sendEvent(`extension_display_view_button_clicked`, payload);
  }

  public static sendGenSqlTablesFetchedEvent(
    payload: GenSqlFetchTablesSucessEventPayload
  ) {
    Logger.info('tables fetched');

    this.sendEvent(`extension_watson_question_sent_get_tables`, payload);
  }

  public static sendGenSqlFiltersFetchedEvent(
    payload: GenSqlFetchFiltersSuccessPayload
  ) {
    Logger.info('filters fetched');
    this.sendEvent(`extension_watson_question_sent_get_filters`, payload);
  }

  public static sendGenSqlSaveBusinessTermEvent(
    payload: GenSqlSaveBusinessTermEventPayload
  ) {
    Logger.info('business term created', payload);
    this.sendEvent(`extension_watson_term_saved`, payload);
  }

  public static sendGenSqlEditFiltersFeedbackEvent(
    payload: GenSqlFeedbackEditFiltersEventPayload
  ) {
    Logger.info('edit filters feedback event', payload);

    this.sendEvent(`extension_watson_feedback_edit_filters`, payload);
  }

  public static sendGenSqlGeneratedQueryAnswerSentEvent(
    payload: GenSqlQueryGeneratedSuccessEventPayload
  ) {
    Logger.info('answer sent event', payload);
    this.sendEvent(`extension_watson_answer_sent`, payload);
  }

  public static sendGenSqlDislikeInfoEvent(
    payload: GenSqlSubmitDislikeInfoEventPayload
  ) {
    Logger.info('dislike info event', payload);
    this.sendEvent(`extension_watson_feedback_dislike_info`, payload);
  }

  public static copilotSettingClick(payload: any) {
    this.sendEvent(`extension_setting_copilot_preferences_clicked`, payload);
  }

  public static dataCatalogSearch(payload: any) {
    this.sendEvent(`extension_search_profile`, payload);
  }

  public static dataCatalogTableProfileSearchField(payload: any) {
    this.sendEvent(`extension_search_fields_in_table_profile`, payload);
  }
  public static dataCatalogProfileClicked(payload: any) {
    this.sendEvent(`extension_profile_clicked`, payload);
  }

  public static dataCatalogProfileQueryClicked(payload: any) {
    this.sendEvent(`extension_query_profile_clicked`, payload);
  }

  public static queryHistoryListViewClicked(payload: any) {
    this.sendEvent(`extension_query_history_list_clicked`, payload);
  }

  public static savedQueryListViewClicked(payload: any) {
    this.sendEvent(`extension_saved_query_list_clicked`, payload);
  }

  public static dataCatalogProfileJoinCopied(payload: any) {
    this.sendEvent(`extension_join_profile_copy_clicked`, payload);
  }
  public static dataCatalogProfileDescriptionSaveClick(payload: any) {
    this.sendEvent(
      `extension_description_save_profile_button_clicked`,
      payload
    );
  }

  public static dataCatalogProfileGenerateDescriptionClick(payload: any) {
    this.sendEvent(`extension_description_ai_profile_button_clicked`, payload);
  }

  public static dataCatalogProfileUpdateProperty(payload: any) {
    this.sendEvent(`extension_properties_profile_updated_clicked`, payload);
  }

  public static messageFreeTrialAppear() {
    this.sendEvent(`extension_message_free_trial_appear`);
  }

  public static messageFreeTrialClicked() {
    this.sendEvent(`extension_message_free_trial_clicked`);
  }

  public static folderTeamSelected(payload) {
    this.sendEvent(`extension_folder_team_selected`, payload);
  }

  public static folderTeamCreated(payload) {
    this.sendEvent(`extension_folder_team_created`, payload);
  }

  public static expandButtonClicked(payload) {
    this.sendEvent(`extesnion_expand_button_clicked`, payload);
  }

  private static async _initWrappers() {
    for (const Wrapper of this.Wrappers) {
      if (Wrapper.hasOwnProperty('init')) {
        await Wrapper.init();
      }
    }
  }

  public static async _identifyWrappers(payload: any) {
    for (const Wrapper of this.Wrappers) {
      if (Wrapper.hasOwnProperty('identify')) {
        Wrapper.identify(payload);
      }
    }
  }

  public static removeInstances = () => {
    for (const Wrapper of this.Wrappers) {
      if (Wrapper.hasOwnProperty('reset')) {
        Wrapper.reset();
      }
    }
  };

  public static getDistinctIds = () => {
    const ids = [];
    for (const Wrapper of this.Wrappers) {
      if (Wrapper.hasOwnProperty('getId')) {
        ids.push(Wrapper.getId());
      }
    }

    return ids;
  };

  private static _emitWrapperEvents(event: string, payload: any) {
    for (const Wrapper of this.Wrappers) {
      Wrapper.sendEvent(event, payload);
    }
  }
}
