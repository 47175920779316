import React from 'react';

interface ModeIconProps {
    size: string;
}

const ModeIcon = ({ size }: ModeIconProps) => {
    return (
        <svg height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#c8f169"
                d="M10.4044 5.86651L10.3343 9.99954C10.3307 10.2248 10.1471 10.378 9.9219 10.378H9.0438C8.81616 10.378 8.63142 10.5629 8.63142 10.7906V13.5794C8.63142 13.8069 8.81561 13.9917 9.04297 13.992L13.5094 13.9995C13.7373 13.9997 13.9226 13.8149 13.9226 13.5869V10.7686C13.9226 10.5409 13.7378 10.356 13.5102 10.356H12.8913C12.6637 10.356 12.479 10.1712 12.479 9.94343V8.037C12.479 7.80897 12.6637 7.62441 12.8916 7.62441H13.5099C13.7378 7.62468 13.9226 7.44012 13.9226 7.21209V2.41259C13.9226 2.18484 13.7378 2 13.5102 2H11.001C10.8462 2 10.7043 2.08692 10.6337 2.225L8.35897 6.68013L6.11202 2.22665C6.04192 2.08774 5.89951 2 5.7439 2H2.41238C2.18475 2 2 2.18484 2 2.41259V4.1936C2 4.42134 2.18475 4.60618 2.41238 4.60618H3.01804C3.24568 4.60618 3.43042 4.79102 3.43042 5.01877V7.91707C3.43042 8.14482 3.24568 8.32966 3.01804 8.32966H2.41238C2.18475 8.32966 2 8.5145 2 8.74225V13.5874C2 13.8152 2.18475 14 2.41238 14H5.86322C6.09085 14 6.2756 13.8152 6.2756 13.5874V10.7975C6.2756 10.5697 6.09085 10.3849 5.86322 10.3849H5.26169C5.03405 10.3849 4.8493 10.2001 4.8493 9.97231V6.2945L7.24993 11.0514C7.3511 11.2519 7.63729 11.253 7.73984 11.053L10.4044 5.86623V5.86651Z"
            />
        </svg>
    );
};

export default ModeIcon;
