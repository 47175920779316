import React from 'react';
import { Box, Button } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';
import { sx } from '../../../styles/MUI/styles';

interface FilterActionButtonsProps {
  onResetFilters?: onClickHandler<HTMLButtonElement>;
  onSubmitSearch?: onClickHandler<HTMLButtonElement>;
  onCancelSearch?: onClickHandler<HTMLButtonElement>;
}

export const FilterActionButtons = ({
  onCancelSearch,
  onResetFilters,
  onSubmitSearch
}: FilterActionButtonsProps) => {
  return (
    <Box className="search-action-container">
      <Button
        sx={{ ...sx.textBtn12, marginLeft: '-8px' }}
        onClick={onResetFilters}
        variant="text"
      >
        Reset Filters
      </Button>
      <Box className="search-action-buttons">
        <Button
          sx={
            isDatagrip()
              ? sx.disabledOutlinedBtn14dark
              : sx.disabledOutlinedBtn14
          }
          variant="outlined"
          onClick={onCancelSearch}
        >
          Cancel
        </Button>
        <Button sx={sx.btn} variant="contained" onClick={onSubmitSearch}>
          Search
        </Button>
      </Box>
    </Box>
  );
};
