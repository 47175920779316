import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import moment from 'moment';
import { LoadingState } from '../../utils/types/general';
import { PaginatedDataType } from '../../utils/types/paginatedData';
import { queriesAsyncThunk } from './queries.thunk';
import { QueryState, FolderContent } from './types';

const initialState: QueryState = {
    updatedAt: -1,
    list: [],
    meta: {
        count: -1,
        last: -1,
        page: -1,
        page_size: -1,
    },
    fetchLoadingState: LoadingState.IDLE,
    createLoadingState: LoadingState.IDLE,
    updateLoadingState: LoadingState.IDLE,
    deleteLoadingState: LoadingState.IDLE,
    loading: false,
};

const slice = createSlice({
    name: 'queries',
    initialState,
    reducers: {},
    extraReducers: {
        [queriesAsyncThunk.index.pending.type]: (state) => {
            state.fetchLoadingState = LoadingState.PENDING;
        },
        [queriesAsyncThunk.index.fulfilled.type]: (
            state,
            { payload }: PA<PaginatedDataType<FolderContent>>,
        ) => {
            state.fetchLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
            state.meta = payload.meta;
            if (payload.meta.page === 1) {
                state.list = payload.list;
            } else {
                // state.list = state.list.concat(payload.list);
            }
        },

        [queriesAsyncThunk.index.rejected.type]: (state) => {
            state.fetchLoadingState = LoadingState.REJECTED;
        },

        [queriesAsyncThunk.search.pending.type]: (state) => {
            state.fetchLoadingState = LoadingState.PENDING;
            state.loading = true;
            state.list = [];
        },
        [queriesAsyncThunk.search.fulfilled.type]: (state, { payload }: PA<FolderContent[]>) => {
            state.fetchLoadingState = LoadingState.FULFILLED;
            state.loading = false;
            state.updatedAt = moment.now();
            state.list = payload;
            state.meta = {
                count: -1,
                last: -1,
                page: -1,
                page_size: -1,
            };
        },

        [queriesAsyncThunk.search.rejected.type]: (state) => {
            state.fetchLoadingState = LoadingState.REJECTED;
            state.loading = false;
            state.list = [];
        },

        [queriesAsyncThunk.create.rejected.type]: (state) => {
            state.createLoadingState = LoadingState.PENDING;
        },
        [queriesAsyncThunk.create.fulfilled.type]: (state, { payload }: PA<FolderContent>) => {
            state.createLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
            state.list.push(payload);
        },
        [queriesAsyncThunk.create.rejected.type]: (state) => {
            state.createLoadingState = LoadingState.REJECTED;
        },

        // eod create query folder

        [queriesAsyncThunk.update.pending.type]: (state) => {
            state.updateLoadingState = LoadingState.PENDING;
        },
        [queriesAsyncThunk.update.fulfilled.type]: (state, { payload }: PA<FolderContent>) => {
            const index = state.list.findIndex((item) => item.query?.uid === payload.query?.uid);
            if (index !== -1) {
                state.list[index] = payload;
            }
            state.updateLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
        },
        [queriesAsyncThunk.update.rejected.type]: (state) => {
            state.updateLoadingState = LoadingState.REJECTED;
        },

        [queriesAsyncThunk.updateFolder.fulfilled.type]: (
            state,
            { payload }: PA<FolderContent>,
        ) => {
            const index = state.list.findIndex((item) => item.query?.uid === payload.query?.uid);
            if (index !== -1) {
                state.list[index] = payload;
            }
            state.updateLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
        },
        [queriesAsyncThunk.updateFolder.rejected.type]: (state) => {
            state.updateLoadingState = LoadingState.REJECTED;
        },

        [queriesAsyncThunk.remove.pending.type]: (state) => {
            state.deleteLoadingState = LoadingState.PENDING;
        },
        [queriesAsyncThunk.remove.fulfilled.type]: (state, { payload }: PA<string>) => {
            const index = state.list.findIndex((item) => item.query?.uid === payload);
            if (index !== -1) {
                // state.list.splice(index, 1);
            }
            state.deleteLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
        },
        [queriesAsyncThunk.remove.rejected.type]: (state) => {
            state.deleteLoadingState = LoadingState.REJECTED;
        },

        // remove query folder
        [queriesAsyncThunk.removeQueryFolder.pending.type]: (state) => {
            state.deleteLoadingState = LoadingState.PENDING;
        },
        [queriesAsyncThunk.removeQueryFolder.fulfilled.type]: (state, { payload }: PA<string>) => {
            const index = state.list.findIndex((item) => item.child?.uid === payload);
            if (index !== -1) {
                state.list.splice(index, 1);
            }
            state.deleteLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
        },
        [queriesAsyncThunk.removeQueryFolder.rejected.type]: (state) => {
            state.deleteLoadingState = LoadingState.REJECTED;
        },

        // folder content start
        [queriesAsyncThunk.folderContent.pending.type]: (state) => {
            state.fetchLoadingState = LoadingState.PENDING;
            state.list = [];
        },
        [queriesAsyncThunk.folderContent.fulfilled.type]: (
            state,
            { payload }: PA<PaginatedDataType<FolderContent>>,
        ) => {
            state.fetchLoadingState = LoadingState.FULFILLED;
            state.updatedAt = moment.now();
            state.list = payload.list;
            if (payload.meta.page === 1) {
                state.list = payload.list;
            } else {
                state.list = state.list.concat(payload.list);
            }
        },

        [queriesAsyncThunk.folderContent.rejected.type]: (state) => {
            state.fetchLoadingState = LoadingState.REJECTED;
            state.list = [];
        },

        // eod folder content
    },
});

export const {} = slice.actions;

export default slice.reducer;
