import PageLoader from '../../../../lib/components/PageLoader';
import { Header } from './components/Header';
import { RecentQueries } from './components/RecentQueries';
import { Container, ContentBox } from './components/atoms/styled';
import { ConditionComponent } from '../../../../lib/utils';
import { FieldProfileProvider } from './context/provider';
import { useFieldProfileDataStore } from './hooks/useFieldProfileDataStore';
import { UsageTables } from '../TableProfile/components/UsageTables';
import { Description } from './components/Description';
import { useEffect } from 'react';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useSubscribeToCopyEvents } from '../../../../lib/hooks';

const Component = () => {
  const { data, loading } = useFieldProfileDataStore();

  if (loading || !data) {
    return <PageLoader />;
  }

  const handleCopyFromPage = (selectedText: string) => {
    MixedPanelEvent.queryManuallyCopied({
      page: 'field-profile',
      copiedText: selectedText
    });
  };
  const { ref } = useSubscribeToCopyEvents({
    onCopy: handleCopyFromPage,
    enable: Boolean(handleCopyFromPage)
  });

  useEffect(() => {
    if (data) {
      MixedPanelEvent.dataCatalogProfileClicked({
        type: 'field',
        assetName: data.field_name
      });
    }
  }, [data]);

  return (
    <Container ref={ref}>
      <ConditionComponent condition={!loading}>
        <Header data={data} />
        <ContentBox className="scroll-container">
          <Description />
          <UsageTables
            users={data?.all_users}
            joins={data?.joins}
            assetId={data.field_id}
            assetType={'field'}
          />
          <RecentQueries text={`${data?.table_name}.${data?.field_name}`} />
        </ContentBox>
      </ConditionComponent>
    </Container>
  );
};

export const FieldProfile = () => {
  return (
    <FieldProfileProvider>
      <Component />
    </FieldProfileProvider>
  );
};
