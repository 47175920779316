import isArray from 'lodash/isArray';
import unionBy from 'lodash/unionBy';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../store';
import { sx } from '../../styles/MUI/styles';
import FolderModal from '../modals/FolderModal';
import DeleteModal from '../modals/DeleteModal';
import React, {
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useTransition
} from 'react';
import AddEditor from '../../pages/Editor/AddEditor';
import CopyToClipboard from 'react-copy-to-clipboard';
import FolderPublicIcon from '../Icons/FolderPublicIcon';
import { CustomTooltip } from '../../atoms/CustomTooltip';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { getFolderFullPath } from '../../lib/utils/folderPath';
import { FolderSummary } from '../../store/folderSummary/type';
import { useUserInfoSelector } from '../../store/auth/selectors';
import { Link, Folder, FolderSharedRounded } from '@mui/icons-material';
import { shortenString } from '../../pages/Editor/helpers/helpers';
import { queryFoldersThunks } from '../../store/queryFolder/thunk';
import { MoreVertOutlined, ShareOutlined, Search } from '@mui/icons-material';
import { queriesAsyncThunk } from '../../store/queries/queries.thunk';
import QueryFolderContentPage from '../../pages/QueryFolderContentPage';
import { Add, EventNoteRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';
import { newQueryFolderContentThunks } from '../../store/newFolderContent/thunk';
import { useNewFolderContentState } from '../../store/newFolderContent/selectors';
import {
  Menu,
  Box,
  Accordion,
  AccordionSummary,
  Typography
} from '@mui/material';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { debounce } from 'lodash';
import { AlertModal } from '../modals/AlertModal';
import { ConditionComponent } from '../../lib/utils';
import {
  AccordionBody,
  ContentMetaStateBox,
  MenuOptionItem
} from './components/styled';
import { Logger } from '../../utils/browser/logger';
import { LoadingState } from '../../utils/types/general';
import { SearchActions } from '../../store/searchAndFilter';
import { useSearchAndFilterState } from '../../store/searchAndFilter/selector';
import { useToggleSearchFiltersModal } from '../../context/hooks';

interface Props {
  isSubFolder: boolean;
  folder: FolderSummary;
  queryType?: string;
  accordionId?: string;
  level?: number;
}

const MAX_DESC_LENGTH = 60;
const openFolderSvg = (darkMode: boolean) => <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill={darkMode ? "white" : 'rgba(0,0,0,0.8)'} d="M19 20H4a2 2 0 0 1-2-2V6c0-1.11.89-2 2-2h6l2 2h7a2 2 0 0 1 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5"/></svg>

function findFolderByUid(foldersArray, targetUid) {
  for (const folder of foldersArray) {
      // Check if the current folder is the target
      if (folder.uid === targetUid) {
          return folder;
      }

      // If not, recursively search in sub_folders
      if (folder.sub_folders && folder.sub_folders.length > 0) {
          const result = findFolderByUid(folder.sub_folders, targetUid);
          if (result) {
              return result;
          }
      }
  }

  // Return null if the folder with the target uid is not found
  return null;
}


const foldersContainText = (folderList, filterText) => {
  if (!folderList) return true
  if (folderList.filter(folder => folder != null && (folder?.name ?? '').toLowerCase().includes((filterText ?? '').toLowerCase())).length > 0) {
    return true
  } else {
    let flag = false
    folderList.forEach(folder => {
      if (folder != null) {
        flag = flag || foldersContainText(folder.sub_folders ?? [], filterText)
      }
    })
    return flag
  }
}

const QueryFolderListItem = React.forwardRef(
  ({ folder, isSubFolder, accordionId, level = 0, queryType }: Props, ref) => {
    const dispatch = useAppDispatch();
    const userInfo = useUserInfoSelector();
    const accordionRef = useRef<HTMLDivElement>(null);
    const searchAndFilters = useSearchAndFilterState();
    const toggleSearchFilters = useToggleSearchFiltersModal()!;
    const [folderType, setFolderType] = useState(folder.shared_type);
    const folderContents = useNewFolderContentState(folder.uid);
    const [folderName, setFolderName] = React.useState(folder.name);
    const [currentSelectedId, setCurrentSelectedId] = React.useState('');
    const [isFolderEditor, setIsFolderEditor] = useState<Boolean>(false);
    const [folderParentId, setFolderParentId] = React.useState(folder.uid);
    const [deleteFolderModal, setDeleteFolderModal] = React.useState(false);
    const [folderActionIcons, setFolderActionIcons] = React.useState(false);
    const [singleFolderModal, setSingleFolderModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showAddFolderModal, setShowAddFolderModal] = React.useState(false);
    const [showEditFolderModal, setShowEditFolderModal] = React.useState(false);
    const [showShareFolderModal, setShowShareFolderModal] = React.useState(false);
    const [folderDescription, setFolderDescription] = React.useState(
      folder.description
    );
    const darkMode = useDarkModeState();

    const [isPending, startTransition] = useTransition();
    const [data, setData] = useState([]);

    const [members, setMembers] = useState<string[]>(
      Array.isArray(folder?.members) && folder?.members.length > 0
        ? folder?.members.map(obj => obj.email)
        : []
    );

    const folderSummary = useFolderSummarySelector();
    const folderSummaryShared = useSharedFoldersSelector();
    const folders = unionBy(
      folderSummary?.list,
      folderSummaryShared?.list,
      'uid'
    );

    const canRender = useMemo(() => {
      const folderInSummary = findFolderByUid(folderSummary.list, folder.uid) || findFolderByUid(folderSummaryShared.list, folder.uid)
      return (
        foldersContainText([folderInSummary], folderSummary?.filterText ?? '') &&
        (isSubFolder ||
        (userInfo?.email &&
          folder?.user_email &&
          folder?.user_email.split('@')[1] === userInfo?.email.split('@')[1]))
      );
    }, [isSubFolder, userInfo, folder, folderSummary?.filterText]);
    const isFolderOwner = folder.user_id === userInfo?.uid;
    const folderIsNotDefault = folder.name.toLowerCase() !== 'default';

    const sx1 = {
      fontSize: 18,
      color: darkMode ? 'white' : '#252631',
      '&:hover': { color: '#7209B7' }
    };
    const sx2 = {
      fontSize: 12,
      color: darkMode ? 'white' : '#252631',
      '&:hover': { color: '#7209B7' }
    };
    const [accordionStates, setAccordionStates] = React.useState<
      Record<string, boolean>
    >({});

    const handleCloseAddFolderModal = () => {
      setShowAddFolderModal(false);
      setFolderName('');
      setFolderParentId('');
    };
    const handleShowEditFolderModal = () => {
      setFolderName(folder.name);
      setFolderDescription(folder.description!);
      setShowEditFolderModal(true);
    };
    const handleShowShareFolderModal = () => {
      if (folder.user_email === userInfo.email) {
        setFolderName(folder.name);
        setFolderDescription(folder.description!);
        setShowShareFolderModal(true);
      } else {
        toast('Only the folder owner can share it with others.', { type: 'error'} )
      }

    };
    const handleSearchInFolder = () => {
      const localFilter = { ...searchAndFilters };
      localFilter.in_folder = folder.uid;
      dispatch(SearchActions.setFilters(localFilter));
      
      setTimeout(() => {
        toggleSearchFilters();

      }, 100)
      setTimeout(() => {
        const input = document.getElementById('searchString1');
        if (input) {
          input.focus();
        }

      }, 200)
      MixedPanelEvent.searchFromFolder()
    };
    const handleCloseShareFolderModal = () => {
      setShowShareFolderModal(false);
      setFolderName('');
      setFolderParentId('');
    };
    const handleShowAddFolderModal = () => {
      setFolderName('');
      setFolderDescription('');
      setFolderParentId(folder.uid);
      setShowAddFolderModal(true);
    };
    const handleSubmitEditFolder = async () => {
      if (!folderName) {
        toast('Folder name is required', { type: 'error' });
        return;
      }

      const toastId = toast('Share folder', { isLoading: true });

      dispatch(
        queriesAsyncThunk.updateFolder({
          uid: folder.uid,
          payload: {
            name: folderName!,
            description: folderDescription,
            is_shared: isSubFolder
              ? false
              : folderType === 'personal'
              ? false
              : true,
            parent_id: folderParentId,
            members:
              folderType === 'shared'
                ? !members?.includes(userInfo?.email as string)
                  ? [...members, userInfo?.email as string]
                  : members
                : []
          }
        })
      ).finally(() => {
        setTimeout(() => toast.dismiss(toastId), 500);
      });

      setShowEditFolderModal(false);
      setShowShareFolderModal(false);
    };
    const handleSubmitAddFolder = async (isSub: boolean) => {
      if (!folderName) {
        toast('Folder name is required', { type: 'error' });
        return;
      }
      if (folderName.toLowerCase() === 'default') {
        toast(`Folder name cannot be ${folderName}`, { type: 'error' });
        return;
      }
      toast('Creating folder', { isLoading: true });
      const payload = {
        parent_id: isSub ? folderParentId : '',
        name: folderName!,
        description: folderDescription,
        is_shared: false,
        members: []
      };
      dispatch(
        queryFoldersThunks.createQueryFolder({
          ...payload,
          withAnalytics: true,
          withToast: true
        })
      )
        .then(() => {
          debounce(() => toast.dismiss(), 1000)();

          setTimeout(() => {
            setFolderName('');
            setFolderDescription('');
            setFolderParentId(folder.uid);
            handleCloseAddFolderModal();
          }, 2000)
          dispatch(newQueryFolderContentThunks.refetch({ uid: folder.uid! }));
        })
        .catch(e => {
          toast.dismiss();
          toast('Failed to create folder', { type: 'error' });
        });
    };
    const closeDeleteFolderModal = () => {
      setDeleteFolderModal(false);
    };
    const handleDeleteFolder = async () => {
      if (folder.uid) {
        toast(`Deleting ${folder.name}`, { isLoading: true });
        MixedPanelEvent.queryFolderAction({
          actionName: 'deleted',
          folderName: folder.name,
          folderUid: folder.uid,
          folderDescription: folder.description,
          sharedWith: folder?.members.map(obj => obj.email)
        });
        await dispatch(
          queriesAsyncThunk.removeQueryFolder(folder.uid!)
        ).unwrap();
        debounce(() => toast.dismiss(), 1000)(); // to delay the unmounting of toast
      }
      closeDeleteFolderModal();
    };
    function handleChange(isExpanded: boolean, accordionId: string) {
      setAccordionStates({ ...accordionStates, [accordionId]: isExpanded });
      setCurrentSelectedId(accordionId);
      if (isExpanded) {
        let fPath = JSON.parse(sessionStorage.getItem('fpath'));
        if (isArray(fPath)) {
          if (!fPath.includes(accordionId)) {
            sessionStorage.setItem(
              'fpath',
              JSON.stringify(getFolderFullPath(accordionId, folders, []))
            );
          }
        } else {
          sessionStorage.setItem(
            'fpath',
            JSON.stringify(getFolderFullPath(accordionId, folders, []))
          );
        }
        setCurrentSelectedId(accordionId);
      } else {
        if (JSON.parse(sessionStorage.getItem('fpath'))) {
          let fPath = JSON.parse(sessionStorage.getItem('fpath'));
          sessionStorage.setItem(
            'fpath',
            JSON.stringify(
              fPath.filter((v: string) => !v.includes(accordionId))
            )
          );
        }
        setIsFolderEditor(false);
      }
    }

    useImperativeHandle(ref, () => {
      return {
        openAccordion: handleChange
      };
    });

    const setHoveredItems = (state: boolean) => {
      setFolderActionIcons(state);
    };
    const handleShowDeleteModal = () => {
      if (folderSummary?.list.length === 1) {
        setSingleFolderModal(true);
      } else {
        setDeleteFolderModal(true);
      }
    };
    const handleCloseFolderModal = () => {
      setShowAddFolderModal(false);
      setShowEditFolderModal(false);
      setFolderType(folder.shared_type);
      setFolderName(folder.name);
      setFolderDescription(folder.description);
      setMembers(
        Array.isArray(folder?.members) && folder?.members.length > 0
          ? folder?.members.map(obj => obj.email)
          : []
      );
    };
    const getTooltipTitle = () => {
      if (isSubFolder) return folder.name;
      if (folder.shared_type === 'personal')
        return (
          <>
            <Typography variant="caption">{folder.name}</Typography>
            <br />
            <Typography variant="caption">
              Created by {folder.user_first_name} {folder.user_last_name}
            </Typography>
          </>
        );
      if (folder.shared_type === 'public')
        return (
          <>
            <Typography variant="caption">{folder.name}</Typography>
            <br />
            <Typography variant="caption">
              Created by {folder.user_first_name} {folder.user_last_name}
            </Typography>
          </>
        );
      if (folder.shared_type === 'shared')
        return (
          <Typography variant="caption">
            {folder.name}
            <br />
            {`Created by:`}
            <br />
            {`\xa0 ${folder.user_first_name} ${folder.user_last_name}`}
            <br />
            {`Members:`}
            <br />
            {folder?.members?.length > 0 ? (
              folder?.members.map(m => (
                <Typography key={m.email} variant="caption">
                  {`\xa0 ` + m.email}
                  <br />
                </Typography>
              ))
            ) : (
              <></>
            )}
          </Typography>
        );

      return ``;
    };

    const handleOnClickAccordionSummary: MouseEventHandler<
      HTMLDivElement
    > = () => {
      MixedPanelEvent.clickOnFolderListItem(folder.name);
      const isOpened = accordionStates[accordionId];
      if (!isOpened) {
        startTransition(() => {
          handleChange(!accordionStates[accordionId!], accordionId!);
        });
      } else {
        handleChange(!accordionStates[accordionId!], accordionId!);
      }
    };

    const handleOnChangeAccordionExpandedState = () => {
      const isOpened = accordionStates[accordionId];
      if (!isOpened) {
        const accordionFirstTimeLoad = !folderContents;
        if (accordionFirstTimeLoad) {
          dispatch(newQueryFolderContentThunks.index({ uid: folder.uid! }));
        } else {
          dispatch(newQueryFolderContentThunks.refetch({ uid: folder.uid! }));
        }
      }
    };

    const handleOnClickAddSubFolder = (event: React.MouseEvent) => {
      setAnchorEl(null);
      handleShowAddFolderModal();
      event.stopPropagation();
    };

    const handleOnClickEditFolder = (event: React.MouseEvent) => {
      setAnchorEl(null);
      handleShowEditFolderModal();
      event.stopPropagation();
    };

    const handleOnClickDeleteFolder = (event: React.MouseEvent) => {
      setAnchorEl(null);
      handleShowDeleteModal();
      event.stopPropagation();
    };

    const handleOpenMenu = (e: React.MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget as any);
    };

    const handleOnCopy = () => {
      MixedPanelEvent.shareFolderClicked(folderName, folder.uid);
      toast('Query successfully copied to clipboard ', {
        type: 'success'
      });
    };

    const handleOnCopyFolder = () => {
      MixedPanelEvent.shareFolderClicked(folderName, folder.uid);
      toast('Folder successfully copied to clipboard ', {
        type: 'success'
      });
    };

    useEffect(() => {
      let folderPath = JSON.parse(sessionStorage.getItem('fpath'));
      if (folderPath && folderPath.includes(accordionId)) {
        handleChange(true, accordionId);
      }
      dispatch(newQueryFolderContentThunks.index({ uid: folder.uid }));
    }, []);

    const isFolderExpanded = (JSON.parse(sessionStorage.getItem('fpath')) &&
      JSON.parse(sessionStorage.getItem('fpath')).includes(
        accordionId
      )) ||
    isPending

    if (!canRender) {
      return null;
    }

    return (
      <Box className="query-list-items">
        <Accordion
          disableGutters={true}
          style={{
            boxShadow: 'none',
            backgroundImage: 'none',
            flex: 1
          }}
          key={accordionId}
          expanded={isFolderExpanded
            }
          onChange={handleOnChangeAccordionExpandedState}
        >
          <AccordionSummary
            onMouseOver={() => setHoveredItems(true)}
            onMouseOut={() => setHoveredItems(false)}
            className="accordion-summary"
            expandIcon={
              <ExpandMoreRounded sx={darkMode ? sx.sx0dark : sx.sx0} />
            }
            onClick={handleOnClickAccordionSummary}
            ref={accordionRef}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className="folder-item">
              <CustomTooltip title={getTooltipTitle()} placement="right-end">
                <Box className="folder-icon-and-title">
                  {queryType === 'history' ? (
                    <EventNoteRounded sx={darkMode ? sx.sxFolderDark : sx.sxFolder} />
                  ) : folder.shared_type === 'personal' ? (
                    isFolderExpanded ? openFolderSvg(darkMode) : <Folder sx={darkMode ? sx.sxFolderDark : sx.sxFolder} />
                  ) : folder.shared_type === 'shared' ? (
                    isFolderExpanded ? openFolderSvg(darkMode) : <FolderSharedRounded sx={darkMode ? sx.sxFolderDark : sx.sxFolder} />
                  ) : folder.shared_type === 'public' ? (
                    isFolderExpanded ? openFolderSvg(darkMode) : <FolderPublicIcon size="24px" darkMode={darkMode}/>
                  ) : (
                    <></>
                  )}
                  <Typography
                    sx={{ ':hover': { color: 'primary.main' } }}
                    variant="body2"
                  >
                    {shortenString(folder.name, 60)}
                  </Typography>
                </Box>
              </CustomTooltip>
              {folderActionIcons && (
                <Box className="folder-actions">
                  <CustomTooltip title="Add query" placement="top">
                    <Add
                      sx={sx1}
                      onClick={e => {
                        if (isFolderEditor) {
                          setIsFolderEditor(false);
                          e.stopPropagation();
                        } else {
                          setIsFolderEditor(true);
                          if (accordionStates[accordionId!])
                            e.stopPropagation();
                        }
                      }}
                    />
                  </CustomTooltip>
                  <Box onClick={e => e.stopPropagation()}>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_SHERLOQ_AUTH_URL}/folderInfo/?folderId=${folder?.uid}`}
                      onCopy={handleOnCopyFolder}
                    >
                      <CustomTooltip title="Copy folder link" placement="top">
                        <Link sx={sx1} style={{ marginTop: 8 }}/>
                      </CustomTooltip>
                    </CopyToClipboard>
                  </Box>
                  <Box onClick={handleShowShareFolderModal}>
                    {/* <CopyToClipboard
                      text={`${process.env.REACT_APP_SHERLOQ_AUTH_URL}/folderInfo/?folderId=${folder?.uid}`}
                      onCopy={handleOnCopy}
                    > */}
                      <CustomTooltip title="Share folder" placement="top">
                        <ShareOutlined sx={sx1} style={{ marginTop: 8 }} />
                      </CustomTooltip>
                    {/* </CopyToClipboard> */}
                  </Box>
                  {/* <Box onClick={handleSearchInFolder}>
                      <CustomTooltip title="Search in folder" placement="top">
                        <Search sx={sx1} style={{ marginTop: 8 }} />
                      </CustomTooltip>
                  </Box> */}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      'aria-labelledby': 'folder-menu-opt'
                    }}
                  >
                    <ConditionComponent condition={level < 2}>
                      <MenuOptionItem onClick={handleOnClickAddSubFolder}>
                        Add sub folder
                      </MenuOptionItem>
                    </ConditionComponent>
                    <ConditionComponent condition={isFolderOwner}>
                      <MenuOptionItem onClick={handleOnClickEditFolder}>
                        Edit folder
                      </MenuOptionItem>
                    </ConditionComponent>
                    <ConditionComponent condition={isFolderOwner}>
                      <MenuOptionItem onClick={handleOnClickDeleteFolder}>
                        Delete folder
                      </MenuOptionItem>
                    </ConditionComponent>
                  </Menu>
                  <ConditionComponent condition={folderIsNotDefault}>
                    <CustomTooltip title="More actions" placement="top">
                      <MoreVertOutlined
                        id="folder-menu-opt"
                        aria-controls={
                          Boolean(anchorEl) ? 'basic-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                        sx={sx1}
                        onClick={handleOpenMenu}
                      />
                    </CustomTooltip>
                  </ConditionComponent>
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionBody>
            <Box>
            {folder.description && <CustomTooltip
                title={
                  folder.description &&
                  folder.description.length > MAX_DESC_LENGTH
                    ? folder.description
                    : null
                }
                placement="top"
              >
                <Typography className="folder-description" variant="caption">
                  {folder.description}
                </Typography>
              </CustomTooltip> }
              <ConditionComponent condition={!!isFolderEditor}>
                <Box className="add-editor-res-wrap-box">
                  <AddEditor
                    defFolderName={folder.name}
                    defFolderID={folder.uid}
                    isFromFolder={true}
                    setIsFolderEditor={setIsFolderEditor}
                  />
                </Box>
              </ConditionComponent>
              <Box className="list-contents">
                <ConditionComponent condition={isPending}>
                  <ContentMetaStateBox>
                    <Typography variant="caption">Loading</Typography>
                  </ContentMetaStateBox>
                </ConditionComponent>
                <ConditionComponent
                  condition={
                    !isPending &&
                    !!folderContents &&
                    currentSelectedId === accordionId
                  }
                >
                  <QueryFolderContentPage
                    folderId={folder.uid}
                    queryType={queryType!}
                    folderName={folder.name}
                    data={data}
                    isFolderEditor={isFolderEditor}
                    setIsFolderEditor={setIsFolderEditor}
                  />
                </ConditionComponent>
              </Box>
            </Box>
          </AccordionBody>
        </Accordion>
        <FolderModal
          type="add"
          title="Add folder"
          folders={folders}
          show={showAddFolderModal}
          name={folderName}
          setName={setFolderName}
          description={folderDescription}
          setDescription={setFolderDescription}
          folderType={folderType}
          setFolderType={setFolderType}
          members={members}
          setMembers={setMembers}
          setClose={handleCloseFolderModal}
          SaveFolder={handleSubmitAddFolder}
          isSubFolder={true}
          parentName={folder.name}
          setParentId={setFolderParentId}
          selectedFolder={folderParentId}
          key={`${showAddFolderModal}`}
        />
        <FolderModal
          type="edit"
          title="Edit Folder"
          folders={folders}
          show={showEditFolderModal}
          name={folderName}
          setName={setFolderName}
          description={folderDescription}
          setDescription={setFolderDescription}
          folderType={folderType}
          setFolderType={setFolderType}
          members={members}
          setMembers={setMembers}
          setClose={handleCloseFolderModal}
          SaveFolder={handleSubmitEditFolder}
          isSubFolder={isSubFolder}
          parentName={''}
          setParentId={setFolderParentId}
        />
        <FolderModal
          type="share"
          title={`Share Folder "${folderName}"`}
          folders={folders}
          show={showShareFolderModal}
          name={folderName}
          setName={setFolderName}
          description={folderDescription}
          setDescription={setFolderDescription}
          folderType={folderType}
          setFolderType={setFolderType}
          members={members}
          setMembers={setMembers}
          setClose={handleCloseShareFolderModal}
          SaveFolder={handleSubmitEditFolder}
          isSubFolder={isSubFolder}
          parentName={''}
          setParentId={setFolderParentId}
        />
        <DeleteModal
          show={deleteFolderModal}
          title="Delete folder?"
          subTitle="Are you sure you want to delete this folder? &#10;Deleting this folder will also delete all of it's content."
          setClose={() => setDeleteFolderModal(false)}
          handleDelete={handleDeleteFolder}
        />
        <AlertModal
          title="You have only 1 folder..."
          description="You can’t delete this folder since you must have at least one folder in  &#10;Sherloq."
          open={singleFolderModal}
          onClose={() => setSingleFolderModal(false)}
          variant="error"
        />
      </Box>
    );
  }
);
export default QueryFolderListItem;
