import React from 'react';
import { styled, Box, PaperProps, Paper, IconButton } from '@mui/material';
export const Container = styled(Box)(() => {
  return {
    padding: '5px 0px',
    width: '100%',
    '@media(max-width: 300px)': {
      display: 'none'
    }
  };
});

type FormProps = Omit<PaperProps<'form'>, 'component'>;
export const Form = styled((props: FormProps) => (
  <Paper component={'form'} {...props} />
))(() => {
  return {
    p: '4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    height: '30px'
  };
});

export const ExtensionIcon = styled(IconButton)(() => {
  return {
    '@media(max-width: 300px)': {
      display: 'none'
    }
  };
});
