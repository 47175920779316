const queriedByListContainerStyle: React.CSSProperties = {
  display: 'flex',
  gap: '8px',
  width: '100%',
  flexWrap: 'wrap'
};

const folderFilterButtonStyles = {
  width: '402px',
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid #00000030'
};

const folderFilterTextStyles = {
  fontSize: '12px'
};

export {
  queriedByListContainerStyle,
  folderFilterButtonStyles,
  folderFilterTextStyles
};
