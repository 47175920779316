import { useMemo } from 'react';
import { useTeamMembersSelector } from '../../../store/teamMembers/selectors';
import { User } from '../../../store/auth/types';
import { TeamMember } from '../../../store/teamMembers/type';

interface IUseTeamMemberOptionsPayload {
  user: User;
}

function transformCompanyMembers(teamMembers: TeamMember[], id: string) {
  const restOfCompany = teamMembers.filter(usr => usr.uid !== id);
  return restOfCompany.map(({ first_name, last_name, uid }) => {
    const name = `${first_name} ${last_name}`;
    return {
      label: name,
      value: uid
    };
  });
}

export const useTeamMemberOptions = (user: User) => {
  const { list: teamMembers } = useTeamMembersSelector();

  const teamMemberOptions = useMemo(() => {
    if (!user || !teamMembers) return [];
    const { first_name, last_name, uid } = user;
    const username = `${first_name} ${last_name}`;
    const transformedUser = {
      label: username,
      value: uid
    };
    const anyone = { label: 'Anyone', value: 'anyone' };
    const companyMembers = transformCompanyMembers(teamMembers, user.uid);
    return [transformedUser, anyone, ...companyMembers];
  }, [teamMembers, user]);

  return { options: teamMemberOptions, data: teamMembers };
};
