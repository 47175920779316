import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { indexOf } from 'lodash';
import { LoadingState } from '../../utils/types/general';
import { QueryHistorySummaryThunk } from './thunk';
import { QueryHistorySummary, QueryHistorySummaryState } from './type';

const initialState: QueryHistorySummaryState = {
    list: [],
    loading: LoadingState.IDLE,
};

const index = createSlice({
    name: 'Query_History_summary',
    initialState,
    reducers: {
        addHistoryToSummary: (state, { payload }: PA<QueryHistorySummary>) => {
            state.list.push(payload);
        },
        removeHistoryFromSummary: (state, { payload }: PA<string>) => {},
    },

    extraReducers: {
        [QueryHistorySummaryThunk.index.pending.type]: (state) => {
            state.loading = LoadingState.PENDING;
            state.list = [];
        },
        [QueryHistorySummaryThunk.index.fulfilled.type]: (
            state,
            { payload }: PA<QueryHistorySummary[]>,
        ) => {
            state.loading = LoadingState.FULFILLED;
            state.list = payload;
        },
        [QueryHistorySummaryThunk.index.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
            state.list = [];
        },
    },
});

export const { addHistoryToSummary, removeHistoryFromSummary } = index.actions;

export default index.reducer;
