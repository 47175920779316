import axios, { Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Platforms from '../../../utils/platform';
import { logout } from '../browser';

export interface ApiClientConfig<T = any> extends AxiosRequestConfig<T> {
    isProtected?: boolean;
}

const onFulfilInterceptor = (value: AxiosResponse<any>) => {
    return value;
};
const onErrorInterceptor = (error: AxiosError) => {
    if (error.response) {
        // const { status } = error.response;
        // if (status === 401) {
        //     logout();
        // }
        throw error;
    }
};
export default class ApiClient {
    /* A private static variable that is being used to store the instance of the axios client. */
    private static _instance: Axios;
    /**
     * > If the token is not set, then set it to the value of the `getAuthToken` function
     * @returns The token is being returned.
     */
    private static _getToken() {
        return localStorage.getItem('sherloq-auth-token');
    }
    /**
     * It creates an instance of axios with the baseURL set to the API_URL environment variable.
     */
    private static _initialize() {
        this._instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
        });
        // this._instance.interceptors.response.use(responseFn, responseErrorFn);
        // this._instance.interceptors.request.use(requestFn);
    }

    /**
     * If the instance is not yet initialized, initialize it
     * @returns The instance of the class.
     */
    public static get instance() {
        if (!this._instance) {
            this._initialize();
        }
        this._instance.interceptors.response.use(onFulfilInterceptor, onErrorInterceptor);
        return this._instance;
    }

    /**
     * It takes in a config object, and returns a new config object
     * @param [config] - This is the configuration object that you pass to the API client.
     * @returns The config object is being returned.
     */
    private static async getConfig<T = any>(config?: ApiClientConfig<T>) {
        let newConfig: ApiClientConfig = { headers: {} };

        if (config) {
            const { isProtected } = config;

            newConfig = config;
            if (isProtected && this._getToken !== null) {
                const token = await this._getToken();

                const Authorization = `bearer ${token}`;
                newConfig = { ...config };
                newConfig.headers = {
                    Authorization,
                    'x-request-origin': Platforms.platform,
                };
            }
        }
        newConfig = { ...newConfig };
        delete newConfig.isProtected;
        return newConfig as AxiosRequestConfig;
    }

    /**
     * A generic function that returns a promise of type T.
     * @param {string} url - The url to make the request to.
     * @param {ApiClientConfig} [config] - This is the configuration object that will be passed to the
     * axios instance.
     * @returns The return type is a Promise.
     */
    public static async get<T = any>(url: string, config?: ApiClientConfig) {
        const newConfig = await this.getConfig(config);
        return this.instance.get<T>(url, newConfig);
    }

    /**
     * A generic function that makes a POST request to the given url with the given body and config.
     * @param {string} url - The url to make the request to.
     * @param {D} body - The data you want to send to the server.
     * @param {ApiClientConfig} config - ApiClientConfig - This is the configuration object that we will
     * use to configure the request.
     * @returns A promise that resolves to an AxiosResponse<T>
     */
    public static async post<T = any, D = any>(url: string, body: D, config?: ApiClientConfig, abortSignal?: AbortSignal) {
        const newConfig = { ...await this.getConfig(config), signal: abortSignal};
        return this.instance.post<T, AxiosResponse<T>, D>(url, body, newConfig);
    }
    /**
     * A generic function that takes a url, body, and config. It then returns an axios put request.
     * @param {string} url - The url to make the request to.
     * @param {D} body - The data to be sent to the server.
     * @param {ApiClientConfig} config - ApiClientConfig - This is the configuration object that we will
     * use to configure the request.
     * @returns A promise that resolves to the response from the server.
     */
    public static async put<T = any, D = any>(url: string, body: D, config?: ApiClientConfig) {
        const newConfig = await this.getConfig(config);
        return this.instance.put<T, AxiosResponse<T>, D>(url, body, newConfig);
    }
    /**
     * "This function returns a promise that resolves to a response object of type T, and it takes a URL, a
     * body, and a config object as parameters."
     *
     * The first parameter is a URL string. The second parameter is a body object of type D. The third
     * parameter is a config object of type ApiClientConfig
     * @param {string} url - The url to make the request to.
     * @param {D} body - The data to be sent to the server.
     * @param {ApiClientConfig} config - ApiClientConfig - This is the configuration object that you can
     * pass to the method.
     * @returns A promise that resolves to an AxiosResponse<T>
     */
    public static async path<T = any, D = any>(url: string, body: D, config?: ApiClientConfig) {
        const newConfig = await this.getConfig(config);
        return this.instance.patch<T, AxiosResponse<T>, D>(url, body, newConfig);
    }
    /**
     * "This function returns a promise that resolves to the response body of a DELETE request to the given
     * URL."
     *
     * The first line of the function is a TypeScript type annotation. It's not required, but it's a good
     * idea to include it. It tells TypeScript that the function returns a promise that resolves to a value
     * of type T
     * @param {string} url - The url to make the request to.
     * @param {ApiClientConfig} [config] - This is an optional parameter that allows you to override the
     * default configuration.
     * @returns A Promise
     */
    public static async delete<T = any>(url: string, config?: ApiClientConfig) {
        const newConfig = await this.getConfig(config);
        return this.instance.delete<T>(url, newConfig);
    }

    public static getErrorMessageFromError(error: AxiosError | Error) {
        if (error instanceof AxiosError) {
            return error.response?.data.data;
        }
        return error.message;
    }
}
