import { DataCatalogProvider } from './context/DataCatalogProvider';
import DataCatalogUI from './ui';

const DataCatalog = () => {
  return (
    <DataCatalogProvider>
      <DataCatalogUI />
    </DataCatalogProvider>
  );
};

export default DataCatalog;
