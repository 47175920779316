import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';

const initialState: any = {
    origin: 'web'
};

const index = createSlice({
    name: 'User_Origin',
    initialState,
    reducers: {
        setOrigin: (state, {payload}: PA<string>) => {
            state.origin = payload;
        }
    },
});

export const { setOrigin } = index.actions;

export default index.reducer;
