import { useMemo } from 'react';
import { useUserOriginState } from '../store/userOrigin/selectors';

export const useEnvs = () => {
  const userOrigin = useUserOriginState();
  const isWebApp = useMemo(() => {
    try {
      return Boolean(window.parent.origin === window.location.origin);
    } catch (error) {
      return false;
    }
  }, []);
  const isDataGrip = userOrigin && userOrigin === 'datagrip';

  return {
    isExtension: !(isDataGrip || isWebApp)
  };
};
