import { unionBy } from 'lodash';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';

/**
 *
 * @constructs A folder tree with shared and private folders
 */
export const useFolderTree = () => {
    const privateFolders = useFolderSummarySelector();
    const sharedFolders = useSharedFoldersSelector();
    const folders = unionBy(privateFolders.list, sharedFolders.list, 'uid');

    return folders;
};
