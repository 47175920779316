import React, { Fragment, useMemo } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import { BookmarkAddOutlined, ContentCopyRounded } from '@mui/icons-material';
import { RenderIf } from '../../../../lib/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { toast } from 'react-toastify';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ShwoMoreButton } from './showMoreButton';
import { IWatsonDefaultMessage, IWatsonMessage } from '../../interfaces';

interface Props extends CodeProps {
  onSave?: (...args: any[]) => void;
  expandState: [boolean, Function];
  data: IWatsonDefaultMessage & { conversation_uid: string };
}
/**
 *
 * @summary avoid internal state management since a state change remounts the component. manage state in the parent response component.
 * use optimization techniques since there are lots of string manipulations in the component
 */
export const ResponseCodeBlock = ({
  node,
  inline,
  className,
  children,
  onCopy,
  onSave,
  expandState,
  data,
  ...props
}: Props) => {
  const [expand, setExpand] = expandState;
  const parsed = useMemo(() => {
    return /language-(\w+)/.exec(className || '');
  }, [className]);
  const lang = parsed ? parsed[1] : '';
  const isSql = parsed && parsed[1] === 'sql';
  const isBash = parsed && parsed[1] === 'bash';
  const isCode = !inline && !!lang;
  const isMoreThan15lines = useMemo(() => {
    return String(children).split('\n').length > 15;
  }, [children]);

  const extraStyles = useMemo(() => {
    if (expand)
      return {
        maxHeight: '100vh',
        overflowY: 'auto'
      };
    return {
      maxHeight: '200px',
      overflowY: 'hidden'
    };
  }, [expand]);

  const showMore = (isSql || isBash) && isCode && isMoreThan15lines;
  const handleOnSave = () => {
    onSave(String(children).replace(/\n$/, ''));
  };
  const handleOnCopy = () => {
    toast('Snippet successfully copied to clipboard ', {
      type: 'success'
    });
    MixedPanelEvent.watsonCopyCode({
      message: String(children).replace(/\n$/, ''),
      conversation_uid: data.conversation_uid,
      chat_type: 'none'
    });
  };

  const handleToggleContentButton = () => {
    setExpand(p => !p);
    if (!expand) {
      MixedPanelEvent.watsonShowMoreResponseClicked({
        prompt_type: data.promptType,
        question: data.regenerateQuestion
      });
    }
  };

  if (!isCode) return <code>{children}</code>;

  return (
    <Fragment>
      {RenderIf(
        isSql,
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            width: '100%'
          }}
        >
          <CustomTooltip title="Save to Sherloq" placement="top">
            <BookmarkAddOutlined
              onClick={handleOnSave}
              style={{ fontSize: '20px', cursor: 'pointer' }}
            />
          </CustomTooltip>
          <CopyToClipboard
            text={String(children).replace(/\n$/, '')}
            onCopy={handleOnCopy}
          >
            <CustomTooltip title="Copy snippet" placement="top">
              <ContentCopyRounded
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </CustomTooltip>
          </CopyToClipboard>
        </Box>
      )}
      <SyntaxHighlighter
        {...props}
        children={String(children).replace(/\n$/, '')}
        style={{ ...atomDark, ...extraStyles }}
        language={lang}
        PreTag="div"
        wrapLongLines={true}
        codeTagProps={{
          style: {
            maxWidth: '100%',
            fontSize: '11px',
            width: 'inherit',
            display: 'inline-block',
            overflowWrap: 'break-word',
            transition: 'max-height 2s ease',
            ...extraStyles
          }
        }}
      />
      {RenderIf(
        showMore,
        <ShwoMoreButton onClick={handleToggleContentButton}>
          {expand ? 'Show less' : 'Show more'}
        </ShwoMoreButton>
      )}
    </Fragment>
  );
};
