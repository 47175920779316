import { ReactNode } from 'react';
import { TableProfileContext, TableRecentQueriesContext } from './definition';
import { useParams } from 'react-router-dom';
import { useTableProfile } from '../hooks/useTableProfile';
import { useTableRecentQueries } from '../hooks/useTableRecentQueries';

interface Props {
  children: ReactNode | ReactNode[];
  text?: string;
}
export const TableProfileProvider = (props: Props) => {
  const { id } = useParams<Record<string, string>>();
  const tableProfile = useTableProfile(Number(id));

  return (
    <TableProfileContext.Provider value={tableProfile}>
      {props.children}
    </TableProfileContext.Provider>
  );
};

export const TableRecentQueriesProvider = (props: Props) => {
  const recentQueries = useTableRecentQueries(props.text);

  return (
    <TableRecentQueriesContext.Provider value={recentQueries}>
      {props.children}
    </TableRecentQueriesContext.Provider>
  );
};
