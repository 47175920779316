import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import SuspendedPublicRoute from './SuspendedPublicRoute';
import SuspendedProtectedPage from './SuspendedProtectedPage';
import SavedQueries from '../pages/QueryListPage/SavedQueries';
import Admin from '../pages/Admin/Admin';
import PublicFolderQueriesPage from '../pages/QueryListPage/PublicQueries';
import QueryHistory from '../pages/QueryListPage/QueryHistory';
import WordpressIframe from '../pages/WordpressIframe/WordpressIframe';
import NotFound from '../pages/NotFound';
import RepositoryTables from '../pages/RepositoryTables';
import { DynamicProtectedRoute } from './DynamicProtectedRoute';
import { useSimplyRoutingIntegration } from '../hooks/platform-integration/simpliIntegration';
import AiBot from '../Botson';
import DataCatalog from '../data-catalog';
import { TableProfile } from '../data-catalog/ui/Profiles/TableProfile';
import { FieldProfile } from '../data-catalog/ui/Profiles/FieldProfile';

const HomePagePublic = React.lazy(
  () => import('../pages/Homepage/HomepagePublic')
);
const Profile = React.lazy(() => import('../pages/Profile/Profile'));
const QueryPage = React.lazy(() => import('../pages/Editor/Editor'));
const SearchResults = React.lazy(() => import('../pages/SearchResults'));
const AllSearchResults = React.lazy(
  () => import('../pages/SearchResults/subPages/all')
);
const PublicSearchResults = React.lazy(
  () => import('../pages/SearchResults/subPages/public')
);
const SavedSearchResults = React.lazy(
  () => import('../pages/SearchResults/subPages/saved')
);
const HistorySearchResults = React.lazy(
  () => import('../pages/SearchResults/subPages/history')
);
const Settings = React.lazy(() => import('../pages/Settings/Settings'));

const Router = () => {
  const { authorize: authoriseSimplyRule } = useSimplyRoutingIntegration();
  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={<SuspendedPublicRoute element={<HomePagePublic />} />}
        />
        <Route
          path="/saved-queries"
          element={
            <SuspendedProtectedPage
              path="/saved-queries"
              element={<SavedQueries />}
            />
          }
        />
        {/* <Route
          path="/admin"
          element={
            <SuspendedProtectedPage
              path="/admin"
              element={<Admin />}
            />
          }
        /> */}
        <Route
          path="/public-queries"
          element={
            <SuspendedProtectedPage
              path="/public-queries"
              element={<PublicFolderQueriesPage />}
            />
          }
        />
        <Route
          path="/tables"
          element={
            <DynamicProtectedRoute
              rule={authoriseSimplyRule}
              element={<RepositoryTables />}
              suspended
              path="/tables"
            />
          }
        />
        <Route
          path="/query-history"
          element={
            <SuspendedProtectedPage
              path="/query-history"
              element={<QueryHistory />}
            />
          }
        />
        <Route
          path="/queries/:folderName/:id/:type/:folderId"
          element={
            <SuspendedProtectedPage
              path="/queries/:folder/:id/:type/:folderId"
              element={<QueryPage selectorType="saved" />}
            />
          }
        />
        <Route
          path="/queries/search/:folder/:id/:type/:folderId"
          element={
            <SuspendedProtectedPage
              path="/queries/search/:folder/:id/:type/:folderId"
              element={<QueryPage selectorType="search" />}
            />
          }
        />
        <Route
          path="/queries/hitory/public/:id"
          element={
            <SuspendedProtectedPage
              path="/queries/hitory/public/:id"
              element={<QueryPage selectorType="history" />}
            />
          }
        />
        <Route
          path="/queries/hitory/my/:id"
          element={
            <SuspendedProtectedPage
              path="/queries/hitory/my/:id"
              element={<QueryPage selectorType="my-history" />}
            />
          }
        />
        <Route
          path="/search"
          element={
            <SuspendedProtectedPage
              path="/search"
              element={<SearchResults />}
            />
          }
        >
          <Route path="/search/all" element={<AllSearchResults />} />
          <Route path="/search/saved" element={<SavedSearchResults />} />
          <Route path="/search/public" element={<PublicSearchResults />} />
          <Route path="/search/history" element={<HistorySearchResults />} />
        </Route>
        <Route
          path="/watson"
          element={
            <SuspendedProtectedPage path="/watson" element={<AiBot />} />
          }
        />
        <Route
          path="/profile"
          element={
            <SuspendedProtectedPage path="/profile" element={<Profile />} />
          }
        />
        <Route
          path="/settings"
          element={
            <SuspendedProtectedPage path="/settings" element={<Settings />} />
          }
        />
        <Route
          path="/data-management"
          element={
            <SuspendedProtectedPage
              path="/data-management"
              element={<DataCatalog />}
            />
          }
        />
        <Route
          path="/data-management/table-profile/:id"
          element={
            <SuspendedProtectedPage
              path="/data-management/table-profile/:id"
              element={<TableProfile />}
            />
          }
        />
        <Route
          path="/data-management/field-profile/:id"
          element={
            <SuspendedProtectedPage
              path="/data-management/field-profile/:id"
              element={<FieldProfile />}
            />
          }
        />

        <Route path="/wordpress_iframe" element={<WordpressIframe />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default Router;
