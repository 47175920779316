import React, { ReactNode } from 'react';

interface Props {
  factor: string;
  children: ReactNode | ReactNode[];
  style?: React.CSSProperties;
}

export const Minimize = ({ children, factor, style }: Props) => {
  return (
    <div
      style={{
        scale: factor,
        top: '3.5px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      {children}
    </div>
  );
};
