import { useTypedSelector } from '..';
import { QueryHistorySearchResult, QuerySearchResponse } from './type';

export const useNewQueriesSelector = () =>
    useTypedSelector(({ sherloqNewQueries }) => sherloqNewQueries);

export const useSingleSearchQuerySelector = (uid: string) =>
    useTypedSelector(({ sherloqNewQueries }) =>
        (sherloqNewQueries.list as QuerySearchResponse[]).find((item) => item?.query_uid === uid),
    );

export const useSingleSearchQueryHistorySelector = (uid: string) =>
    useTypedSelector(({ sherloqNewQueries }) =>
        (sherloqNewQueries.list as QueryHistorySearchResult[]).find((item) => item?.uid === uid),
    );
