import React, { useState } from 'react';
import {
  ToggleDockStatePositionContext,
  ToggleFrameVisibleContext,
  ToggleSearchPanelVisibleContext,
  UIStateContext,
  ToggleSearchModalContext,
  ToggleSaveQueryModalContext,
  SetFrameVisibleContext,
  ToggleSearchFiltersContext,
  GetSearchFiltersContext,
  RootPageLayoutPropsStateContext
} from './contexts';
import { IRootPageLayoutProps } from '../utils/types/page-layout';
export interface UIStateType {
  framePosition: 'left' | 'right';
  frameVisible: boolean;
  searchPanelVisible: boolean;
  searchModalOpen: boolean;
  saveQueryModalOpen: boolean;
  searchFiltersVisible: boolean;
}

interface Props {
  children: React.ReactNode;
}

const UIContextProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = React.useState<UIStateType>({
    framePosition: 'right',
    frameVisible: false,
    searchPanelVisible: false,
    searchModalOpen: false,
    saveQueryModalOpen: false,
    searchFiltersVisible: false
  });
  const [pageLayoutProps, setPageLayoutProps] = useState<IRootPageLayoutProps>(
    {}
  );
  const toggleFramePosition = (position?: 'left' | 'right') => {
    if (position) {
      return setState(state => ({ ...state, framePosition: position }));
    }
    if (state.framePosition === 'left') {
      setState(state => ({ ...state, framePosition: 'right' }));
    } else {
      setState(state => ({ ...state, framePosition: 'left' }));
    }
  };

  const toggleFrameVisible = () => {
    setState(state => ({ ...state, frameVisible: !state.frameVisible }));
  };
  const toggleSearchPanelVisible = () => {
    setState(state => ({
      ...state,
      searchPanelVisible: !state.searchPanelVisible
    }));
  };

  const toggleSearchModalOpen = () => {
    setState(state => ({ ...state, searchModalOpen: !state.searchModalOpen }));
  };

  const toggleSaveQueryModalOpen = () => {
    setState(state => ({
      ...state,
      saveQueryModalOpen: !state.saveQueryModalOpen
    }));
  };

  const setFrameVisible = () => {
    setState(state => ({ ...state, frameVisible: true }));
  };

  const toggleSearchFiltersVisible = () => {
    setState(state => ({
      ...state,
      searchFiltersVisible: !state.searchFiltersVisible
    }));
  };

  return (
    <UIStateContext.Provider value={state}>
      <ToggleDockStatePositionContext.Provider value={toggleFramePosition}>
        <ToggleFrameVisibleContext.Provider value={toggleFrameVisible}>
          <ToggleSearchPanelVisibleContext.Provider
            value={toggleSearchPanelVisible}
          >
            <ToggleSearchModalContext.Provider value={toggleSearchModalOpen}>
              <ToggleSaveQueryModalContext.Provider
                value={toggleSaveQueryModalOpen}
              >
                <SetFrameVisibleContext.Provider value={setFrameVisible}>
                  <ToggleSearchFiltersContext.Provider
                    value={toggleSearchFiltersVisible}
                  >
                    <GetSearchFiltersContext.Provider
                      value={state.searchFiltersVisible}
                    >
                      <RootPageLayoutPropsStateContext.Provider
                        value={[pageLayoutProps, setPageLayoutProps]}
                      >
                        {children}
                      </RootPageLayoutPropsStateContext.Provider>
                    </GetSearchFiltersContext.Provider>
                  </ToggleSearchFiltersContext.Provider>
                </SetFrameVisibleContext.Provider>
              </ToggleSaveQueryModalContext.Provider>
            </ToggleSearchModalContext.Provider>
          </ToggleSearchPanelVisibleContext.Provider>
        </ToggleFrameVisibleContext.Provider>
      </ToggleDockStatePositionContext.Provider>
    </UIStateContext.Provider>
  );
};

export default UIContextProvider;
