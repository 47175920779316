import React, { Fragment, ReactNode } from 'react';

interface Props {
  condition: boolean;
  children: ReactNode | ReactNode[];
}
export const ConditionComponent = ({ children, condition }: Props) => {
  if (!condition) return null;
  return <Fragment>{children}</Fragment>;
};
