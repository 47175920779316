import { styled, TypographyProps, Typography, Button } from '@mui/material';

export const DescrContainer = styled('div')(({ theme }) => {
  const { grey, mode } = theme.palette;
  const isDark = mode === 'dark';
  let borderColor = grey['300'];
  if (isDark) borderColor = grey['700'];
  return `
  width: 300px;
  min-height: 400px;
  max-height: 500px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  overflow: hidden;
  grid-column: 1;
  `;
});
export const DescrHeader = styled('div')(({ theme }) => {
  const { grey } = theme.palette;

  return `
  display: flex;
  background-color: ${grey['100']};
  align-items:center;
  border-bottom: 1px solid ${grey['500']};
  padding: 8px;
  padding-left: 18px;
  height: 64px;
  column-gap: 12px;
  `;
});

export const DescHeading = styled((props: TypographyProps) => (
  <Typography {...props} />
))(() => {
  return `
  font-weight: bold;
  `;
});

export const DescInput = styled('textarea')(({ theme }) => {
  const {
    text: { primary }
  } = theme.palette;
  return `
  padding: 4px;
  border: none;
  resize: none;
  outline: none;
  background-color: inherit;
  color: ${primary};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  ::placeholder{ 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  `;
});

export const DescContent = styled('div')(({ theme }) => {
  const {
    input: { background }
  } = theme.palette;
  return `
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-left: 18px;
  background-color: ${background};
  `;
});

export const DescSaveBtn = styled(Button)(() => {
  return {
    marginLeft: 'auto'
  };
});
