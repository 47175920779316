import { TableValueText } from '../styled';
import { RowDataBox, RowDataLeft, RowDataRight } from './styled';
import { Typography } from '@mui/material';
import { ITableProfileUser } from '../../../../../utils/types';

export const RowData = ({ data }: { data: ITableProfileUser }) => {
  return (
    <RowDataBox>
      <RowDataLeft>
        <Typography variant="caption">{data?.username}</Typography>
      </RowDataLeft>
      <RowDataRight>
        <TableValueText>{data?.num_of_references}</TableValueText>
      </RowDataRight>
    </RowDataBox>
  );
};
