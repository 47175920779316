import React, { MouseEventHandler } from 'react';
import { HighlightOffRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  IconButton,
  SvgIconProps
} from '@mui/material';
import { isDatagrip } from '../../utils/checkOrigin';

export interface SherloqChipProps {
  name?: string;
  content: string;
  onClick?: MouseEventHandler<HTMLOrSVGElement>;
  className?: string;
  style?: React.CSSProperties;
  contentProps?: TypographyProps;
  cancelIconProps?: SvgIconProps;
  cancelIconButtonContainerProps?: BoxProps;
}

const SherloqChip: React.FC<SherloqChipProps> = ({
  className,
  name,
  content,
  onClick,
  ...rest
}) => {
  return (
    <Box
      className={`sherloq-chip ${className}`}
      {...rest}
      style={{
        height: '10px',
        background: isDatagrip() ? '#575454' : '',
        ...rest.style
      }}
    >
      <Typography variant="caption" {...rest.contentProps}>
        {content}
      </Typography>
      <Box
        className="sherloq-chip-close-icon"
        {...rest.cancelIconButtonContainerProps}
      >
        <IconButton
          size="small"
          onMouseDown={event => event.stopPropagation()}
          onClick={onClick}
          sx={{ padding: '0px' }}
        >
          <HighlightOffRounded {...rest.cancelIconProps} fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SherloqChip;
