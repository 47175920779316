import { Reducer } from 'react';

export const ActionTypes = {
  SET_OPEN_FILTER_SIDE_VIEW: 'SET_OPEN_FILTER_SIDE_VIEW',
  SET_RESULTS_VIEW: 'SET_RESULTS_VIEW',
  SET_DATA: 'SET_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_ORDER_BY: 'SET_ORDER_BY',
  SET_QUERIED_IN: 'SET_QUERIED_IN',
  SET_SEARCH_STRING: 'SET_SEARCH_STRING',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_DATA_FOR_MODAL: 'SET_DATA_FOR_MODAL'
};

export const InitialData = {
  openFilterSideView: false,
  resultsView: 'table',
  data: [],
  isLoading: true,
  orderBy: 'mostUsed',
  queriedIn: 365,
  searchString: '',
  showModal: false,
  dataForModal: []
};

export const DataCatalogStoreReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_OPEN_FILTER_SIDE_VIEW:
      return { ...state, openFilterSideView: action.payload };
    case ActionTypes.SET_RESULTS_VIEW:
      return { ...state, resultsView: action.payload };
    case ActionTypes.SET_DATA:
      return { ...state, data: action.payload };
    case ActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_ORDER_BY:
      return { ...state, orderBy: action.payload };
    case ActionTypes.SET_QUERIED_IN:
      return { ...state, queriedIn: action.payload };
    case ActionTypes.SET_SEARCH_STRING:
      return { ...state, searchString: action.payload };
    case ActionTypes.SET_SHOW_MODAL:
      return { ...state, showModal: action.payload };
    case ActionTypes.SET_DATA_FOR_MODAL:
      return { ...state, dataForModal: action.payload };
    default:
      return state;
  }
};
