import React, { useCallback } from 'react';
import { getUserName } from '../../../utils/helpers';
import { Typography } from '@mui/material';
import SherloqChip from '../../../atoms/SherloqChip';
import { queriedByListContainerStyle } from '../styles';

export const useRenderFilterSelectedByValues = ({
  teamMembers,
  user,
  last_queried_by,
  onRemove
}) => {
  const renderSelectedQueriedBy = useCallback(
    (selectedTeamMembers: any[]) => {
      if (selectedTeamMembers.length === 1) {
        const uid = selectedTeamMembers[0];
        const username =
          uid === 'anyone' ? 'Anyone' : getUserName(uid, teamMembers);

        return (
          <Typography
            variant="caption"
            className="sherloq-last-queried-by-text"
          >
            {username}
          </Typography>
        );
      }

      return (
        <div style={queriedByListContainerStyle}>
          {(selectedTeamMembers as any[]).map(uid => {
            const username =
              uid === 'anyone' ? 'Anyone' : getUserName(uid, teamMembers);

            return (
              <SherloqChip
                onClick={() => onRemove?.(uid)}
                key={uid}
                content={username}
              />
            );
          })}
        </div>
      );
    },
    [user, last_queried_by]
  );

  return renderSelectedQueriedBy;
};
