import React from 'react';
import { UIStateType } from '.';
import { RootPageLayoutPropsContextType } from '../utils/types/page-layout';

export const UIStateContext = React.createContext<UIStateType | null>(null);
export const ToggleDockStatePositionContext =
  React.createContext<Function | null>(null);
export const ToggleFrameVisibleContext = React.createContext<Function | null>(
  null
);
export const ToggleSearchPanelVisibleContext =
  React.createContext<Function | null>(null);
export const ToggleSearchModalContext = React.createContext<Function | null>(
  null
);
export const ToggleSaveQueryModalContext = React.createContext<Function | null>(
  null
);
export const SetFrameVisibleContext = React.createContext<Function | null>(
  null
);
export const ToggleSearchFiltersContext = React.createContext<Function | null>(
  null
);
export const GetSearchFiltersContext = React.createContext<boolean>(false);
export const RootPageLayoutPropsStateContext =
  React.createContext<RootPageLayoutPropsContextType>(null);
