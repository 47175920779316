import { styled } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SectionContainer } from './SharedComponents';

export const MostJoinedContainer = styled(SectionContainer)(() => {
  return {
    overflowY: 'auto',
    '@media(max-width: 1280px)': {
      width: '100%'
    }
  };
});

export const MostJoinedRowDataBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    paddingRight: '12px'
  };
});

export const MostJoinedRowDataTitle = styled('div')(() => {
  return {
    flex: 1
  };
});

export const MostJoinedRowDataRight = styled('div')(() => {
  return {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    columnGap: '4px'
  };
});

export const CopyIcon = styled(ContentCopyIcon)(({ theme }) => {
  const { grey } = theme.palette;
  return {
    color: grey['600'],
    cursor: 'pointer'
  };
});
