import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import {
  IDataCatalogGlobalState,
  IProfileRecentQueriesPayload,
  SearchData
} from './type';
import { DataCatalogThunks } from './thunk';

const initialState: IDataCatalogGlobalState = {
  tableProfile: {},
  fieldProfile: {},
  search: { filters: {}, results: [] }
};
const index = createSlice({
  name: 'data-catalog',
  initialState,
  reducers: {
    setSearchResults(state, { payload }: PA<SearchData>) {
      state.search.filters = payload.filters;
      state.search.results = payload.results;
    },
    setProfileRecentQueries(
      state,
      { payload }: PA<IProfileRecentQueriesPayload>
    ) {
      const { id, asset, data: recentQueries } = payload;
      if (asset === 'field') {
        const data = state.fieldProfile[id];
        if (data) {
          state.fieldProfile[id].data.recentQueries = recentQueries;
        }
      } else if (asset === 'table') {
        const data = state.tableProfile[id];
        if (data) {
          state.tableProfile[id].data.recentQueries = recentQueries;
        }
      }
    }
  },
  extraReducers: {
    [DataCatalogThunks.getTableProfile.pending.type](state, action) {
      const id = getThunkArgumentValue(action, 'table_id');
      const data = state.tableProfile[id];
      if (data) {
        state.tableProfile[id].loading = true;
      } else {
        state.tableProfile[id] = {
          loading: true,
          data: null,
          error: ''
        };
      }
    },
    [DataCatalogThunks.getTableProfile.fulfilled.type](state, action: any) {
      const id = getThunkArgumentValue(action, 'table_id');
      const data = state.tableProfile[id];
      if (data) {
        state.tableProfile[id].loading = false;
        state.tableProfile[id].data = action.payload as any;
      }
    },
    [DataCatalogThunks.getTableProfile.rejected.type](state, action) {
      const id = getThunkArgumentValue(action, 'table_id');
      const data = state.tableProfile[id];
      if (data) {
        state.tableProfile[id].loading = false;
        const errorMessage = 'Failed to fetch table profile';
        state.tableProfile[id].error = errorMessage;
      }
    },
    [DataCatalogThunks.updateAsset.fulfilled.type](state, action) {
      const id = getThunkArgumentValue(action, 'asset_id');
      const asset = getThunkArgumentValue(action, 'asset_type');
      if (asset === 'table') {
        const data = state.tableProfile[id];
        if (data) {
          state.tableProfile[id].data = {
            ...state.tableProfile[id].data,
            ...action.payload
          };
        }
      } else if (asset === 'field') {
        const data = state.fieldProfile[id];
        if (data) {
          state.fieldProfile[id].data = {
            ...state.fieldProfile[id].data,
            ...action.payload
          };
        }
      }
    },
    [DataCatalogThunks.getFieldProfile.pending.type](state, action) {
      const id = getThunkArgumentValue(action, 'field_id');
      const data = state.fieldProfile[id];
      if (data) {
        state.fieldProfile[id].loading = true;
      } else {
        state.fieldProfile[id] = {
          loading: true,
          data: null,
          error: ''
        };
      }
    },
    [DataCatalogThunks.getFieldProfile.fulfilled.type](state, action: any) {
      const id = getThunkArgumentValue(action, 'field_id');
      const data = state.fieldProfile[id];
      if (data) {
        state.fieldProfile[id].loading = false;
        state.fieldProfile[id].data = action.payload as any;
      }
    },
    [DataCatalogThunks.getFieldProfile.rejected.type](state, action) {
      const id = getThunkArgumentValue(action, 'field_id');
      const data = state.fieldProfile[id];
      if (data) {
        state.fieldProfile[id].loading = false;
        const errorMessage = 'Failed to fetch field profile';
        state.fieldProfile[id].error = errorMessage;
      }
    }
  }
});

export const dataCatalogStateActions = index.actions;

export default index.reducer;

function getThunkArgumentValue(action, key: string) {
  return action.meta.arg[key];
}
