import { styled, Typography, TypographyProps } from '@mui/material';
import { SectionContainer } from '../styled';

export const Container = styled(SectionContainer)(() => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridColumn: '2 / span 2',
    padding: '8px',
    paddingBottom: '24px',
    '@media(max-width: 1280px)': {
      gridRow: '3',
      gridColumn: '1 / span 2',
      minWidth: '100%'
    }
  };
});

export const ContentBox = styled(Container)(() => {
  return {
    marginTop: '24px'
  };
});

export const AccordionPreviewBox = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column'
  };
});

export const AccordionPreviewBottom = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center'
  };
});

export const BottomText = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    color: grey['500']
  };
});

export const BottomTextDark = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(({ theme }) => {
  return {};
});
