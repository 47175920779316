import { useMemo } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../utils/core/getUserCompanyFromEmail';

export default () => {
  const user = useUserInfoSelector();
  return useMemo(() => {
    let company: string;
    let isSherloq = false;
    if (user && user.email && !['demo@sherloqdata.io', 'hello@sherloqdata.com', 'hello@sherloqdata.io'].includes(user.email)) {
      company = getUserCompanyFromEmailUseCase(user.email) ?? '';
      isSherloq = ['sherloq'].includes(company);
    }
    return isSherloq;
  }, [user]);
};
