import React, { useState } from 'react';
import { SubmitSubjectForm } from './submitSubject';
import { Container } from './components';
import { SubmittedMessage } from './submittedMessage';
import { Typography } from '@mui/material';

import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { debounce } from 'lodash';

interface Props {
  data: {
    body: string;
    subject?: string;
  };
}

export const UnknownSubjectResponse = ({ data }: Props) => {
  const { body, subject } = data;
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const submitForm = (inputVal: string) => {
    setLoading(true);

    MixedPanelEvent.submittedUnknownWatsonSubject({
      subject,
      value: inputVal
    });
    debounce(() => {
      setLoading(false);
      setValue(inputVal);
    }, 500)();
  };

  if (value) {
    return <SubmittedMessage data={{ value, subject }} />;
  }
  return (
    <Container>
      <Typography>{body}</Typography>
      <SubmitSubjectForm submitForm={submitForm} loading={loading} />
    </Container>
  );
};
