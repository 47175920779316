import { useContext } from 'react';
import { AiBotDataStoreContext } from '../context/defs';
import { IAiBotMeta } from '../types';

export const useAiBotMeta = (): IAiBotMeta => {
  const {
    botInitialized,
    chatType,
    pageLoading,
    sessionId,
    loadingMessage,
    userConfig: { company }
  } = useContext(AiBotDataStoreContext);
  return {
    botInitialized,
    chatType,
    pageLoading,
    sessionId,
    company,
    loadingMessage
  };
};
