import { DiscoveryType } from '../types';

export const buildFilterMessage = (filters: {}, type: DiscoveryType) => {
  let filterMessage: string;
  if (type === 'join') {
    if (filters && filters['src'] && filters['dst']) {
      filterMessage = `Join between ${filters['src']} and ${filters['dst']}`;
    }
  } else if (type === 'where') {
    filterMessage = `Where filters on ${filters['table_name']}`;
  } else {
    filterMessage = 'Filter by ';
    if (filters && filters['db_name']) {
      filterMessage += `Schema: ${filters['db_name']} `;
    }
    if (filters && filters['table_name']) {
      filterMessage += `Table: ${filters['table_name']} `;
    }
    if (filters && filters['field_name']) {
      filterMessage += `Field: ${filters['field_name']} `;
    }
    if (filters && filters['dashboard_name']) {
      if (filters['dashboard_type'] === 'view') {
        filterMessage += `View: ${filters['dashboard_name']} `;
      } else {
        filterMessage += `Dashboard: ${filters['dashboard_name']} `;
      }
    }

    if (filters && filters['dashboard_tables']) {
      filterMessage += `Dashboard tables: ${filters['dashboard_tables'].join(
        ' '
      )} `;
    }

    if (filters && filters['queried_by']) {
      filterMessage += `Queried by: ${filters['queried_by']} `;
    }

    if (filters && filters['partitioned']) {
      filterMessage += `Is Partitioned: ${filters['partitioned']} `;
    }

    if (filters && filters['calculated_field_name']) {
      filterMessage += `Calculated field name: ${filters['calculated_field_name']}`;
    }

    if (filters && filters['query_contains']) {
      filterMessage += `Query contains: ${filters['query_contains']}`;
    }
  }
  return filterMessage;
};
