import { ICustomMessageComponent } from '../../../../pages/Watson/interfaces';
import { AiDiscoveryBotResponseMessage, DiscoveryType } from '../types';

export function isBotsonDiscoveryMessage(
  data: any
): data is AiDiscoveryBotResponseMessage {
  return (
    data?.discoveryAssets &&
    Array.isArray(data?.discoveryAssets) &&
    data?.discoveryFilters
  );
}

export function isBotsonDiscoveryMessageJoin(
  data: any,
  discoveryType: DiscoveryType
): data is AiDiscoveryBotResponseMessage {
  return (
    Array.isArray(data?.discoveryAssets) &&
    data?.discoveryFilters &&
    discoveryType === 'join'
  );
}

export function isBotsonDiscoveryMessageWhere(
  data: any,
  discoveryType: DiscoveryType
): data is AiDiscoveryBotResponseMessage {
  return (
    Array.isArray(data?.discoveryAssets) &&
    data?.discoveryFilters &&
    discoveryType === 'where'
  );
}

export function isBotsonDiscoveryMessageJoinPick(
  data: any,
  discoveryType: DiscoveryType
): data is AiDiscoveryBotResponseMessage {
  return data?.discoveryAssets === null && discoveryType === 'join';
}

export function isBotsonDiscoveryMessageWherePick(
  data: any,
  discoveryType: DiscoveryType
): data is AiDiscoveryBotResponseMessage {
  return data?.discoveryAssets === null && discoveryType === 'where';
}

export function isBotsonDiscoveryAssetFilterMessage(data: any) {
  return (
    data.discoveryType !== 'join' &&
    data.discoveryFilters &&
    Object.keys(data.discoveryFilters).length > 0 &&
    data.discoveryAssets === null
  );
}

export function isBotsonDiscoveryCustomMessage(
  data: any
): ICustomMessageComponent {
  return data?.children;
}

export function isBotsonErrorMessage(data: any) {
  return data.discoveryAssets === null && data.discoveryFilters === null;
}
