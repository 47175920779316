import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { QuerySearchResponse } from './type';

const search = createAsyncThunk(
    '/queries/search',
    async (search: any, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.post<QuerySearchResponse[]>(
                `/queries/search/v3`,
                search,
                {
                    isProtected: true,
                },
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

export const newQueryThunk = { search };
