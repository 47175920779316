import unionBy from 'lodash/unionBy';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useAppDispatch } from '../../store';
import FolderModal from '../modals/FolderModal';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField } from '@mui/material';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';
import { QueryHistorySummaryThunk } from '../../store/queryHistorySummary/thunk';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { RenderIf } from '../../lib/utils';
import { HotSaveAsFlow } from '../../pages/HotSaveAsFlow';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { useGlobalSaveAsState } from '../../store/saveAs/selectors';
import { saveAsActions } from '../../store/saveAs';
import { SubPages } from './header';
import { queryFoldersThunks } from '../../store/queryFolder/thunk';
import { Header, NewFolderBtn, RightAligned } from './component';
import FolderIcon from '@mui/icons-material/Folder';
import RepositoryDisplayToggle from '../RepositoryDisplayToggle';
import { SearchInput } from '../../data-catalog/ui/Profiles/TableProfile/components/AllFields/SearchInput';
import { changeFilterText } from '../../store/folderSummary';

function showLoader(text: string) {
  toast(text, { isLoading: true });
}

const ListPageHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveAsState = useGlobalSaveAsState();
  const [members, setMembers] = useState<string[]>([]);
  const [folderName, setFolderName] = React.useState('');
  const [folderType, setFolderType] = useState('personal');
  const [folderParentId, setFolderParentId] = React.useState('');
  const [folderDescription, setFolderDescription] = React.useState('');
  const [showAddFolderModal, setShowAddFolderModal] = React.useState(false);

  const folderSummary = useFolderSummarySelector();
  const folderSummaryShared = useSharedFoldersSelector();
  const folders = unionBy(folderSummary.list, folderSummaryShared.list, 'uid');
  const darkMode = useDarkModeState();

  const handleSubmitNewFolder = async (isSub: boolean) => {
    try {
      if (!folderName) {
        toast('Folder name is required', { type: 'error' });
        return;
      }
      if (folderName.toLowerCase() === 'default') {
        toast(`Folder name cannot be ${folderName}`, { type: 'error' });
        return;
      }
      const payload = {
        name: folderName,
        description: folderDescription,
        is_shared: folderType === 'personal' ? false : true,
        parent_id: isSub ? folderParentId : '',
        members: folderType === 'shared' ? members : []
      };
      showLoader('Creating new folder');
      await dispatch(
        queryFoldersThunks.createQueryFolder({
          ...payload,
          withAnalytics: true,
          withToast: false
        })
      ).unwrap();
      toast.dismiss();
      toast('Successfully created new folder', {
        type: 'success',
        autoClose: 2000
      });
      MixedPanelEvent.sharedFolderInviteMember(folderName, '', members);
      setFolderParentId('');
      closeAddFolderModal();
    } catch (error) {
      toast.dismiss();
      toast('Failed to create folder. ', { type: 'error' });
    }
  };
  const closeAddFolderModal = () => {
    setMembers([]);
    setFolderName('');
    setFolderParentId('');
    setFolderDescription('');
    setFolderType('personal');
    setShowAddFolderModal(false);
  };

  const handleOnCloseSaveAsFlow = () => {
    dispatch(saveAsActions.cleanUp());
  };

  const handleOnClickMy = () => {
    navigate('/saved-queries');
    MixedPanelEvent.tabClicked('saved-queries');
  };
  const handleOnClickTables = () => {
    navigate('/tables');
    MixedPanelEvent.tabClicked('tables');
  };
  const handleOnClickCompany = () => {
    navigate('/public-queries');
    MixedPanelEvent.tabClicked('public-queries');
  };
  const handleOnClickHistory = () => {
    navigate('/query-history');
    dispatch(QueryHistorySummaryThunk.index());
    MixedPanelEvent.tabClicked('query-history');
  };

  return (
    <Header>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '8px',
          flexGrow: 0,
          boxSizing: 'border-box',
          width: '100%'
        }}
      >
        <SubPages
          variant="default"
          onClickMy={handleOnClickMy}
          onClickCompany={handleOnClickCompany}
          onClickHistory={handleOnClickHistory}
          onClickTables={handleOnClickTables}
        />
        <RightAligned>
          <RepositoryDisplayToggle />
          <NewFolderBtn
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={() => setShowAddFolderModal(true)}
          >
            <FolderIcon sx={{ color: 'white', fontSize: '16px' }} />
            New
          </NewFolderBtn>
        </RightAligned>
      </Box>
      <Typography color={darkMode ? 'white' : ''} variant="h6" fontWeight={400}>
        <TextField size='small' sx={{ marginTop: '10px', '& .MuiInputBase-input': { fontSize: '12px' } }} type='search' variant="standard" value={folderSummary.filterText} onChange={(e) => dispatch(changeFilterText(e.target.value)) } placeholder='Filter by folder name'/>
        {/* <SearchInput isFilter value={folderSummary.filterText} onSearchChange={(e) => dispatch(changeFilterText(e.target.value)) } placeholder='folders filter'/> */}
      </Typography>

      <FolderModal
        type="add"
        title="Add folder"
        folders={folders}
        show={showAddFolderModal}
        name={folderName}
        setName={setFolderName}
        description={folderDescription}
        setDescription={setFolderDescription}
        folderType={folderType}
        setFolderType={setFolderType}
        members={members}
        setMembers={setMembers}
        setClose={() => closeAddFolderModal()}
        SaveFolder={handleSubmitNewFolder}
        isSubFolder={false}
        parentName={''}
        selectedFolder={folderParentId}
        setParentId={setFolderParentId}
        key={`${showAddFolderModal}`}
      />
      {RenderIf(
        saveAsState.render,
        <HotSaveAsFlow
          onClose={handleOnCloseSaveAsFlow}
          query={saveAsState.payload}
        />
      )}
    </Header>
  );
};

export default ListPageHeader;
