import React from 'react';

interface SherloqIconProps {
    size: string;
}

const SherloqIcon = ({ size }: SherloqIconProps) => {
    return (
        <svg height={size} viewBox="0 0 205 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M203.204 189.743L161.925 148.464C174.624 132.802 182.243 112.857 182.243 91.124C182.243 40.7962 141.447 0 91.1191 0C40.7912 0 0 40.7962 0 91.124C0 141.452 40.7962 182.248 91.124 182.248C110.536 182.248 128.519 176.158 143.3 165.814L185.221 207.736C187.128 209.643 190.216 209.643 192.123 207.736L203.209 196.65C205.116 194.743 205.116 191.655 203.209 189.748L203.204 189.743ZM32.1608 92.1947C32.1608 54.3019 58.5598 23.5853 91.124 23.5853C123.688 23.5853 150.087 54.3019 150.087 92.1947C150.087 130.088 123.688 160.804 91.124 160.804C58.5598 160.804 32.1608 130.088 32.1608 92.1947Z"
                fill="url(#paint0_linear_97_11384)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_97_11384"
                    x1="45.557"
                    y1="12.196"
                    x2="166.95"
                    y2="222.457"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F42386" />
                    <stop offset="1" stopColor="#7509B6" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SherloqIcon;
