import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { LoadingState } from '../../utils/types/general';
import { newQueryFolderContentThunks } from './thunk';
import { NewFolderContentType } from './types';
import { CreateContentResponse } from './types';
import { Logger } from '../../utils/browser/logger';

const initialState: NewFolderContentType = {
  data: {}
};

const index = createSlice({
  name: 'content-new',
  initialState,
  reducers: {
    addContent: (state, { payload }: PA<CreateContentResponse>) => {
      if (!(payload.parent_uid in state.data)) {
        state.data[payload.parent_uid] = {
          loading: LoadingState.IDLE,
          list: []
        };
      }
      state.data[payload.parent_uid].list = payload.contents;
    },
    deleteContent: (
      state,
      { payload }: PA<{ parentId: string; contentId: string }>
    ) => {
      const contentList = state.data[payload.parentId].list;
      const index = contentList.findIndex(
        item =>
          item.query?.uid === payload.contentId ||
          item.child?.uid === payload.contentId
      );
      if (index !== 1) {
        state.data[payload.parentId].list.splice(index, 1);
      }
      state.data[payload.parentId].list = contentList.filter(
        item =>
          item.query?.uid !== payload.contentId &&
          item.child?.uid !== payload.contentId
      );
    }
  },
  extraReducers: {
    [newQueryFolderContentThunks.index.pending.type]: (
      state,
      { meta, payload }: PA<CreateContentResponse> & { meta: any }
    ) => {
      const folderId = payload?.parent_uid ?? meta.arg.uid;
      if (state.data[folderId]) {
        state.data[folderId] = {
          ...state.data[folderId],
          loading: LoadingState.PENDING
        };
      } else {
        state.data[folderId] = {
          loading: LoadingState.PENDING,
          list: [],
          meta: null
        };
      }
    },
    [newQueryFolderContentThunks.index.fulfilled.type]: (
      state,
      { payload }: PA<CreateContentResponse>
    ) => {
      if (state.data[payload?.parent_uid]) {
        state.data[payload?.parent_uid].meta = payload.meta;
        state.data[payload?.parent_uid].loading = LoadingState.FULFILLED;
        // to overwrite the folder content after a folder is deleted or moved.
        if (payload.meta.page === 1) {
          state.data[payload?.parent_uid].list = payload.contents;
        } else if (payload.meta.page > 1) {
          state.data[payload?.parent_uid].list = state.data[
            payload?.parent_uid
          ].list.concat(payload.contents);
        }
      } else {
        // must never call since the reducer will pass the pending type before the fulfilled
        state.data[payload?.parent_uid] = {
          list: payload.contents,
          loading: LoadingState.FULFILLED,
          meta: payload.meta
        };
      }
    },
    [newQueryFolderContentThunks.index.rejected.type]: (
      state,
      { payload, meta }: PA<CreateContentResponse> & { meta: any }
    ) => {
      const folderId = payload?.parent_uid ?? meta.arg.uid;
      if (state.data[payload?.parent_uid]) {
        state.data[folderId].loading = LoadingState.REJECTED;
      }
    },
    [newQueryFolderContentThunks.refetch.pending.type]: (
      state,
      { payload, meta }: PA<CreateContentResponse> & { meta: any }
    ) => {
      const folderId = payload?.parent_uid ?? meta.arg.uid;
      if (state.data[payload?.parent_uid]) {
        state.data[folderId].loading = LoadingState.REFETCHING;
      }
    },
    [newQueryFolderContentThunks.refetch.fulfilled.type]: (
      state,
      { payload, meta }: PA<CreateContentResponse> & { meta: any }
    ) => {
      const folderId = payload?.parent_uid ?? meta.arg.uid;
      state.data[folderId].meta = payload.meta;
      state.data[folderId].loading = LoadingState.REFETCH_COMPLETE;
      if (state.data[folderId]) {
        state.data[folderId].list = payload.contents;
      }
    },
    [newQueryFolderContentThunks.refetch.rejected.type]: (
      state,
      { payload, meta }: PA<CreateContentResponse> & { meta: any }
    ) => {
      const folderId = payload?.parent_uid ?? meta.arg.uid;
      state.data[folderId].loading = LoadingState.REFETCH_ERROR;
    }
  }
});
export const newQueryFolderContentReducers = index.actions;

export const { addContent } = index.actions;

export default index.reducer;
