import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { addContent } from '../newQueryHistoryContent';
import { ResponsePayload } from './type';
import { FolderContent } from '../queries/types'

const index = createAsyncThunk(
    'new-query-history/index',
    async (day: ResponsePayload, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            const response = await ApiClient.post<FolderContent[]>(`/query-histories/by-date`, day, {
                isProtected: true,
            });
            dispatch(
                addContent({
                    parent_uid: day.day,
                    contents: response.data,
                }),
            );
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Error occurred');
        }
    },
);

export const queryHistoryNewThunks = { index };
