import { UserCompanyType } from '../types/user';

export function getUserDomainFromEmail(email: string) {
  if (!email) null;
  return email.split('@')[1];
}

export const getUserCompanyFromDomain = (domain: string): UserCompanyType => {
  if (domain.includes('sherloqdata')) return 'sherloq';
  if (domain.includes('lightricks')) return 'lightricks';
  if (domain.includes('appsflyer')) return 'appsflyer';
  if (domain.includes('armis')) return 'armis';
  if (domain.includes('ludeo')) return 'ludeo';
  if (domain.includes('naturalint')) return 'naturalint';
  if (domain.includes('hellosimply') || domain.includes('joytunes')) return 'simply'; 
  return null;
};
