import { LoadingState } from '../../utils/types/general';
import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { queryHistoryNewThunks } from './thunk';
import { QueryHistory, QueryHistoryState } from './type';

const initialState: QueryHistoryState = {
    list: [],
    isLoading: false,
    loading: LoadingState.PENDING,
};

const slice = createSlice({
    name: 'query-history-new',
    initialState,
    reducers: {},
    extraReducers: {
        //Fetch actions
        [queryHistoryNewThunks.index.pending.type]: (state) => {
            state.loading = LoadingState.PENDING;
            state.list = [];
            state.isLoading = true;
        },
        [queryHistoryNewThunks.index.fulfilled.type]: (state, { payload }: PA<QueryHistory[]>) => {
            state.loading = LoadingState.FULFILLED;
            state.list = payload;
            state.isLoading = false;
        },
        [queryHistoryNewThunks.index.rejected.type]: (state) => {
            state.loading = LoadingState.REJECTED;
            state.list = [];
            state.isLoading = false;
        },
    },
});

export const {} = slice.actions;

export default slice.reducer;
