export interface UpdateItemPayload<T> {
  uid: string;
  payload: any;
}

export enum LoadingState {
  PENDING,
  FULFILLED,
  REJECTED,
  IDLE,
  REFETCHING,
  REFETCH_COMPLETE,
  REFETCH_ERROR
}
