import React, { useCallback, useMemo } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Typography, Stack, Box } from '@mui/material';
import { PlatformIcon } from '../../Icons/PlatformIcon';
import { footerQueryTypeIconContainerStyles } from '../styles';
import { UTCtoLocal, localTime } from '../../../pages/Editor/helpers/helpers';
import { QueryItemFooterIcon } from './QueryItemFooterIcon';
import { useQueryItemType } from '../hooks/useQueryItemIconType';
import { addStemsToText, getLastSeenString } from '../../../utils/helpers';
import { CustomTooltip } from '../../../atoms/CustomTooltip';

interface IQueryItemFooterData {
  type: string;
  query_origin: string;
  query_owner_username: string;
  query_created_at: string;
  parentFolderId: string;
  showHighlight: boolean;
  keyword: string;
  edited: any;
}

interface QueryItemFooterProps {
  data: IQueryItemFooterData;
}

export const QueryItemFooter = ({ data }: QueryItemFooterProps) => {
  const transformedDate = localTime(data?.query_created_at);
  const username = data?.query_owner_username;
  const queryType = useQueryItemType({
    parentFolderId: data.parentFolderId,
    query_owner: data.query_owner_username,
    type: data.type
  });
  const isHistory = queryType === 'history';
  const getKeywordMatches = useCallback(
    (text: string) => {
      if (typeof text !== 'string') {
        return [];
      } // Handle non-string inputs
      const matches = match(text, addStemsToText(data.keyword), {
        insideWords: true
      });
      return parse(text, matches ?? []);
    },
    [data.keyword]
  );
  const highlightMap = useMemo(() => {
    const highlightMap = getKeywordMatches(username);
    return highlightMap;
  }, [data.keyword, username]);

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={0.5} marginTop="8px" padding='2px 0'>
        <PlatformIcon origin={data.query_origin} size="16px" />
        <Typography
          style={{ fontWeight: 300 }}
          variant="caption"
          color="text.secondary"
        >
          {isHistory ? 'Query was run by' : 'Created by'}
        </Typography>
        <Typography
          style={{
            fontWeight: 300,
            maxWidth: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
          variant="caption"
          color="text.primary"
        >
          {highlightMap.map((part, idx) => (
            <span
              key={idx}
              style={{
                backgroundColor: part.highlight
                  ? 'rgba(255,255,0,0.7)'
                  : 'transparent'
              }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
        {/* <Box sx={footerQueryTypeIconContainerStyles}>
        <QueryItemFooterIcon type={queryType} />
      </Box> */}
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5} marginLeft="19px" padding='0 0'>
        {data?.edited?.created_at &&
          <CustomTooltip title={`Last updated at ${UTCtoLocal(data?.edited?.created_at)} by ${(data?.edited?.user.first_name) + ' ' + (data?.edited?.user.last_name)}`}>
            <Typography
              style={{ fontWeight: 300 }}
              variant="caption"
              color="text.secondary"
            >
              Edited {getLastSeenString(new Date(data?.edited?.created_at))}
            </Typography>
          </CustomTooltip>
        }
      </Stack>
    </div>
  );
};
