export const sx = {
  sx0: {
    fontSize: 16,
    color: '#252631',
    textTransform: 'none'
  },
  sx0dark: {
    fontSize: 16,
    color: 'white',
    textTransform: 'none'
  },
  sx1: {
    textTransform: 'none',
    fontSize: 16,
    color: '#252631',
    '&:hover': { color: '#7209B7' },
    mr: 0.5,
    ml: 0.5
  },
  sx1dark: {
    textTransform: 'none',
    fontSize: 16,
    color: 'white',
    '&:hover': { color: '#7209B7' },
    mr: 0.5,
    ml: 0.5
  },
  sx2: {
    textTransform: 'none',
    fontSize: 16,
    color: '#c4c4c4',
    mr: 0.5,
    ml: 0.5
  },
  sx3: {
    textTransform: 'none',
    fontSize: 12,
    color: '#252631'
  },
  sx3dark: {
    textTransform: 'none',
    fontSize: 12,
    color: 'white'
  },
  sx4: {
    textTransform: 'none',
    fontSize: 20,
    color: '#252631',
    '&:hover': { color: '#7209B7' },
    mr: 0.5,
    ml: 0.5
  },
  sx4dark: {
    textTransform: 'none',
    fontSize: 20,
    color: 'white',
    '&:hover': { color: '#7209B7' },
    mr: 0.5,
    ml: 0.5
  },
  sx5: {
    textTransform: 'none',
    fontSize: 24,
    color: '#252631'
  },
  sx5dark: {
    textTransform: 'none',
    fontSize: 24,
    color: 'white'
  },
  sx6: {
    textTransform: 'none',
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.8)',
    '&:hover': { color: '#0075E7' }
  },
  sx6dark: {
    textTransform: 'none',
    fontSize: 16,
    color: 'white',
    '&:hover': { color: '#0075E7' }
  },
  sx7: {
    textTransform: 'none',
    fontSize: 16,
    color: '#252631'
  },
  sxFolder: {
    textTransform: 'none',
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.8)'
  },
  sxFolderDark: {
    textTransform: 'none',
    fontSize: 24,
    color: 'white'
  },
  HeaderBtn: {
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 400
  },
  HeaderBtnDark: {
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 400,
    color: 'rgb(128,186,243)'
  },
  btn: {
    textTransform: 'none',
    height: '32px',
    fontSize: '14px',
    borderRadius: '4px'
  },
  SignUpBtn: {
    textTransform: 'none',
    height: '32px',
    fontSize: '14px',
    borderRadius: '4px',
    color: '#0075E7',
    BorderColor: '#0075E7'
  },
  textBtn12: {
    textTransform: 'none',
    height: '20px',
    fontSize: '12px',
    color: '#0075E7'
  },
  disabledOutlinedBtn14: {
    textTransform: 'none',
    height: '32px',
    fontSize: '14px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.6)',
    border: '1px solid rgba(0, 0, 0, 0.6)',
    '&:hover': { border: '1px solid rgba(0, 0, 0, 0.6)' }
  },
  disabledOutlinedBtn14dark: {
    textTransform: 'none',
    height: '32px',
    fontSize: '14px',
    borderRadius: '4px',
    color: '#d2cfcf',
    border: '1px solid #d2cfcf',
    '&:hover': { border: '1px solid rgba(0, 0, 0, 0.6)' }
  },
  plusSignBtn: {
    textTransform: 'none',
    height: '32px',
    fontSize: '14px',
    borderRadius: '4px'
  }
};
