import { styled, Box, Button } from '@mui/material';
export const NotifyToggleBox = styled(Box)(() => {
  return {
    borderRadius: '8px',
    maxWidth: '400px',
    padding: '12px'
  };
});

export const NotifyToggleBoxBtnsBox = styled(Box)(() => {
  return {
    display: 'flex',
    columnGap: '12px',
    marginTop: '8px'
  };
});

export const CancelBtn = styled(Button)(() => {
  return {
    color: 'grey',
    textTransform: 'none'
  };
});

export const BotTypeIconBox = styled('div')(() => ({
  width: '32px',
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'flex-start'
}));
