import {
  styled,
  TypographyProps,
  Typography,
  Input as MuiInput,
  InputProps
} from '@mui/material';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';

export const Container = styled('section')(() => {
  return `
  display: flex;
  flex-direction: column;
  @media(max-width: 1280px) {
    grid-row: 2;
    grid-column: 1 / span 2;
  }
   `;
});

export const InputBox = styled('div')(({ theme }) => {
  const { grey } = theme.palette;

  return `
  display:flex;
  width: 300px;
  border: 1px solid ${grey['100']};
  border-radius: 48px;
  column-gap: 8px;
  align-items:center;
  padding-left: 8px;
  padding-right: 12px;
  `;
});

export const Input = styled((props: InputProps) => (
  <MuiInput {...props} disableUnderline />
))(() => {
  return `
  outline: none;
  height: 36px;
  font-size: 14px;
  flex:1;
  `;
});

export const RowDataBox = styled('div')(({ theme }) => {
  const { grey } = theme.palette;
  return `
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${grey['400']};
  padding-bottom: 2px;
  `;
});

export const RowDataTopBox = styled('div')(() => {
  return `
  display: flex;
  align-items:flex-end;
  `;
});

export const DescriptionText = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return `
  color: ${grey['600']};
  margin-top: 8px;
  `;
});

export const TableBodyWrapper = styled('div')(() => {
  return {
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '8px'
  };
});

export const RowDataTopRightBox = styled('div')(
  () => `
display: flex;
align-items:center;
margin-left: auto;
column-gap: 8px;
`
);

export const RightMeta = styled('div')(
  () => `
display: flex;
column-gap: 4px;
align-items: center;
cursor: default;
`
);

export const RightDataLeft = styled('div')(
  () => `
display: flex;
align-items:center;
column-gap: 8px;
`
);

export const RowDataTitleText = styled((props: TypographyProps) => (
  <Typography {...props} variant="body1" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return `
  border-bottom: 1px solid ${grey['200']};
  cursor: pointer;
  `;
});

export const GraphIcon = styled(EqualizerRoundedIcon)(() => {
  return `
  font-size: 14px;
  `;
});

export const HistoryIcon = styled(HistoryToggleOffOutlinedIcon)(() => {
  return `
  font-size: 14px;
  `;
});
