import React, { useState } from 'react';

import { Box, Typography, RadioGroup } from '@mui/material';
import { SideViewContainer } from './components';
import { QueriedInFilter } from './queriedInFilter';
import { LastQueriedFilter } from './lastQueriedFilter';
import { RuleFilter } from './ruleFilter';
import { FilterActionButtons } from './actionButtons';
import { defaultAssetFilterRule } from './ruleFilter/data';
export const FilterSideView = ({
  handleFilterIconClick,
  queriedInValue,
  setQueriedInValue,
  onSubmitSearch,
  onResetFilters
}) => {
  const [lastQueriedValue, setLastQueriedValue] = useState('');
  const [rules, setRules] = useState([
    { column: '', condition: '', value: '' }
  ]);
  const handleQueriedInChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQueriedInValue(event.target.value);
  };

  const handleLastQueriedChange = (event: any) => {
    setLastQueriedValue(event.target.value);
  };

  const addRule = () => {
    setRules(prev => [...prev, defaultAssetFilterRule]);
  };
  return (
    <SideViewContainer>
      <Typography
        fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
        fontWeight={400}
        variant="h4"
        marginBottom={'24px'}
      >
        Advanced search
      </Typography>
      <QueriedInFilter
        value={queriedInValue}
        onChange={handleQueriedInChange}
      />
      {/* <LastQueriedFilter
        value={lastQueriedValue}
        onChange={handleLastQueriedChange}
      /> */}
      {/* <RuleFilter rules={rules} addRule={addRule} /> */}
      <FilterActionButtons
        onCancelSearch={handleFilterIconClick}
        onResetFilters={onResetFilters}
        onSubmitSearch={onSubmitSearch}
      />
    </SideViewContainer>
  );
};
