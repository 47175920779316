import { InfoLabel } from '../../../../components/InfoLabel';
import { TableHeadWrapper } from '../styled';
import { RowData } from './RowData';
import { SearchInput } from './SearchInput';
import { Container, TableBodyWrapper } from './styled';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material';
import { ITableProfileTopField } from '../../../../../utils/types';
import { useState } from 'react';
import MixedPanelEvent from '../../../../../../utils/analytics/mixPanel';

export const AllFields = ({
  fields,
  tableName
}: {
  fields: Array<ITableProfileTopField>;
  tableName: string;
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    setSearchTerm(text.toLowerCase());
    MixedPanelEvent.dataCatalogTableProfileSearchField({
      searchText: text,
      assetName: tableName
    });
  };

  const filteredFields = fields?.filter(field =>
    field.field_name.toLowerCase().includes(searchTerm)
  );
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadWrapper>
                <InfoLabel>All fields</InfoLabel>
                <SearchInput onSearchChange={handleSearchChange} />
              </TableHeadWrapper>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyWrapper className="scroll-container">
              {filteredFields?.map((fieldData: ITableProfileTopField) => (
                <RowData data={fieldData} />
              ))}
            </TableBodyWrapper>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
