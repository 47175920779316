import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useWatsonMessages } from '../../hooks/useWatsonMessages';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useWatsonMeta } from '../../hooks/useWatsonMeta';
import { WhereFieldFilter } from './WhereTextFilter';
import { useDiscoveryAiBotActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryActions';
import { useAiBotVariantMeta } from '../../../../Botson/hooks/useAiBotVariantMeta';
import { useAiBotMeta } from '../../../../Botson/hooks/useAiBotMeta';
import { useAiBotEntryMessage } from '../../../../Botson/hooks/useAiBotEntryMessage';

export const WhereMessage = ({ filters }: { filters: {} }) => {
  const { chooseFilter } = useDiscoveryAiBotActions();
  const { sessionId: conversation_uid } = useAiBotMeta();
  const message = useAiBotEntryMessage();
  const { discoveryType } = useAiBotVariantMeta();

  // const meta = useWatsonMeta()[0];
  // const messages = useWatsonMessages()[0];
  // const discoverySearch = useWatsonMessages()[4];

  const [tableName, setTableName] = useState(
    filters && filters['table_name'] ? filters['table_name'] : ''
  );
  const [schemaName, setSchemaName] = useState(
    filters && filters['db_name'] ? filters['db_name'] : ''
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleTableNameChange = (value: any) => {
    setTableName(value);
  };

  const handleSecondTableChange = (value: any) => {
    setSchemaName(value);
  };
  const isTableNameEmpty = () => {
    return !tableName;
  };
  const handleSubmitClick = () => {
    setIsButtonDisabled(true);
    let filters = {
      table_name: tableName,
      db_name: schemaName
    };
    MixedPanelEvent.watsonFilterSubmit({
      conversation_uid,
      question_type: discoveryType,
      table_name: tableName,
      db_name: schemaName
    });
    chooseFilter('where', filters);
  };

  useEffect(() => {
    MixedPanelEvent.watsonWhereQuestionExtraction({
      conversation_uid,
      question_type: discoveryType,
      question_message: message.message,
      tables_extraction: {
        table_name:
          filters && filters['table_name'] ? filters['table_name'] : '',
        db_name: filters && filters['db_name'] ? filters['db_name'] : ''
      }
    });
  }, [filters]);

  return (
    <Box>
      <Typography>Cool.</Typography>
      <Typography>
        Let me know which table's best practices you're looking for
      </Typography>
      <Box
        marginTop={'10px'}
        gap={'15px'}
        display={'flex'}
        flexDirection={'column'}
      >
        <WhereFieldFilter
          selectedValue={tableName}
          onSelectionChange={handleTableNameChange}
          isDisabled={isButtonDisabled}
          type={'table'}
          label={'Table name'}
          width={'100%'}
          placeholderText={''}
        />
        <WhereFieldFilter
          selectedValue={schemaName}
          onSelectionChange={handleSecondTableChange}
          isDisabled={isButtonDisabled}
          type={'schema'}
          label={'Schema name'}
          width={'100%'}
          placeholderText={''}
        />
        <CustomTooltip
          title={
            isTableNameEmpty() ? (
              <Typography>
                Required to fill out at least the table name
              </Typography>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <Box>
            <Button
              sx={{
                fontSize: '15px',
                textTransform: 'none',
                height: '100%'
              }}
              variant="contained"
              onClick={handleSubmitClick}
              disabled={isButtonDisabled || isTableNameEmpty()}
            >
              Submit
            </Button>
          </Box>
        </CustomTooltip>
      </Box>
    </Box>
  );
};
