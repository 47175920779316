import {Box} from '@mui/material';
import React, { Suspense } from 'react';
import PublicRoute, { PublicRoutesProps } from './PublicRoutes';

interface Props extends PublicRoutesProps {}

const SuspendedPublicRoute: React.FC<Props> = ({ element }) => {
    return (
        <Suspense fallback={<Box style={{background:'white', zIndex:10000000}}/>}>
            <PublicRoute element={element} />
        </Suspense>
    );
};

export default SuspendedPublicRoute;
