export const RenderIf = (condition: boolean, Component: JSX.Element | JSX.Element[]) => {
    if (condition) return Component;
    return null;
};

export const RenderCondition = (condition: boolean, Truthy, Falsy: any) => {
    if (condition) return Truthy;
    return Falsy;
};

export const returnIfWithNull = (condition: boolean, val: any) => {
    if (condition) return val;
    return null;
};
