import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

type Instance = InstanceType<typeof Cookies>;
type GetCookiesFn = (key: string, options?: CookieGetOptions) => any;
type SetCookiesFn = Instance['set'];
type ClearCookiesFn = Instance['remove'];

/**
 * Cookie management class to interact with Browser cookies
 */
export class CookiesManager {
  private static _instance: InstanceType<typeof Cookies> | null = null;
  private static get instance() {
    if (this._instance) return this._instance;
    this._instance = new Cookies();
    return this._instance;
  }

  public static get keys() {
    return {
      extensionAuth: 'extensionUserAuthData'
    };
  }

  public static getByKey: GetCookiesFn = (...args) => {
    return this.instance?.get(...args);
  };

  public static clearCookieByKey: ClearCookiesFn = (
    key: string,
    options?: CookieSetOptions
  ) => {
    return this.instance?.remove(key, options);
  };

  public static setByKey: SetCookiesFn = (...args) => {
    return this.instance?.set(...args);
  };
}
