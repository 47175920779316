import { toast } from 'react-toastify';
import DeleteModal from '../modals/DeleteModal';
import React, { useEffect, useState } from 'react';
import { Sell, Clear, HighlightOff } from '@mui/icons-material';
import { Box, Chip, Input, Menu, Button } from '@mui/material';
import { shortenString } from '../../pages/Editor/helpers/helpers';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import {
  addQueryTag,
  addTag,
  getQueryTags,
  getTags,
  deleteQueryTag,
  deleteTag
} from './API/fetch';
import { newQueryFolderContentThunks } from '../../store/newFolderContent/thunk';
import { useAppDispatch } from '../../store';

interface Tag {
  id: number;
  uid: string;
  name: string;
  color: string;
}

interface TagsProps {
  query_uid: string;
  preTags?: Tag[];
  preQueryTags?: Tag[];
  folder_id?: string;
}

const Tags = ({ query_uid, preTags, preQueryTags, folder_id }: TagsProps) => {
  const dispatch = useAppDispatch();
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [currTag, setCurrTag] = useState<Tag>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [queryTags, setQueryTags] = useState<Tag[]>([]);
  const [deleteTagModal, setDeletetagModal] = useState(false);

  useEffect(() => {
    if (preQueryTags) {
      setQueryTags(preQueryTags);
    } else {
      getQueryTags(query_uid).then(res => {
        setQueryTags(res.data.tags);
      });
    }
    if (preTags) {
      setTags(preTags);
    } else {
      getTags().then(res => {
        setTags(res.data.tags);
      });
    }
  }, [preTags]);

  const onKeyDown = (e: any) => {
    if (e.key == 'Enter' && tag.length > 1) {
      addTag(tag).then(res => {
        setTag('');
        if (res?.data?.id) {
          setTags([...tags, res.data]);
        }
      });
      MixedPanelEvent.addTagAction(tag);
    }
  };

  const addQueryTagHandler = (t: Tag) => {
    setAnchorEl(null);
    addQueryTag(query_uid, t.id).then(res => {
      if (res?.data?.id) {
        setQueryTags([...queryTags, t]);
      }
    });
    dispatch(newQueryFolderContentThunks.index({ uid: folder_id }));
    MixedPanelEvent.addQueryTagAction(t.name);
  };

  const deleteQueryTagHandler = (t: Tag) => {
    deleteQueryTag(query_uid, t.id).then(() => {
      setQueryTags(queryTags.filter(qt => qt.id !== t.id));
      dispatch(newQueryFolderContentThunks.index({ uid: folder_id }));
    });
  };

  const deleteTagHandler = () => {
    MixedPanelEvent.deleteQueryTagAction(currTag?.name);
    setDeletetagModal(false);
    deleteTag(currTag?.id!).then(() => {
      toast.info('The tag and all its relationships deleted successfully', {
        type: 'success'
      });
      setTags(tags.filter(qt => qt.id !== currTag?.id!));
      setQueryTags(queryTags.filter(qt => qt.id !== currTag?.id!));
    });
  };

  return (
    <Box>
      <Box style={{ display: 'flex', flexFlow: 'wrap' }}>
        {queryTags.map(t => (
          <Chip
            variant="filled"
            sx={{
              margin: '2px',
              height: '22px',
              background: t.color,
              color: 'white',
              fontSize: 11
            }}
            size='small'
            key={t.id}
            label={shortenString(t.name, 10)}
            onDelete={() => deleteQueryTagHandler(t)}
            deleteIcon={
              <HighlightOff style={{ fontSize: '18px', color: 'white' }} />
            }
          />
        ))}
        <Chip
          variant="filled"
          color="default"
          label={'+'}
          onDelete={e => {
            setAnchorEl(e.currentTarget);
          }}
          sx={{ margin: '2px', height: '22px', fontSize: '11px' }}
          deleteIcon={<Sell style={{ fontSize: '16px' }} />}
          id="tagsMenu"
          aria-controls={!!anchorEl ? 'tagsMenu' : undefined}
          aria-haspopup="true"
          aria-expanded={!!anchorEl ? 'true' : undefined}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
          }}
        />
      </Box>
      <Menu
        id="tagsMenu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={e => {
          e.stopPropagation();
        }}
        MenuListProps={{
          'aria-labelledby': 'tagsMenu'
        }}
      >
        <Input
          size="small"
          placeholder="Add new tag"
          value={tag}
          onChange={e => {
            e.stopPropagation();
            setTag(e.target.value);
          }}
          onKeyDown={onKeyDown}
          style={{ margin: '5px', fontSize: '12px' }}
        />
        {tags.map(t => (
          <Box key={t.id}>
            <Button
              color="inherit"
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                cursor: 'pointer',
                margin: '3px',
                justifyContent: 'space-between',
                textTransform: 'none',
                color: t.color,
                background: 'rgba(255,255,255,1)'
              }}
              fullWidth={true}
              onClick={e => {
                addQueryTagHandler(t), e.stopPropagation();
              }}
              variant="text"
              endIcon={
                <Clear
                  onClick={e => {
                    setAnchorEl(null),
                      e.stopPropagation(),
                      setCurrTag(t),
                      setDeletetagModal(true);
                  }}
                />
              }
            >
              {shortenString(t.name, 10)}
            </Button>
          </Box>
        ))}
      </Menu>
      <DeleteModal
        show={deleteTagModal}
        title="Delete Tag?"
        subTitle="Are you sure you want to delete this tag? &#10; That means this tag will not be visible in all the queries that it was attached to."
        setClose={() => setDeletetagModal(false)}
        handleDelete={() => deleteTagHandler()}
      />
    </Box>
  );
};

export default Tags;
