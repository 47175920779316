import Tags from '../Tags/Tags';
import unionBy from 'lodash/unionBy';
import { toast } from 'react-toastify';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '../../store';
import { sx } from '../../styles/MUI/styles';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../modals/DeleteModal';
import CustomSqlEditor, { AceEditorExtraProps } from '../CustomSqlEditor';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Popover } from '../../lib/components/Popover';
import { CustomTooltip } from '../../atoms/CustomTooltip';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { useDeleteQuery, useMoveQuery } from '../../lib/hooks';
import { useUserInfoSelector } from '../../store/auth/selectors';
import { sharedFolderThunk } from '../../store/sharedFolders/thunk';
import { folderSummaryThunk } from '../../store/folderSummary/thunk';
import { Typography, Stack, Menu, MenuItem, Box, Checkbox } from '@mui/material';
import { shortenString } from '../../pages/Editor/helpers/helpers';
import {
  ContentCopyRounded,
  Link,
  MoreVertRounded,
  Keyboard,
  SubdirectoryArrowRightRounded,
} from '@mui/icons-material';
import { useFolderSummarySelector } from '../../store/folderSummary/selectors';
import { useSharedFoldersSelector } from '../../store/sharedFolders/selectors';
import { Button } from '@mui/material';
import { useDarkModeState } from '../../store/themeMode/selectors';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { BrowserUtils, RenderCondition, RenderIf } from '../../lib/utils';
import { parseDescription } from '../../lib/utils/helperFns/transformFns';
import { DisplayQueryEditor } from '../CustomSqlEditor/displayQueryEditor';
import { QueryItemFooter } from './components/footer';
import { MoveQueryModal } from '../modals/moveQueryModal';
import { Container } from './components/container';
import { QueryDetailInput } from '../../pages/Editor/components/input';
import { queriesAsyncThunk } from '../../store/queries/queries.thunk';
import { QueryName } from './components/queryName';
import { QueryDescription } from './components/queryDescription';
import { addShortcut, deleteShortcut, updateQueryVersion } from '../../pages/Editor/api/fetch';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { formatSql } from '../../utils/formaters';
import AceEditor from 'react-ace';
import { Logger } from '../../utils/browser/logger';
import { addStemsToText } from '../../utils/helpers';
import ApiClient from '../../lib/utils/apiClient';
import MemberActions from '../../pages/Editor/components/MemberActions';

interface SearchHighlightMap {
  title?: any;
  description?: any;
  parentFolder?: any;
  query?: any;
}

interface Props {
  query: string;
  query_uid: string;
  query_name: string;
  query_origin: string;
  query_created_at: string;
  query_description: string;
  query_owner_username: string;
  queryTags?: any[];
  tags?: any[];
  type: string;
  folderName?: string;
  folderId?: string;
  pageType?: 'search' | 'query';
  user_uid?: string;
  searchResultsProps?: {
    showHighlight?: boolean;
    keyword?: string;
  };
}

interface QueryMetaSummary {
  query_name: string;
  query_description: string;
  query_string: string;
}

const autoDescriptionStyle = {
  backgroundColor: '#2E7D3233',
  display: 'flex',
  borderRadius: '4px',
  minWidth: '16px'
};

function selectText(elementId: string) {
  const element = document.getElementById(elementId) as HTMLInputElement;
  
  if (element) {
    element.select()
    element.scrollLeft = 0;
  }
}

const QueryItem: React.FC<
  Props & { className?: string; disableOnClick?: boolean }
> = ({
  query,
  query_uid,
  query_name,
  query_origin,
  query_created_at,
  query_description,
  query_owner_username,
  queryTags = [],
  tags,
  type,
  folderName,
  folderId,
  pageType,
  user_uid,
  searchResultsProps,
  disableOnClick
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userInfo = useUserInfoSelector();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [showViewIcon, setShowViewIcon] = React.useState(false);
    const darkMode = useDarkModeState();
    const aceEditorRef = useRef(null);
    const queryItemRef = useRef(null);
    const [extraEditorProps, setExtraEditorProps] = useState<AceEditorExtraProps>(
      {}
    );
    const metaRef = useRef<QueryMetaSummary>({
      query_name,
      query_description,
      query_string: query
    }); // using a ref here to hold a reference to the meta summary across updates since the redux state for updating query and fetching folder content are in diff slices.
    const [meta, setMeta] = useState<QueryMetaSummary>({
      query_name,
      query_description,
      query_string: query
    });

    // const allowMetaEdit = userInfo ? user_uid === userInfo.uid : false;
    const allowMetaEdit = true
    const [isEditingQuery, setIsEditingQuery] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const showHighlight = Boolean(
      pageType === 'search' &&
      searchResultsProps?.showHighlight &&
      searchResultsProps.keyword
    );

    const onQueryNameClick = (e) => {
      if (allowMetaEdit) {
        e.stopPropagation();
        e.preventDefault();
        setIsEditingName(true)
        setTimeout(() => {
          if (document.getElementById('query-name')) {
            document.getElementById('query-name').focus()
          }
        }, 100)
        setTimeout(() => {
          if (document.getElementById('query-name')) {
            selectText('query-name')
          }
        }, 150)

      }
    }

    const onQueryDescriptionClick = (e) => {
      if (allowMetaEdit) {
        e.stopPropagation();
        e.preventDefault();
        setIsEditingDescription(true)
        setTimeout(() => {
          if (document.getElementById('query-description')) {
            document.getElementById('query-description').focus()
          }
        }, 100)
        setTimeout(() => {
          if (document.getElementById('query-description')) {
            selectText('query-description')
          }
        }, 150)
      }
    }

    const handleOnChangeMeta = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      if (name === 'query-name') {
        setMeta(p => ({ ...p, query_name: e.target.value }));
      } else if (name === 'query-description') {
        setMeta(p => ({ ...p, query_description: e.target.value }));
      }
    };

    const updateQueryMeta = (payload: Omit<QueryMetaSummary, 'query_string'>) => {
      if (meta.query_description !== metaRef.current.query_description) {
        ApiClient.post('queries/query_seen', { query_uid, action_type: 3 }, {
          isProtected: true,
        })
      }
      if (meta.query_name !== metaRef.current.query_name) {
        ApiClient.post('queries/query_seen', { query_uid, action_type: 2 }, {
          isProtected: true,
        })
      }

      dispatch(
        queriesAsyncThunk.update({
          uid: query_uid!,
          payload: {
            name: payload.query_name!,
            description: payload.query_description!,
            query: query
          },
          source: 'list'
        })
      ).then(() => {
        toast('Updated successfully', { type: 'success' });
        metaRef.current = { ...payload, query_string: query };
      });
    };

    const handleOnMetaKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isEnter = e.key === 'Enter';
      const noShiftKey = !e.shiftKey;
      if (isEnter && noShiftKey) {
        (e.target as HTMLInputElement).blur();
      }
      if (e.key === 'Escape') {
        handleOnMetaBlur(null, isEditingName ? 'query-name' : 'query-description')
      }
    };

    const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleQueryBlur = (value: string) => {
      handleUpdateQuery();
  
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current);
      }
  
      blurTimeoutRef.current = setTimeout(() => {
        setIsEditingQuery(false);
        blurTimeoutRef.current = null;
      }, 200);
    }
  
    const handleQueryFocus = (value: string) => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current);
        blurTimeoutRef.current = null;
      }
  
      setIsEditingQuery(true);
    }
  
    useEffect(() => {
      return () => {
        if (blurTimeoutRef.current) {
          clearTimeout(blurTimeoutRef.current);
        }
      };
    }, []);

    const handleOnMetaBlur = (e?: React.FocusEvent<HTMLInputElement>, fieldName?: string) => {
      const field = e ? e.target.name : fieldName;
      if (field === 'query-name') {
        const nameUpdated = meta.query_name !== metaRef.current.query_name;
        if (nameUpdated) {
          updateQueryMeta({
            query_description: metaRef.current.query_description,
            query_name: meta.query_name
          });
        }
        setTimeout(() => {
          setIsEditingName(false);
        }, 200)
      } else if (field === 'query-description') {
        const descriptionUpdated =
          meta.query_description !== metaRef.current.query_description;
        if (descriptionUpdated) {
          updateQueryMeta({
            query_description: meta.query_description,
            query_name: metaRef.current.query_name
          });
        }
        setTimeout(() => {
          setIsEditingDescription(false);
        }, 200)
      }
    };

    const updateQueryString = async () => {
      const queryVersion = {
        query_uid: query_uid,
        name: meta.query_name,
        description: meta.query_description,
        query: meta.query_string,
        user_uid: user_uid,
        created_at: new Date(query_created_at).getTime() / 1000,
        updated_at: new Date().getTime() / 1000
      };
      const {
        data: { id }
      } = await updateQueryVersion(queryVersion);
      ApiClient.post('queries/query_seen', { query_uid, action_type: 4 }, {
        isProtected: true,
      })
      MixedPanelEvent.queryInListAction({
        actionName: 'updated',
        query: {
          description: meta.query_description,
          name: meta.query_name,
          query: meta.query_string,
          uid: query_uid,
          created_at: query_created_at,
          user: userInfo,
          id
        }
      });
      toast('Query updated successfully', {
        type: 'success',
        hideProgressBar: true
      });
      metaRef.current.query_string = meta.query_string;
    };

    const handleOnChangeQueryString = (newString: string) => {
      setMeta(p => ({ ...p, query_string: newString }));
    };

    const handleUpdateQuery = () => {
      const queryStringUpdated =
        meta.query_string !== metaRef.current.query_string;
      if (queryStringUpdated) {
        updateQueryString();
      }
    }

    const handleOnEditorKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
      const isEscape = ev.key === 'Escape';
      // const notShift = !ev.shiftKey;
      if (isEscape) {
        ev.stopPropagation();
        aceEditorRef.current?.blur();
      }
    };

    const scrollIntoView = () => {
      // Find the first element with the class 'scroll-container'
      const scrollContainer = document.querySelector('.scroll-container');
    
      if (scrollContainer && queryItemRef.current) {
        let offsetTop = -180;
        let element = queryItemRef.current;
    
        // Traverse up the DOM tree until we reach the scroll container
        while (element && element !== scrollContainer) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
    
        // Scroll the container to bring the query item into view
        scrollContainer.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    };

    const sx1 = {
      fontSize: 16,
      color: darkMode ? 'white' : '#252631',
      '&:hover': { color: 'primary.main' }
    };
    const folderSummary = useFolderSummarySelector();
    const folderSummaryShared = useSharedFoldersSelector();
    const folders = unionBy(folderSummary.list, folderSummaryShared.list, 'uid');
    const [isShortcut, setIsShortcut] = useState(false)

    const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
    const reloadSavedQueries = async () => {
      setTimeout(() => {
        navigate('/saved-queries');
      }, 500);
    };
    let {
      moveQuery,
      openModal: openMoveQueryModal,
      toggleModal: toggleMoveQueryModal,
      onSelectFolder: onSelectMoveQueryFolder,
      selectedFolder: selectedMoveQueryFolder
    } = useMoveQuery({ onCompleteMove: reloadSavedQueries });
    const {
      deleteQuery,
      openModal: openDeleteQueryModal,
      toggleModal: toggleDeleteQueryModal
    } = useDeleteQuery();

    const handleOnManuallyCopyEditorText = (text: string) => {
      const currentUrl = location.origin + location.pathname;
      ApiClient.post('queries/query_seen', { query_uid, action_type: 9 }, {
        isProtected: true,
      })
      MixedPanelEvent.queryManuallyCopied({
        currentUrl,
        queryId: query_uid,
        copiedText: text
      });
    };

    const { isAuto } = parseDescription(query_description);

    const handleDeletion = () => {
      deleteQuery(query_uid, folderId!);
      toggleDeleteQueryModal();
    };
    const navigateToEditorPage = () => {
      if (disableOnClick || isEditingName || isEditingDescription || isEditingQuery) {
        return;
      }
      ApiClient.post('queries/query_seen', { query_uid, action_type: 6 }, {
        isProtected: true,
      })
      if (
        type === 'saved' ||
        type === 'shared' ||
        (pageType !== 'search' && !type.includes('history'))
      ) {
        MixedPanelEvent.queryClicked(query);
        const encodedFolderName = encodeURIComponent(folderName);
        navigate(
          `/queries/${encodedFolderName}/${query_uid}/${type}/${folderId}`
        );
      }
      if (pageType === 'search' && !type.includes('history')) {
        navigate(
          `/queries/search/${folderName}/${query_uid}/${type}/${folderId}`
        );
      }
      if (type === 'my-history') {
        MixedPanelEvent.queryHistoryClicked(query);
        navigate(`/queries/hitory/my/${query_uid}`);
      }
      if (type === 'history') {
        MixedPanelEvent.queryHistoryClicked(query);
        navigate(`/queries/hitory/public/${query_uid}`);
      }
    };

    const handleShareQuery = e => {
      e.stopPropagation();
      MixedPanelEvent.editorListItemLinkAction(query, query_uid);
      ApiClient.post('queries/query_seen', { query_uid, action_type: 10 }, {
        isProtected: true,
      })
      const url = `${process.env.REACT_APP_SHERLOQ_AUTH_URL}/queryInfo/?queryId=${query_uid}`;
      BrowserUtils.copyTextToClipboard(url);
      toast('Shared link successfully copied to clipboard', {
        type: 'success'
      });
    };

    const getKeywordMatches = useCallback(
      (text: string) => {
        if (typeof text !== 'string') {
          return [];
        } // Handle non-string inputs
        const matches = match(text, addStemsToText(searchResultsProps?.keyword), {
          insideWords: true
        });
        return parse(text, matches ?? []);
      },
      [searchResultsProps?.keyword]
    );

    const formattedQuery = useMemo(() => {
      // return formatSql(meta.query_string);
      return meta.query_string;
    }, [meta.query_string]);

    const editorMarkers = useMemo(() => {
      const queryArray = formattedQuery?.toLowerCase().split('\n') ?? [];
      const markers = [];
      queryArray.forEach((el, i, arr) => {
        addStemsToText(searchResultsProps?.keyword.toLowerCase()).split(' ').forEach(keyword => {
          if (keyword && el.includes(keyword)) {
            markers.push({
              startRow: i,
              startCol: queryArray[i].indexOf(keyword),
              endRow: i,
              endCol: arr[i].indexOf(keyword) + keyword.length,
              className: 'ace_highlighter',
              type: 'text'
            });
          }
        })
       
      });

      return markers;
    }, [searchResultsProps?.keyword, formattedQuery]);

    const [editorEl, setEditorEl] = useState(null)
    const [firstMarkerRow, setFirstMarkerRow] = useState(0)

    const editorMaxHeight = () => {
      const editor = editorEl
      if (!editor) return
      // const editor = aceEditorRef.current.editor;
      const lineHeight = editor.renderer.lineHeight;
      const contentHeight = editor.getSession().getScreenLength() * lineHeight;
  
      // Adding a buffer to ensure all lines are visible
      const buffer = lineHeight * 2; // Adding one line height as buffer
      return contentHeight + buffer
    }

    const handleOnLoadEditor = (editor: AceEditor['editor']) => {
      if (editorEl) return
      setEditorEl(editor)
      const firstMarkerIsOutOfView = editorMarkers[0]?.startRow > 6;
      if (firstMarkerIsOutOfView) {
        setFirstMarkerRow(editorMarkers[0]?.startRow);
        editor.scrollToRow(editorMarkers[0]?.startRow);
      }
    };

    const preventScrolling = (e) => {
      if (editorEl && !editorEl.isFocused()) {
        editorEl.scrollToRow(firstMarkerRow)
        if (document.getElementsByClassName('scroll-container').length) {
          document.getElementsByClassName('scroll-container')[0].scrollBy(0, e.deltaY);
        }
        if (document.getElementsByClassName('search-list-container').length) {
          document.getElementsByClassName('search-list-container')[0].scrollBy(0, e.deltaY);
        }
      }
      if (editorEl && editorEl.isFocused() && editorEl.container.clientHeight + 16 < editorMaxHeight()) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    useEffect(() => {
      if (editorEl) {
        editorEl.container.addEventListener('wheel', preventScrolling)
      }
      return (() => {
        if (editorEl) {
          editorEl.container.removeEventListener('wheel', preventScrolling)
        }
      })
    }, [editorEl])

    const handleShortcutCheckboxChange = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (isShortcut) {
        if (query_uid) {
          deleteShortcut(query_uid);
          toast('Snippet has been removed successfully.', {
            type: 'success'
          });
          MixedPanelEvent.deleteQueryFromSnippets(
            query ?? 'Query string n/a'
          );
        }
      } else {
        if (query_uid) {
          addShortcut(query_uid);
          toast('Snippet added successfully. Type @@ in your query editor to open Snippets menu.', {
            type: 'success'
          });
          MixedPanelEvent.setQueryAsSnippet(query ?? 'Query string n/a');
        }
      }
      ApiClient.post('queries/query_seen', { query_uid, action_type: 7 }, {
        isProtected: true,
      })
      setIsShortcut(!isShortcut);
    };

    const highlightMap = useMemo(() => {
      const highlightMap: SearchHighlightMap = {
        description: [],
        parentFolder: [],
        title: [],
        query: []
      };
      if (
        pageType === 'search' &&
        searchResultsProps?.showHighlight &&
        searchResultsProps?.keyword
      ) {
        const parentFolderName = shortenString(`${folderName}`, 30);
        // create highlight map
        const description = meta?.query_description.length > 50 ? meta?.query_description.substring(0, 50) + '...' : meta?.query_description
        highlightMap.title = getKeywordMatches(meta?.query_name);
        highlightMap.description = getKeywordMatches(description);
        highlightMap.parentFolder = getKeywordMatches(parentFolderName);
      }
      return highlightMap;
    }, [pageType, searchResultsProps, meta, folderName]);

    useEffect(() => {
      if (folderSummary && folderSummary.shortcuts && folderSummary.shortcuts.length) {
        setIsShortcut(folderSummary.shortcuts.find(sc => sc.query.uid === query_uid))
      }
    }, [folderSummary.shortcuts])

    const [querySeen, setQuerySeen] = useState([]);
    const [querySeenUsers, setQuerySeenUsers] = useState([]);

    useEffect(() => {
      if ((type.includes('history'))) return;
      ApiClient.get(`/queries/${query_uid}/query_seen`, { isProtected: true }).then((res => {
        const allQueryseen = [...res.data, {
          created_at: query_created_at,
          action_type: 1,
          user: {
            first_name: query_owner_username.split(' ')[0],
            last_name: query_owner_username.split(' ')[1],
          }
        }]
        setQuerySeen(allQueryseen.sort((a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime()))
        const tempSeenUsers = []
    
        allQueryseen.sort((a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime()).forEach(qs => {
          if (!tempSeenUsers.find(qs2 => (qs2.first_name === qs.user.first_name && qs2.last_name === qs.user.last_name))) {
            tempSeenUsers.push(qs.user)
          }
        })
    
        setQuerySeenUsers(tempSeenUsers)
      }))
    }, [])
    const edited = useMemo(() => {
      return querySeen.find(qs => qs.action_type === 4) || querySeen.find(qs => qs.action_type === 1)
    }, [querySeen])

    const content = useMemo(() => {
      return {
        created_at: query_created_at,
        user_first_name: query_owner_username.split(' ')[0],
        user_last_name: query_owner_username.split(' ')[1],
      }
    }, [querySeen])

    return (
      <>
        <Container
          onClick={navigateToEditorPage}
          onMouseEnter={() => setShowViewIcon(true)}
          onMouseLeave={() => setShowViewIcon(false)}
          className="queryItem"
          ref={queryItemRef}
          sx={{ cursor: disableOnClick || isEditingName || isEditingDescription || isEditingQuery ? 'default' : 'pointer', userSelect: 'none' }}
        >
          <div className='member-actions-container'>
            <MemberActions {... {querySeen, querySeenUsers, edited, content, queryName: query_name }} />
          </div>

          {(
            <Box
              className="QueryItemActionList"
              style={{
                position: 'absolute',
                top: 4,
                right: 0
              }}
            >
              <Box sx={{ height: 22, display: 'flex', alignItems: 'start' }} >
                <Button
                  onClick={handleShortcutCheckboxChange}
                  sx={{ padding: 0, margin: 0, minWidth: 0 }}
                >
                  <CustomTooltip title={`${isShortcut ? 'Remove Snippet' : 'Add as snippet to IDE'}`} placement="top">
                    <Keyboard sx={{ ...(darkMode ? sx.sx6dark : sx.sx6), color: isShortcut ? '#3F61B9' : (darkMode ? sx.sx6dark.color : sx.sx6.color) }} />
                  </CustomTooltip>
                </Button>
              </Box>
              <Box sx={{ cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
                <CopyToClipboard
                  text={query}
                  onCopy={() => {
                    if (type.includes('history')) {
                      MixedPanelEvent.queryHistoryCopyQuery(query);
                    } else {
                      MixedPanelEvent.queryCopyClicked(query);
                    }
                    ApiClient.post('queries/query_seen', { query_uid, action_type: 8 }, {
                      isProtected: true,
                    })

                    toast('Query successfully copied to clipboard ', {
                      type: 'success'
                    });
                  }}
                >
                  <CustomTooltip title="Copy query" placement="top">
                    <ContentCopyRounded sx={darkMode ? sx.sx6dark : sx.sx6} />
                  </CustomTooltip>
                </CopyToClipboard>
              </Box>
              <Box sx={{ height: 22, display: 'flex', alignItems: 'start' }} >
                <Button
                  onClick={handleShareQuery}
                  sx={{ padding: 0, margin: 0, minWidth: 0 }}
                >
                  <CustomTooltip title="Copy a link" placement="top">
                    <Link sx={sx1} />
                  </CustomTooltip>
                </Button>
              </Box>
              {!type.includes('history') && allowMetaEdit && (
                <Box
                  id="queryItemMore"
                  aria-controls={Boolean(anchorEl2) ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl2) ? 'true' : undefined}
                  onClick={e => {
                    setAnchorEl2(e.currentTarget);
                    e.stopPropagation();
                  }}
                >
                  <CustomTooltip title="More actions" placement="top">
                    <MoreVertRounded sx={darkMode ? sx.sx6dark : sx.sx6} />
                  </CustomTooltip>
                </Box>
              )}
            </Box>
          )}
          {false && !type.includes('history') && <Box sx={{ position: 'absolute', right: 12, top: 76 }}>
            <CustomTooltip
              title="Type @@ in your query editor to use Snippets"
              placement="top"
            >
              <Box
                className="checkbox-section joyride_snippet"
                onClick={handleShortcutCheckboxChange}
              >
                <Checkbox
                  sx={{
                    padding: 0,
                    marginRight: '4px',
                    width: 14,
                    height: 14,
                    color: 'text.primary'
                  }}
                  checked={isShortcut}
                />
                <Typography
                  sx={{ lineHeight: '16px' }}
                  variant="caption"
                  color="text.primary"
                >
                  Save to Snippets
                </Typography>
              </Box>
            </CustomTooltip>
          </Box> }
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClick={e => {
              e.stopPropagation();
            }}
            onClose={() => setAnchorEl2(null)}
            MenuListProps={{
              'aria-labelledby': 'queryItemMore'
            }}
          >
            {user_uid === userInfo?.uid && (
              <MenuItem
                sx={darkMode ? sx.sx3dark : sx.sx3}
                key={'move'}
                onClick={e => {
                  setAnchorEl2(null);
                  toggleMoveQueryModal();
                  e.stopPropagation();
                }}
              >
                Move to
              </MenuItem>
            )}
            {user_uid === userInfo?.uid && (
              <MenuItem
                sx={darkMode ? sx.sx3dark : sx.sx3}
                key={'delete'}
                onClick={e => {
                  setAnchorEl2(null);
                  toggleDeleteQueryModal();
                  e.stopPropagation();
                }}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
          <Box
            className={
              type.includes('history')
                ? 'queryItemContainer QueryItemActionListHistory'
                : 'queryItemContainer'
            }
          >
            <Box className="folder-name-container">
              {type.includes('history') ? (
                <Box
                  sx={{
                    flex: 1,
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="body1"
                    className="query-name"
                    color={darkMode ? 'white' : '#252525'}
                  >
                    Query History
                  </Typography>
                  <DisplayQueryEditor
                    query={query}
                    onManualCopy={handleOnManuallyCopyEditorText}
                    searchText={searchResultsProps?.keyword.toLowerCase()}
                  />
                </Box>
              ) : (
                <Box sx={{ width: '100%' }}>
                  {RenderCondition(
                    showHighlight,
                    <Fragment>
                      <Typography>
                        {highlightMap.title.map((part, idx) => (
                          <Typography
                            component={'span'}
                            key={idx}
                            sx={{
                              backgroundColor: part.highlight
                                ? 'rgba(255,255,0,0.7)'
                                : 'transparent'
                            }}
                          >
                            {part.text}
                          </Typography>
                        ))}
                      </Typography>
                    </Fragment>,
                    RenderCondition(
                      allowMetaEdit && isEditingName,
                      <QueryDetailInput
                        name="query-name"
                        onChange={handleOnChangeMeta}
                        onClick={stopPropagation}
                        value={meta.query_name}
                        onKeyDown={handleOnMetaKeyDown}
                        onBlur={handleOnMetaBlur}
                        placeholder="Query name"
                        maxRows={1}
                        multiline={false}
                        fullWidth={false}
                        sx={{ width: '65%' }}
                      />,
                      <QueryName value={meta.query_name} onClick={onQueryNameClick}/>
                    )
                  )}

                  <Stack
                    direction="row"
                    alignItems="left"
                    gap={1}
                    style={{ marginBottom: '8px' }}
                  >
                    <SubdirectoryArrowRightRounded
                      sx={{
                        fontSize: '14px',
                        color: darkMode ? 'white' : 'rgba(0, 0, 0, 0.6)'
                      }}
                    />
                    {RenderCondition(
                      showHighlight,
                      <Typography>
                        {highlightMap.parentFolder.map((part, idx) => (
                          <Typography
                            component={'span'}
                            variant="caption"
                            key={idx}
                            sx={{
                              backgroundColor: part.highlight
                                ? 'rgba(255,255,0,0.7)'
                                : 'transparent'
                            }}
                          >
                            {part.text}
                          </Typography>
                        ))}
                      </Typography>,
                      <Typography variant="caption">{folderName}</Typography>
                    )}
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '5px',
                      width: '100%'
                    }}
                  >
                    {RenderIf(
                      isAuto,
                      <CustomTooltip title="Auto-Generated Description">
                        <Box sx={autoDescriptionStyle}>
                          <AutoAwesomeIcon
                            sx={{
                              fontSize: '12px',
                              margin: 'auto',
                              color: '#2E7D32'
                            }}
                          />
                        </Box>
                      </CustomTooltip>
                    )}
                    {RenderCondition(
                      showHighlight,
                      <Fragment>
                        <Typography>
                          {highlightMap.description.map((part, idx) => (
                            <Typography
                              component={'span'}
                              key={idx}
                              sx={{
                                backgroundColor: part.highlight
                                  ? 'rgba(255,255,0,0.7)'
                                  : 'transparent',
                              }}
                            >
                              {part.text}
                            </Typography>
                          ))}
                        </Typography>
                      </Fragment>,
                      RenderCondition(
                        allowMetaEdit && isEditingDescription,
                        <QueryDetailInput
                          minRows={2}
                          value={meta.query_description}
                          name="query-description"
                          onChange={handleOnChangeMeta}
                          onClick={stopPropagation}
                          onKeyDown={handleOnMetaKeyDown}
                          onBlur={handleOnMetaBlur}
                        />,
                        <QueryDescription value={meta?.query_description.length > 50 ? meta?.query_description.substring(0, 50) + '...' : meta?.query_description} onClick={onQueryDescriptionClick}/>
                      )
                    )}
                  </Box>
                  <Box sx={{ marginTop: '4px' }}>
                    <Tags
                      query_uid={query_uid}
                      preQueryTags={
                        queryTags && pageType === 'search'
                          ? queryTags
                          : queryTags?.map(t => t.tag)
                      }
                      preTags={tags}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {!type.includes('history') && (
              <Box onClick={stopPropagation} onKeyDown={handleOnEditorKeyDown} style={{ position: 'relative' }}>
                <CustomSqlEditor
                  name="query"
                  value={formattedQuery}
                  variant="outlined"
                  height={`100px`}
                  width="100%"
                  readOnly={!allowMetaEdit}
                  onManualCopy={handleOnManuallyCopyEditorText}
                  onChange={handleOnChangeQueryString}
                  ref={aceEditorRef}
                  markers={editorMarkers}
                  onLoad={handleOnLoadEditor}
                  onBlur={handleQueryBlur}
                  onFocus={handleQueryFocus}
                  showExpandIcon
                  onMinimize={scrollIntoView}
                />
              </Box>
            )}
          </Box>
          <QueryItemFooter
            data={{
              type,
              query_origin,
              query_owner_username,
              query_created_at,
              parentFolderId: folderId,
              showHighlight,
              keyword: searchResultsProps?.keyword,
              edited
            }}
          />
        </Container>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none'
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          disableRestoreFocus
        >
          <Box className="query-editor-popover">
            <CustomSqlEditor
              name="query"
              value={formattedQuery}
              variant="outlined"
              height="350px"
              width="100%"
              readOnly={!allowMetaEdit}
              onManualCopy={handleOnManuallyCopyEditorText}
              onChange={handleOnChangeQueryString}
              ref={aceEditorRef}
              markers={editorMarkers}
              onLoad={handleOnLoadEditor}
            />
          </Box>
        </Popover>
        <DeleteModal
          show={openDeleteQueryModal}
          setClose={toggleDeleteQueryModal}
          handleDelete={() => handleDeletion()}
          title={'Delete query?'}
          subTitle="Are you sure you want to delete this query?"
        />
        <MoveQueryModal
          value={selectedMoveQueryFolder}
          onChange={folder => onSelectMoveQueryFolder(folder.uid)}
          folders={folders}
          open={openMoveQueryModal}
          onClose={toggleMoveQueryModal}
          handleSubmit={() => {
            if (!selectedMoveQueryFolder) {
              selectedMoveQueryFolder = folders[0].uid;
            }
            ApiClient.post('queries/query_seen', { query_uid, action_type: 14 }, {
              isProtected: true,
            })
            moveQuery(selectedMoveQueryFolder, query_uid, folderId!, query_name);
            dispatch(folderSummaryThunk.index());
            dispatch(sharedFolderThunk.index());
          }}
          defaultValue={folderId!}
        />
      </>
    );
  };

export default QueryItem;

function stopPropagation(e) {
  e.stopPropagation();
}
