import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { InternalTeam } from './type';

const index = createAsyncThunk(
    'shared-folders/internal_teams',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.get<InternalTeam[]>(`/shared-folders/internal_teams`, {
                isProtected: true,
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(String(error));
        }
    },
);

const create = createAsyncThunk(
    'shared-folders/create_internal_teams',
    async (payload: { name: string, user_emails: string[]}, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await ApiClient.post<InternalTeam>(`/shared-folders/internal_teams`, payload, {
                isProtected: true,
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(String(error));
        }
    },
);


export const internalTeamThunk = { index, create };
