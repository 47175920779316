import {
  Button,
  ButtonProps,
  TypographyProps,
  Box,
  SxProps
} from '@mui/material';

import React from 'react';
import {
  dropdownListItemSummaryMenuItemIconStyles,
  selectedFolderContainerStyles
} from '../styles';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { sx } from '../../../styles/MUI/styles';
import { RenderCondition } from '../../../lib/utils';
import { FolderSummary, FolderType } from '../../../store/folderSummary/type';
import { FolderIcon } from './folderIcon';
import { Typography } from '../../../atoms/Typography';

interface SelectedFolderProps extends ButtonProps {
  placeholder?: string;
  textProps?: TypographyProps;
  data: FolderSummary;
  iconStyles?: SxProps;
}

export const SelectedDropdownItem = ({
  textProps,
  placeholder,
  data,
  iconStyles,
  sx: buttonSx,
  ...props
}: SelectedFolderProps) => {
  const name = data?.name ? data?.name : data?.folder_name;

  return (
    <Button
      {...props}
      sx={{
        ...selectedFolderContainerStyles,
        ...(buttonSx as any)
      }}
      variant="text"
      aria-haspopup="true"
    >
      <Box sx={dropdownListItemSummaryMenuItemIconStyles}>
        <FolderIcon
          styles={iconStyles}
          type={data?.shared_type as FolderType}
        />
      </Box>
      {RenderCondition(
        !!placeholder && !name,
        <Typography {...textProps}>{placeholder}</Typography>,
        <Typography {...textProps}>{name}</Typography>
      )}
      <ArrowDropDownRounded sx={sx.sx5} />
    </Button>
  );
};
