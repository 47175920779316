import { Button } from '@mui/material';
import { DiscoveryFeedbackBtnsBox } from '../../../pages/Watson/components/components';
import { ConditionComponent } from '../../../lib/utils';
import { AiBotMessageType, AiBotVariantMeta } from '../../types';
import { useDiscoveryBotPhaseResponseActions } from './hooks/useDiscoveryBotPhaseResponseActions';
import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../hooks/useAiBotVariantMeta';
import MixedPanelEvent from '../../../utils/analytics/mixPanel';
import { useDiscoveryAiBotActions } from './hooks/useDiscoveryActions';
import { useAiBotDataStoreActions } from '../../hooks/useAiBotDataStoreActions';
import { useAppDispatch } from '../../../store';
import { toggleShowConfetti } from '../../../store/confetti';
import { AiBotVariant } from '../../domain/chatTypes';
import { uniqueId } from 'lodash';

interface Props {
  meta: AiBotVariantMeta;
}

const FeedbackPhase = ({ meta }: Props) => {
  const { disableFeedbackState, setDiscoveryPhase } =
    useDiscoveryBotPhaseResponseActions();
  const { sessionId: conversation_uid, chatType } = useAiBotMeta();
  const { discoveryType, discoveryPhasePayload } = useAiBotVariantMeta();
  const { chooseAsset, reloadFilters } = useDiscoveryAiBotActions();
  const { clearMessages, addToBotMessages } = useAiBotDataStoreActions();
  const dispatch = useAppDispatch();

  const sendNoAndEditFiltersMessage = () => {
    const message: AiBotMessageType = {
      message: 'Is there any information you have about the table?',
      chatType: AiBotVariant.BotsonDiscovery,
      variant: 'response',
      conversationId: conversation_uid,
      status: 'success',
      id: uniqueId()
    };
    addToBotMessages(message);
  };

  const handleOnClickFeedback = async (
    val: 'yes' | 'no' | 'restart' | 'join' | 'edit-filters' | 'where'
  ) => {
    setDiscoveryPhase(null);
    if (['join', 'where'].includes(val)) {
      MixedPanelEvent.watsonNewJoinButtonClick({
        conversation_uid,
        question_type: discoveryType
      });
      chooseAsset(val === 'join' ? 'join' : 'where');
      return;
    }
    if (val === 'restart') {
      clearMessages();
      MixedPanelEvent.watsonFeedbackRestartConversation({
        conversation_uid,
        question_type: discoveryType
      });
      return;
    }
    if (val === 'no' || val === 'edit-filters') {
      sendNoAndEditFiltersMessage();
      disableFeedbackState();
      if (val === 'no') {
        MixedPanelEvent.watsonMessageDisliked({
          conversation_uid,
          question_type: discoveryType,
          chat_type: chatType,
          message: ''
        });
      }
      setTimeout(() => {
        reloadFilters(discoveryPhasePayload?.discoveryFilters);
      }, 1200);
    } else {
      dispatch(toggleShowConfetti(true));
      MixedPanelEvent.watsonMessageLiked({
        conversation_uid,
        question_type: discoveryType,
        chat_type: chatType,
        message: ''
      });
      setDiscoveryPhase('post-feedback');
    }
  };

  return (
    <DiscoveryFeedbackBtnsBox data-type={'phase'}>
      <ConditionComponent condition={meta.discoveryType === 'join'}>
        <Button
          variant="contained"
          onClick={() => handleOnClickFeedback('join')}
        >
          Try join on other tables
        </Button>
      </ConditionComponent>
      <ConditionComponent condition={meta.discoveryType === 'where'}>
        <Button
          variant="contained"
          onClick={() => handleOnClickFeedback('where')}
        >
          Search filter for other table
        </Button>
      </ConditionComponent>
      <ConditionComponent
        condition={!['join', 'where'].includes(meta.discoveryType)}
      >
        <Button
          variant="contained"
          onClick={() => handleOnClickFeedback('yes')}
        >
          Yes
        </Button>
        <Button
          disabled={meta?.discoveryPhasePayload?.disableEditFilters}
          variant="contained"
          onClick={() => handleOnClickFeedback('no')}
        >
          No
        </Button>
        <Button
          disabled={meta?.discoveryPhasePayload?.disableEditFilters}
          variant="contained"
          onClick={() => handleOnClickFeedback('edit-filters')}
        >
          Edit Filters
        </Button>
      </ConditionComponent>

      <Button
        variant="contained"
        onClick={() => handleOnClickFeedback('restart')}
      >
        Restart and ask a new question
      </Button>
    </DiscoveryFeedbackBtnsBox>
  );
};

export default FeedbackPhase;
