import { useEffect, useRef } from 'react';
import { useFolderTree } from '../useFolderTree';

type SherloqCommandsFromClientType = 'saveQuery' | 'saveAs';

interface SherloqCommandFromClient {
  exec: SherloqCommandsFromClientType;
  payload: any;
}

interface SherloqMessageEvent {
  sherloq_command_to_iframe: SherloqCommandFromClient;
}

export const useSubscribeToSaveQueryFromExtClient = () => {
  const ref = useRef<any>(null);
  const folders = useFolderTree();

  useEffect(() => {
    const handleMessage = (ev: MessageEvent<SherloqMessageEvent>) => {
      const cmd = ev.data.sherloq_command_to_iframe;
      if (cmd?.exec === 'saveQuery') {
        const defaultFolder = folders.find(
          folder => folder.name === cmd.payload.folderName
        );
        ref?.current?.openAccordion(true, defaultFolder.uid);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [folders]);

  return { ref };
};
