import { Fragment } from 'react';
import { BaseMessengerBox } from './styled';
import { MessageWrapper } from '../../../pages/Watson/components/components';
import { ConditionComponent } from '../../../lib/utils';
import { AiBotMessageType } from '../../types';
import { UserMessageAvatar } from '../../../pages/Watson/components/atoms/userMessageAvatar';
import { AiChatIconVariant } from '../../../pages/Watson/components/AiChatIcon';
import { ThreadMessageInset } from '../../../pages/Watson/styles';
import { MessageContainer } from '../../../pages/Watson/components/atoms/messageContainer';
import { AiBotVariant } from '../../domain/chatTypes';
import { AiBotMessageRootDataAdapter } from './AiBotMessageRootDataAdapter';

export const AiBotRootMessageRenderer = (props: { data: AiBotMessageType }) => {
  const { data } = props;
  const { variant } = data;
  const isResponse = variant === 'response';

  const showUserAvatar = variant === 'question';
  const isThreadMessage = data.isThread;
  const showBotIcon = !isThreadMessage && variant === 'response';

  return (
    <Fragment>
      <BaseMessengerBox>
        <MessageWrapper flexDirection={isResponse ? 'row' : 'row-reverse'}>
          <ConditionComponent condition={showUserAvatar}>
            <UserMessageAvatar />
          </ConditionComponent>
          <ConditionComponent condition={showBotIcon}>
            <AiChatIconVariant variant={data.chatType} />
          </ConditionComponent>
          <MessageContainer
            isGpt={data.chatType === AiBotVariant.GPT4}
            className="message"
            variant={variant}
          >
            <div className="message-body-container">
              <AiBotMessageRootDataAdapter data={data} />
            </div>
          </MessageContainer>
        </MessageWrapper>
      </BaseMessengerBox>
      <ConditionComponent condition={isThreadMessage}>
        <ThreadMessageInset />
      </ConditionComponent>
    </Fragment>
  );
};
