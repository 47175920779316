import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/ext-language_tools';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { useSubscribeToCopyEvents } from '../../lib/hooks';
import { useTheme } from '@mui/material';
import ReactAce from 'react-ace/lib/ace';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

export interface AceEditorExtraProps {
  autoScrollToRowOnMount?: number;
}

interface Props extends IAceEditorProps, AceEditorExtraProps {
  value?: string;
  readOnly?: boolean;
  className?: string;
  height?: string;
  width?: string;
  variant?: 'default' | 'outlined' | 'custom-editor';
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  name?: string;
  placeholder?: string;
  isFocussed?: boolean;
  style?: React.CSSProperties;
  header?: boolean;
  headerDate?: string;
  liveAutocompletion?: boolean;
  onManualCopy?: (text: string) => Promise<void> | void;
  showExpandIcon?: boolean;
  onMinimize?: () => void
}

const Component = forwardRef(
  (
    {
      value,
      height = '300px', // Default height
      width = '100%',
      readOnly = true,
      variant,
      className,
      name,
      placeholder,
      isFocussed,
      onChange,
      onFocus,
      onBlur,
      style,
      liveAutocompletion = false,
      onManualCopy,
      markers,
      showExpandIcon,
      onMinimize,
      ...props
    }: Props,
    ref
  ) => {
    const aceRef = useRef<ReactAce>(null);
    const darkMode = useDarkModeState();
    const { palette } = useTheme();
    const [inputState, setInputState] = useState({
      focused: false,
      blur: false
    });
    const [editorHeight, setEditorHeight] = useState(height);
    const [isExpanding, setIsExpanding] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null);

    const editorStyles: React.CSSProperties = useMemo(() => {
      const { focused, background, border } = (palette as any).input;

      const borderColor = inputState.focused ? focused : border;
      return {
        borderColor: borderColor,
        backgroundColor: background,
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '2px',
        position: 'relative',
        height: editorHeight,
        width: width,
        overflow: 'hidden',
        transition: isExpanding ? 'height 300ms ease' : 'none'
      };
    }, [palette, inputState, editorHeight, width, isExpanding]);

    const handleOnFocus = ev => {
      setInputState({ blur: false, focused: true });
      onFocus?.(ev);
    };
    const handleOnBlur = ev => {
      setInputState({ blur: true, focused: false });
      onBlur?.(ev);
    };

    const handleMouseDown = (e) => {
      const startY = e.clientY;
      const startHeight = divRef.current.offsetHeight;

      const onMouseMove = (e) => {
        const newHeight = startHeight + e.clientY - startY;
        setEditorHeight(`${newHeight}px`);
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    useImperativeHandle(ref, function () {
      return {
        blur: () => {
          aceRef.current.editor.blur();
        }
      };
    });

    const subscribeRef = useSubscribeToCopyEvents({
      onCopy: onManualCopy,
      enable: Boolean(onManualCopy)
    }).ref;
    const divRef = useRef<HTMLDivElement>(null);

    const combinedRef = useCallback(
      (node: HTMLDivElement) => {
        subscribeRef.current = node;
        divRef.current = node;
      },
      [subscribeRef]
    );

    const editorMaxHeight = () => {
      const editor = aceRef?.current?.editor
      if (!editor) return
      // const editor = aceEditorRef.current.editor;
      const lineHeight = editor.renderer.lineHeight;
      const contentHeight = editor.getSession().getScreenLength() * lineHeight;
  
      // Adding a buffer to ensure all lines are visible
      const buffer = lineHeight * 2; // Adding one line height as buffer
      return contentHeight + buffer
    }

    const updateEditorHeight = (type: string) => {
      MixedPanelEvent.expandButtonClicked({ type })
      setIsExpanding(true)
      setTimeout(() => {
        setIsExpanding(false)
      }, (300));
      if (type === 'maximize') {
        setEditorHeight(`${editorMaxHeight()}px`);
      } else {
        setEditorHeight('100px')
        onMinimize();
      }
    };

    useEffect(() => {
      setEditorHeight(height)
    }, [height])

    return (
      <div ref={combinedRef} style={editorStyles}>
        <div style={{ width: '100%', height: 'calc(100% - 10px)', overflow: 'auto' }}>
        { showExpandIcon && <div className={`query-expand-container ${darkMode ? '' : 'light-mode'}`} onClick={() => updateEditorHeight('maximize')} style={{ display: Number(editorHeight.split('px')[0]) >= editorMaxHeight() ? 'none' : 'block' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="4" fill="#333333"/>
                    <path d="M6 18V14.7273M6 18H9.27273M6 18L10.5455 13.4545M18 6L14.7273 6M18 6V9.27273M18 6L13.4545 10.5455" stroke="#B1B1B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div> }
        { showExpandIcon && <div className={`query-expand-container ${darkMode ? '' : 'light-mode'}`} onClick={() => updateEditorHeight('minimize')} style={{ display: Number(editorHeight.split('px')[0]) >= Math.max(editorMaxHeight(), 101) ? 'block' : 'none' }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="4" fill="#333333"/>
                    <path d="M10.5455 13.4545V16.7273M10.5455 13.4545H7.27273M10.5455 13.4545L6 18M13.4545 10.5455H16.7273M13.4545 10.5455V7.27273M13.4545 10.5455L18 6" stroke="#B1B1B1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div> }
          <AceEditor
            placeholder={placeholder}
            className={
              variant == 'custom-editor'
                ? 'custom-editor'
                : `custom-editor
                      ${variant ?? ''} ${className ?? ''} ${
                    isFocussed ? 'sherloq-editor-focused' : ''
                  }`
            }
            ref={aceRef}
            enableLiveAutocompletion={liveAutocompletion}
            readOnly={readOnly}
            highlightActiveLine={false}
            showPrintMargin={false}
            showGutter={false}
            mode="mysql"
            width="100%"
            height="100%"
            value={value}
            onChange={onChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            name={name}
            theme={darkMode ? 'monokai' : 'sqlserver'}
            markers={markers}
            setOptions={{
              useWorker: true
            }}
            {...props}
            />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '10px',
            cursor: 'row-resize',
            background: 'rgba(0,0,0,0)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onMouseDown={handleMouseDown}
        >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <circle cx="5" cy="12" r="2" fill="currentColor" />
        <circle cx="12" cy="12" r="2" fill="currentColor" />
        <circle cx="19" cy="12" r="2" fill="currentColor" />
      </svg>
        </div>
      </div>
    );
  }
);

export default Component;
