import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import React, { Suspense } from 'react';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoutes';
import { ErrorFallback } from './ErrorFallback';

interface Props extends ProtectedRouteProps {}

const SuspendedProtectedPage: React.FC<Props> = ({ element, path }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={<Box style={{ background: 'white', zIndex: 10000000 }} />}
      >
        <ProtectedRoute path={path} element={element} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default SuspendedProtectedPage;
