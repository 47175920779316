import { ignoreError } from '../../lib/utils/errorHandling/ignoreError';
import { documentQueryVersion } from '../../pages/Editor/api/fetch';
import { localTime } from '../../pages/Editor/helpers/helpers';
import store from '../../store';
import { queriesAsyncThunk } from '../../store/queries/queries.thunk';
import { NewCreateQueryPayload } from '../../store/queries/types';

const DEFAULT_DESCRIPTION = 'Click here to edit description';
const AUTO_DESCRIPTION_PREFIX = 'AutoGenerated | ';

const generateDescription = async (query: string) => {
  const data = await documentQueryVersion(query).catch(ignoreError);
  if (!data) {
    return DEFAULT_DESCRIPTION;
  }
  return data.data?.res;
};

const generateQueryName = () => {
  return localTime(new Date());
};

const createNewQueryFactory = async (_payload: NewCreateQueryPayload) => {
  const dispatch = store.dispatch;
  const payload = { ..._payload };
  if (!payload.query?.name) {
    payload.query.name = generateQueryName();
  }
  if (!payload.query?.description) {
    const desc = await generateDescription(payload.query?.query);
    payload.query.description = AUTO_DESCRIPTION_PREFIX + desc;
  }

  return await dispatch(
    queriesAsyncThunk.create({
      ...payload,
      withAnalytics: true,
      withToast: false
    })
  ).unwrap();
};

export default createNewQueryFactory;
