import { TableValueText } from '../styled';
import { Typography } from '@mui/material';
import { ITableProfileUserJoin } from '../../../../../utils/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import {
  CopyIcon,
  MostJoinedRowDataBox,
  MostJoinedRowDataRight,
  MostJoinedRowDataTitle
} from '../../../components/MostJoinedStyledComponents';
import MixedPanelEvent from '../../../../../../utils/analytics/mixPanel';
import { useTableProfileDataStore } from '../../hooks/useTableProfileDataStore';

export const RowData = ({
  data,
  assetId,
  assetType
}: {
  data: ITableProfileUserJoin;
  assetId: number;
  assetType: string;
}) => {
  const handleCopy = () => {
    toast('The JOIN clause has been copied to your clipboard', {
      type: 'success'
    });
    MixedPanelEvent.dataCatalogProfileJoinCopied({
      profileType: assetType,
      join: data.query,
      assetId: assetId
    });
  };

  return (
    <MostJoinedRowDataBox>
      <MostJoinedRowDataTitle>
        <Typography variant="body2">{data?.query}</Typography>
      </MostJoinedRowDataTitle>
      <MostJoinedRowDataRight>
        <CopyToClipboard text={data?.query} onCopy={handleCopy}>
          <CopyIcon />
        </CopyToClipboard>
        <TableValueText>{data?.num_of_references}</TableValueText>
      </MostJoinedRowDataRight>
    </MostJoinedRowDataBox>
  );
};
