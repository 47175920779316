import React from 'react';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

const WordpressIframe = () => {
    React.useEffect(() => {
        MixedPanelEvent.init();
        const [mixpanelId, postHogId] = MixedPanelEvent.getDistinctIds();

        parent.postMessage(
            {
                sherloq_command_to_wordpress: {
                    mixpanelId: mixpanelId,
                    postHogId: postHogId,
                },
            },
            '*',
        );
    }, []);

    return <div style={{ height: '100vh', width: '100vh' }}></div>;
};

export default WordpressIframe;
