import React, { useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useAppRepositoryDisplayMode } from '../../../store/sherloqAppConfig/selector';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import QueryItem from '../../../components/QueryItem';
import { ConditionComponent } from '../../../lib/utils';
import { IRepositoryFolderQuery } from '../../QueryListPage/interface';
import { EditorFooter } from '../../Editor/components/editorFooter';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  AccordionBox,
  AccordionDetailsBox,
  IconBox
} from '../../QueryListPage/components/styled';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Summary } from './styled';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import { shortenString } from '../../Editor/helpers/helpers';

interface RepositoryFolderQuery {
  data: IRepositoryFolderQuery;
  forcedState?: string;
  disableOnClick?: boolean;
}

export default ({ data, forcedState, disableOnClick }: RepositoryFolderQuery) => {
  const state = forcedState ?? useAppRepositoryDisplayMode()[0];
  const [opened, setOpened] = useState(false);
  const handleChange = () => setOpened(p => !p);
  const expanded = state === 'thumbnail' ? true : opened;

  const showHighlight = useMemo(() => {
    const { pageType, searchResultsProps } = data;
    return Boolean(
      pageType === 'search' &&
        searchResultsProps?.showHighlight &&
        searchResultsProps.keyword
    );
  }, [data]);

  const getKeywordMatches = useCallback(
    (text: string) => {
      if (typeof text !== 'string') {
        return [];
      }
      const matches = match(text, data.searchResultsProps?.keyword, {
        insideWords: true
      });
      return parse(text, matches ?? []);
    },
    [data.searchResultsProps?.keyword]
  );

  const titleSearchMatches = useMemo(() => {
    const str = shortenString(data?.query_name, 120);
    if (showHighlight) {
      return getKeywordMatches(str);
    }
    return [str];
  }, [showHighlight, data]);

  return (
    <AccordionBox disableGutters expanded={expanded} onChange={handleChange}>
      <ConditionComponent condition={state === 'list'}>
        <Summary expandIcon={null}>
          <IconBox expanded={expanded}>
            <ConditionComponent condition={expanded}>
              <PlayArrowIcon />
            </ConditionComponent>
            <ConditionComponent condition={!expanded}>
              <PlayArrowOutlinedIcon
                sx={{ top: '4px', position: 'relative' }}
              />
            </ConditionComponent>
          </IconBox>
          <Stack direction={'column'}>
            <CustomTooltip title={data.query_name} placement="top">
              <Typography>
                {titleSearchMatches?.map((part, idx) => {
                  return (
                    <Typography
                      key={idx}
                      component={'span'}
                      variant="body2"
                      sx={{
                        backgroundColor: part.highlight
                          ? 'rgba(255,255,0,0.7)'
                          : 'transparent'
                      }}
                    >
                      {part.text ? part.text : part}
                    </Typography>
                  );
                })}
              </Typography>
            </CustomTooltip>
            <EditorFooter
              paddingBottom={0}
              style={{ paddingBottom: 0 }}
              data={{
                createdAt: data.query_created_at,
                origin: data.query_origin,
                username: data.query_owner_username
              }}
            />
          </Stack>
        </Summary>
      </ConditionComponent>
      <AccordionDetailsBox>
        <QueryItem {...data} disableOnClick={disableOnClick} />
      </AccordionDetailsBox>
    </AccordionBox>
  );
};
