import axios from 'axios';
import { dotenv } from '../../../env';
import { AnalyticsWrapper } from './WrapperClass';

interface SherloqAnalyticsConfigType {
  debug: boolean;
}

export class SherloqAnalytics extends AnalyticsWrapper {
  private static config: SherloqAnalyticsConfigType;

  public static init() {
    this.config = {
      debug: dotenv.appEnv === 'development'
    };
    if (this.config.debug) {
      console.log('Sherloq Analytics Init');
    }
  }

  public static sendEvent = async (event: string, payload: string) => {
    try {
      await axios.post(
        'https://e5cfzvb4sa.execute-api.eu-west-1.amazonaws.com/Events/',
        { event_name: event, properties: payload }
      );
      if (this.config.debug) {
        console.log('<!>Sherloq Analytics', { event, properties: payload });
      }
    } catch {}
  };
}
