import React from 'react';
import { useUiState } from '../../context/hooks';
import AnimatedBox from '../../lib/components/AnimatedBox';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../store';
import { authThunks } from '../../store/auth/thunk';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    open: boolean;
}

const SideBarFrame: React.FC<Props> = ({ open, children }) => {
    const dispatch = useAppDispatch();
    const { framePosition } = useUiState()!;

    React.useEffect(() => {
        dispatch(authThunks.index());
        dispatch(authThunks.userInfo());
        dispatch(authThunks.userAllowed());
    }, []);

    return (
        <AnimatedBox initial={{ x: -100 }} animate={{ x: 0 }} className={`frame ${framePosition}`}>
            <Box className="frame-content">{children}</Box>
        </AnimatedBox>
    );
};

export default SideBarFrame;
