import { useEffect, useState } from 'react';
import { TableRecentQueriesContextType } from '../../../../utils/types';
import { DataCatalogApiService } from '../../../../api/api.service';
import { useDataCatalogGlobalStateActions } from '../../../../hooks/useDataCatalogGlobalStateActions';
import { useParams } from 'react-router-dom';
import { useTableProfileData } from '../../../../../store/DataCatalog/selectors';
export const useTableRecentQueries = (
  text: string
): TableRecentQueriesContextType => {
  const [dataStore, setDatastore] = useState({
    loading: false,
    error: null
  });
  const startLoading = () => setDatastore(p => ({ ...p, loading: true }));
  const stopLoading = () => setDatastore(p => ({ ...p, loading: false }));
  const { id } = useParams();

  const { data: profileData } = useTableProfileData(Number(id));

  const { setProfileRecentQueries } = useDataCatalogGlobalStateActions();
  const recentQueries = profileData.recentQueries;

  const fetchData = async () => {
    startLoading();
    try {
      const payload = { text: text };
      const data = await DataCatalogApiService.getRecentQueries(payload);
      setProfileRecentQueries({
        asset: 'table',
        data,
        id: profileData?.table_id
      });
    } catch (error) {
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (!recentQueries) {
      fetchData();
    }
  }, [text]);

  return { state: { ...dataStore, data: recentQueries } };
};
