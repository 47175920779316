import React from 'react';
import { Typography } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';

interface FilterLabelProps {
  children: React.ReactNode;
}

export const FilterLabel = ({ children }: FilterLabelProps) => {
  return (
    <div className="filter-label-container">
      <Typography
        color={isDatagrip() ? 'white' : '#707070'}
        variant="caption"
        className="sherloq-last-queried-by-text"
      >
        {children}
      </Typography>
    </div>
  );
};
