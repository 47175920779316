import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../store';
import { newQueryFolderContentThunks } from '../../../store/newFolderContent/thunk';
import { queriesAsyncThunk } from '../../../store/queries/queries.thunk';

export const useQueryDeletion = () => {
  const dispatch = useAppDispatch();

  const deleteQuery = async (id: string, parentId: string) => {
    toast('Deleting query', { isLoading: true });
    await dispatch(queriesAsyncThunk.remove(id!));
    dispatch(newQueryFolderContentThunks.index({ uid: parentId }));
    setTimeout(() => {
      toast.dismiss();
    }, 1000);
  };

  return {
    deleteQuery
  };
};
