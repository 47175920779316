import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '../../atoms/Typography';
const NotFound = () => {
  return (
    <Box sx={{ margin: 'auto auto' }}>
      <Typography variant="h1">Ooops</Typography>
      <Typography sx={{ textAlign: 'center' }}>Page not found</Typography>
    </Box>
  );
};

export default NotFound;
