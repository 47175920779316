import { useTypedSelector } from '..';

export const useNewFolderContentState = (uid: string) =>
  useTypedSelector(state => state.sherloqNewFolderContent.data[uid]);

export const useNewFolderSingleFolderContentState = (
  uid: string,
  parentId: string
) =>
  useTypedSelector(state =>
    state.sherloqNewFolderContent.data[parentId]?.list.find(
      item => item.query?.uid === uid || item.child?.uid
    )
  );
