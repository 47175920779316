import { useMemo } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';

export const useUserNameAndInitials = () => {
  const user = useUserInfoSelector();
  const userInitials = useMemo(() => {
    if (!user) return '';
    return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
  }, [user]);

  const username = useMemo(() => {
    if (!user) return '';
    return `${user?.first_name} ${user.last_name}`;
  }, [user]);

  return { username, userInitials };
};
