import { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentCopyRounded } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { CustomTooltip } from '../../../../atoms/CustomTooltip';
import Empty from '../DiscoveryData/empty';
// import { ICustomMessageComponent } from '../../interfaces';
// import { DiscoveryDataFeedback } from '../customMessages';
// import { useWatsonMessages } from '../../hooks/useWatsonMessages';
// import { useWatsonMeta } from '../../hooks/useWatsonMeta';
import { ConditionComponent } from '../../../../lib/utils';
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { FeedbackState } from '../LikeDislikeForAssets';
import MixedPanelEvent from '../../../../utils/analytics/mixPanel';
import { useAiBotMeta } from '../../../../Botson/hooks/useAiBotMeta';
import { useAiBotVariantMeta } from '../../../../Botson/hooks/useAiBotVariantMeta';
import { useAiBotEntryMessage } from '../../../../Botson/hooks/useAiBotEntryMessage';
import { useDiscoveryBotPhaseResponseActions } from '../../../../Botson/bots/Discovery/hooks/useDiscoveryBotPhaseResponseActions';

const Content = ({ results, filters }) => {
  const [showCopySuccessIndex, setShowCopySuccessIndex] = useState(null);
  // const [feedbackState, setFeedbackState] = useState<FeedbackState>('none');
  // const likeActive = feedbackState === 'liked' ? 'active-liked' : '';
  // const unlikeActive = feedbackState === 'disliked' ? 'active-disliked' : '';
  const { displayDataFeedback } = useDiscoveryBotPhaseResponseActions();
  // const disabled = Boolean(likeActive || unlikeActive);
  const handleCopy = (index: number) => {
    setShowCopySuccessIndex(index);
    setTimeout(() => {
      setShowCopySuccessIndex(null);
    }, 1500);
  };
  const { sessionId: conversationId } = useAiBotMeta();
  const { discoveryType } = useAiBotVariantMeta();
  const message = useAiBotEntryMessage();
  // const addMessage = useWatsonMessages()[5];
  // const meta = useWatsonMeta()[0];
  // const setMeta = useWatsonMeta()[1];
  // const displayDataFeedback = () => {
  //   const message: ICustomMessageComponent = {
  //     body: '',
  //     variant: 'response',
  //     status: 'success',
  //     type: 'botson',
  //     children: DiscoveryDataFeedback
  //   };
  //   addMessage(message);
  //   setMeta(p => ({
  //     ...p,
  //     discoveryPhase: 'feedback',
  //     discoveryPhasePayload: results
  //   }));
  // };
  useEffect(() => {
    displayDataFeedback(results);
  }, []);
  // const LikeClicked = () => {
  //   // MixedPanelEvent.watsonAssetFeedbackLike({
  //   //   asset_name: asset.name,
  //   //   asset_type: asset.type,
  //   //   message: message,
  //   //   chat_type: 'none'
  //   // });
  //   setFeedbackState('liked');
  // };

  // const DislikeClicked = () => {
  //   // MixedPanelEvent.watsonAssetFeedbackDislike({
  //   //   asset_name: asset.name,
  //   //   asset_type: asset.type,
  //   //   message: message,
  //   //   chat_type: 'none'
  //   // });
  //   setFeedbackState('disliked');
  // };

  useEffect(() => {
    MixedPanelEvent.watsonDataTableResults({
      conversation_uid: conversationId,
      question_type: discoveryType,
      question_message: message.message,
      tables: {
        first_table: filters['src'],
        second_table: filters['dst']
      },
      answer: results
    });
  }, [results]);
  return (
    <Box>
      <ConditionComponent condition={results.length > 1}>
        <Box marginBottom={'10px'}>
          <Typography fontFamily="Roboto, Helvetica, Arial, sans-serif">
            Sure,
            <Typography fontFamily="Roboto, Helvetica, Arial, sans-serif">
              here are the most common joins from {filters['src']} to{' '}
              {filters['dst']}, sorted by most relevant:
            </Typography>
          </Typography>
        </Box>
      </ConditionComponent>
      {results.map(
        (result: { header: string; query: string }, index: number) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="body1" gutterBottom>
                {result.header}
              </Typography>
              <ConditionComponent condition={result && 'query' in result}>
                <CopyToClipboard
                  text={String(result.query).replace(/\n$/, '')}
                  onCopy={() => handleCopy(index)}
                >
                  <CustomTooltip
                    title={
                      showCopySuccessIndex === index
                        ? 'Copied!'
                        : 'Copy snippet'
                    }
                    placement="top"
                  >
                    {showCopySuccessIndex === index ? (
                      <CheckIcon
                        sx={{
                          fontSize: '22px',
                          visibility: `${
                            showCopySuccessIndex === index
                              ? 'visible'
                              : 'hidden'
                          }`,
                          fill: '#7209B7'
                        }}
                      />
                    ) : (
                      <ContentCopyRounded
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                      />
                    )}
                  </CustomTooltip>
                </CopyToClipboard>
              </ConditionComponent>
            </Box>
            <ConditionComponent condition={result?.query && 'query' in result}>
              <SyntaxHighlighter
                children={result.query}
                style={{ ...atomDark }}
                language={'sql'}
                PreTag="div"
                wrapLongLines={true}
                codeTagProps={{
                  style: {
                    maxWidth: '100%',
                    fontSize: '11px',
                    width: 'inherit',
                    display: 'inline-block',
                    overflowWrap: 'break-word',
                    transition: 'max-height 2s ease'
                  }
                }}
              />
            </ConditionComponent>
          </Box>
        )
      )}
    </Box>
  );
};

interface Props {
  results: any[];
  filters: {};
}
export const JoinResponse = ({ results, filters }: Props) => {
  if (results.length === 0) {
    return <Empty>There is no joins between the tables</Empty>;
  }
  return <Content results={results} filters={filters} />;
};
