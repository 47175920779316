import React from 'react';
import { styled, IconButton, IconButtonProps } from '@mui/material';

export const RepositoryStatesBtnsBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '12px',
    padding: '0px',
    height: '34px',
    borderRadius: '4px',
    columnGap: '4px'
  };
});

export const RepoDisplayToggleIconBtn = styled(
  (props: IconButtonProps & { selected: boolean }) => <IconButton {...props} />
)(({ selected, theme }) => {
  const {
    primary: { main },
    background,
    text,
    grey
  } = theme.palette;
  const backgroundColor = selected ? main : grey['300'];
  return {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor,
    color: selected ? background.default : text.secondary,
    ':hover': {
      color: text.disabled,
      backgroundColor
    }
  };
});
