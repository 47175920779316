import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useDarkModeState } from '../../store/themeMode/selectors';
import { useSubscribeToCopyEvents } from '../../lib/hooks';
import { addStemsToText } from '../../utils/helpers';

export interface DisplayQueryEditorProps {
  query?: string;
  onManualCopy?: (text: string) => Promise<void> | void;
  searchText?: string;
}

export const DisplayQueryEditor = ({
  query,
  onManualCopy,
  searchText = ''
}: DisplayQueryEditorProps) => {
  const darkMode = useDarkModeState();
  const [isResizing, setIsResizing] = useState(false)

  const getKeywordMatches = useCallback(
    (text: string) => {
      if (typeof text !== 'string') {
        return [];
      } // Handle non-string inputs
      const matches = match(text, addStemsToText(searchText), {
        insideWords: true
      });
      return parse(text, matches ?? []);
    },
    [searchText]
  );

  const highlightMap = useMemo(() => {
    const highlightMap = getKeywordMatches(query);
    return highlightMap
  }, [query]);

  const highlightedQuery = query.split(new RegExp(`(${searchText})`, 'gi')).map((part, index) =>
    part.toLowerCase() === searchText.toLowerCase() ?
      <mark key={index}>{part}</mark> :
      part
  );

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const startY = e.clientY;
    const startHeight = preRef.current?.offsetHeight || 0;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      setIsResizing(true)
      const newHeight = startHeight + (moveEvent.clientY - startY);
      if (preRef.current) {
        preRef.current.style.height = `${newHeight}px`;
      }
    };

    const handleMouseUp = (e) => {
      setTimeout(() => {
        setIsResizing(false)
      }, 100)
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };


  const subscribeRef = useSubscribeToCopyEvents({
    onCopy: onManualCopy,
    enable: Boolean(onManualCopy)
  }).ref;
  const preRef = useRef<HTMLPreElement>(null);

  const combinedRef = useCallback(
    (node: HTMLPreElement) => {
      subscribeRef.current = node;
      preRef.current = node;
    },
    [subscribeRef]
  );

  useEffect(() => {
    if (preRef.current) {
      const firstMark = preRef.current.querySelector('mark');
      if (firstMark) {
        preRef.current.scrollTop = firstMark.offsetTop - preRef.current.offsetTop;
      }
    }
  }, [searchText, query]);

  const spanRefs = useRef([]);

  useEffect(() => {
    // Find the first highlighted part
    const firstHighlightedIndex = highlightMap.findIndex(part => part.highlight);

    if (firstHighlightedIndex !== -1 && spanRefs.current[firstHighlightedIndex]) {
      // Calculate the offset of the highlighted span relative to the pre element
      const highlightedSpan = spanRefs.current[firstHighlightedIndex];
      const preElement = preRef.current;
      
      // Scroll the pre element to the position of the highlighted span
      preElement.scrollTop = highlightedSpan.offsetTop - preElement.offsetTop;
    }
  }, [highlightMap]);

  return (
    <div style={{ position: 'relative', maxWidth: '100%' }} onClick={(e) => {
      if (isResizing) {
        e.preventDefault();
        e.stopPropagation();
      }
    }}>
      <pre
        className="accordion-query"
        ref={combinedRef}
        style={{
          backgroundColor: darkMode ? '#424242' : '#f5f5f5',
          whiteSpace: 'pre-wrap', // ensures that text wraps within the <pre> element
          // overflow: 'auto', // add overflow auto to enable scrolling
          // maxHeight: '400px', // you can adjust this based on your requirements
          // height: '200px', // initial height
          // resize: 'vertical', // allows vertical resizing by default
          // padding: '10px',
          // boxSizing: 'border-box'
        }}
      >
        {highlightMap.map((part, idx) => (
                          <span
                            key={idx}
                            style={{
                              backgroundColor: part.highlight
                                ? 'rgba(255,255,0,0.7)'
                                : 'transparent'
                            }}
                            ref={el => spanRefs.current[idx] = el}
                          >
                            {part.text}
                          </span>
                        ))}
      </pre>
      <div
        onMouseDown={handleMouseDown}
        style={{
          height: '10px',
          background: darkMode ? '#333' : '#ddd',
          cursor: 'ns-resize',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <circle cx="5" cy="12" r="2" fill="currentColor" />
        <circle cx="12" cy="12" r="2" fill="currentColor" />
        <circle cx="19" cy="12" r="2" fill="currentColor" />
      </svg>
      </div>
    </div>
  );
};
