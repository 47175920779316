import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { DockPosition } from './type';

const initialState: DockPosition = {
    position: 'right',
};

const index = createSlice({
    name: 'Dock_Position',
    initialState,
    reducers: {
        toggleDockPosition: (state, { payload }: PA<string>) => {
            state.position = payload;
        },
    },
});

export const { toggleDockPosition } = index.actions;

export default index.reducer;
