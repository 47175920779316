import { AIBotQuestion } from '../../types';
import { DiscoveryBotMessageAdapter } from '../../bots/Discovery/MessageAdapter';
import { AiBotResponseMessage } from './AiBotResponseMessage';
import { AiBotVariant } from '../../domain/chatTypes';
import { Fragment } from 'react';

interface Props {
  data: AIBotQuestion;
}

export const AiBotResponseAdapter = ({ data }: Props) => {
  const content = (() => {
    if (data.chatType === AiBotVariant.BotsonDiscovery) {
      return <DiscoveryBotMessageAdapter data={data} />;
    }
    return <AiBotResponseMessage data={data} />;
  })();

  return <Fragment>{content}</Fragment>;
};
