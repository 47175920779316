import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  data: {
    value: string;
    subject: string;
  };
}

export const SubmittedMessage = ({ data: { subject, value } }: Props) => {
  return (
    <Box>
      <Typography fontWeight={300} display={'inline'}>
        Provided details/sql on
      </Typography>
      <Typography fontWeight={700} display={'inline'}>
        {' '}
        {subject}
      </Typography>
      <Typography component={'p'} fontStyle="italic" display={'block'}>
        {value}
      </Typography>
    </Box>
  );
};
