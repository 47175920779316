import { useAiBotVariantMeta } from '../../hooks/useAiBotVariantMeta';
import AssetChoosePhase from './AssetChoosePhase';
import FeedbackPhase from './FeedbackPhase';
import PostFeedbackPhase from './PostFeedbackPhase';

const DiscoveryPhaseAdapter = () => {
  const meta = useAiBotVariantMeta();

  if (meta.discoveryPhase === 'feedback') {
    return <FeedbackPhase meta={meta} />;
  } else if (meta.discoveryPhase === 'post-feedback') {
    return <PostFeedbackPhase meta={meta} />;
  } else if (meta.discoveryPhase === 'asset-choose') {
    return <AssetChoosePhase />;
  }

  return null;
};

export default DiscoveryPhaseAdapter;
