import React from 'react';
import { Box, Typography, Autocomplete, TextField } from '@mui/material';

export const PartitionFilter = ({
  selectedValue,
  onSelectionChange,
  isDisabled
}) => {
  let filterOptions = ['true', 'false'];
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
      <Box
        sx={{ lineHeight: 'normal' }}
        marginRight={'50px'}
        marginBottom={'40px'}
      >
        <Typography
          fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          fontSize={18}
          sx={{ marginBottom: '0' }}
        >
          Is partitioned:{' '}
        </Typography>
      </Box>
      <Autocomplete
        disabled={isDisabled}
        id="table-field"
        options={filterOptions}
        sx={{ minWidth: '200px' }}
        value={selectedValue}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        onChange={(event, newValue) => onSelectionChange(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.875rem'
              },
              '& .MuiOutlinedInput-root': {
                height: '40px'
              },
              textTransform: 'capitalize'
            }}
            placeholder="Optional"
          />
        )}
      />
    </Box>
  );
};
