import { ReactNode } from 'react';
import {
  CancelBtn,
  EditProfileMetaPopoverBox,
  EditProfileMetaPopoverInputActionsBox,
  EditProfileMetaPopoverWrapper,
  SaveBtn,
  CloseIcon
} from './styled';
import { PopoverProps } from '@mui/material';

interface Props extends PopoverProps {
  contentNode: ReactNode;
  submit: (...args: unknown[]) => void;
}

export const ProfileMetaModal = ({ contentNode, submit, ...props }: Props) => {
  const closeModal = (ev: React.MouseEvent) =>
    props.onClose(ev, 'backdropClick');

  return (
    <EditProfileMetaPopoverBox {...props}>
      <EditProfileMetaPopoverWrapper>
        {contentNode}
        <EditProfileMetaPopoverInputActionsBox>
          <CancelBtn onClick={closeModal}>Cancel</CancelBtn>
          <SaveBtn onClick={submit}>Save</SaveBtn>
        </EditProfileMetaPopoverInputActionsBox>
      </EditProfileMetaPopoverWrapper>
      <CloseIcon sx={{ cursor: 'pointer' }} onClick={closeModal} />
    </EditProfileMetaPopoverBox>
  );
};
