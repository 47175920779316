import React from 'react';
import { Modal, ModalProps, BoxProps, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ModalContainer } from './components/container';

export interface ModalRootProps extends Omit<ModalProps, 'children'> {
  containerProps?: BoxProps;
  variant: 'default' | 'error';
  blurredBG?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

/**
 *
 * @param param0
 * id sherloq_root keeps the modal within the sherloq_
 */
export const ModalRoot = ({
  children,
  containerProps,
  variant,
  ...props
}: ModalRootProps) => {
  const handleOnClose = ev => props?.onClose?.(ev, 'backdropClick');
  return (
    <Modal id="sherloq-modal-root" {...props} sx={{
      '& .MuiBackdrop-root': {
        backdropFilter: props?.blurredBG ? 'blur(2px)' : 'blur(0)',
      },
    }}>
      <ModalContainer variant={variant} {...containerProps}>
        {children}
        {props.onClose != null && <Button onClick={handleOnClose} className="close-button-button">
          <ClearIcon style={{ color: '#a9a9a9', fontSize: '18px' }} />
        </Button> }
      </ModalContainer>
    </Modal>
  );
};
