import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { IAppConfig, RepositoryDisplayModeType } from './type';

const initialState: IAppConfig = {
  repositoryDisplayMode: 'thumbnail'
};

const index = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setRepositoryDisplayMode(
      state,
      { payload }: PA<RepositoryDisplayModeType>
    ) {
      state.repositoryDisplayMode = payload;
    }
  }
});

export const { setRepositoryDisplayMode } = index.actions;

export default index.reducer;
