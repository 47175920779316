import {
  DescriptionText,
  GraphIcon,
  HistoryIcon,
  RightDataLeft,
  RightMeta,
  RowDataBox,
  RowDataTitleText,
  RowDataTopBox,
  RowDataTopRightBox
} from './styled';
import { Typography } from '@mui/material';
import { ITableProfileTopField } from '../../../../../utils/types';
import { formatDate } from '../../../../../../pages/Watson/components/DiscoveryData/filterTableDataRows';
import { useNavigate } from 'react-router-dom';
import { ViewChip } from '../../../../ViewToggle/components';
import { ConditionComponent } from '../../../../../../lib/utils';
import { CustomTooltip } from '../../../../../../atoms/CustomTooltip';

export const RowData = ({ data }: { data: ITableProfileTopField }) => {
  const navigator = useNavigate();
  const handleFieldNameClick = () => {
    navigator(`/data-management/field-profile/${data.field_id}`);
  };
  return (
    <RowDataBox>
      <RowDataTopBox>
        <RightDataLeft>
          <RowDataTitleText onClick={handleFieldNameClick}>
            {data?.field_name}
          </RowDataTitleText>
          <ConditionComponent condition={data && data.field_type !== ''}>
            <ViewChip
              value={data?.field_type}
              isActive={false}
              backgroundColor={'#7209B730'}
            />
          </ConditionComponent>
        </RightDataLeft>
        <RowDataTopRightBox>
          <CustomTooltip
            title={
              '# of field uses in the last month / # of table uses in the last month'
            }
          >
            <RightMeta>
              <GraphIcon />
              <Typography variant="caption" color="primary.main">
                {`${data?.reference_percentage_out_of_table_references}%`}
              </Typography>
            </RightMeta>
          </CustomTooltip>
          <RightMeta>
            <HistoryIcon />
            <Typography variant="caption" color="primary.main">
              {data ? formatDate(data?.last_reference_datetime) : ''}
            </Typography>
          </RightMeta>
        </RowDataTopRightBox>
      </RowDataTopBox>
      <DescriptionText>{data.field_description ?? ''}</DescriptionText>
    </RowDataBox>
  );
};
