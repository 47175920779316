import React from 'react';
import { CircularProgress, Box } from '@mui/material';

interface LoaderProps {
  loaderSize?: string;
  marginTop?: string;
}

const Loader = ({ loaderSize, marginTop }: LoaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '100px' ?? marginTop
      }}
    >
      <CircularProgress size={'32px' ?? loaderSize} />
    </Box>
  );
};

export default Loader;
