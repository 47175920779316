import {
  MetaActions,
  MetaBox,
  MetaFieldBox,
  MetaFieldLabel,
  MetaFieldValue
} from './styled';
import ShareIcon from '@mui/icons-material/Share';
import { formatDate } from '../../../../../../pages/Watson/components/DiscoveryData/filterTableDataRows';
import {
  useFieldProfileDataStore,
  useUpdateFieldProfileData
} from '../../hooks/useFieldProfileDataStore';
import {
  MetaEditIcon,
  MetaFieldInnerBox
} from '../../../TableProfile/components/Header/styled';
import { Fragment, useRef, useState } from 'react';
import { EditdDate } from '../../../components/ProfileMetaModals/EditCreatedDateMetaModal';
import { toast } from 'react-toastify';

type EditType = 'created-date';

export const SecondaryMeta = () => {
  const { data } = useFieldProfileDataStore();
  const [meta, setCurrentMeta] = useState<Nullable<EditType>>(null);
  const anchorRef = useRef<HTMLElement>(null);
  const updateField = useUpdateFieldProfileData();

  const editMeta = (ev: React.MouseEvent, variant: EditType) => {
    setCurrentMeta(variant);
    anchorRef.current = ev.target as HTMLElement;
  };
  const closeModal = () => {
    setCurrentMeta(null);
    anchorRef.current = null;
  };

  const editCreatedDate = (ev: React.MouseEvent) => {
    editMeta(ev, 'created-date');
  };

  const onUpdateFieldSuccess = () => {
    toast('The property has been updated', { type: 'success' });
  };

  const updateCreatedDate = async (date: string) => {
    closeModal();
    await updateField('asset_created_date', date);
    onUpdateFieldSuccess();
  };

  return (
    <Fragment>
      <MetaBox>
        <MetaFieldBox>
          <MetaFieldLabel>Monthly references</MetaFieldLabel>
          <MetaFieldValue>{data?.num_of_references ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Last queried</MetaFieldLabel>
          <MetaFieldValue>
            {formatDate(data?.last_reference_datetime)}
          </MetaFieldValue>
          <MetaFieldValue>{data?.last_username}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Owner</MetaFieldLabel>
          <MetaFieldValue>{data?.asset_owner ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Source</MetaFieldLabel>
          <MetaFieldValue>{data?.source ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Created date</MetaFieldLabel>
          <MetaFieldInnerBox>
            <MetaFieldValue>{data?.asset_created_date ?? '-'}</MetaFieldValue>
            <MetaEditIcon onClick={editCreatedDate} />
          </MetaFieldInnerBox>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Data freshness date</MetaFieldLabel>
          <MetaFieldValue>{data.asset_updated_date ?? '-'}</MetaFieldValue>
        </MetaFieldBox>
        <MetaFieldBox>
          <MetaFieldLabel>Update frequency</MetaFieldLabel>
          <MetaFieldValue>
            Each {data.asset_update_frequency_in_days ?? '-'} days
          </MetaFieldValue>
        </MetaFieldBox>
        <MetaActions>{/* <ShareIcon /> */}</MetaActions>
      </MetaBox>
      <EditdDate
        open={meta === 'created-date'}
        anchorEl={anchorRef.current}
        onClose={closeModal}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        label="Created date"
        submit={updateCreatedDate}
        data={data?.asset_created_date}
      />
    </Fragment>
  );
};
