import React from 'react';
import {
  styled,
  Box,
  TableContainer,
  Button,
  ButtonProps
} from '@mui/material';

export const DiscosveryDataBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%'
  };
});

export const FiltersContainer = styled('div')(({ theme }) => {
  const { grey } = theme.palette;

  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    backgroundColor: grey['200'],
    padding: '4px 12px',
    borderRadius: '12px',
    rowGap: '12px'
  };
});

export const FiltersBox = styled('ul')(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    marginBlockStart: 0,
    paddingInlineStart: 0,
    rowGap: '8px',
    columnGap: '8px'
  };
});

export const FilterBox = styled('li')(({ theme }) => {
  const { background } = theme.palette;
  return {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    padding: '6px 12px',
    backgroundColor: background.default
  };
});

export const FilterDataBox = styled(TableContainer)(({ theme }) => {
  const { grey } = theme.palette;
  return {
    width: '100%',
    backgroundColor: grey['100'],
    borderRadius: '12px',
    flex: 1
  };
});

export const FilterDataContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px'
  };
});

export const ShowMoreBtn = styled((props: ButtonProps) => (
  <Button {...props} variant="text" />
))(() => {
  return {
    marginRight: 'auto'
  };
});

export const EditFiltersBtn = styled((props: ButtonProps) => (
  <Button {...props} variant="text" />
))(() => {
  return {
    borderRadius: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    marginLeft: '12px'
  };
});

export const FiiltersHeader = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };
});

export const QueriedByRowBox = styled('div')(() => {
  return {
    minWidth: '100px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  };
});

export const LastUsedRowBox = styled('div')(() => {
  return {
    minWidth: '120px'
  };
});

export const DefaultRowBox = styled('div')(() => {
  return {
    minWidth: '50px'
  };
});

export const EmptyBox = styled('div')(() => {
  return {
    height: '120px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

export const DiscoveryDataFeedbackBox = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

export const TableHeaderBox = styled('div')(({ theme }) => {
  const { primary } = theme.palette;
  return {
    height: '60px',
    backgroundColor: primary.main
  };
});
