import { useMemo } from 'react';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../utils/core/getUserCompanyFromEmail';

export default () => {
  const user = useUserInfoSelector();
  return useMemo(() => {
    let company: string;
    let isNatural = false;
    if (user && user.email) {
      company = getUserCompanyFromEmailUseCase(user.email) ?? '';
      isNatural = ['naturalint'].includes(company);
    }
    return isNatural;
  }, [user]);
};
