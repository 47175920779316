import React from 'react';
import { Typography } from '@mui/material';
import { FilterBox } from './components';

interface Props {
  children: any;
}

export default ({ children }: Props) => {
  return (
    <FilterBox>
      <Typography variant="caption">{children}</Typography>
    </FilterBox>
  );
};
