import React from 'react';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { DateQueriedFilterOptions } from '../utils/data';
import { isDatagrip } from '../../../utils/checkOrigin';
import { FilterLabel } from './filterLabel';

interface DateQueriedFilterProps {
  value: string;
  onChange?: any;
}

export const DateQueriedFilter = ({
  value,
  onChange
}: DateQueriedFilterProps) => {
  return (
    <Box className="filter-item-container" >
      <FilterLabel>Date queried</FilterLabel>
      <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        value={value}
        defaultValue=""
        onChange={onChange}
        sx={{
          flex: 1
        }}
      >
        {DateQueriedFilterOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} style={{ height: '25px' }}>
            <Typography variant="caption">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
