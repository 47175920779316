import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { shortenString } from '../../../pages/Editor/helpers/helpers';
import { CustomTooltip } from '../../../atoms/CustomTooltip';
import { useDarkModeState } from '../../../store/themeMode/selectors';
interface Props {
  value: string;
  onClick: (e: any) => void;
}
export const QueryDescription = ({ value, onClick }: Props) => {
  const darkMode = useDarkModeState()
  const { displayValue, showToolTip } = useMemo(() => {
    return {
      showToolTip: value ? value.length > 120 : false,
      displayValue: shortenString(`${value}`, 120)
    };
  }, [value]);

  const node = (
    <Typography variant="caption" sx={{ ':hover': { color: 'primary.main' }, 'cursor': 'text', whiteSpace: 'nowrap', color: darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)', fontSize: 11 }} onClick={onClick}>
      {displayValue}
    </Typography>
  );

  if (showToolTip) {
    return (
      <CustomTooltip title={value} placement="top-start">
        {node}
      </CustomTooltip>
    );
  }

  return node;
};
