import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';

const initialState = {
    render: false,
    payload: '',
};

const slice = createSlice({
    name: 'saveAs',
    initialState,
    reducers: {
        initPayload: (state, { payload }: PA<string>) => {
            state.render = true;
            state.payload = payload;
        },
        cleanUp: (state) => {
            state.render = false;
            state.payload = '';
        },
    },
});

export const { actions: saveAsActions } = slice;
export default slice.reducer;
