import { useMemo } from 'react';
import { Stack } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HistoryIcon from '@mui/icons-material/History';
import { sx } from '../../styles/MUI/styles';
import { useLocation } from 'react-router-dom';
import { Button } from './component';
import TableChartIcon from '@mui/icons-material/TableChart';
import { ConditionComponent } from '../../lib/utils';
import { useSimplyRoutingIntegration } from '../../hooks/platform-integration/simpliIntegration';
import { useUserInfoSelector } from '../../store/auth/selectors';
import { useDarkModeState } from '../../store/themeMode/selectors';

type ClickEventHandler = onClickHandler<HTMLButtonElement>;

interface ActivePagesPayload {
  histoyPageActive: boolean;
  privatePageActive: boolean;
  companyPageActive: boolean;
  allPageActive?: boolean;
}

interface DefaultSubPagesProps {
  variant: 'search' | 'default';
  onClickMy?: ClickEventHandler;
  onClickCompany?: ClickEventHandler;
  onClickHistory?: ClickEventHandler;
  onClickAll?: ClickEventHandler;
  activePages?: ActivePagesPayload;
  onClickTables?: ClickEventHandler;
}

const PageHeaderIconStyles = {
  fontSize: '16px',
  marginRight: '4px'
};

export const SubPages = ({
  variant,
  onClickCompany,
  onClickHistory,
  onClickMy,
  onClickAll,
  onClickTables
}: DefaultSubPagesProps) => {
  const location = useLocation();
  const { authorize: authorizeOnSimply } = useSimplyRoutingIntegration();
  const userInfo = useUserInfoSelector();
  const darkMode = useDarkModeState();
  const companyName = userInfo && userInfo.email ? userInfo.email.split('@')[1].split('.')[0] : ''
  const companyStr = companyName ? companyName.charAt(0).toUpperCase() + companyName.slice(1) : ''
  const {
    histoyPageActive,
    privatePageActive,
    companyPageActive,
    allPageActive,
    tablesPageActive
  } = useMemo(() => {
    const activePages = {
      histoyPageActive: false,
      privatePageActive: false,
      companyPageActive: false,
      allPageActive: false,
      tablesPageActive: false
    };
    if (location.pathname.includes('saved')) {
      activePages.privatePageActive = true;
    } else if (location.pathname.includes('public')) {
      activePages.companyPageActive = true;
    } else if (location.pathname.includes('history')) {
      activePages.histoyPageActive = true;
    } else if (location.pathname.includes('all')) {
      activePages.allPageActive = true;
    } else if (location.pathname.includes('tables')) {
      activePages.tablesPageActive = true;
    }
    return activePages;
  }, [location.pathname]);

  const AllNode = useMemo(() => {
    if (variant === 'search') {
      return (
        <Button sx={sx.HeaderBtn} onClick={onClickAll} isActive={allPageActive}>
          All
        </Button>
      );
    }
    return null;
  }, [variant]);

  return (
    <Stack component={'nav'} direction="row" alignItems="center" gap={1}>
      {AllNode}
      <Button
        sx={darkMode ? sx.HeaderBtnDark : sx.HeaderBtn}
        onClick={onClickMy}
        isActive={privatePageActive}
      >
        <FolderIcon sx={PageHeaderIconStyles} />
        My
      </Button>
      <Button
        sx={darkMode ? sx.HeaderBtnDark : sx.HeaderBtn}
        onClick={onClickCompany}
        isActive={companyPageActive}
      >
        <ApartmentIcon sx={PageHeaderIconStyles} />
        {companyStr}
      </Button>
      <Button
        sx={darkMode ? sx.HeaderBtnDark : sx.HeaderBtn}
        onClick={onClickHistory}
        isActive={histoyPageActive}
      >
        <HistoryIcon sx={PageHeaderIconStyles} />
        History
      </Button>
      <ConditionComponent
        condition={variant === 'default' && authorizeOnSimply()}
      >
        <Button
          sx={darkMode ? sx.HeaderBtnDark : sx.HeaderBtn}
          onClick={onClickTables}
          isActive={tablesPageActive}
        >
          <TableChartIcon sx={PageHeaderIconStyles} />
          Tables
        </Button>
      </ConditionComponent>
    </Stack>
  );
};
