import { styled, Menu } from '@mui/material';
export const MenuContainer = styled(Menu)(() => {
  return {
    '& .MuiPaper-root': {
      minHeight: '100px',
      marginTop: '6px',
      border: '1px solid #d7d7d720'
    },
    '& .MuiList-root': {
      padding: 0
    }
  };
});
