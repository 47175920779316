import { NewFolderRedirectModal } from './components/NewFolderRedirect';
import { Root } from './components/root';
import './index.scss';
import React, { useState } from 'react';
import { HotSaveAsPhase, QueryMeta } from './interface';
import { HotSaveAsFLowContext, defaultSaveAsQueryPayload, useHotSaveAsFlowState } from './context';

interface HotSaveAsFlow {
    query: string;
    onClose?: () => void;
}

const Flow = () => {
    const syncPayload = useHotSaveAsFlowState()[1];
    const [phase, setPhase] = useState<HotSaveAsPhase>('as-new');

    const redirectToNewFolder = () => {
        setPhase('with-new-folder');
    };

    const toNewQuery = () => setPhase('as-new');

    const handleOnCreateNewFolder = (payload: any) => {
        // set new folder as default selected
        syncPayload((p) => ({ ...p, parentFolder: payload.parentFolder }));
        setPhase('as-new');
    };

    if (phase === 'as-new') return <Root nagivateToNewFolder={redirectToNewFolder} />;
    if (phase === 'with-new-folder')
        return <NewFolderRedirectModal withAsyncCreate={true} onCancel={toNewQuery} onSave={handleOnCreateNewFolder} />;
};

export const HotSaveAsFlow = ({ query, onClose }: HotSaveAsFlow) => {
    const [payload, setPayload] = useState<QueryMeta>({ ...defaultSaveAsQueryPayload, query });

    return (
        <HotSaveAsFLowContext.Provider value={[payload, setPayload, onClose]}>
            <Flow />
        </HotSaveAsFLowContext.Provider>
    );
};
