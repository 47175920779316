import ApiClient from '../../../lib/utils/apiClient';
import { DiscoveryType } from '../interfaces';

export const SendMessage = async (
  message: string,
  uid: string,
  source: string = 'sherloq',
  chatType: string = 'botson',
  isBuiltPrompt: boolean = false,
  promptType: string,
  promptValue: string,
  userCompany: string,
  controller?: AbortController
) => {
  return ApiClient.post(
    '/ai/watson',
    {
      message,
      uid,
      source,
      chatType,
      isBuiltPrompt,
      promptType,
      promptValue,
      userCompany
    },
    { isProtected: true },
    controller.signal
  );
};

export const DiscoveryChooseType = async (
  message: string,
  uid: string,
  type: string,
  company: string,
  controller?: AbortController
) => {
  return ApiClient.post(
    '/ai/discover/type',
    {
      message,
      uid,
      type,
      company
    },
    { isProtected: true },
    controller.signal
  );
};

export const DiscoveryChooseFilters = async (
  message: string,
  uid: string,
  type: string,
  company: string,
  filters: {} = null
) => {
  return ApiClient.post(
    '/ai/discover/search',
    {
      message,
      uid,
      type,
      company,
      filters
    },
    { isProtected: true }
  );
};

export const DiscoveryFiltersPrep = async (
  type: DiscoveryType,
  company: string,
  value?: string
) => {
  return ApiClient.post(
    '/ai/discover/filters',
    {
      type,
      company,
      value
    },
    { isProtected: true }
  );
};
