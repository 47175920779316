import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuthenticatedState } from '../store/auth/selectors';

export interface ProtectedRouteProps {
  element: React.ReactElement;
  path?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ path, element }) => {
  const isLoggedIn = useUserAuthenticatedState();
  if (isLoggedIn) {
    return element;
  }

  return <Navigate to="/" state={{ next: path }} />;
};

export default ProtectedRoute;
