import { useCallback, useEffect, useState } from 'react';
import { authThunks } from '../../store/auth/thunk';
import { useAppDispatch } from '../../store';
import { CookiesManager } from '../../utils/browser/cookieManagement';

/**
 * hook to initialise user auth from cookie to localstorage
 */
export const useInitUserAuthState = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const init = useCallback(() => {
    setLoading(true);
    let authToken = localStorage.getItem('sherloq-auth-token');
    if (!authToken) {
      const appLoginUser = CookiesManager.getByKey(
        CookiesManager.keys.extensionAuth
      );

      if (!!appLoginUser) {
        localStorage.setItem('sherloq-auth-token', appLoginUser.access_token);
        dispatch(authThunks.userInfo());
        dispatch(authThunks.userAllowed());
        authToken = appLoginUser.access_token;
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    init();
  }, []);

  return { loading };
};
