import React, { useMemo } from 'react';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { isDatagrip } from '../../../utils/checkOrigin';
import { useUserInfoSelector } from '../../../store/auth/selectors';
import { getUserCompanyFromEmailUseCase } from '../../../utils/core/getUserCompanyFromEmail';
import { createUserSourceFilterOptionsUseCase } from '../utils/createUserSourceFilterOptions';
import { FilterLabel } from './filterLabel';
import { PlatformIcon } from '../../../components/Icons/PlatformIcon';

interface SourceFilterProps {
  onChange?: any;
  value: string;
}

export const SourceFilter = ({ value, onChange }: SourceFilterProps) => {
  const user = useUserInfoSelector();
  const sourceOptions = useMemo(() => {
    if (!user) return [];
    const userCompany = getUserCompanyFromEmailUseCase(user.email);
    return createUserSourceFilterOptionsUseCase(userCompany);
  }, [user]);

  return (
    <Box className="filter-item-container">
      <FilterLabel>Source</FilterLabel>
      <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        value={value}
        sx={{ width: '402px' }}
        onChange={onChange}
      >
        {sourceOptions.map(({ label, value }) => (
          <MenuItem key={label} value={value} style={{ height: '25px' }}>
            <PlatformIcon origin={value} size="16px" />
            <Typography sx={{ marginLeft: '5px' }} variant="caption">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
