import { Typography, Button, Popover, PopoverProps } from '@mui/material';
import { CancelBtn, NotifyToggleBox, NotifyToggleBoxBtnsBox } from './styled';

interface Props extends PopoverProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default ({ onCancel, onConfirm, ...props }: Props) => {
  return (
    <Popover
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      {...props}
    >
      <NotifyToggleBox {...props}>
        <Typography variant="body2">
          This action will restart your conversation
        </Typography>
        <NotifyToggleBoxBtnsBox>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
          <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
        </NotifyToggleBoxBtnsBox>
      </NotifyToggleBox>
    </Popover>
  );
};
