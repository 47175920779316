import React, { useTransition } from 'react';
import moment from 'moment';
import { sx } from '../../styles/MUI/styles';
import { useAppDispatch } from '../../store';
import { queryHistoryNewThunks } from '../../store/queryHistoryNew/thunk';
import { QueryHistorySummary } from '../../store/queryHistorySummary/type';
import {
  EventNoteRounded,
  ExpandMoreRounded,
  Folder
} from '@mui/icons-material';
import {
  Accordion,
  Box,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import MixedPanelEvent from '../../utils/analytics/mixPanel';
import { useDarkModeState } from '../../store/themeMode/selectors';
import QueryHistoryContent from './components/QueryHistoryContent';
import { ConditionComponent } from '../../lib/utils';
import { ContentMetaStateBox } from '../QueryFolderListItem/components/styled';

interface Props {
  history: QueryHistorySummary;
  className?: string;
  height?: number;
  width?: number;
  queryType?: 'saved' | 'shared' | 'history';
  accordionId?: string;
  openAccordionId?: string;
  setOpenAccordionId?: any;
}
const QueryHistoryListItem: React.FC<Props> = ({
  history,
  accordionId,
  queryType,
  openAccordionId,
  setOpenAccordionId
}) => {
  const darkMode = useDarkModeState();
  const [isPending, startTransition] = useTransition();

  const dispatch = useAppDispatch();
  const isExpanded = openAccordionId == accordionId;

  function handleChange(_: React.ChangeEvent<{}>, accordionId: string) {
    if (isExpanded) {
      setOpenAccordionId(null);
    } else {
      startTransition(() => {
        setOpenAccordionId(accordionId);
      });
      dispatch(queryHistoryNewThunks.index({ day: accordionId }));
    }
  }

  const formatDate = date => moment(new Date(date)).format('DD.MM.YY');

  return (
    <Box className="query-list-items">
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        disableGutters={true}
        style={{
          boxShadow: 'none',
          backgroundImage: 'none',
          flex: 1
        }}
        key={accordionId}
        expanded={isExpanded}
        onChange={event => {
          handleChange(event, accordionId!);
          MixedPanelEvent.queryHistoryAccordionClicked(
            formatDate(history?.day)
          );
        }}
      >
        <AccordionSummary
          className="accordion-summary"
          expandIcon={<ExpandMoreRounded sx={darkMode ? sx.sx0dark : sx.sx0} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flex: 1 }}
        >
          <Box className="folder-item">
            <Box className="folder-icon-and-title">
              {queryType === 'history' ? (
                <EventNoteRounded sx={sx.sxFolder} />
              ) : (
                <Folder sx={sx.sxFolder} />
              )}

              <Typography className="folder-title" variant="body2">
                {formatDate(history?.day)} ({history.count})
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ margin: 0 }}>
          <ConditionComponent condition={isPending}>
            <ContentMetaStateBox>
              <Typography variant="caption">Loading</Typography>
            </ContentMetaStateBox>
          </ConditionComponent>
          <ConditionComponent condition={!isPending}>
            <QueryHistoryContent />
          </ConditionComponent>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default QueryHistoryListItem;
