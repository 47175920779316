import './index.scss';
import React from 'react';
import { sx } from '../../styles/MUI/styles';
import { Box, Button } from '@mui/material';
import { ModalRoot } from './root';
import { CancelButton } from './components/cancelButton';
import { ModalTypography } from './components/label';
import { Input } from '../Input';
import { RenderIf } from '../../lib/utils';

interface InviteFeedbackModalProps {
  title: string;
  subTitle: string;
  show: boolean;
  text: string;
  setText: any;
  placeholder: string;
  setClose: () => void;
  handlerSubmit: () => void;
  showCancel?: boolean;
  saveButtonText?: string;
  rows?: number;
}

const InviteFeedbackModal = ({
  title,
  subTitle,
  show,
  setClose,
  handlerSubmit,
  text,
  setText,
  placeholder,
  showCancel = true,
  saveButtonText = 'Save',
  rows = 1
}: InviteFeedbackModalProps) => {
  return (
    <ModalRoot open={show} variant="default" onClose={setClose}>
      <ModalTypography className="title">{title}</ModalTypography>
      <Box className="content">
        <ModalTypography className="sub-title">{subTitle}</ModalTypography>
        <br />
        <Input
          placeholder={placeholder}
          value={text}
          onChange={e => setText(e)}
          rows={rows}
        />
        <br />
        <br />
        <Box className="buttons-container">
          {RenderIf(showCancel, <CancelButton onClick={setClose} />)}
          <Button
            sx={sx.btn}
            variant="contained"
            onClick={handlerSubmit}
            disabled={!text}
          >
            {saveButtonText}
          </Button>
        </Box>
      </Box>
    </ModalRoot>
  );
};

export default InviteFeedbackModal;
