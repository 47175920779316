import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateToSearchSubPage = () => {
  const location = useLocation();
  const nav = useNavigate();

  const navigate = (tab: 'saved' | 'public' | 'history' | 'all') => {
    const preSearchRoute = !location.pathname.includes('search')
      ? location.pathname
      : (location.state as any)?.preSearchRoute;
    nav(`/search/${tab}`, { state: { preSearchRoute } });
  };

  return navigate;
};
