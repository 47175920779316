import { User } from '../../store/auth/types';
import { generateConversationId } from '../../utils/core/generateConversationId';
import { getUserCompanyFromEmailUseCase } from '../../utils/core/getUserCompanyFromEmail';
import { AiBotVariant } from '../domain/chatTypes';
import { AiSetupConfig } from '../types';

const allChatTypes = [AiBotVariant.BotsonDiscovery, AiBotVariant.GPT4];

export function createAiChatConfiguration(user: User): AiSetupConfig {
  const company = getUserCompanyFromEmailUseCase(user.email);
  const chatTypes: AiBotVariant[] = allChatTypes;
  const sessionId = generateConversationId();
  return {
    availableChatTypes: chatTypes,
    authorized: chatTypes.length > 0,
    company,
    sessionId
  };
}
