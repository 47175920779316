import React from 'react';
import {
  styled,
  Button as MUIButton,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Box,
  Typography
} from '@mui/material';
import { textColor as chooseColor } from '../components';

const Row = styled(Box)(() => {
  return {
    display: 'flex'
  };
});

export const ViewToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2)
}));

export const ViewChip = ({
  value,
  isActive,
  handleClick,
  backgroundColor,
  textColor
}: {
  value: string;
  isActive: boolean;
  handleClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
}) => {
  return (
    <Box
      id={`${value}-chip`}
      sx={{
        borderRadius: '20px',
        backgroundColor: backgroundColor
          ? backgroundColor
          : isActive
          ? '#7209b7'
          : '#f1f1f1',
        padding: '5px 20px',
        cursor: handleClick ? 'pointer' : 'inherit'
      }}
      width={'fit-content'}
      onClick={handleClick}
    >
      <Typography
        fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
        fontWeight={600}
        color={textColor ?? chooseColor()}
      >
        {value}
      </Typography>
    </Box>
  );
};
