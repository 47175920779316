import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomTooltip } from '../../atoms/CustomTooltip';

interface Props {
  firstName: string;
  lastName: string;
  width: string;
  height: string;
  fontSize: string;
  fontWeight: number;
  color: string;
}
const UserInitialsComponent: React.FC<Props> = ({
  firstName,
  lastName,
  width,
  height,
  fontSize,
  fontWeight,
  color
}) => {
  const getInitials = (name: string) => {
    return name && name.slice(0, 1);
  };
  return (
    <Box
      className="user-initials"
      sx={{
        height: `${height}`,
        width: `${width}`,
        backgroundColor: `${color}`
      }}
    >
      <CustomTooltip
        title={firstName + ' ' + lastName}
        PopperProps={{
          style: {
            zIndex: 3000,
            opacity: 0.9,
            borderRadius: '2px'
          }
        }}
        placement="left-start"
      >
        <Typography
          variant="caption"
          style={{
            height: `${height}`,
            lineHeight: `${height}`,
            fontSize: `${fontSize}`,
            fontWeight: fontWeight,
            color: '#fff',
            textTransform: 'uppercase'
          }}
        >
          {getInitials(firstName!) + '' + getInitials(lastName!)}
        </Typography>
      </CustomTooltip>
    </Box>
  );
};

export default UserInitialsComponent;
