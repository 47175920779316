import { useContext } from 'react';
import { AiBotAbstractActionsContext } from '../context/defs';
import { IAiBotAbstractActions } from '../types';

/**
 *
 * @returns implementations of the Ai Abstract actions  context.
 */
export const useAiBotActions = (): IAiBotAbstractActions => {
  const actions = useContext(AiBotAbstractActionsContext);
  return actions;
};
