import store from '../store';

export const isDatagrip = () => {
  const state = store.getState();
  if (
    state &&
    state.sherloqDarkModeToggle &&
    state.sherloqDarkModeToggle.isDarkMode
  ) {
    return true;
  } else {
    return false;
  }
};
