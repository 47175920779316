import {
  EditProfileMetaPopoverContentBox,
  Label,
  NumberInput,
  UpdateFrequencyContentBox
} from './styled';
import { PopoverProps, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { ProfileMetaModal } from '.';

const Content = (props: TextFieldProps) => {
  return (
    <EditProfileMetaPopoverContentBox>
      <Label>Update frequency</Label>
      <UpdateFrequencyContentBox>
        <Label>Each</Label>
        <NumberInput {...props} />
        <Label>days</Label>
      </UpdateFrequencyContentBox>
    </EditProfileMetaPopoverContentBox>
  );
};

interface Props extends PopoverProps {
  submit: (data: unknown) => void;
}

export const EditUpdateFrequencyView = (props: Props) => {
  const [value, setValue] = useState('');

  const handleSubmit = (ev: any) => {
    props.submit(value);
  };
  const handleDateInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setValue(value);
  };

  return (
    <ProfileMetaModal
      {...props}
      contentNode={<Content onChange={handleDateInputChange} value={value} />}
      submit={handleSubmit}
    />
  );
};
