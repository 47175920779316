import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { SharedFolder } from './type';

const index = createAsyncThunk(
  '/shared-folders/summary',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await ApiClient.get<SharedFolder[]>(
        `/shared-folders/v2/summary`,
        {
          isProtected: true
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

export const sharedFolderThunk = { index };
