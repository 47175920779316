import { useAiBotMeta } from '../../hooks/useAiBotMeta';
import { ReloadChat } from '../../../pages/Watson/components/reloadChat';
import { useAiBotTypes } from '../../hooks/useAiBotTypes';
import { HeaderBox } from './styled';
import { useAiBotDataStoreActions } from '../../hooks/useAiBotDataStoreActions';
import { AiBotVariant } from '../../domain/chatTypes';
import { useAiBotActions } from '../../hooks/useAiBotActions';
import ChatToggle from './chatToggle';

export const Header = () => {
  const { changeBotType, clearMessages } = useAiBotDataStoreActions();
  const { reset } = useAiBotActions();
  const { chatType } = useAiBotMeta();
  const userBotTypes = useAiBotTypes();

  const handleOnChangeBotType = (variant: AiBotVariant) => {
    changeBotType(variant);
    clearMessages();
  };

  const handleReloadChat = () => {
    reset();
  };

  return (
    <HeaderBox>
      <ChatToggle
        handleChatTypeToggle={handleOnChangeBotType}
        initalValue={chatType}
        options={userBotTypes}
      />
      <ReloadChat onClick={handleReloadChat} />
    </HeaderBox>
  );
};
