import React from 'react';
import { Box } from '@mui/material';
import { PartitionFilter } from './fieldFilters/Partitionfilter';
import { TextFieldFilter } from './TextFieldFilter';

export const FieldFilters = ({
  selectedTable,
  handleTableSelection,
  selectedField,
  handleFieldSelection,
  selectedSchema,
  handleSchemaSelection,
  selectedQueriedBy,
  handleQueriedBySelection,
  isDisabled,
  selectedIsPartitioned,
  handleIsPartitionedFieldSelection
}) => {
  return (
    <Box>
      <TextFieldFilter
        selectedValue={selectedField}
        onSelectionChange={handleFieldSelection}
        isDisabled={isDisabled}
        type={'field'}
        label={'Field name'}
        subLabel={'Full or partial field name'}
      />
      <TextFieldFilter
        selectedValue={selectedTable}
        onSelectionChange={handleTableSelection}
        isDisabled={isDisabled}
        type={'table'}
        label={'Table Name:'}
        subLabel={'Full or partial table name'}
      />
      <TextFieldFilter
        selectedValue={selectedSchema}
        onSelectionChange={handleSchemaSelection}
        isDisabled={isDisabled}
        type={'schema'}
        label={'Schema Name:'}
        subLabel={'Full or partial schema name'}
        marginRight={'10px'}
      />
      <TextFieldFilter
        selectedValue={selectedQueriedBy}
        onSelectionChange={handleQueriedBySelection}
        isDisabled={isDisabled}
        type={'user'}
        label={'Queried by users'}
        subLabel={'In the last 30 days'}
      />
      <PartitionFilter
        selectedValue={selectedIsPartitioned}
        onSelectionChange={handleIsPartitionedFieldSelection}
        isDisabled={isDisabled}
      />
    </Box>
  );
};
