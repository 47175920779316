import { Button, useTheme, ButtonProps } from '@mui/material';
import { forwardRef } from 'react';

type Props = Omit<ButtonProps, 'sx'>;
const Component = (props: Props, ref) => {
  const {
    palette: { background, mode, primary, secondary }
  } = useTheme();
  const isDark = mode === 'dark';
  return (
    <Button
      ref={ref}
      sx={{
        borderRadius: '12px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginLeft: 'auto',
        backgroundColor: isDark
          ? background.surfaceOverlaySurface
          : background.paper,
        border: `1px solid ${primary.light}`,
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '52px'
      }}
      {...props}
    />
  );
};

export const ChatToggleContainer = forwardRef(Component);
