import React from 'react';

interface DatabricksIconProps {
    size: string;
}

const DatabricksIcon = ({ size }: DatabricksIconProps) => {
    return (
        <svg
            height={size}
            version="1.0"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1259.7 926.9"
            fill="none"
            xmlSpace="preserve">
            <polygon
                fill="#CD2920"
                points="306.7,526.3 306.7,643.4 630.5,828.2 953,643.4 953,526.3 "
            />
            <polygon fill="#E6331C" points="630.5,526.3 630.5,828.2 953,643.4 953,526.3 " />
            <polygon fill="#EB5C2B" points="306.7,526.3 630.5,711.1 953,526.3 630.5,341.4 " />
            <polygon
                fill="#CD2920"
                points="306.7,283.5 306.7,400.7 630.5,584.1 953,400.7 953,283.5 "
            />
            <polygon fill="#E6331C" points="630.5,283.5 630.5,584.1 953,400.7 953,283.5 " />
            <polygon fill="#EB5C2B" points="306.7,283.5 630.5,468.4 953,283.5 630.5,98.7 " />
        </svg>
    );
};

export default DatabricksIcon;
