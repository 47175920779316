import React, { useEffect, useState } from 'react';
import { Box, keyframes, Fade, Typography } from '@mui/material';

const WatsonAnimate = () => {
  const moveRightLeft = keyframes`
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(60px);
    }
    60% {
      transform: translateX(-80px);
    }
    80% {
        transform: translateX(40px);
    }
    100% {
        transform: translateX(0);
    }
  `;
  const sentences = [
    'Always use JOINs instead of subqueries',
    'Index foreign keys for faster queries',
    'Normalize data to reduce redundancy',
    'Use aliases to clarify query structure',
    'Prefer WHERE over HAVING for performance',
    'Utilize EXPLAIN PLAN for query optimization',
    'Batch INSERTs to improve performance',
    'Use LIMIT to test query results',
    'Avoid SELECT * for better efficiency',
    'Regularly update statistics for optimizer',
    'Choose the right data type',
    'Implement proper indexing strategies.',
    'Opt for stored procedures for complexity',
    'Be cautious with NULL values',
    'Use transactions for data integrity',
    'Prefer INNER JOIN over OUTER JOIN',
    'Use temp tables for large datasets',
    'Optimize LIKE queries with indexes',
    'Avoid functions on indexed columns',
    'Keep SQL scripts clear and readable',
    'Turning coffee into statistical insights daily',
    'Data never lies, but sometimes naps',
    'Numbers dance, I interpret the steps',
    'Pie charts bring the sweetest insights',
    'Spreadsheets speak louder than words',
    'Do one thing everyday that scares you',
    'Wear Sunscreen'
  ];
  return (
    <Box alignSelf={'center'}>
      <Box
        sx={{
          width: 'black',
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '10px',
          '& svg': {
            animation: `${moveRightLeft} 2s ease-in-out infinite`
          }
        }}
      >
        <svg
          className="spin expand"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_1441_5226)">
            <path
              d="M21 9.00002C20.5 -3.00003 3.49998 -3.00002 2.99998 9C8.99998 9.99998 15 9.99998 21 9.00002Z"
              fill="url(#paint0_linear_1441_5226)"
            />
            <path
              d="M23.3314 11.3117C22.9032 10.9258 22.0529 10.901 21.5 11C15.0207 12.1674 8.98339 12.09 2.49998 11C1.35759 10.7821 -0.0110556 11.6175 6.73558e-05 12.8583C6.73558e-05 13.8122 0.622269 14.8044 1.5 15C8.39426 16.5438 15.6044 16.5441 22.5 15C24.0278 14.6656 24.5369 12.3824 23.3314 11.3117Z"
              fill="url(#paint1_linear_1441_5226)"
            />
            <path
              d="M11.5497 19.5899C11.654 19.7488 11.8217 19.8428 12.0003 19.8429C12.1789 19.8428 12.3466 19.7488 12.4509 19.5899C13.5302 17.9444 14.6899 16.9284 16.5003 17C18.3106 17.0716 18.2537 17.8538 18.8624 18.3506C19.8188 19.1309 20.7568 19.8962 22.464 19.757C22.8879 19.743 23.2292 20.1143 23.0002 20.5C21.9863 22.2078 19.9648 23.5315 18.0153 23.8667C15.6887 24.2668 13.4322 23.7464 12.4003 22.571C12.2965 22.4478 12.1484 22.3862 12.0003 22.3861C11.8522 22.3862 11.7041 22.4478 11.6003 22.571C10.5684 23.7464 8.31187 24.2668 5.98527 23.8667C4.03583 23.5315 2.01415 22.2078 1.00026 20.5C0.771292 20.1143 1.11266 19.743 1.5366 19.757C3.24374 19.8962 4.18175 19.1309 5.13815 18.3506C5.74693 17.8538 6.50029 17 7.50029 17C8.81063 16.9999 10.4704 17.9444 11.5497 19.5899Z"
              fill="url(#paint2_linear_1441_5226)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1441_5226"
              x1="1.04554e-08"
              y1="7.09089"
              x2="20.4395"
              y2="18.6856"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7A0F9D" />
              <stop offset="1" stopColor="#EF2489" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1441_5226"
              x1="1.04554e-08"
              y1="7.09089"
              x2="20.4395"
              y2="18.6856"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7A0F9D" />
              <stop offset="1" stopColor="#EF2489" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1441_5226"
              x1="1.00024"
              y1="20.3091"
              x2="12.0482"
              y2="32.6196"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7A0F9D" />
              <stop offset="1" stopColor="#EF2489" />
            </linearGradient>
            <clipPath id="clip0_1441_5226">
              <rect width="50" height="50" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
      <SentenceCycler sentences={sentences} />
      {/* <TypeAnimation
        sequence={[
          'Always use JOINs instead of subqueries.',
          1000,
          'Index foreign keys for faster queries.',
          1000,
          'Normalize data to reduce redundancy.',
          1000,
          'Use aliases to clarify query structure.',
          1000,
          'Prefer WHERE over HAVING for performance.',
          1000,
          'Utilize EXPLAIN PLAN for query optimization.',
          1000,
          'Batch INSERTs to improve performance.',
          1000,
          'Use LIMIT to test query results.',
          1000,
          'Avoid SELECT * for better efficiency.',
          1000,
          'Regularly update statistics for optimizer.',
          1000,
          'Choose the right data type.',
          1000,
          'Implement proper indexing strategies.',
          1000,
          'Opt for stored procedures for complexity.',
          1000,
          'Be cautious with NULL values.',
          1000,
          'Use transactions for data integrity.',
          1000,
          'Prefer INNER JOIN over OUTER JOIN.',
          1000,
          'Use temp tables for large datasets.',
          1000,
          'Optimize LIKE queries with indexes.',
          1000,
          'Avoid functions on indexed columns.',
          1000,
          'Keep SQL scripts clear and readable.',
          1000
        ]}
        speed={60}
        style={{ fontSize: '2em' }}
        repeat={Infinity}
        deletionSpeed={80}
      /> */}
    </Box>
  );
};
interface SentenceCyclerProps {
  sentences: string[];
}
const SentenceCycler: React.FC<SentenceCyclerProps> = ({ sentences }) => {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const getRandomIndex = (currentIndex: number): number => {
    if (sentences.length <= 1) {
      return 0;
    }

    let newIndex = currentIndex;
    while (newIndex === currentIndex) {
      newIndex = Math.floor(Math.random() * sentences.length);
    }
    return newIndex;
  };

  useEffect(() => {
    const fadeOutTimeout = setTimeout(() => {
      setFade(false);
    }, 3000);

    const changeSentenceTimeout = setTimeout(() => {
      setIndex(prevIndex => getRandomIndex(prevIndex));
      setFade(true);
    }, 3500);

    return () => {
      clearTimeout(fadeOutTimeout);
      clearTimeout(changeSentenceTimeout);
    };
  }, [index, sentences.length]);

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      sx={{
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="body1">Pro SQL Tip: </Typography>
      <Fade in={fade} timeout={500}>
        <Box fontFamily={'Roboto, Helvetica, Arial, sans-serif'}>
          {sentences[index]}
        </Box>
      </Fade>
    </Box>
  );
};
export default WatsonAnimate;
