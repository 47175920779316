import React from 'react';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { queriedByListContainerStyle } from '../styles';
import SherloqChip from '../../../atoms/SherloqChip';
import { FilterLabel } from './filterLabel';

interface TagsFilterProps {
  value: any[];
  onChange?: any;
  onClickSelectedValueChip?: any;
  options: any[];
}

export const TagsFilter = ({
  value,
  onChange,
  onClickSelectedValueChip,
  options
}: TagsFilterProps) => {
  return (
    <Box className="filter-item-container">
      <FilterLabel>Tags</FilterLabel>
      <Select
        size="small"
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        multiple
        value={value}
        sx={{ flex: 1 }}
        onChange={onChange}
        renderValue={selectedTags => (
          <div style={queriedByListContainerStyle}>
            {(selectedTags as any[]).map(tag => (
              <SherloqChip
                onClick={() => onClickSelectedValueChip?.(tag)}
                key={tag.name}
                content={tag.name}
              />
            ))}
          </div>
        )}
      >
        {options?.map(tag => (
          <MenuItem key={tag.name} value={tag} style={{ height: '25px' }}>
            <Typography variant="caption">{tag.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
