import './index.scss';
import React, { useState } from 'react';
import { sx } from '../../styles/MUI/styles';
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@mui/material';
import { ModalRoot } from './root';
import { ModalTypography } from './components/label';

interface QueryFeedbackModalProps {
  show?: boolean;
  setClose?: () => void;
  handlerSubmit?: ({}, text: string) => void;
}

const QueryFeedbackModal = ({
  show,
  setClose,
  handlerSubmit
}: QueryFeedbackModalProps) => {
  const [checkboxValues, setCheckboxValues] = useState({
    tables: false,
    joins: false,
    filters: false,
    synatx: false
  });
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.value]: event.target.checked
    });
  };
  const [text, setText] = useState('');

  const radioSize = { '& .MuiSvgIcon-root': { fontSize: '14px' } };
  return (
    <ModalRoot open={show} onClose={setClose} variant="default">
      <ModalTypography>{'Give us feedback! What was wrong?'}</ModalTypography>
      <Box className="content">
        <FormGroup
          onChange={handleCheckboxChange}
          aria-labelledby="demo-radio-buttons-group-label"
        >
          <FormControlLabel
            value="tables"
            control={<Checkbox sx={radioSize} />}
            label={
              <Typography variant="body2" color="GrayText">
                Incorrect tables
              </Typography>
            }
          />
          <FormControlLabel
            value="joins"
            control={<Checkbox sx={radioSize} />}
            label={
              <Typography variant="body2" color="GrayText">
                Incorrect join
              </Typography>
            }
          />
          <FormControlLabel
            value="filters"
            control={<Checkbox sx={radioSize} />}
            label={
              <Typography variant="body2" color="GrayText">
                Missing / wrong filters statements
              </Typography>
            }
          />
          <FormControlLabel
            value="syntax"
            control={<Checkbox sx={radioSize} />}
            label={
              <Typography variant="body2" color="GrayText">
                Incorrect SQL syntax
              </Typography>
            }
          />
        </FormGroup>
        <TextField
          inputProps={{
            style: { fontSize: 12, width: '450px' }
          }}
          fullWidth
          value={text}
          onChange={e => setText(e.target.value)}
          multiline={true}
          rows={4}
        />
      </Box>
      <br />
      <Box className="buttons-container">
        <Button
          sx={sx.btn}
          variant="contained"
          onClick={() => handlerSubmit(checkboxValues, text)}
          className="default-button"
        >
          Send
        </Button>
      </Box>
    </ModalRoot>
  );
};

export default QueryFeedbackModal;
