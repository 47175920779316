import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { PlatformIcon } from '../../../components/Icons/PlatformIcon';
import { UTCtoLocal } from '../helpers/helpers';
import { editorFooterContainer } from '../styles';
import { RenderIf } from '../../../lib/utils';

interface EditorFooterData {
  origin: string;
  username: string;
  createdAt: string;
}

interface EditorFooterProps extends StackProps {
  data: EditorFooterData;
}

export const EditorFooter = ({
  data,
  style = {},
  ...props
}: EditorFooterProps) => {
  const dataExists = Boolean(data.createdAt && data.username);
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      style={{ ...editorFooterContainer, ...style }}
      {...props}
    >
      <PlatformIcon origin={data?.origin as string} size="16px" />
      <Typography
        style={{ fontWeight: 300 }}
        variant="caption"
        color="text.secondary"
      >
        Created by
      </Typography>
      {RenderIf(
        dataExists,
        <Typography
          style={{ fontWeight: 300 }}
          variant="caption"
          color="text.primary"
        >
          {data.username} {'  '}
          {UTCtoLocal(data?.createdAt || '')}
        </Typography>
      )}
    </Stack>
  );
};
