import React from 'react';
import { Box } from '@mui/material';

import { QueriedByFilter } from './QueriedByFilter';
import { TextFieldFilter } from './TextFieldFilter';

export const TableFilters = ({
  selectedTable,
  handleTableSelection,
  selectedField,
  handleFieldSelection,
  selectedSchema,
  handleSchemaSelection,
  selectedQueriedBy,
  handleQueriedBySelection,
  selectedDashboardName,
  handleDashboardNameSelection,
  isDisabled
}) => {
  return (
    <Box>
      <TextFieldFilter
        selectedValue={selectedTable}
        onSelectionChange={handleTableSelection}
        isDisabled={isDisabled}
        type={'table'}
        label={'Table Name:'}
        subLabel={'Full or partial table name'}
      />
      <TextFieldFilter
        selectedValue={selectedField}
        onSelectionChange={handleFieldSelection}
        isDisabled={isDisabled}
        type={'field'}
        label={'Field in the table'}
        subLabel={'Full or partial field name'}
      />
      <TextFieldFilter
        selectedValue={selectedSchema}
        onSelectionChange={handleSchemaSelection}
        isDisabled={isDisabled}
        type={'schema'}
        label={'Schema Name:'}
        subLabel={'Full or partial schema name'}
        marginRight={'10px'}
      />
      <TextFieldFilter
        selectedValue={selectedQueriedBy}
        onSelectionChange={handleQueriedBySelection}
        isDisabled={isDisabled}
        type={'user'}
        label={'Queried by users'}
        subLabel={'In the last 30 days'}
      />
      <TextFieldFilter
        selectedValue={selectedDashboardName}
        onSelectionChange={handleDashboardNameSelection}
        isDisabled={isDisabled}
        type={'dashboard'}
        label={'In dashboard:'}
        marginRight={'50px'}
      />
    </Box>
  );
};
