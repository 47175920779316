import { useState } from 'react';
import { ProfileMetaModal } from '.';
import { PopoverProps, TextFieldProps } from '@mui/material';
import { DateInput, EditProfileMetaPopoverContentBox, Label } from './styled';
import { Logger } from '../../../../../utils/browser/logger';
import { toast } from 'react-toastify';

interface Props extends PopoverProps {
  label: string;
  data: string;
  submit: (data: any) => void;
}

const Content = (props: TextFieldProps) => {
  return (
    <EditProfileMetaPopoverContentBox>
      <Label>{props.label}</Label>
      <DateInput {...props} />
    </EditProfileMetaPopoverContentBox>
  );
};

export const EditdDate = (props: Props) => {
  const [date, setDate] = useState(props?.data ?? '');
  Logger.info(props?.data);
  const handleSubmit = (ev: any) => {
    props.submit(date);
  };
  const handleDateInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    Logger.info({ value });
    setDate(value);
  };

  return (
    <ProfileMetaModal
      {...props}
      contentNode={<Content onChange={handleDateInputChange} value={date} />}
      submit={handleSubmit}
    />
  );
};
