import { EditProfileMetaPopoverContentBox, Label, TextInput } from './styled';
import { PopoverProps, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { ProfileMetaModal } from '.';

const Content = (props: TextFieldProps) => {
  return (
    <EditProfileMetaPopoverContentBox>
      <Label>Table owner</Label>
      <TextInput {...props} />
    </EditProfileMetaPopoverContentBox>
  );
};

interface Props extends PopoverProps {
  submit: (data: unknown) => void;
  data: string;
}
export const EditOwner = (props: Props) => {
  const [value, setValue] = useState(props?.data ?? '');

  const handleSubmit = (ev: any) => {
    props.submit(value);
  };
  const handleDateInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  return (
    <ProfileMetaModal
      {...props}
      contentNode={<Content onChange={handleDateInputChange} value={value} />}
      submit={handleSubmit}
    />
  );
};
