import React from 'react';
import { Typography } from '@mui/material';

export const EmptyResulsts = () => {
  return (
    <Typography
      component={'p'}
      variant="caption"
      color="text.primary"
      sx={{ textAlign: 'center', marginTop: '24px' }}
    >
      No results.
    </Typography>
  );
};
