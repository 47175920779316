import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { defaultTheme } from './default.theme';

const darkMode = createTheme({
  palette: {
    mode: 'dark',
    grey: {
      '100': '#555555',
      '200': '#424242',
      '300': '#3b3b3b'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)'
    },
    primary: {
      main: 'rgba(0, 117, 231, 1)',
      dark: 'rgba(3, 105, 204, 1)',
      light: 'rgba(0, 117, 231, 0.5)',
      '200': 'rgba(0, 117, 231, 0.2)',
      '100': 'rgba(0, 117, 231, 0.1)'
    },
    secondary: {
      main: '#9c27b0',
      dark: '#7b1fa2',
      light: '#e1bee7'
    },
    error: {
      main: '#f44336',
      dark: '#ba000d',
      light: '#e57373'
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d'
    },
    success: {
      main: '#4caf50',
      dark: '#388e3c',
      light: '#81c784'
    },
    background: {
      default: '#303030',
      paper: '#424242',
      paperPopup: '#555555',
      paperSurface: '#333333',
      surfaceOverlay: '#444444',
      surfaceOverlaySurface: '#222222'
    },
    input: {
      background: '#333333',
      border: '#555555',
      focused: 'rgba(0, 117, 231, 1)'
    }
  }
});

export const darkModeTheme = createTheme(deepmerge(defaultTheme, darkMode));
