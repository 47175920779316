import {
  AiChatDataStoreTypedDispatch,
  AiSetupConfig,
  IAiChatConfig,
  IAiChatSetupController
} from '../types';
import { AiBotDataStoreActions } from '../context/stateManagement/actions';

export function createUserAiChatSetupController(
  dispatch: AiChatDataStoreTypedDispatch
): IAiChatSetupController {
  const setUserConfig = (config: AiSetupConfig) => {
    dispatch({ type: AiBotDataStoreActions.SetUserConfig, payload: config });
  };

  const stopBotInitialLoad = () => {
    dispatch({ type: AiBotDataStoreActions.SetLoading, payload: false });
  };

  const triggerBotInitialLoad = () => {
    dispatch({ type: AiBotDataStoreActions.SetLoading, payload: true });
  };

  return {
    setUserConfig,
    stopBotInitialLoad,
    triggerBotInitialLoad
  };
}
