import { default as MUIPopover, PopoverProps } from '@mui/material/Popover';
import React from 'react';

export const Popover: React.FC<PopoverProps> = ({ style, ...props }) => {
    return (
        <MUIPopover
            style={{
                zIndex: 99999999,
                width: '400px',
                height: '482px',
                ...style,
            }}
            {...props}
        />
    );
};
