import { styled, Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => {
  const {
    background: { paper },
    primary: { main }
  } = theme.palette;
  const border = (theme.palette as any).input.border;
  return {
    position: 'relative',
    paddingBottom: '8px',
    margin: '8px 0px',
    boxSizing: 'border-box',
    backgroundColor: paper,
    border: `1px solid ${border}`,
    borderRadius: '3px',
    borderLeft: `4px solid ${main}`,
    padding: '8px',
    transition: 'all 0.7s ease-in-out 0.1s',
    cursor: 'pointer'
  };
});
