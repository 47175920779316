import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../store';
import { queryFolderContentThunks } from '../../../store/queryFolderContent/thunk';
import { newQueryFolderContentThunks } from '../../../store/newFolderContent/thunk';
export interface MoveQueryHookParams {
  onCompleteMove?: () => void;
}
export const useMoveQuery = ({ onCompleteMove }: MoveQueryHookParams) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<string | undefined>();
  const toggleModal = () => setOpenModal(!openModal);
  const onSelectFolder = (uid: string) => setSelectedFolder(uid);
  const moveQuery = (
    selectedFolder: string,
    queryId: string,
    currentFolderId: string,
    queryName: string
  ) => {
    toast('Move query', { isLoading: true });
    if (!selectedFolder) {
      toast('Please select a folder', { type: 'error' });
      return;
    }
    dispatch(
      queryFolderContentThunks.move({
        query_id: queryId,
        new_folder_id: selectedFolder,
        parentId: queryName //TODO AE this will break, the line was "query_name: queryName," which should also break
      })
    ).then(() => {
      dispatch(newQueryFolderContentThunks.index({ uid: currentFolderId }));
      dispatch(newQueryFolderContentThunks.index({ uid: selectedFolder }));
      onCompleteMove?.();
      if (openModal) {
        toggleModal();
      }
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    });
    setOpenModal(false);
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };
  return {
    openModal,
    toggleModal,
    moveQuery,
    selectedFolder,
    onSelectFolder
  };
};
