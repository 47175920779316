import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../lib/utils/apiClient';
import { FolderSummary } from './type';
import { getShortcuts } from '../../pages/Editor/api/fetch';

const index = createAsyncThunk(
  'query-folders/summary',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await ApiClient.get<FolderSummary[]>(
        `/query-folders/v2/summary`,
        {
          isProtected: true
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

const shortcuts = createAsyncThunk(
  'query-folders/summary-shortcuts',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getShortcuts()
      return fulfillWithValue(response.data.shortcuts);
    } catch (error) {
      return rejectWithValue('Error occurred');
    }
  }
);

export const folderSummaryThunk = { index, shortcuts };
