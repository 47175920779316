import { setRepositoryDisplayMode } from '.';
import { useAppDispatch, useTypedSelector } from '..';
import { RepositoryDisplayModeType } from './type';

export const useAppRepositoryDisplayMode = (): [
  RepositoryDisplayModeType,
  (mode: RepositoryDisplayModeType) => void
] => {
  const state = useTypedSelector(
    ({ sherloqAppConfig }) => sherloqAppConfig.repositoryDisplayMode
  );
  const dispatch = useAppDispatch();
  const setState = (mode: RepositoryDisplayModeType) => {
    dispatch(setRepositoryDisplayMode(mode));
  };
  return [state, setState];
};
