import React from 'react';
import { Button as MUIButton, ButtonProps, styled } from '@mui/material';

const Button = styled(MUIButton)(() => ({
  position: 'relative'
}));

export const ShwoMoreButton = (props: ButtonProps) => {
  return <Button {...props} />;
};
