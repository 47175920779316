import { useContext } from 'react';
import { AiBotAbstractActionsContext } from '../context/defs';

/**
 *
 * @deprecated
 * Use useAiBotActions
 */
export const useSendBotMessage = () => {
  const botActions = useContext(AiBotAbstractActionsContext);
  const fn = (message: string) => {
    botActions.sendMessage(message);
  };
  return fn;
};
