import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';

const initialState: any = {
  settings: {
    displayCoPilotSuggestions: 'both',
    displayValues: 'yes',
    displayPartitionFields: 'both'
  }
};

const index = createSlice({
  name: 'User_Settings',
  initialState,
  reducers: {
    setSettings: (state, { payload }: PA<string>) => {
      state.settings = payload;
    }
  }
});

export const { setSettings } = index.actions;

export default index.reducer;
