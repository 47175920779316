import React from 'react';
import {
  styled,
  Box,
  Typography,
  Select,
  SelectProps,
  TableContainer,
  Button,
  ButtonProps
} from '@mui/material';

export const DashboardFilterBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  };
});

export const DashboardFilterTextBox = styled(Box)(() => {
  return {
    lineHeight: 'normal',
    marginRight: '36px',
    marginBottom: '40px'
  };
});

export const DashboardFilterText = styled(Typography)(() => {
  return {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 18,
    marginBottom: 0
  };
});

export const DashboardSelect = styled((props: SelectProps) => (
  <Select {...props} />
))(() => {
  return {
    height: '40px',
    '& .MuiSelect-select': {
      fontSize: '0.875rem',
      textTransform: 'capitalize'
    }
  };
});

export const QueriedAtFilterTextBox = styled(Box)(() => {
  return {
    lineHeight: 'normal',
    marginRight: '25px',
    marginBottom: '40px'
  };
});