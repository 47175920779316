import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Typography, ButtonProps } from '@mui/material';
import { GenerateAiDescriptionBtn } from './styled';

export const GenerateAiDescriptionButton = (props: ButtonProps) => {
  return (
    <GenerateAiDescriptionBtn {...props}>
      <AutoAwesomeIcon color="inherit" fontSize="inherit" />
      <Typography color="inherit" fontSize="inherit">
        Generate AI-description
      </Typography>
    </GenerateAiDescriptionBtn>
  );
};
