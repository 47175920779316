import React from 'react';
import AppRoutes from './routes';
import { Box } from '@mui/material';
import { useAppDispatch } from './store';
import SideBarFrame from './components/frame';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useToggleDockPosition, useUiState } from './context/hooks';
import ErrorBoundary from './lib/components/ErrorBoundary';
import MixedPanelEvent from './utils/analytics/mixPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import { setOrigin } from './store/userOrigin';
import { ThemeProvider } from '@mui/material/styles';
import { saveAsActions } from './store/saveAs';
import { toggleDarkMode } from './store/themeMode';
import { isDatagrip } from './utils/checkOrigin';
import CssBaseline from '@mui/material/CssBaseline';
import useSherloqTheme from './styles/useSherloqTheme';
import { useInitUserAuthState } from './hooks/auth/useInitUserAuthState';
import PageLoader from './lib/components/PageLoader';

const App = () => {
  const dispatch = useAppDispatch();
  const { framePosition: position } = useUiState();
  const { loading } = useInitUserAuthState();
  const toggleDock = useToggleDockPosition()!;
  const location = useLocation();
  const theme = useSherloqTheme();
  const toastPosition = position == 'left' ? 'top-left' : 'top-right';
  const navigate = useNavigate();

  const handleIframeMessage = (e: any) => {
    const cmd = e.data.sherloq_command_to_iframe;
    if (cmd) {
      if (cmd.exec === 'saveAs') {
        dispatch(saveAsActions.initPayload(cmd.payload));
      } else if (cmd.redirectTo === 'watson') {
        navigate('/watson');
      }
      if (cmd.DockPosition === 'left' || cmd.DockPosition === 'right') {
        toggleDock(cmd.DockPosition);
      }
      if (cmd.SourceUrl) {
        MixedPanelEvent.setExtCurrentUrl(cmd.SourceUrl);
      }
    }
  };

  React.useEffect(() => {
    if (location.pathname != '/wordpess_iframe') {
      window.addEventListener('message', handleIframeMessage, false);
      MixedPanelEvent.panelOpen();
    }
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const origin = urlParams.get('origin');
    const runningDatagrip = isDatagrip();
    window.Appcues.page();
    if (origin) {
      dispatch(setOrigin(origin));
      if (origin === 'datagrip' || runningDatagrip) {
        dispatch(toggleDarkMode(true));
      }
    }
  }, [dispatch, location]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box id="sherloq_root">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position={toastPosition}
          autoClose={2000}
          theme="dark"
        />
        <SideBarFrame open={true}>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </SideBarFrame>
      </ThemeProvider>
    </Box>
  );
};

export default App;

const resetLogs = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    window.console.log = _ => {};
    window.console.error = _ => {};
    window.console.warn = _ => {};
  }
};
const init = async () => {
  resetLogs();

  await MixedPanelEvent.init();
};
(async () => {
  await init();
})();
