import React, { useEffect, useState } from 'react';
import {
  Menu,
  Box,
  ButtonProps,
  TypographyProps,
  BoxProps
} from '@mui/material';
import { selectFolderFromFoldersByUUID } from '../../utils/helpers';
import { RenderIf } from '../../lib/utils';
import { DropdownMenuListItem } from './components/menuListItem';
import {
  dropdownMenuPaperStyle,
  selectedFolderTextDefaultStyles
} from './styles';
import { SelectedDropdownItem } from './components/selectedFolder';
import { TreeDropdownContext } from './context';
import { FolderSummary } from '../../store/folderSummary/type';
import { Typography } from '../../atoms/Typography';

interface Props {
  onChange: (folder: FolderSummary) => void;
  folders: FolderSummary[];
  showFolderCheckbox?: boolean;
  enableFolderSelected?: boolean;
  selected?: string;
  buttonProps?: Omit<ButtonProps, 'onClick' | 'variant'>;
  textProps?: Omit<TypographyProps, 'onClick' | 'variant'>;
  labelProps?: Omit<TypographyProps, 'onClick' | 'variant'>;
  label?: string;
  placeholder?: string;
  selectDefaultOnMount?: boolean;
  selectedValueIconStyles?: any;
  containerProps?: BoxProps;
}

const selectFolderFromFolders = (folders, def) => {
  const folder = selectFolderFromFoldersByUUID(def, folders);
  return folder || folders.find((folder: any) => folder.name === 'Default');
};

export const SelectFolderDropdown: React.FC<Props> = ({
  onChange,
  label,
  selected,
  buttonProps,
  textProps,
  placeholder,
  selectedValueIconStyles,
  folders,
  containerProps,
  labelProps,
}) => {
  const [selectedFolder, setSelectedFolder] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const selectLabel =
    label === undefined ? 'Select Folder' : !label ? null : label; // to allow backward compatibility with where label is not called but Select Folder is required to show

  const handleItemClick = (folder: FolderSummary) => {
    setSelectedFolder(folder);
    setAnchorEl(null);
    onChange(folder);
  };

  const handleOpenDropdown: onClickHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    let folder;
    if (selected) {
      folder = selectFolderFromFolders(folders, selected);
    } else {
      folder = folders.find(folder => folder.name === 'Default');
    }
    onChange(folder);
    setSelectedFolder(folder);
  }, []);

  return (
    <TreeDropdownContext.Provider value={handleItemClick}>
      <Box {...containerProps} className="sherloq-save-query-select-folder">
        {RenderIf(
          !!selectLabel,
          <Typography {...labelProps} variant="caption">
            Select Folder
          </Typography>
        )}
        <div>
          <SelectedDropdownItem
            onClick={handleOpenDropdown}
            id="subfolders-menu"
            aria-controls={!!anchorEl ? 'basic-menu' : undefined}
            aria-expanded={!!anchorEl ? 'true' : undefined}
            placeholder={placeholder}
            data={selectedFolder}
            {...buttonProps}
            textProps={{
              sx: selectedFolderTextDefaultStyles,
              noWrap: true,
              ...textProps
            }}
            iconStyles={selectedValueIconStyles}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'subfolders-menu'
            }}
            PaperProps={{
              style: dropdownMenuPaperStyle
            }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            {folders?.map(folder => {
              return (
                <DropdownMenuListItem
                  parentMenuOpen={!!anchorEl}
                  key={folder.uid}
                  data={folder}
                />
              );
            })}
          </Menu>
        </div>
      </Box>
    </TreeDropdownContext.Provider>
  );
};
