import React from 'react';
import { useAppRepositoryDisplayMode } from '../../store/sherloqAppConfig/selector';
import { RepositoryDisplayModeType } from '../../store/sherloqAppConfig/type';
import { RepoDisplayToggleIconBtn, RepositoryStatesBtnsBox } from './styled';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { CustomTooltipWithWrapper } from '../../atoms/CustomTooltip/withWrapper';
import MixedPanelEvent from '../../utils/analytics/mixPanel';

export default function () {
  const [displayMode, setDisplayMode] = useAppRepositoryDisplayMode();

  const toggleDisplayMode = (mode: RepositoryDisplayModeType) => {
    setDisplayMode(mode);
    MixedPanelEvent.displayViewToggleClick({ displayView: mode });
  };

  return (
    <RepositoryStatesBtnsBox>
      <CustomTooltipWithWrapper title="Thumbnail View" placement="top">
        <RepoDisplayToggleIconBtn
          onClick={() => toggleDisplayMode('thumbnail')}
          selected={displayMode === 'thumbnail'}
        >
          <ViewDayIcon sx={{ fontSize: '18px' }} />
        </RepoDisplayToggleIconBtn>
      </CustomTooltipWithWrapper>
      <CustomTooltipWithWrapper title="List view" placement="top">
        <RepoDisplayToggleIconBtn
          onClick={() => toggleDisplayMode('list')}
          selected={displayMode === 'list'}
        >
          <FormatListBulletedOutlinedIcon sx={{ fontSize: '18px' }} />
        </RepoDisplayToggleIconBtn>
      </CustomTooltipWithWrapper>
    </RepositoryStatesBtnsBox>
  );
}
