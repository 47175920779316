import {
  styled,
  Popover,
  Button,
  ButtonProps,
  TypographyProps,
  Typography,
  TextField,
  TextFieldProps
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export const EditProfileMetaPopoverBox = styled(Popover)(() => {
  return {};
});

export const EditProfileMetaPopoverWrapper = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 12px',
    minWidth: '350px'
  };
});

export const EditProfileMetaPopoverContentBox = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column'
  };
});

export const EditProfileMetaPopoverInputActionsBox = styled('div')(() => {
  return {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: '8px',
    marginTop: '12px'
  };
});

export const CancelBtn = styled((props: ButtonProps) => (
  <Button {...props} variant="outlined" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    borderColor: grey['400'],
    color: grey['800']
  };
});

export const SaveBtn = styled((props: ButtonProps) => (
  <Button {...props} variant="contained" />
))(({ theme }) => {
  const {
    primary: { light, main },
    text
  } = theme.palette;

  return {
    backgroundColor: light,
    color: main,
    ':hover': {
      backgroundColor: main,
      color: text.darkBgPrimary
    }
  };
});

export const CloseIcon = styled(CancelIcon)(({ theme }) => {
  const { grey } = theme.palette;
  return {
    color: grey['600'],
    position: 'absolute',
    top: '8px',
    right: '8px'
  };
});

export const Label = styled((props: TypographyProps) => (
  <Typography {...props} variant="caption" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    color: grey['700']
  };
});

export const DateInput = styled((props: TextFieldProps) => (
  <TextField {...props} type="date" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    border: `1px solid ${grey['300']}`,
    borderRadius: '6px'
  };
});
export const TextInput = styled((props: TextFieldProps) => (
  <TextField {...props} type="email" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    border: `1px solid ${grey['300']}`,
    borderRadius: '6px'
  };
});

export const NumberInput = styled((props: TextFieldProps) => (
  <TextField {...props} type="number" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    borderRadius: '6px',
    width: '100px',
    input: {
      height: '10px'
    }
  };
});

export const ModalHeader = styled((props: TypographyProps) => (
  <Typography {...props} variant="body2" />
))(({ theme }) => {
  const { grey } = theme.palette;
  return {
    color: grey['700'],
    fontWeight: 500,
    marginBottom: '8px'
  };
});

export const UpdateFrequencyContentBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px'
  };
});

export const Row = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center'
  };
});
