import ApiClient from '../../../lib/utils/apiClient';
import { AiBotVariant } from '../../domain/chatTypes';
import {
  AiBotTypeApiService,
  Gpt4MessagePayload,
  SendSpt4MessagePayload
} from '../../types';

function createMessagePaylod(
  message: Gpt4MessagePayload
): SendSpt4MessagePayload {
  return {
    chatType: AiBotVariant.GPT4,
    isBuiltPrompt: false,
    message: message.message,
    source: 'sherloq',
    uid: message.conversationId,
    userCompany: message.company
  };
}

async function sendMessage(message: Gpt4MessagePayload) {
  const payload = createMessagePaylod(message);
  const res = await ApiClient.post('/ai/watson', payload, {
    isProtected: true
  });
  return res.data as any;
}

export const Gpt4ApiService: AiBotTypeApiService<Gpt4MessagePayload> =
  Object.freeze({
    sendMessage
  });
