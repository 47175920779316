import React from 'react';
import NestedMenuItem, { NestedMenuItemProps } from '../NestedMenu';

interface ListItemProps extends NestedMenuItemProps {
  data: any;
}

export const DropdownMenuListItem = ({ data, ...props }: ListItemProps) => {
  return (
    <NestedMenuItem
      {...props}
      label={data?.name}
      value={data?.uid}
      parentMenuOpen={props?.parentMenuOpen}
      data={data}
    >
      {data?.sub_folders?.map(subFolder => {
        return (
          <DropdownMenuListItem
            key={subFolder?.uid}
            data={subFolder}
            parentMenuOpen={props.parentMenuOpen}
          />
        );
      })}
    </NestedMenuItem>
  );
};
