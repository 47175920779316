import React from 'react';

import { useDarkModeState } from '../../store/themeMode/selectors';

const CloseIcon = () => {
    const darkMode = useDarkModeState();
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={darkMode ? "white" : 'none'} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.773 8.28766L8.28777 16.7729C7.99785 17.0629 7.51702 17.0629 7.22711 16.7729C6.93719 16.483 6.93719 16.0022 7.22711 15.7123L15.7124 7.227C16.0023 6.93709 16.4831 6.93709 16.773 7.227C17.063 7.51692 17.063 7.99775 16.773 8.28766Z"
                fill={darkMode ? "white" : '#252525'}
            />
            <path
                d="M16.773 16.773C16.4831 17.0629 16.0023 17.0629 15.7124 16.773L7.22711 8.28772C6.93719 7.9978 6.93719 7.51697 7.22711 7.22706C7.51702 6.93714 7.99785 6.93714 8.28777 7.22706L16.773 15.7123C17.063 16.0023 17.063 16.4831 16.773 16.773Z"
                fill={darkMode ? "white" : '#252525'}
            />
        </svg>
    );
};

export default CloseIcon;
