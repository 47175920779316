import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ConditionComponent } from '../../lib/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentCopyRounded } from '@mui/icons-material';
import { formatSql } from '../../utils/formaters';
const QueryModalButtons = ({
  handlePrevious,
  handleNext,
  currentIndex,
  data,
  showCopySuccess,
  handleCopy
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      <ConditionComponent condition={data && data.length > 1}>
        <IconButton onClick={handlePrevious}>
          <ArrowBackIosIcon />
        </IconButton>

        <Typography>{`${currentIndex + 1} / ${data.length}`}</Typography>

        <IconButton onClick={handleNext}>
          <ArrowForwardIosIcon />
        </IconButton>
      </ConditionComponent>
      <CopyToClipboard
        text={formatSql(data && data.length > 0 && data[currentIndex].query)}
        onCopy={() => handleCopy()}
      >
        <Button variant="contained">
          <ConditionComponent condition={showCopySuccess}>
            <CheckIcon
              sx={{
                fontSize: '16px',
                visibility: `${showCopySuccess ? 'visible' : 'hidden'}`,
                marginRight: '2px'
              }}
            />
          </ConditionComponent>
          <ConditionComponent condition={!showCopySuccess}>
            <ContentCopyRounded
              style={{ fontSize: '16px', cursor: 'pointer' }}
              sx={{ marginRight: '2px' }}
            />
          </ConditionComponent>

          <Typography
            fontSize={'14px'}
            fontFamily={'Roboto, Helvetica, Arial, sans-serif'}
          >
            {showCopySuccess ? 'Copied' : 'Copy'}
          </Typography>
        </Button>
      </CopyToClipboard>
    </Box>
  );
};

export default QueryModalButtons;
