import React from 'react';

interface PrestoIconProps {
  size: string;
}

const PrestoIcon = ({ size }: PrestoIconProps) => {
  return (
    <svg
      height={size}
      viewBox="0 0 256 256"
      version="1.1"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <rect
          fill="#000000"
          x="0"
          y="0"
          width="256"
          height="256"
          rx="18"
        ></rect>
        <g transform="translate(25.000000, 40.000000)">
          <path
            d="M205.118,88.531 C205.118,95.578 199.405,101.291 192.357,101.291 C185.31,101.291 179.597,95.578 179.597,88.531 C179.597,81.483 185.31,75.77 192.357,75.77 C199.405,75.77 205.118,81.483 205.118,88.531"
            fill="#669CFE"
          ></path>
          <path
            d="M178.806,114.531 C178.806,121.578 173.093,127.291 166.045,127.291 C158.997,127.291 153.284,121.578 153.284,114.531 C153.284,107.483 158.997,101.77 166.045,101.77 C173.093,101.77 178.806,107.483 178.806,114.531"
            fill="#3FE7FE"
          ></path>
          <path
            d="M152.451,141.072 C152.451,148.12 146.738,153.833 139.691,153.833 C132.643,153.833 126.93,148.12 126.93,141.072 C126.93,134.025 132.643,128.312 139.691,128.312 C146.738,128.312 152.451,134.025 152.451,141.072"
            fill="#FFFFFF"
          ></path>
          <path
            d="M177.993,62.336 C177.993,68.933 172.645,74.28 166.049,74.28 C159.452,74.28 154.104,68.933 154.104,62.336 C154.104,55.739 159.452,50.391 166.049,50.391 C172.645,50.391 177.993,55.739 177.993,62.336"
            fill="#669CFE"
          ></path>
          <path
            d="M151.738,88.298 C151.738,94.895 146.39,100.243 139.794,100.243 C133.197,100.243 127.849,94.895 127.849,88.298 C127.849,81.701 133.197,76.353 139.794,76.353 C146.39,76.353 151.738,81.701 151.738,88.298"
            fill="#3FE7FE"
          ></path>
          <path
            d="M125.569,114.642 C125.569,121.239 120.222,126.587 113.625,126.587 C107.028,126.587 101.68,121.239 101.68,114.642 C101.68,108.045 107.028,102.698 113.625,102.698 C120.222,102.698 125.569,108.045 125.569,114.642"
            fill="#FFFFFF"
          ></path>
          <path
            d="M150.743,36.235 C150.743,42.226 145.886,47.084 139.894,47.084 C133.902,47.084 129.045,42.226 129.045,36.235 C129.045,30.243 133.902,25.386 139.894,25.386 C145.886,25.386 150.743,30.243 150.743,36.235"
            fill="#669CFE"
          ></path>
          <path
            d="M124.522,62.191 C124.522,68.182 119.664,73.04 113.672,73.04 C107.681,73.04 102.823,68.182 102.823,62.191 C102.823,56.199 107.681,51.341 113.672,51.341 C119.664,51.341 124.522,56.199 124.522,62.191"
            fill="#3FE7FE"
          ></path>
          <path
            d="M98.253,88.618 C98.253,94.61 93.396,99.467 87.404,99.467 C81.412,99.467 76.555,94.61 76.555,88.618 C76.555,82.627 81.412,77.769 87.404,77.769 C93.396,77.769 98.253,82.627 98.253,88.618"
            fill="#FFFFFF"
          ></path>
          <path
            d="M123.618,10.008 C123.618,15.394 119.252,19.76 113.866,19.76 C108.48,19.76 104.114,15.394 104.114,10.008 C104.114,4.621 108.48,0.255 113.866,0.255 C119.252,0.255 123.618,4.621 123.618,10.008"
            fill="#669CFE"
          ></path>
          <path
            d="M97.297,36.089 C97.297,41.475 92.931,45.842 87.545,45.842 C82.159,45.842 77.793,41.475 77.793,36.089 C77.793,30.703 82.159,26.337 87.545,26.337 C92.931,26.337 97.297,30.703 97.297,36.089"
            fill="#3FE7FE"
          ></path>
          <path
            d="M71.06,62.471 C71.06,67.857 66.693,72.223 61.307,72.223 C55.921,72.223 51.555,67.857 51.555,62.471 C51.555,57.085 55.921,52.718 61.307,52.718 C66.693,52.718 71.06,57.085 71.06,62.471"
            fill="#FFFFFF"
          ></path>
          <path
            d="M70.06,114.721 C70.06,119.508 66.178,123.389 61.391,123.389 C56.603,123.389 52.722,119.508 52.722,114.721 C52.722,109.933 56.603,106.052 61.391,106.052 C66.178,106.052 70.06,109.933 70.06,114.721"
            fill="#6A9EFE"
          ></path>
          <path
            d="M96.185,140.846 C96.185,145.633 92.303,149.514 87.516,149.514 C82.728,149.514 78.847,145.633 78.847,140.846 C78.847,136.058 82.728,132.177 87.516,132.177 C92.303,132.177 96.185,136.058 96.185,140.846"
            fill="#6A9EFE"
          ></path>
          <path
            d="M122.268,166.929 C122.268,171.717 118.387,175.598 113.599,175.598 C108.811,175.598 104.93,171.717 104.93,166.929 C104.93,162.141 108.811,158.26 113.599,158.26 C118.387,158.26 122.268,162.141 122.268,166.929"
            fill="#6A9EFE"
          ></path>
          <path
            d="M70.185,166.929 C70.185,171.717 66.303,175.598 61.516,175.598 C56.728,175.598 52.847,171.717 52.847,166.929 C52.847,162.141 56.728,158.26 61.516,158.26 C66.303,158.26 70.185,162.141 70.185,166.929"
            fill="#6A9EFE"
          ></path>
          <path
            d="M17.601,166.929 C17.601,171.717 13.72,175.598 8.932,175.598 C4.145,175.598 0.263,171.717 0.263,166.929 C0.263,162.141 4.145,158.26 8.932,158.26 C13.72,158.26 17.601,162.141 17.601,166.929"
            fill="#6A9EFE"
          ></path>
          <path
            d="M43.726,140.804 C43.726,145.592 39.845,149.473 35.057,149.473 C30.27,149.473 26.388,145.592 26.388,140.804 C26.388,136.016 30.27,132.135 35.057,132.135 C39.845,132.135 43.726,136.016 43.726,140.804"
            fill="#6A9EFE"
          ></path>
          <path
            d="M70.172,10.032 C70.172,15.035 66.116,19.091 61.113,19.091 C56.11,19.091 52.054,15.035 52.054,10.032 C52.054,5.028 56.11,0.972 61.113,0.972 C66.116,0.972 70.172,5.028 70.172,10.032"
            fill="#3FE7FE"
          ></path>
          <path
            d="M43.799,36.538 C43.799,41.542 39.743,45.598 34.739,45.598 C29.736,45.598 25.68,41.542 25.68,36.538 C25.68,31.535 29.736,27.479 34.739,27.479 C39.743,27.479 43.799,31.535 43.799,36.538"
            fill="#FFFFFF"
          ></path>
          <path
            d="M16.174,9.663 C16.174,13.7 12.901,16.973 8.864,16.973 C4.828,16.973 1.555,13.7 1.555,9.663 C1.555,5.627 4.828,2.354 8.864,2.354 C12.901,2.354 16.174,5.627 16.174,9.663"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default PrestoIcon;
