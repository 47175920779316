import { selectFolderButtonStyles } from '../../pages/Editor/styles';
import { sx } from '../../styles/MUI/styles';

const addFolderDropdownFolderTextStyles = {
  ...sx.sx1,
  maxWidth: '150px',
  fontSize: '12px'
};

const addFolderSubFolderSelectionContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  columnGap: '20px'
};

const addFolderDropdownContainerStyles = {
  display: 'inline'
};

const addFolderDropdownButtonStyles = {
  ...selectFolderButtonStyles,
  paddingLeft: '8px',
  padding: '4px'
};

const selectFolderDropdownContainerStyle = {
  marginTop: '8px',
  display: 'block'
};

const checkBoxContainerStyle = {
  marginLeft: '-8px',
  display: 'flex',
  alignItems: 'center'
};

const selectFolderDropdownComponent = {
  border: '1px solid #00000030',
  padding: '4px',
  marginTop: '8px'
};

export {
  addFolderDropdownFolderTextStyles,
  addFolderSubFolderSelectionContainerStyles,
  addFolderDropdownContainerStyles,
  addFolderDropdownButtonStyles,
  selectFolderDropdownContainerStyle,
  checkBoxContainerStyle,
  selectFolderDropdownComponent
};
